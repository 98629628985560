import ColumnChart from "../../../../../common/components/charts/ColumnChart";
import ChartWrapperCard from "../../../../../common/components/charts/ChartWrapperCard";
import React, { useState } from "react";
import ChartsDemoTable, { ChartsTableDemoDataType } from "./ChartsDemoTable";
import { Card, Typography } from "antd";
import colorList from "../../../../../common/components/lists/chartColorList";

const ColumnChartDemo: React.FC = () => {
  const [dataSource, setDataSource] = useState<ChartsTableDemoDataType[]>([]);

  return (
    <div className={`grid desktop:grid-cols-2 gap-1`}>
      <Card title={`Column Chart Builder`}>
        <ChartsDemoTable
          dataSource={dataSource}
          onDataSourceChange={setDataSource}
        />
      </Card>
      <ChartWrapperCard
        title={`Column Chart`}
        extraTop={<Typography.Text code>ColumnChart.tsx</Typography.Text>}
      >
        <ColumnChart
          dataSource={dataSource.map((item, i) => ({
            value: item.value,
            xField: item.name,
            valueTitle: item.valueTitle,
            color: colorList[i],
          }))}
        />
      </ChartWrapperCard>
    </div>
  );
};

export default ColumnChartDemo;
