/**
 * @generated SignedSource<<650e1be144e0313cec6975e1a3659dd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_certification_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SFUpdateCertsDataTotalRefetchableQuery$variables = {
  where: worker_certification_bool_exp;
};
export type SFUpdateCertsDataTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SFUpdateCertsData_total">;
};
export type SFUpdateCertsDataTotalRefetchableQuery = {
  response: SFUpdateCertsDataTotalRefetchableQuery$data;
  variables: SFUpdateCertsDataTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SFUpdateCertsDataTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SFUpdateCertsData_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SFUpdateCertsDataTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allSubcontractorsToAddConnection",
        "args": (v1/*: any*/),
        "concreteType": "worker_certificationConnection",
        "kind": "LinkedField",
        "name": "worker_certification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_certification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f545de832d61488beecd6207becd3e6",
    "id": null,
    "metadata": {},
    "name": "SFUpdateCertsDataTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query SFUpdateCertsDataTotalRefetchableQuery(\n  $where: worker_certification_bool_exp!\n) {\n  ...SFUpdateCertsData_total_3FC4Qo\n}\n\nfragment SFUpdateCertsData_total_3FC4Qo on query_root {\n  allSubcontractorsToAddConnection: worker_certification_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "600e21fef95e55a6867d72d9319ea4ff";

export default node;
