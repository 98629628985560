import {
  Alert,
  Button,
  Card,
  DatePicker,
  Image,
  message,
  notification,
} from "antd";
import Meta from "antd/lib/card/Meta";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import StyledContent from "src/common/components/layouts/StyledContent";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { VerifyDrugtestQuery } from "src/common/types/generated/relay/VerifyDrugtestQuery.graphql";
import ProfilPicButton from "./components/ProfilePicButton";
import RejectAndArchiveButtons from "./components/RejectAndArchiveButtons";
import { VerifyDrugtestUpsertWorkerOrientationMutation } from "src/common/types/generated/relay/VerifyDrugtestUpsertWorkerOrientationMutation.graphql";
import * as uuid from "uuid";
import CustomButton from "src/common/components/general/button/Button";

interface VerifyDrugtestProps {
  certId: string;
  workerId: string;
  loggedInUserId: string;
  projectId?: string | null;
}

const query = graphql`
  query VerifyDrugtestQuery(
    $where: certificates_to_verify_bool_exp!
    $certId: uuid!
    $userOrientationWhere: user_orientation_bool_exp!
  ) {
    user_orientation_connection(where: $userOrientationWhere, first: 1) {
      edges {
        node {
          drug_test_id
        }
      }
    }
    worker_drug_test_connection(where: { verification_id: { _eq: $certId } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    certificates_to_verify_connection(where: $where) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          worker_id
          worker {
            subcontractor_id
            user {
              name
            }
          }
          uploaded_by_uid
          uploaded_by_user {
            role
            name
          }
          front_image_id
          front_image {
            url
            md_url
            lg_url
          }
          back_image_id
          back_image {
            url
            md_url
            lg_url
          }
          project_id
          project {
            name
            drugtest_validity_days
          }
        }
      }
    }
    reason_to_reject_connection(order_by: { reason: asc }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          reason
          type
        }
      }
    }
  }
`;

export const upsertWorkerOrientationMutation = graphql`
  mutation VerifyDrugtestUpsertWorkerOrientationMutation(
    $imageIds: [uuid!]!
    $imageSet: image_set_input!
    $userOrientationObjects: [user_orientation_insert_input!]!
    $certToVerifyWhere: certificates_to_verify_bool_exp!
    $certToVerifySet: certificates_to_verify_set_input
    $workerDTObjects: [worker_drug_test_insert_input!]!
    $workerDTSet: worker_drug_test_set_input!
    $workerDTWhere: worker_drug_test_bool_exp!
  ) {
    update_worker_drug_test(where: $workerDTWhere, _set: $workerDTSet) {
      affected_rows
      returning {
        id
        pk: id @__clientField(handle: "pk")
      }
    }
    update_certificates_to_verify(
      where: $certToVerifyWhere
      _set: $certToVerifySet
    ) {
      affected_rows
    }
    insert_worker_drug_test(objects: $workerDTObjects) {
      affected_rows
      returning {
        id
        pk: id @__clientField(handle: "pk")
      }
    }
    update_image(where: { id: { _in: $imageIds } }, _set: $imageSet) {
      affected_rows
    }
    insert_user_orientation(
      objects: $userOrientationObjects
      on_conflict: {
        constraint: user_orientation_user_id_project_id_key
        update_columns: [
          drug_test_id
          drug_test_updated_at
          drug_test_updated_by_user_id
        ]
      }
    ) {
      returning {
        id @__clientField(handle: "pk")
        project_id
        user_id
        drug_test_id
        drug_test_updated_at
        drug_test_updated_by_user_id
      }
    }
  }
`;

// FOR NOW
// 1. SFe only verifies drugtest that are submitted by SubAdmin but we still verify them and they are not project specific
// 2.and the ones submitted by Worker through Orientation screen, GC added drugtest are already verified
const VerifyDrugtest: FC<VerifyDrugtestProps> = ({
  certId,
  loggedInUserId,
  projectId,
  workerId,
}) => {
  const [result, setResult] = useState<"positive" | "negative" | undefined>(
    undefined,
  );
  const [testDate, setTestDate] = useState<Dayjs | undefined>(undefined);
  const data = useLazyLoadQuery<VerifyDrugtestQuery>(query, {
    certId,
    where: {
      id: { _eq: certId },
      verified_at: { _is_null: true },
      status: { _eq: "submitted" },
      document: { _eq: "drugtest" },
    },
    userOrientationWhere: projectId
      ? {
          project_id: { _eq: projectId },
          user_id: { _eq: workerId },
        }
      : { id: { _is_null: true } },
  });

  const navigate = useNavigate();
  const foundUploadedDT = data.worker_drug_test_connection.edges[0];

  const [upsertDrugTestInWorkerOrientation, isUpserting] =
    useAsyncMutation<VerifyDrugtestUpsertWorkerOrientationMutation>(
      upsertWorkerOrientationMutation,
    );

  if (data.certificates_to_verify_connection.edges.length < 1)
    return (
      <>This drugtest is already verified. Go back to verify another drugtest</>
    );
  const certToVerify = data.certificates_to_verify_connection.edges[0];
  const workerOrientation = data.user_orientation_connection.edges[0]?.node;
  const subId = certToVerify.node.worker.subcontractor_id;
  // projectId is null for when subadmin inserts the drugtest( for now) before mobile changes happen
  const onSubmit = async () => {
    try {
      if (!result) {
        message.error("select Drug Test Result");
        return;
      }
      if (!testDate) {
        message.error("select Drug Test Date");
        return;
      }
      if (!projectId) {
        message.warning(
          "This Drugtest was not for added for a particular Project",
        );
      }
      message.success("started Uploading");
      const validDrugtest =
        !!certToVerify.node.project?.drugtest_validity_days &&
        dayjs(testDate)
          .add(certToVerify.node.project?.drugtest_validity_days, "days")
          .endOf("day")
          .isAfter(dayjs());

      const dtId = uuid.v4();
      const toUpdateImageIds: string[] = [];
      if (certToVerify.node.front_image?.url) {
        toUpdateImageIds.push(certToVerify.node.front_image_id);
      }
      if (certToVerify.node.back_image_id) {
        toUpdateImageIds.push(certToVerify.node.back_image_id);
      }
      await upsertDrugTestInWorkerOrientation({
        variables: {
          imageIds: toUpdateImageIds,
          imageSet: foundUploadedDT
            ? { parent_id: foundUploadedDT.node.pk }
            : { parent_id: dtId },
          workerDTWhere: foundUploadedDT
            ? { verification_id: { _eq: certId } }
            : { id: { _is_null: true } },
          workerDTSet: {
            status: result,
            drug_test_date: dayjs(testDate).format(),
          },
          certToVerifyWhere: { id: { _eq: certId } },
          certToVerifySet: {
            verified_at: dayjs().format(),
            verified_by_uid: auth.currentUser?.uid,
          },
          userOrientationObjects:
            projectId &&
            validDrugtest &&
            !workerOrientation?.drug_test_id &&
            !foundUploadedDT
              ? [
                  {
                    project_id: projectId,
                    user_id: workerId,
                    drug_test_id: dtId,
                    drug_test_updated_at: dayjs().format(),
                    drug_test_updated_by_user_id: auth.currentUser?.uid,
                  },
                ]
              : [],
          workerDTObjects: foundUploadedDT
            ? []
            : [
                {
                  id: dtId,
                  project_id: projectId,
                  subcontractor_id: subId,
                  status: result,
                  worker_id: workerId,
                  drug_test_date: dayjs(testDate).format(),
                  entered_through_uid: certToVerify.node.uploaded_by_uid,
                  verification_id: certId,
                },
              ],
        },
      });
      if (!validDrugtest)
        message.warning(
          "This drugtest does not match the validity criteria for the project it was submitted, So it will not be used for that project",
        );
      message.success("Added Drugtest successfully");
      navigate(`/sfe/verify/drugtest`);
    } catch (err) {
      notification.error({
        message: "Add Drugtest Error",
        description: err instanceof Error ? err.message : JSON.stringify(err),
      });
    }
  };
  return (
    <StyledContent style={{ textAlign: "center" }}>
      <RejectAndArchiveButtons
        changeType="certificate"
        reasons={data.reason_to_reject_connection.edges}
        loggedInUserId={loggedInUserId}
        certId={certId}
        workerId={workerId}
        onSuccess={() => navigate(`/sfe/verify/drugtest`)}
      />
      <div>
        <span className="font-accent">
          {
            data.certificates_to_verify_connection.edges[0].node.worker.user
              .name
          }
        </span>{" "}
        uploaded this Drug Test result. Please validate it:
        <br /> - Confirm names match.
        <br /> - Did they Pass the drug test?
        <br /> - Enter the date of the test
      </div>
      <br />
      <div className="font-accent text-1.5 float-left">
        <Alert
          message="IF THE DOCUMENT UPLOADED INCLUDES PERSONAL MEDICAL INFORMATION PLEASE DO NOT VALIDATE TEST AND CONTACT YOUR DIRECTOR"
          type="warning"
          showIcon
        />
      </div>

      <div>
        <div className="w-6/12 inline-block mt-2 font-accent">
          Front
          <Card
            hoverable
            className="w-11/12 ml-1"
            cover={
              <div>
                <ProfilPicButton
                  workerId={certToVerify.node.worker_id}
                  imageId={certToVerify.node.back_image_id}
                  certId={certToVerify.node.pk}
                  loggedInUserId={loggedInUserId}
                  onSuccess={() => navigate(`/sfe/verify/drugtest`)}
                />
                <Image
                  src={certToVerify.node.front_image?.url}
                  alt="Front Image"
                  className="h-24"
                  preview={{
                    src:
                      certToVerify.node.front_image?.lg_url ??
                      certToVerify.node.front_image?.url,
                  }}
                />
              </div>
            }
          >
            <Meta
              title="Drug Test's front photo"
              description={`Image Id: ${certToVerify.node.front_image_id}`}
            />
          </Card>
        </div>

        {certToVerify.node.back_image?.url && (
          <div className="w-6/12 inline-block">
            Back
            <Card
              hoverable
              className="w-11/12"
              cover={
                <div>
                  <ProfilPicButton
                    workerId={certToVerify.node.worker_id}
                    imageId={certToVerify.node.back_image_id}
                    certId={certToVerify.node.pk}
                    loggedInUserId={loggedInUserId}
                    onSuccess={() => navigate(`/sfe/verify/drugtest`)}
                  />
                  <Image
                    src={certToVerify.node.back_image?.url}
                    alt="Back Image"
                    className="h-24"
                    preview={{
                      src:
                        certToVerify.node.back_image?.lg_url ??
                        certToVerify.node.back_image?.url,
                    }}
                  />
                </div>
              }
            >
              <Meta
                title="Drug Test's back photo"
                description={`Image Id: ${certToVerify.node.back_image_id}`}
              />
            </Card>
          </div>
        )}
        <br />
        <br />
        <div className="flex gap-1 justify-center items-center">
          <CustomButton
            loading={isUpserting}
            interactiveOnHover
            textColor="red"
            delete={result === "positive"}
            secondary={result !== "positive"}
            onClick={() => setResult("positive")}
            label="Positive"
          ></CustomButton>
          <CustomButton
            loading={isUpserting}
            interactiveOnHover
            textColor="green"
            green={result === "negative"}
            secondary={result !== "negative"}
            onClick={() => setResult("negative")}
            label="Negative"
          ></CustomButton>
        </div>
        <br />
        <br />
        <div>
          <DatePicker
            className="rounded-2 font-accent"
            size="large"
            onChange={(date) => {
              setTestDate(date);
            }}
          />
        </div>
        <br />
        <br />

        <Button
          className="mt-1 rounded-2 font-accent"
          loading={isUpserting}
          size="large"
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
        >
          Verify and Complete
        </Button>
        <br />
        <br />
        <Button
          className="mt-1 bg-white border-interactive-primary text-interactive-primary rounded-2 font-accent"
          size="large"
          loading={isUpserting}
          type="primary"
          onClick={() => {
            navigate("/sfe/verify/drugtest");
          }}
        >
          Complete without verifying
        </Button>
      </div>
    </StyledContent>
  );
};
const VerifyDrugtestWrapper: React.FunctionComponent = () => {
  const { certId, workerId } = useParams();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const loggedInUserId = auth.currentUser?.uid;
  return loggedInUserId && certId && workerId ? (
    <VerifyDrugtest
      certId={certId}
      loggedInUserId={loggedInUserId}
      projectId={projectId}
      workerId={workerId}
    />
  ) : (
    <Navigate to={`/sfe/verify/drugtest`} />
  );
};
export default VerifyDrugtestWrapper;
