import { useState } from "react";

// This hook is used to throw an error during render time.
// The problem:
// In some case, we want to catch an error and display a custom error fallback.
// However, we cannot do it directly if the error is thrown in the asynchronous code.

const useThrowRenderError = () => {
  const [, setErrorToThrow] = useState<(() => void) | null>(null);

  return (e?: unknown) => {
    setErrorToThrow(() => {
      throw e;
    });
  };
};

export default useThrowRenderError;
