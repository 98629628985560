import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, useEffect } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import Button from "src/common/components/general/button/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import { QrProjectQuery } from "src/common/types/generated/relay/QrProjectQuery.graphql";
import ProjectProps from "src/common/types/manual/ProjectProps";
import ProjectOverviewCard from "../components/ProjectOverviewCard";
import StyledContent from "src/common/components/layouts/StyledContent";
import CardWrapper from "../components/CardWrapper";
import { auth } from "src/common/functions/firebase";

const query = graphql`
  query QrProjectQuery($projectId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          general_contractors {
            general_contractor {
              name
            }
          }
          name
          address {
            city
            line_1
            line_2
            state_code
            zip_code
          }
        }
      }
    }
  }
`;
const QrProject: FC<ProjectProps> = ({ projectId }) => {
  const navigate = useNavigate();
  const data = useLazyLoadQuery<QrProjectQuery>(query, {
    projectId,
  }).project_connection.edges[0]?.node;
  const gcName = data.general_contractors
    .map((gc) => gc.general_contractor.name)
    .join(", ");

  const address = [
    data.address.line_1,
    data.address.line_2,
    data.address.city,
    data.address.state_code,
  ]
    .filter((str) => !!str?.length)
    .join(", ");
  const projectName = data.name;
  useEffect(() => {
    console.log(auth.currentUser?.uid);
    const getRole = async () => {
      const user = await auth.currentUser?.getIdTokenResult();

      if (user && user.claims.role === "pseudo_worker") {
        auth.signOut();
        console.log("Logged Out");
      }
    };
    getRole();
  }, []);
  return (
    // <StyledContent>
    // <div className="bg-white w-full h-full pl-32 pr-32">

    <MobileViewWrapper>
      <CardWrapper>
        <div className="justify-center h-full gap-1">
          <div className="container mx-auto mt-1">
            <div className="h-3 flex justify-center items-center text-center border-suplementary-2 border-0.125 text-1.25">
              {projectName}
            </div>

            <div className="p-1.5 border-0.125 border-t-0 border-suplementary-2 mb-1">
              <span className="text-lightgrey">General Contractor</span>
              <br />
              <span className="text-1.25 font-accent"> {gcName}</span>
              <br />
              <br />
              <span className="text-lightgrey">Address</span>
              <br />
              <span className="text-1.25 font-accent"> {address}</span>
            </div>

            <div className="flex flex-row justify-center items-center space-x-1">
              <div
                className=" h-5  w-1/2 flex justify-center items-center text-center text-1.25 p-1 border-0.125 border-suplementary-2  "
                onClick={() =>
                  navigate(
                    `/qr/p/${projectId}/permits/date/${dayjs().toISOString()}`,
                  )
                }
              >
                Permits
              </div>
              <div
                className="h-5 w-1/2 flex justify-center items-center text-center text-1.25 p-1 border-0.125 border-suplementary-2 "
                onClick={() =>
                  navigate(
                    `/qr/p/${projectId}/ptp/date/${dayjs().toISOString()}`,
                  )
                }
              >
                Pre-Task Plans
              </div>
            </div>

            <div className="flex space-x-1 mt-1">
              <div
                className=" h-5 w-1/2 flex justify-center items-center text-center text-1.25 p-1 border-0.125 border-suplementary-2  "
                onClick={() => navigate(`/covid/${projectId}/form`)}
              >
                Covid-19
              </div>
              <div
                className=" h-5 w-1/2 flex justify-center items-center text-center text-1.25 p-1 border-0.125 border-suplementary-2 "
                onClick={() => navigate(`/qr/p/${projectId}/sds`)}
              >
                SDS
              </div>
            </div>
          </div>
        </div>
      </CardWrapper>
    </MobileViewWrapper>

    // <MobileViewWrapper
    //   footer={
    //     <div className="flex flex-row items-center w-full gap-0.5">
    //       {[
    //         {
    //           label: "Permits",
    //           url: `/qr/p/${projectId}/permits/date/${moment().toISOString()}`,
    //         },
    //         {
    //           label: "PTP's",
    //           url: `/qr/p/${projectId}/ptp/date/${moment().toISOString()}`,
    //         },
    //         {
    //           label: "",
    //           url: `/qr/p/${projectId}/sds`,
    //         },
    //         {
    //           label: "Covid-19",
    //           url: `/covid/${projectId}/form`,
    //         },
    //       ].map((buttonProps) => (
    //         <Button
    //           key={buttonProps.label}
    //           label={buttonProps.label}
    //           onClick={() => {
    //             navigate(buttonProps.url);
    //           }}
    //           fullWidth
    //         />
    //       ))}
    //     </div>
    //   }
    // >
    //   {/* <ProjectOverviewCard
    //     gcTitle={gcName}
    //     projectAddress={address}
    //     projectTitle={projectName}
    //   /> */}

    //   <div className="container mx-auto mt-10">
    //     <div className="flex space-x-4">
    //       <div className="w-1/4 bg-gray-200 p-4"> Permits</div>
    //       <div className="w-1/4 bg-gray-200 p-4">PTPs</div>
    //       <div className="w-1/4 bg-gray-200 p-4">Covid-19</div>
    //       <div className="w-1/4 bg-gray-200 p-4">Logistic Plans</div>
    //     </div>
    //   </div>
    // </MobileViewWrapper>
  );
};
export default withCustomSuspense(QrProject);
