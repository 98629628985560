import { IconMail } from "@tabler/icons";
import { Button, Form, notification, Select } from "antd";
import React, { useState } from "react";
import * as uuid from "uuid";
import { graphql } from "babel-plugin-relay/macro";
import Icon, { IconType } from "./general/Icon";
import { useParams } from "react-router-dom";
import { auth } from "src/common/functions/firebase";
import { MorningManPowerReportEmailQuery } from "src/common/types/generated/relay/MorningManPowerReportEmailQuery.graphql";
import { useLazyLoadQuery } from "react-relay/hooks";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { MorningManPowerReportEmailMutation } from "src/common/types/generated/relay/MorningManPowerReportEmailMutation.graphql";
import { useEmailMorningManPowerReportMutation } from "../types/generated/apollo/graphQLTypes";
import { ConnectionHandler } from "relay-runtime";
import getNormalSelectOptionsFilter from "../functions/getNormalSelectOptionsFilter";
import compareStringsIgnoreCase from "../functions/compareStringsIgnoreCase";

interface MorningManPowerEmailProps {
  message?: string;
  icon?: IconType;
}

interface UserFormValues {
  userIds: Array<string>;
}

const query = graphql`
  query MorningManPowerReportEmailQuery(
    $projectId: uuid!
    $employee_id: uuid!
    $type: String!
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          project_employees {
            employee {
              id
              pk: id @__clientField(handle: "pk")
              user {
                id
                pk: id @__clientField(handle: "pk")
                name
                email
              }
            }
          }
        }
      }
    }
    email_project_employee_user_connection(
      first: 10000
      where: {
        project_id: { _eq: $projectId }
        employee_id: { _eq: $employee_id }
        type: { _eq: $type }
      }
    )
      @connection(
        key: "MorningManPowerReportEmailQuery_email_project_employee_user_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          project_id
          employee_id
          user {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
  }
`;

const morningManPowerReportEmailMutation = graphql`
  mutation MorningManPowerReportEmailMutation(
    $objects: [email_project_employee_user_insert_input!]!
    $where: email_project_employee_user_bool_exp!
  ) {
    insert_email_project_employee_user(objects: $objects) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
        user {
          id
          pk: id @__clientField(handle: "pk")
          name
          email
        }
      }
    }
    delete_email_project_employee_user(where: $where) {
      returning {
        id
      }
    }
  }
`;
const MorningManPowerEmail: React.FC<MorningManPowerEmailProps> = (props) => {
  const [form] = Form.useForm<UserFormValues>();
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("Project id not found");
  }
  const [loading, setLoading] = useState(false);
  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error("User not found");
  }
  const employeeIdLoggedIn = auth.currentUser?.uid;
  const data = useLazyLoadQuery<MorningManPowerReportEmailQuery>(
    query,
    {
      projectId: projectId,
      employee_id: auth.currentUser?.uid!,
      type: "morning_man_power_report",
    },
    {
      fetchPolicy: "store-and-network",
    },
  );
  let userEmail: string = "";
  let userName: string = "";
  data.project_connection.edges[0].node.project_employees.forEach((user) => {
    if (user.employee.user.pk == userId) {
      userEmail = user.employee.user.email!;
      userName = user.employee.user.name;
    }
  });
  if (userEmail == "") {
    throw new Error(" User Email not found");
  }
  const defaultUsers = data.email_project_employee_user_connection.edges.map(
    (user) => {
      return {
        userId: user.node.user?.pk,
        id: user.node.pk,
        name: user.node.user?.name,
      };
    },
  );
  if (!defaultUsers.find((user) => user.userId === userId)) {
    defaultUsers.push({
      userId: userId,
      id: userId,
      name: userName,
    });
  }
  const sortedOptions = data.project_connection.edges[0].node.project_employees
    .slice()
    .sort((a, b) => compareStringsIgnoreCase(a.employee.user.name, b.employee.user.name));

  const [morningManPowerReportMutation] =
    useAsyncMutation<MorningManPowerReportEmailMutation>(
      morningManPowerReportEmailMutation,
    );
  const [
    emailMorningManPowerReport,
    { loading: emailMorningManPowerReportLoading },
  ] = useEmailMorningManPowerReportMutation();
  const confirmUsers = async () => {
    const values = await form.validateFields().catch(v => null);
    if (!values)
        return;
    setLoading(true);
    try {
        const insertEmailProjectUsers = values.userIds
          .filter(
            (uid) => !(defaultUsers ?? []).find((user) => user.userId === uid),
          )
          .map((user) => {
            const id = uuid.v4();
            return {
              id: id,
              project_id: projectId,
              employee_id: employeeIdLoggedIn,
              user_id: user,
              type: "morning_man_power_report",
            };
          });
        const deleteEmailProjectUsers = (defaultUsers ?? [])
          .filter(
            (user) =>
              !values.userIds.find((u) => {
                return u === user.userId;
              }),
          )
          .map(user => user.id);
        await morningManPowerReportMutation({
          variables: {
            objects: insertEmailProjectUsers,
            where: {
              id: { _in: deleteEmailProjectUsers },
            },
          },
          updater: (store) => {
            const insertedEmailProjectUsers = store.getRootField(
              "insert_email_project_employee_user",
            );

            const deletedEmailProjectUser = store.getRootField(
              "delete_email_project_employee_user",
            );

            const conn = ConnectionHandler.getConnection(
              store.getRoot(),
              "MorningManPowerReportEmailQuery_email_project_employee_user_connection",
            );
            if (conn) {
              insertedEmailProjectUsers
                .getLinkedRecords("returning")
                .forEach((c) => {
                  const edge = store.create(uuid.v4(), "edge");
                  edge.setLinkedRecord(c, "node");
                  ConnectionHandler.insertEdgeAfter(conn, edge);
                });

              deletedEmailProjectUser
                .getLinkedRecords("returning")
                .forEach((c) => {
                  ConnectionHandler.deleteNode(conn, c.getDataID());
                });
            }
          },
        });
        form.resetFields();
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex-col items-start justify-between">
      <div className="text-1.25 mt-2">Email Now</div>

      <Form form={form}>
        <div className="text-1.25  mt-1 flex flex-col">
          <Form.Item
            name="userIds"
            label="GC User Selection"
            initialValue={(defaultUsers || []).map((user) => user.userId)}
          >
            <Select
              className="w-half flex flex-col"
              mode="multiple"
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              options={sortedOptions.map((employee) => ({
                value: employee.employee.user.pk,
                key: employee.employee.user.pk,
                label: employee.employee.user.name,
                disabled: employee.employee.user.pk === userId,
              }))}
            ></Select>
          </Form.Item>
        </div>
        <div className="ml-2 flex space-x-2" style={{ marginBottom: "20px" }}>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="rounded-2 font-accent flex"
            onClick={async () => {
              if (!employeeIdLoggedIn)
                throw new Error("employeeIdLoggedIn is null");
              if (!userEmail)
                throw new Error("userEmail is empty");
              await confirmUsers();
              await emailMorningManPowerReport({
                variables: {
                  input: {
                    projectId: projectId,
                    employeeId: employeeIdLoggedIn,
                    email: userEmail,
                  },
                },
              });
              notification.info({
                message: "Email Sent"
              });
            }}
            loading={emailMorningManPowerReportLoading}
          >
            Send Report
            <div className="ml-0.5">
              <Icon icon={IconMail} color="white" />
            </div>
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default MorningManPowerEmail;
