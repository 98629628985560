import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import DevelopmentPlaygroundNavigationTopBar from "./components/DevelopmentPlaygroundNavigationTopBar";
import DevelopmentPlaygroundNavigationMenu from "./components/DevelopmentPlaygroundNavigationMenu";

const { Content, Sider } = Layout;

const DevelopmentPlayground: React.FC = () => {
  return (
    <Layout hasSider>
      <div
        className={`desktop:block hidden sticky overflow-auto h-screen top-0 left-0`}
      >
        <Sider theme={"light"} className={`h-full`}>
          <DevelopmentPlaygroundNavigationMenu />
        </Sider>
      </div>
      <Content className={`w-full`}>
        <DevelopmentPlaygroundNavigationTopBar />
        <div className={`w-full p-1 desktop:p-3`}>
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
};

export default DevelopmentPlayground;
