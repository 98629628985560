import React, { useEffect, useState } from "react";
import { Button, Form, message, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import * as uuid from "uuid";
import { AddCompanyModal_Insert_OAC_Company_Mutation } from "src/common/types/generated/relay/AddCompanyModal_Insert_OAC_Company_Mutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import AddNewCompanyModal, {
  AddNewCompanyFormValues,
} from "./AddNewCompanyModal";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

interface FormValues {
  selectedCompanyIds: Array<string>;
}

interface User {
  name: string;
  email: string;
  id: string;
}

interface Props {
  projectId: string;
  visible: boolean;
  onDoneEditing: (values: FormValues) => Promise<void>;
  onCancel: () => void;
  selectedCompanyIds: Array<string>;
  allCompanyIds: Array<{ name: string; id: string }>;
}

const AddCompanyModal: React.FC<Props> = ({
  projectId,
  visible,
  onDoneEditing,
  onCancel,
  selectedCompanyIds,
  allCompanyIds,
}) => {
  const allUniqueCompanies = allCompanyIds.filter(
    (obj, index, self) =>
      index ===
      self.findIndex(
        (o) => o.name.toLowerCase().trim() === obj.name.toLowerCase().trim(),
      ),
  );

  const [allCompanies, setAllCompanies] =
    useState<Array<{ name: string; id: string }>>(allUniqueCompanies);
  const [insertOACCompany, insertingNewCompany] =
    useAsyncMutation<AddCompanyModal_Insert_OAC_Company_Mutation>(
      graphql`
        mutation AddCompanyModal_Insert_OAC_Company_Mutation(
          $name: String!
          $id: uuid!
        ) {
          insert_oac_company_one(object: { name: $name, id: $id }) {
            name
            pk: id @__clientField(handle: "pk")
          }
        }
      `,
    );
  const [form] = Form.useForm();
  const data = selectedCompanyIds;
  useEffect(() => {
    form.setFieldsValue({ selectedCompanyIds: selectedCompanyIds });
  }, [visible]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  const [showAddNewCompanyModal, setShowAddNewCompanyModal] = useState(false);
  return (
    <>
      <AddNewCompanyModal
        visible={showAddNewCompanyModal}
        onCancel={() => {
          setShowAddNewCompanyModal(false);
        }}
        onCreate={async (values: AddNewCompanyFormValues) => {
          setShowAddNewCompanyModal(false);
          const currentlySelectedCompanyIds: string[] =
            form.getFieldValue("selectedCompanyIds") ?? [];
          const duplicateIndex = allCompanies.findIndex(
            (item) =>
              item.name.toLowerCase().trim() ===
              values.companyName.toLowerCase().trim(),
          );
          if (duplicateIndex != -1) {
            const existingCompanyId = allCompanies[duplicateIndex].id;
            const newSelectedCompanyIds = currentlySelectedCompanyIds.filter(
              (companyId) => companyId != existingCompanyId,
            );
            newSelectedCompanyIds.push(existingCompanyId);
            form.setFieldsValue({
              selectedCompanyIds: newSelectedCompanyIds,
            });
            return;
          }
          const companyId = uuid.v4();
          await insertOACCompany({
            variables: {
              id: companyId,
              name: values.companyName,
            },
          });
          setAllCompanies((prev) => {
            return [...prev, {
              id: companyId,
              name: values.companyName,
            }];
          });
          const newSelectedCompanyIds = [
            ...currentlySelectedCompanyIds,
            companyId,
          ];
          form.setFieldsValue({
            selectedCompanyIds: newSelectedCompanyIds,
          });
        }}
      />
      <Modal
        open={visible}
        title="Add Owner, Architect, Engineer"
        okText="Done"
        cancelText="Cancel"
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "inline" } }}
        footer={
          <div className="flex flex-1 flex-row">
            <div className="flex flex-col items-start flex-1">
              <Button
                className="text-interactive-primary bg-none border-interactive-secondary hover:border-interactive-primary"
                loading={insertingNewCompany}
                onClick={() => {
                  setShowAddNewCompanyModal(true);
                }}
              >
                {"Add +"}
              </Button>
            </div>
            <Button onClick={handleCancel}>{"Cancel"}</Button>
            <Button
              className="text-white bg-interactive-primary border-interactive-primary ml-0"
              onClick={async () => {
                const values = await form.validateFields().catch(v => null);
                if (values) {
                  await onDoneEditing(values);
                }
              }}
            >
              {"Done"}
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public", companyIds: selectedCompanyIds }}
        >
          <Form.Item
            name="selectedCompanyIds"
            label="Select your Owner, Client, Architect, Engineers, Consultants, etc. that may complete orientation on your project"
          >
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              placeholder="Select companies"
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              options={allCompanies.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddCompanyModal;
