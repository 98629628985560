/**
 * @generated SignedSource<<24193b971a49cfa3ceab4249716ef081>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, toolbox_talk_bool_exp, toolbox_talk_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ToolboxTalkTableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  includeProject: boolean;
  includeSub: boolean;
  order_by: ReadonlyArray<toolbox_talk_order_by>;
  where: toolbox_talk_bool_exp;
};
export type ToolboxTalkTableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ToolboxTalkTable_toolbox_talks" | "ToolboxTalkTable_total">;
};
export type ToolboxTalkTableQuery = {
  response: ToolboxTalkTableQuery$data;
  variables: ToolboxTalkTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeProject"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeSub"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order_by"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v6 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v7 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v8 = {
  "kind": "Variable",
  "name": "order_by",
  "variableName": "order_by"
},
v9 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v10 = [
  (v9/*: any*/)
],
v11 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v12 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v14/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ToolboxTalkTableQuery",
    "selections": [
      {
        "args": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "Variable",
            "name": "includeProject",
            "variableName": "includeProject"
          },
          {
            "kind": "Variable",
            "name": "includeSub",
            "variableName": "includeSub"
          },
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ToolboxTalkTable_toolbox_talks"
      },
      {
        "args": (v10/*: any*/),
        "kind": "FragmentSpread",
        "name": "ToolboxTalkTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ToolboxTalkTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "toolbox_talkConnection",
        "kind": "LinkedField",
        "name": "toolbox_talk_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "toolbox_talkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "toolbox_talk",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "en",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "es",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "original",
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": "clientText",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "original",
                        "storageKey": null
                      },
                      {
                        "alias": "clientText",
                        "args": null,
                        "filters": null,
                        "handle": "clientText",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "original"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "includeProject",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "condition": "includeSub",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "toolbox_talk_project_worker_aggregate",
                    "kind": "LinkedField",
                    "name": "toolbox_talk_project_workers_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "toolbox_talk_project_worker_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "filters": [
          "where",
          "order_by"
        ],
        "handle": "connection",
        "key": "ToolboxTalkTable_toolbox_talk_connection",
        "kind": "LinkedHandle",
        "name": "toolbox_talk_connection"
      },
      {
        "alias": "allReportsConnection",
        "args": (v10/*: any*/),
        "concreteType": "toolbox_talkConnection",
        "kind": "LinkedField",
        "name": "toolbox_talk_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "toolbox_talkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "toolbox_talk",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06046f75b1ed273d779310792a803794",
    "id": null,
    "metadata": {},
    "name": "ToolboxTalkTableQuery",
    "operationKind": "query",
    "text": "query ToolboxTalkTableQuery(\n  $first: Int!\n  $after: String\n  $where: toolbox_talk_bool_exp!\n  $order_by: [toolbox_talk_order_by!]!\n  $includeSub: Boolean!\n  $includeProject: Boolean!\n) {\n  ...ToolboxTalkTable_toolbox_talks_1lAmFF\n  ...ToolboxTalkTable_total_3FC4Qo\n}\n\nfragment ToolboxTalkTable_toolbox_talks_1lAmFF on query_root {\n  toolbox_talk_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        pk: id\n        created_at\n        description {\n          id\n          en\n          es\n          pt\n          original\n          pk: id\n          clientText: original\n        }\n        project @include(if: $includeProject) {\n          name\n          id\n        }\n        subcontractor @include(if: $includeSub) {\n          name\n          id\n        }\n        toolbox_talk_project_workers_aggregate {\n          aggregate {\n            count\n          }\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ToolboxTalkTable_total_3FC4Qo on query_root {\n  allReportsConnection: toolbox_talk_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "76928ffb33a9dacc0e0ad5a0e94c4144";

export default node;
