import { IconBuilding, IconUser } from "@tabler/icons";
import { TableProps } from "antd";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import SelectSubcontractorModal, {
  SelectSubcontractorModalRef,
} from "src/common/components/dialogs/SelectSubcontractorModal";
import Button from "src/common/components/general/button/Button";
import LoadingContent from "src/common/components/general/LoadingContent";
import Switcher from "src/common/components/general/Switcher";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import StyledContent from "src/common/components/layouts/StyledContent";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import CustomTable, {
  CustomTableProps,
} from "src/common/components/tables/basic/CustomTable";
import {
  GetAdminProjectQuery,
  GetProjectLocationsQuery,
  useInsertProjectSubcontractorsMutation,
  useGetGcSubsQuery,
  GetAdminProjectDocument,
  useUpdateProjSubLocationMutation,
  useInsertProjSubLocationMutation,
  useUpdateProjectLocationMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import * as uuid from "uuid";
type ColumnsType = TableProps<
  GetAdminProjectQuery["project_subcontractor"][number]
>["columns"];

const ProjSubsTable: React.FC<{
  loading: boolean;
  projectId: string;
  locations: GetProjectLocationsQuery["project_location"];
  projSubs: GetAdminProjectQuery["project_subcontractor"];
}> = ({ loading, projSubs, projectId, locations }) => {
  const selectSubsModal = useRef<SelectSubcontractorModalRef>(null);
  const [insertProjectSubcontractors, { loading: insertingProjectSub }] =
    useInsertProjectSubcontractorsMutation();
  const navigate = useNavigate();
  const [updateProjSubLocation] = useUpdateProjSubLocationMutation();
  const [insertProjSubLocation] = useInsertProjSubLocationMutation();
  const {
    data: gcSubs,
    error,
    loading: gcSubsLoading,
  } = useGetGcSubsQuery({
    variables: {
      where: {
        _not: { subcontractor_projects: { project_id: { _eq: projectId } } },
      },
    },
  });
  const [updateProjectLocation] = useUpdateProjectLocationMutation();

  if (gcSubsLoading) return <LoadingContent />;
  if (error) throw error;
  const showLocationColumns = locations.length >= 2;
  const locationColumns: ColumnsType = showLocationColumns
    ? locations.map((location) => ({
        title: (
          <div>
            {location.name.en + (location.archived_at ? " (Archived)" : "")}
            <br />

            <Switcher
              optionIndex={location.default_payment_model === "sub" ? 1 : 0}
              options={[
                { icon: IconBuilding, label: "Project" },
                { icon: IconUser, label: "Sub" },
              ]}
              onChange={(optionIndex) => {
                const newModel = optionIndex ? "sub" : "gc";
                const set = { default_payment_model: newModel };
                updateProjectLocation({
                  variables: { id: location.id, _set: set },
                  optimisticResponse: {
                    update_project_location_by_pk: { ...location, ...set },
                  },
                });
                const allProjSubLocationIds: string[] = [];
                projSubs.forEach((ps) => {
                  const projSubLocId = ps.project_subcontractor_locations.find(
                    (psl) => psl.project_location_id === location.id,
                  )?.id;
                  if (projSubLocId) allProjSubLocationIds.push(projSubLocId);
                });
                updateProjSubLocation({
                  variables: {
                    where: { id: { _in: allProjSubLocationIds } },
                    _set: { billing_payment_model: newModel },
                  },
                  optimisticResponse: {
                    update_project_subcontractor_location: {
                      returning: allProjSubLocationIds.map((id) => ({
                        id,
                        __typename: "project_subcontractor_location",
                        billing_payment_model: newModel,
                      })),
                    },
                  },
                });
              }}
            />
          </div>
        ),
        dataIndex: [
          "project_subcontractor_locations",
          "0",
          "billing_payment_model",
        ],
        key: location.id,
        size: "ml",
        render: (_, row) => {
          const projSubLocation = row.project_subcontractor_locations.find(
            (p) => p.project_location_id === location.id,
          );
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <Switcher
                optionIndex={
                  (
                    projSubLocation
                      ? projSubLocation.billing_payment_model === "sub"
                      : location.default_payment_model === "sub"
                  )
                    ? 1
                    : 0
                }
                options={[
                  { icon: IconBuilding, label: "Project" },
                  { icon: IconUser, label: "Sub" },
                ]}
                onChange={(optionIndex) => {
                  const newModel = optionIndex === 1 ? "sub" : "gc";
                  console.log(row, ":");
                  if (!projSubLocation) {
                    const id = uuid.v4();
                    insertProjSubLocation({
                      variables: {
                        object: {
                          id,
                          project_id: projectId,
                          subcontractor_id: row.subcontractor.id,
                          project_location_id: location.id,
                          billing_payment_model: newModel,
                        },
                      },
                      optimisticResponse: {
                        insert_project_subcontractor_location_one: {
                          id: id,
                          __typename: "project_subcontractor_location",
                          project_location_id: location.id,
                          billing_payment_model: newModel,
                        },
                      },
                      update: (cache, returningData) => {
                        const newInsertedData =
                          returningData.data
                            ?.insert_project_subcontractor_location_one;
                        if (!newInsertedData)
                          throw new Error(
                            "Server returning null for inserting data",
                          );
                        cache.modify<typeof row>({
                          id: cache.identify(row),
                          fields: {
                            project_subcontractor_locations(
                              existingList = [],
                              { toReference },
                            ) {
                              const newSubLocationRef =
                                toReference(newInsertedData);
                              return newSubLocationRef
                                ? [newSubLocationRef]
                                : [];
                            },
                          },
                        });
                      },
                    });
                  } else {
                    updateProjSubLocation({
                      variables: {
                        where: { id: { _eq: projSubLocation.id } },
                        _set: { billing_payment_model: newModel },
                      },
                      optimisticResponse: {
                        update_project_subcontractor_location: {
                          returning: [
                            {
                              id: projSubLocation.id,
                              __typename: "project_subcontractor_location",
                              billing_payment_model: newModel,
                            },
                          ],
                        },
                      },
                    });
                  }
                }}
              />
            </div>
          );
        },
      }))
    : [];
  return (
    <div className="h-64">
      <BasicWrapper scrollable>
        <SelectSubcontractorModal
          ref={selectSubsModal}
          title={"Add Subs to Project"}
          subsOnProject={[]}
          subsUnderGc={gcSubs?.subcontractor || []}
          onSubmit={async (values) => {
            // TODO async
            insertProjectSubcontractors({
              awaitRefetchQueries: true,
              variables: {
                subcontractorObjects: values.subcontractorIds.map((sid) => ({
                  project_id: projectId,
                  subcontractor_id: sid,
                })),
                projectCrewObjects: [],
              },
              refetchQueries: [
                {
                  query: GetAdminProjectDocument,
                  variables: { projectId },
                },
              ],
            });
          }}
        />
        <BaseTable
          loading={loading}
          title={() => (
            <div className="flex flex-row justify-between">
              <div>
                Project Subcontractors{" "}
                {projSubs.length ? `(${projSubs.length})` : ""}
              </div>
              <Button
                {...{
                  label: "Add Subcontractors",
                  onClick: () => selectSubsModal.current?.open(),
                  loading: insertingProjectSub,
                }}
              />
            </div>
          )}
          dataSource={projSubs}
          pagination={false}
          columns={[
            {
              title: "Subcontractor Name",
              key: "name",
              dataIndex: ["subcontractor", "name"],
            },
            {
              title: "Trade",
              key: "trade",
              dataIndex: ["subcontractor", "trade", "name", "clientText"],
            },
            ...locationColumns,
          ]}
          onRow={(item) => ({
            onClick: () => {
              navigate(
                `/admin/data/projects/${projectId}/${item.subcontractor.id}`,
              );
            },
          })}
        />
      </BasicWrapper>
    </div>
  );
};
export default ProjSubsTable;
