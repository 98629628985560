import { Button, Card, Checkbox, Space } from "antd";
import { useRef, useState } from "react";
import clsx from "clsx";

/* What we test here:
We test if we can replace the video element url with another video url when the video ends, and make the next video play automatically.
The issue is related to IOS policy that prevents videos from playing automatically.
 */

/*
link with video placeholders:
https://gist.github.com/deepakpk009/99fd994da714996b296f11c3c371d5ee
 */

const videoUrlList = [
  `http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4`,
  `http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4`,
];

const VideoElementDemo: React.FC = () => {
  const [videoUrlIndex, setVideoUrlIndex] = useState<number>(0);
  const [showControls, setShowControls] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(true);
  const [playsInline, setPlaysInline] = useState<boolean>(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const setToNext = () => {
    setVideoUrlIndex((prev) => {
      if (prev === videoUrlList.length - 1) {
        return 0;
      }
      return prev + 1;
    });
  };

  const onEnded = () => {
    // 1. Hide for 3 seconds
    // 2. Change Video
    // 3. Show another video

    setShowVideo(false);
    setTimeout(() => {
      setToNext();
      setShowVideo(true);
    }, 3000);
  };

  const goToEnd = async () => {
    if (videoRef.current) {
      const duration = videoRef.current.duration;
      videoRef.current.currentTime = Math.max(duration - 2, 0);
    }
  };

  const play = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  const pause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <Card>
      <div className={`flex flex-col items-start gap-1`}>
        <Space>
          <Button onClick={goToEnd}>Go to End</Button>

          <Button onClick={play}>Play</Button>
          <Button onClick={pause}>Pause</Button>
        </Space>
        <Checkbox
          checked={showControls}
          onChange={(e) => {
            setShowControls(e.target.checked);
          }}
        >
          Show Controls
        </Checkbox>
        <Checkbox
          checked={playsInline}
          onChange={(e) => {
            setPlaysInline(e.target.checked);
          }}
        >
          Plays Inline
        </Checkbox>
        <div className={`relative w-full pb-[50%]`}>
          <video
            className={clsx(
              !showVideo && `hidden`,
              `absolute inset-0 w-full h-full object-cover bg-static-primary`,
            )}
            playsInline={playsInline}
            ref={videoRef}
            onEnded={onEnded}
            src={videoUrlList[videoUrlIndex]}
            controls={showControls}
          />
        </div>
      </div>
    </Card>
  );
};

export default VideoElementDemo;
