import React, { FC, useRef, useState } from "react";
import UploadSdsModal from "src/domain-features/sitesafety/safety-data-sheets/components/UploadSdsModal";
import CustomButton from "src/common/components/general/button/Button";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { VerifyAndAddSDSQuery } from "src/common/types/generated/relay/VerifyAndAddSDSQuery.graphql";
import StyledContent from "src/common/components/layouts/StyledContent";
import Item from "antd/lib/descriptions/Item";
import { Button, Descriptions, message, Popconfirm } from "antd";
import dayjs from "dayjs";
import { IconTrash } from "@tabler/icons";
import {
  useEmailRejectedSdsUploadRequestMutation,
  useEmailUserAfterSdsCreationCompletionMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { VerifyAndAddSDSMutation } from "src/common/types/generated/relay/VerifyAndAddSDSMutation.graphql";
import {
  VerifyAndAddSDS_Insert_SDS_Mutation,
  safety_data_sheet_insert_input,
} from "src/common/types/generated/relay/VerifyAndAddSDS_Insert_SDS_Mutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import SDSTable, {
  SDS,
} from "src/domain-features/sitesafety/safety-data-sheets/components/SdsTable";
import { useNavigate } from "react-router-dom";
import PdfViewer from "src/common/components/pdf/PdfViewer";
import { SdsTable_sds$data } from "src/common/types/generated/relay/SdsTable_sds.graphql";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import noPropagation from "src/common/functions/noPropagation";
import { VerifyAndAddSDS_UpdateSdsMutation } from "src/common/types/generated/relay/VerifyAndAddSDS_UpdateSdsMutation.graphql";
import { auth } from "src/common/functions/firebase";

const query = graphql`
  query VerifyAndAddSDSQuery($requestId: uuid!) {
    sds_request_connection(where: { id: { _eq: $requestId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          project_id
          subcontractor_id
          processed_at
          rejected_at
          requested_by_uid
          requested_by_user {
            name
            email
          }
          processed_by_user {
            name
          }

          rejected_by_user {
            name
          }

          requested_at
          project {
            name
            general_contractor_id
            general_contractor {
              name
            }
          }

          subcontractor {
            name
          }
          attached_files {
            id
            pk: id @__clientField(handle: "pk")
            url
            name
          }
        }
      }
    }
  }
`;

const updateSDSRequestMutation = graphql`
  mutation VerifyAndAddSDSMutation(
    $requestId: uuid!
    $_set: sds_request_set_input!
  ) {
    update_sds_request_by_pk(pk_columns: { id: $requestId }, _set: $_set) {
      id
    }
  }
`;

const insertSDSMutation = graphql`
  mutation VerifyAndAddSDS_Insert_SDS_Mutation(
    $objects: [safety_data_sheet_insert_input!]!
  ) {
    insert_safety_data_sheet(objects: $objects) {
      affected_rows
    }
  }
`;

const VerifyAndAddSDS: FC<{ requestId: string }> = ({ requestId }) => {
  const [emailRejectedSDSUploadRequest] =
    useEmailRejectedSdsUploadRequestMutation();

  const [emailSDSUpload] = useEmailUserAfterSdsCreationCompletionMutation();

  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfModal, setPdfModal] = useState(false);

  const navigate = useNavigate();

  const [sdsList, setSdsList] = useState<Array<safety_data_sheet_insert_input>>(
    [],
  );
  const data = useLazyLoadQuery<VerifyAndAddSDSQuery>(query, {
    requestId,
  });

  const [updateSds, updating] =
    useAsyncMutation<VerifyAndAddSDS_UpdateSdsMutation>(
      graphql`
        mutation VerifyAndAddSDS_UpdateSdsMutation(
          $_set: safety_data_sheet_set_input
          $where: safety_data_sheet_bool_exp!
        ) {
          update_safety_data_sheet(_set: $_set, where: $where) {
            affected_rows
          }
        }
      `,
    );
  const sdsTableRef = useRef<DataScrollTableRef>(null);
  const [insertSDS] =
    useAsyncMutation<VerifyAndAddSDS_Insert_SDS_Mutation>(insertSDSMutation);
  const [updateSDSRequest] = useAsyncMutation<VerifyAndAddSDSMutation>(
    updateSDSRequestMutation,
  );
  const [uploadSDSModalVisible, setUploadSDSModalVisble] = useState(false);
  const [isAddToExistingTableVisible, setIsAddToExistingTableVisible] =
    useState(false);
  const queryData = data.sds_request_connection.edges[0].node;
  const files = queryData.attached_files;

  const onDelete = async (
    sds: SdsTable_sds$data["safety_data_sheet_connection"]["edges"][number]["node"],
  ) => {
    await updateSds({
      variables: {
        _set: { deleted_at: dayjs().toISOString() },
        where: { id: { _eq: sds.pk } },
      },
    })
      .then(async (d) => {
        sdsTableRef.current?.refetch();
        message.success("Sds Deleted");
      })
      .catch((e) => console.log(e));
  };

  return (
    <StyledContent style={{ textAlign: "center", borderRadius: "20px" }}>
      {queryData.subcontractor_id && (
        <UploadSdsModal
          subcontractorId={queryData.subcontractor_id}
          projectId={queryData.project_id}
          requestId={requestId}
          onClose={() => setUploadSDSModalVisble(false)}
          onSubmit={async () => {
            if (isAddToExistingTableVisible) {
              await updateSDSRequest({
                variables: {
                  _set: {
                    rejected_at: null,
                    rejected_by_uid: null,
                    processed_at: dayjs().format(),
                    processed_by_uid: auth?.currentUser?.uid,
                  },
                  requestId: requestId,
                },
              });

              await emailSDSUpload({
                variables: {
                  input: {
                    requestId: requestId,
                  },
                },
              });
            }

            setUploadSDSModalVisble(false);
            sdsTableRef.current?.refetch();
          }}
          visible={uploadSDSModalVisible}
          setSdsList={isAddToExistingTableVisible ? undefined : setSdsList}
          uploadedByUserId={queryData.requested_by_uid}
        />
      )}
      <div className="pb-4">
        <Descriptions size="middle" bordered>
          <Item label="Request Id">{queryData.pk}</Item>
          {queryData.project && (
            <>
              <Item label="Project Name">{queryData.project?.name}</Item>
              <Item label="General Contractor">
                {queryData.project?.general_contractor.name}
              </Item>
            </>
          )}

          <Item label="Subcontractor">{queryData.subcontractor?.name}</Item>

          <Item label="Date Requested">
            {!!queryData.requested_at
              ? dayjs(queryData.requested_at).format("LLL")
              : ""}
          </Item>
          <Item label="Requested By">{queryData.requested_by_user?.name}</Item>

          {queryData.processed_at && (
            <>
              <Item label="Processed By">
                {queryData.processed_by_user?.name}
              </Item>

              <Item label="Processed at">
                {dayjs(queryData.processed_at).format("LLL")}
              </Item>
            </>
          )}

          <br />
        </Descriptions>

        {queryData.processed_at || queryData.rejected_at ? (
          <div className="mt-2">
            <PdfViewer
              visible={pdfModal}
              onClose={() => {
                setPdfModal(false);
              }}
              pdfUrl={pdfUrl}
            />

            <span className="float-left text-left mr-2 text-1.5 mb-1">
              {" "}
              Original File(s):
            </span>

            <div className="float-left">
              {files.map((file, index) => {
                return (
                  <div className="mt-0.5">
                    <CustomButton
                      label={file?.name ?? `File ${index + 1}`}
                      large
                      onClick={() => {
                        file?.url && downloadFromUrl(file.url, true);
                      }}
                      tiny
                    />
                    <br />
                  </div>
                );
              })}
            </div>

            <div className="float-right mr-2">
              <CustomButton
                large
                label="+ Add"
                onClick={() => {
                  setIsAddToExistingTableVisible(true);
                  setUploadSDSModalVisble(true);
                }}
              />
            </div>

            <SDSTable
              loadAll
              ref={sdsTableRef}
              title="SDS Added for the request"
              where={{
                request_id: { _eq: requestId },
                deleted_at: { _is_null: true },
              }}
              onSdsRowClick={(sds) => {
                setPdfUrl(sds.url);
                setPdfModal(true);
              }}
              excludedKeys={["projectName", "addToProjects", "select"]}
              loading
              extraColumns={[
                {
                  title: "",
                  dataIndex: [""],
                  size: "md",
                  key: "delete",
                  render: (_: any, sds: SDS) => (
                    <>
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={noPropagation(() => {
                          onDelete(sds);
                        })}
                        onCancel={noPropagation()}
                        okText="Yes"
                        cancelText="Cancel"
                      >
                        <Button danger type="link" onClick={noPropagation()}>
                          Delete
                        </Button>
                      </Popconfirm>
                    </>
                  ),
                },
              ]}
            />
          </div>
        ) : (
          <div className="flex flex-row items-center justify-center mt-2">
            <div className="flex flex-col items-center justify-center">
              <div>
                <div className="flex flex-col items-center justify-center h-fit mt-auto">
                  {files.map((file, index) => {
                    return (
                      <>
                        <CustomButton
                          key={index}
                          label={`Download File: ${file?.name} `}
                          large
                          onClick={() => {
                            file?.url && downloadFromUrl(file.url, true);
                          }}
                        />
                        <br />
                      </>
                    );
                  })}

                  <div className="mt-4 mb-4">
                    <CustomButton
                      label={"ADD AND PROCESS A SDS"}
                      large
                      onClick={() => setUploadSDSModalVisble(true)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-6/12 ml-4 flex-col items-center justify-center">
              <div className="mt-1 mb-2 text-1.25">
                Follow these steps to process SDS{" "}
              </div>
              <ul className="ml-5 list-decimal text-left">
                <li className="mt-0.5">
                  Download the first file (if there are multiple files added)
                </li>{" "}
                <li className="mt-0.5">
                  Check the pdf to see if there were multiple SDS included{" "}
                </li>{" "}
                <li className="mt-0.5">
                  If only one SDS then skip to Step 5, if multiple SDS then go
                  to Step 4
                </li>
                <li className="mt-0.5">
                  Split the pdf into individual SDS → there are several ways to
                  do this depending on your pdf program. You can delete all but
                  the first SDS or extract the different SDS into individual
                  documents. Once the file is split into the multiple documents,
                  go to next step.{" "}
                </li>
                <li className="mt-0.5">
                  Process each SDS by clicking ADD AND PROCESS A SDS{" "}
                </li>
                <li className="mt-0.5">Upload the single document SDS</li>
                <li className="mt-0.5">Enter ALL information</li>
                <li className="mt-0.5">
                  Confirm the correct file was uploaded
                </li>
                <li className="mt-0.5">Select ADD</li>
                <li className="mt-0.5">Repeat this for all SDS added</li>
                <li className="mt-0.5">
                  If you have any questions ask your supervisor for guidance{" "}
                </li>
              </ul>
              <div className="font-accent mt-1">
                Uploaded SDS list for this request
              </div>{" "}
              {sdsList.map((sds, i) => {
                return (
                  <div
                    key={i}
                    className="flex items-center justify-center space-x-2 mt-1"
                  >
                    <CustomButton
                      onClick={() => sds?.url && downloadFromUrl(sds.url, true)}
                      label={`file ${i + 1}`}
                      tiny
                    />
                    <CustomButton
                      icon={IconTrash}
                      onClick={() => {
                        const updatedSdsList = sdsList.filter(
                          (sds, ind) => ind !== i,
                        );
                        setSdsList(updatedSdsList);
                      }}
                      tiny
                    />
                  </div>
                );
              })}
              <div className="mt-2 flex items-center justify-center">
                {sdsList.length > 0 && (
                  <CustomButton
                    label={"Add the SDSs and complete"}
                    large
                    onClick={async () => {
                      try {
                        await insertSDS({
                          variables: {
                            objects: sdsList,
                          },
                        });

                        await updateSDSRequest({
                          variables: {
                            _set: {
                              processed_at: dayjs().format(),
                              processed_by_uid: auth?.currentUser?.uid,
                            },
                            requestId: requestId,
                          },
                        });

                        await emailSDSUpload({
                          variables: {
                            input: {
                              requestId: requestId,
                            },
                          },
                        });
                        message.success("SDS Uploaded Successfully");
                        navigate(-1);
                      } catch (err) {
                        message.error("Could not upload SDS" + err);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {!queryData.processed_at && !queryData.rejected_at && (
          <div className="float-right mb-1 mr-1">
            <Popconfirm
              title={
                "Are you sure? If you have doubts please check with your supervisor."
              }
              onConfirm={async () => {
                try {
                  await updateSDSRequest({
                    variables: {
                      _set: {
                        rejected_at: dayjs().format(),
                        rejected_by_uid: auth?.currentUser?.uid,
                      },
                      requestId: requestId,
                    },
                  });

                  await emailRejectedSDSUploadRequest({
                    variables: {
                      input: {
                        requestId: requestId,
                      },
                    },
                  });

                  message.success("SDS Upload Request rejected and Email sent");
                  navigate(-1);
                } catch (err) {
                  message.error("Error: Could not reject SDS");
                }
              }}
            >
              <CustomButton
                label={"Reject Request"}
                delete
                large
                onClick={() => console.log("")}
              />
            </Popconfirm>
          </div>
        )}
      </div>
    </StyledContent>
  );
};

export default VerifyAndAddSDS;
