/**
 * @generated SignedSource<<28d17d7450ad0f809c8a63d901bc1f97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { orientation_result_insert_input, user_orientation_insert_input, user_orientation_update_column } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompleteInPersonOrientation_MarkUserAsCompletedMutation$variables = {
  orientationObjects: ReadonlyArray<user_orientation_insert_input>;
  orientationResultObjects: ReadonlyArray<orientation_result_insert_input>;
  workerUpdateColumns: ReadonlyArray<user_orientation_update_column>;
};
export type CompleteInPersonOrientation_MarkUserAsCompletedMutation$data = {
  readonly insert_orientation_result: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_user_orientation: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly in_person_orientated_at: string | null | undefined;
      readonly project_id: string;
      readonly selected: boolean;
      readonly user_id: string;
    }>;
  } | null | undefined;
};
export type CompleteInPersonOrientation_MarkUserAsCompletedMutation = {
  response: CompleteInPersonOrientation_MarkUserAsCompletedMutation$data;
  variables: CompleteInPersonOrientation_MarkUserAsCompletedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orientationObjects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orientationResultObjects"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerUpdateColumns"
},
v3 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "orientationObjects"
  },
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "constraint",
        "value": "user_orientation_user_id_project_id_key"
      },
      {
        "kind": "Variable",
        "name": "update_columns",
        "variableName": "workerUpdateColumns"
      }
    ],
    "kind": "ObjectValue",
    "name": "on_conflict"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "in_person_orientated_at",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "selected",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "objects",
      "variableName": "orientationResultObjects"
    },
    {
      "kind": "Literal",
      "name": "on_conflict",
      "value": {
        "constraint": "orientation_result_pkey",
        "update_columns": [
          "total_slides",
          "viewed_slides",
          "signature_url",
          "status",
          "expired_at",
          "completed_at",
          "group_id",
          "result_inserted_by_uid"
        ]
      }
    }
  ],
  "concreteType": "orientation_result_mutation_response",
  "kind": "LinkedField",
  "name": "insert_orientation_result",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affected_rows",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteInPersonOrientation_MarkUserAsCompletedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user_orientation_mutation_response",
        "kind": "LinkedField",
        "name": "insert_user_orientation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_orientation",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": "id",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompleteInPersonOrientation_MarkUserAsCompletedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user_orientation_mutation_response",
        "kind": "LinkedField",
        "name": "insert_user_orientation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_orientation",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "9265167b3bb0be3c5ba8a4e73de54678",
    "id": null,
    "metadata": {},
    "name": "CompleteInPersonOrientation_MarkUserAsCompletedMutation",
    "operationKind": "mutation",
    "text": "mutation CompleteInPersonOrientation_MarkUserAsCompletedMutation(\n  $workerUpdateColumns: [user_orientation_update_column!]!\n  $orientationResultObjects: [orientation_result_insert_input!]!\n  $orientationObjects: [user_orientation_insert_input!]!\n) {\n  insert_user_orientation(objects: $orientationObjects, on_conflict: {constraint: user_orientation_user_id_project_id_key, update_columns: $workerUpdateColumns}) {\n    returning {\n      id\n      project_id\n      user_id\n      in_person_orientated_at\n      selected\n    }\n  }\n  insert_orientation_result(objects: $orientationResultObjects, on_conflict: {constraint: orientation_result_pkey, update_columns: [total_slides, viewed_slides, signature_url, status, expired_at, completed_at, group_id, result_inserted_by_uid]}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "22a17312847e7a1f63debf426bb98679";

export default node;
