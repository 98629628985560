import sendHotjarEvent from "../event-tracking/hotjar/sendHotjarEvent";
import * as Sentry from "@sentry/react";

const reportException = (exception: unknown) => {
  // report error to hotjar
  sendHotjarEvent("error");
  // report error to sentry
  Sentry.captureException(exception);
};

export default reportException;
