import React, { PropsWithChildren } from "react";
import { SiteOrientationStepProps } from "../../../utils/siteOrientationTypes";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import sendHotjarEvent from "src/utility-features/event-tracking/hotjar/sendHotjarEvent";
import { Button, Divider, Dropdown } from "antd";
import ButtonHuge from "../../../../../common/components/general/button/ButtonHuge";
import { DownOutlined, GlobalOutlined } from "@ant-design/icons";
import CountryFlag from "../../../../../common/components/CountryFlag";
import clsx from "clsx";
import { Language } from "../../../../../utility-features/i18n/language-utils/i18nTypes";
import getLanguageTitle from "../../../../../utility-features/i18n/language-utils/getLanguageTitle";
import { AVAILABLE_LANGUAGES } from "../../../../../utility-features/i18n/languages";
import useLangStrings, {
  useCurrentLangStrings,
  useCurrentLanguageState,
} from "../../../../../utility-features/i18n/context/languageHooks";

interface TranslatedButtonProps {
  lang: Language;
}

const TranslatedStartString: React.FC<TranslatedButtonProps> = (props) => {
  const langStrings = useLangStrings(props.lang);
  return <>{langStrings.strings.begin}</>;
};

export interface SiteOrientationRegistrationStepsHelloProps
  extends SiteOrientationStepProps {
  projectName?: string;
}

const SiteOrientationRegistrationStepsHello: React.FC<
  PropsWithChildren<SiteOrientationRegistrationStepsHelloProps>
> = (props) => {
  const [_, setCurrentLanguage] = useCurrentLanguageState();

  const startOrientation = (lang: Language) => {
    // Let hotjar know that the user has started the orientation
    sendHotjarEvent("orientation_started");
    // Begin orientation
    setCurrentLanguage(lang);
    props.onNext();
  };

  const langStrings = useCurrentLangStrings();
  const primaryLanguages = AVAILABLE_LANGUAGES.slice(0, 6);
  const secondaryLanguages = AVAILABLE_LANGUAGES.slice(6);

  return (
    <>
      <SiteFeatureStepLayout>
        <div className="flex flex-col gap-1 min-h-full justify-between">
          <div className={`flex flex-1 flex-col justify-center`}>
            <p className={`text-1.5 tablet-portrait:text-3 font-accent block`}>
              {props.projectName
                ? langStrings.strings.welcomeToProject(props.projectName)
                : ""}
            </p>
            {props.children}
            <Divider />
            <p className="">
              {langStrings.strings.chooseYourLanguageToBeginOrientation}
            </p>
          </div>

          <div className={`flex flex-col gap-0.25`}>
            {primaryLanguages.map((lang) => {
              const flagComponent =
                lang === "es" ? (
                  <CountryFlag countryCode={"mx"} svg />
                ) : lang === "pt" ? (
                  <CountryFlag countryCode={"pt"} svg />
                ) : lang === "it" ? (
                  <CountryFlag countryCode={"it"} svg />
                ) : lang === "fr" ? (
                  <CountryFlag countryCode={"fr"} svg />
                ) : lang === "en" ? (
                  <CountryFlag countryCode={"us"} svg />
                ) : lang === "zh_hans" ? (
                  <CountryFlag countryCode={"cn"} svg />
                ) : null;
              return (
                <ButtonHuge key={lang} onClick={() => startOrientation(lang)}>
                  {flagComponent}
                  <span className={clsx(flagComponent && "ml-0.5")}>
                    <TranslatedStartString lang={lang} />
                  </span>
                </ButtonHuge>
              );
            })}

            {secondaryLanguages.length > 0 && (
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: secondaryLanguages.map((lang) => ({
                    label: getLanguageTitle(lang),
                    key: lang,
                    onClick: () => startOrientation(lang),
                  })),
                }}
              >
                <Button className={`overflow-hidden`}>
                  <div
                    className={`overflow-hidden mx-auto h-full w-full relative`}
                  >
                    <div
                      className={`left-0 flex flex-row items-center gap-0.5`}
                    >
                      <div
                        className={`absolute right-1 w-[calc(100%-5rem)] h-full top-0 bg-inherit bg-gradient-to-l from-white to-transparent`}
                      ></div>
                      <GlobalOutlined />
                      {secondaryLanguages.map((lang) => (
                        // <TranslatedBeginString lang={lang} />
                        <span key={lang}>{getLanguageTitle(lang)}</span>
                      ))}
                    </div>
                    <div
                      className={
                        "absolute bg-white right-0 top-0 bottom-0 h-full flex items-center justify-center"
                      }
                    >
                      <DownOutlined />
                    </div>
                  </div>
                </Button>
              </Dropdown>
            )}
          </div>
        </div>
      </SiteFeatureStepLayout>
    </>
  );
};

export default SiteOrientationRegistrationStepsHello;
