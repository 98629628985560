import { IconUserPlus, IconStatusChange } from "@tabler/icons";
import React, { useState } from "react";
import CreateGCEmployeeModal, {
  CreateGCEmployeeFormValues,
} from "src/common/components/dialogs/CreateGCEmployeeModal";
import Button from "src/common/components/general/button/Button";
import SearchBar from "src/common/components/SearchBar";
import { SortOrderType } from "src/common/components/SortingTypePicker";
import capitalize from "src/common/functions/capitalize";
import AddGCProjectTeamModal from "./AddGCProjectTeamModal";
import GCProjectTeamList, { GCProjectTeamListProps } from "./GCProjectTeamList";
import PerformEmployeeChangeFunctionModal from "src/common/components/modals/PerformEmployeeChangeFunctionModal";
import { general_contractor_employee_bool_exp } from "src/common/types/generated/relay/AddGCProjectTeamModalQuery.graphql";
const textData = {
  noUsers: `Add your team`,
};

export interface GCProjectTeamUIProps extends GCProjectTeamListProps {
  onCreateGCEmployee: (values: CreateGCEmployeeFormValues) => any;
  projectsToInvite: string[];
  directProjectTeam: boolean;
  onRemoveFromTeam?: (userId: string) => Promise<void>;
  excludeEmployeeWhere: general_contractor_employee_bool_exp;
  onInviteSuccess: (userId: string[]) => Promise<void> | void;
}

const GCProjectTeamUI: React.FC<GCProjectTeamUIProps> = (props) => {
  const [sortingOrder, setSortingOrder] = useState<SortOrderType>("ascending");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [fetchKey, setFetchKey] = useState(0);
  const [createEmployeeOpen, setCreateEmployeeOpen] = useState(false);
  const [handleChangeEmployee, setHandleChangeEmployee] = useState(false);

  const [selectedSortingTypeId, setSelectedSortingType] = useState<
    `name` | "title"
  >("name");

  const searchQueryUpperCase = searchQuery.toUpperCase();
  const checkdata = props.gcUsers.map((employee) => ({
    name: employee.name,
    email: employee.email,
    uid: employee.id,
    created_password: employee.created_password,
  }));
  const filteredUsers = props.gcUsers
    .filter((user) => user.name.toUpperCase().includes(searchQueryUpperCase))
    .sort((user1, user2) => {
      let value1;
      let value2;
      switch (selectedSortingTypeId) {
        case "name":
          value1 = user1.name;
          value2 = user2.name;
          break;
        case "title":
          value1 = user1.title;
          value2 = user2.title;
          break;
      }
      if (!value1 && !value1) {
        return 0;
      }
      if (!value1) {
        return 1;
      }
      if (!value2) {
        return -1;
      }
      const returnValue = value1 === value2 ? 0 : value1 > value2 ? 1 : -1;
      return sortingOrder === "ascending" ? returnValue : -returnValue;
    });

  const handleAddUser = () => {
    setFetchKey((prev) => prev + 1);
    setVisible(true);
  };
  const handleEmployeeChange = () => {
    setHandleChangeEmployee((val) => !val);
  };

  return (
    <>
      {/* {!!props.gcUsers.length ? ( */}
      <div className="flex flex-col w-48 max-h-full gap-1">
        <div className={`flex flex-row items-center gap-1`}>
          <div className="flex-1">
            <SearchBar
              onSearchSubmit={setSearchQuery}
              sorting={{
                onOrderClick: setSortingOrder,
                onSortingTypeClick: (newSortyingType) => {
                  setSelectedSortingType((prevState) =>
                    newSortyingType === "name" || newSortyingType === "title"
                      ? newSortyingType
                      : prevState,
                  );
                },
                order: sortingOrder,
                selectedSortingTypeId,
                sortingTypes: ["name", "title"].map((v) => ({
                  title: capitalize(v),
                  id: v,
                })),
              }}
            />
          </div>
          {/* <div className="flex-1"> */}
          <Button onClick={handleAddUser} icon={IconUserPlus} secondary />
          {/* </div> */}
          <Button
            onClick={handleEmployeeChange}
            icon={IconStatusChange}
            secondary
          />
        </div>
        <div className="flex-1 overflow-y-scroll flex flex-col gap-0.5">
          <GCProjectTeamList
            onRemoveFromTeam={props.onRemoveFromTeam}
            gcUsers={filteredUsers.filter(({ status }) => status?.active)}
          />
          <GCProjectTeamList
            onRemoveFromTeam={props.onRemoveFromTeam}
            gcUsers={filteredUsers.filter(({ status }) => !status?.active)}
          />
        </div>
      </div>
      {/* ) : (
        <ErrorMessage message={textData.noUsers} />
      )} */}
      <AddGCProjectTeamModal
        modalClose={() => {
          setVisible(false);
        }}
        directProjectTeam={props.directProjectTeam}
        onInviteSuccess={props.onInviteSuccess}
        excludeEmployeeWhere={props.excludeEmployeeWhere}
        projects={props.projectsToInvite}
        modalVisible={visible}
        onClickInvite={() => {
          setVisible(false);
          setCreateEmployeeOpen(true);
        }}
        fetchKey={fetchKey}
      />
      <CreateGCEmployeeModal
        visible={createEmployeeOpen}
        onCancel={() => {
          setCreateEmployeeOpen(false);
          handleAddUser();
        }}
        title={"Invite Office or Field Staff to SiteForm"}
        warning={"DO NOT invite Workers here (foremen or field labor)"}
        onCreate={async (values: CreateGCEmployeeFormValues) => {
          await props.onCreateGCEmployee(values);
          setCreateEmployeeOpen(false);
          // handleAddUser();
        }}
      />
      <PerformEmployeeChangeFunctionModal
        visible={handleChangeEmployee}
        onClose={() => setHandleChangeEmployee(false)}
        data={props.gcUsers.map((employee) => ({
          name: employee.name,
          email: employee.email || undefined,
          uid: employee.id!,
          created_password: employee.created_password,
          dataId: employee.dataId,
          phoneNumber: employee.phoneNumber,
        }))}
        subs={props.subs}
      />
    </>
  );
};

export default GCProjectTeamUI;
