import { Alert, Form, message, Modal, notification, Select } from "antd";
import { FormInstance, useForm } from "antd/es/form/Form";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery, useMutation } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import LoadingContent from "src/common/components/general/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useGetProcoreProjectListQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { ProcoreProjectConfigModal_procoreProjectData_Query } from "src/common/types/generated/relay/ProcoreProjectConfigModal_procoreProjectData_Query.graphql";
import { ProcoreProjectConfigModal_resetEverything_Mutation } from "src/common/types/generated/relay/ProcoreProjectConfigModal_resetEverything_Mutation.graphql";
import { ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation } from "src/common/types/generated/relay/ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation.graphql";
import GetFullID from "src/common/functions/GetFullId";

type FormValues = {
  procore_project_id: string;
};

type ProcoreProject = {
  project_id: string, 
  project_name: string, 
  company_id: string, 
  company_name: string 
}

type ModalFormRef<V extends object> = {
  form: FormInstance<V>;
  previoudProcoreProjectId: string | null | undefined;
  getProcoreProject: (id: string) => ProcoreProject | undefined;
};

type ProcoreProjectConfigModalContentProps = {
  projectId: string;
  saving: boolean;
  fetchKey: number;
};

export type ProcoreProjectConfigModalProps = {
  projectId: string;
  onSubmit?: () => void;
};

const ProcoreProjectConfigModalConent = forwardRef<
  ModalFormRef<FormValues>,
  ProcoreProjectConfigModalContentProps
>(({ projectId, saving, fetchKey }, ref) => {
  const { data, loading } = useGetProcoreProjectListQuery({
    variables: {
      projectId,
    },
  });
  const projectData =
    useLazyLoadQuery<ProcoreProjectConfigModal_procoreProjectData_Query>(
      graphql`
        query ProcoreProjectConfigModal_procoreProjectData_Query(
          $projectId: uuid!
        ) {
          procore_project_data_connection(
            where: { project_id: { _eq: $projectId } }
          ) {
            edges {
              node {
                id
                procore_project_id
                procore_project_name
                integration_enabled
              }
            }
          }
        }
      `,
      { projectId },
      {
        fetchKey,
      },
    );

  const project_data_edges =
    projectData.procore_project_data_connection.edges || [];
  const project_data_node = project_data_edges[0]?.node;

  const [formRef] = useForm<FormValues>();
  useImperativeHandle<ModalFormRef<FormValues>, ModalFormRef<FormValues>>(
    ref,
    () => ({
      form: formRef,
      previoudProcoreProjectId: project_data_node?.procore_project_id,
      getProcoreProject: (id: string) =>
        (data?.procoreGetProjectList.projects || []).find(
          (p) => p.project_id === id,
        ),
    }),
  );

  /*
  if (loading)
    return (
    <StyledContent align="center">
      <LoadingContent />
    </StyledContent>
    );
  else*/

  return (
    <Form form={formRef} layout="vertical">
      {project_data_node && (
        <>
          <Alert
            message="WARNING: Changing the project will reset subcontrator and folder setup
            and need to setup again. This will not affect previous logs"
            type="warning"
            showIcon
            closable
          />
          <br />
        </>
      )}
      <Form.Item
        name="procore_project_id"
        label="Choose Project"
        rules={[{ required: true, message: "Choose project" }]}
        initialValue={project_data_node?.procore_project_id}
      >
        <Select
          style={{ width: "100%" }}
          disabled={saving}
          loading={loading || saving}
          dropdownRender={(item) =>
            loading ? (
              <StyledContent align="center">
                <LoadingContent />
              </StyledContent>
            ) : (
              item
            )
          }
        >
          {(data?.procoreGetProjectList.projects || []).map((t) => (
            <Select.Option key={String(t.project_id)} value={String(t.project_id)}>
              {t.project_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
});

export type ProcoreProjectConfigModalRef = {
  open: () => void;
};

const ProcoreProjectConfigModal = forwardRef<
  ProcoreProjectConfigModalRef,
  ProcoreProjectConfigModalProps
>(({ projectId, onSubmit }, ref) => {
  const modalRef = useRef<ModalFormRef<FormValues>>(null);
  const [saving, setSaving] = useState(false);
  const [visible, setVisible] = useState(false);
  // Refresh
  const [key, setKey] = useState(0);

  // Refresh ends
  useImperativeHandle<
    ProcoreProjectConfigModalRef,
    ProcoreProjectConfigModalRef
  >(ref, () => ({
    open: () => {
      setKey((v) => v + 1);
      setVisible(true);
    },
  }));

  const [upsertProcoreData] =
    useAsyncMutation<ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation>(
      graphql`
        mutation ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation(
          $object: procore_project_data_insert_input!
        ) {
          insert_procore_project_data_one(
            object: $object
            on_conflict: {
              constraint: procore_project_data_pkey
              update_columns: [
                procore_project_id
                procore_project_name
                procore_company_id
                procore_company_name
                upload_permits_enabled
                upload_permits_folder_id
                upload_daily_reports_enabled
                upload_daily_reports_folder_id
                upload_toolbox_talks_enabled
                upload_toolbox_talks_folder_id
                upload_safety_reports_enabled
                upload_safety_reports_folder_id
                upload_worker_orientation_transcript_enabled
                upload_worker_orientation_transcript_folder_id
              ]
            }
          ) {
            id
            pk: id @__clientField(handle: "pk")
            project_id
            procore_project_id
            procore_project_name

            procore_company_id
            procore_company_name

            upload_permits_enabled
            upload_permits_folder_id

            upload_daily_reports_enabled
            upload_daily_reports_folder_id

            upload_toolbox_talks_enabled
            upload_toolbox_talks_folder_id

            upload_safety_reports_enabled
            upload_safety_reports_folder_id

            upload_worker_orientation_transcript_enabled
            upload_worker_orientation_transcript_folder_id
          }
        }
      `,
    );

  const [resetProcoreProject] =
    useAsyncMutation<ProcoreProjectConfigModal_resetEverything_Mutation>(
      graphql`
        mutation ProcoreProjectConfigModal_resetEverything_Mutation(
          $project_id: uuid!
        ) {
          delete_procore_subcontractor(
            where: { project_id: { _eq: $project_id } }
          ) {
            affected_rows
          }
          delete_procore_trade(where: { project_id: { _eq: $project_id } }) {
            affected_rows
          }
        }
      `,
    );
  return (<>
    <Modal
      open={visible}
      title="Project Setup"
      okText="Setup"
      onCancel={() => {
        setVisible(false);
      }}
      onOk={async () => {
          const modal = modalRef.current;
          if (!modal)
              return 
          const form = modal.form;
          if (!form) return;

          const values = await form.validateFields().catch(v => null);
          if (!values)
              return;

          setSaving(true);
          try {
            const procoreProject = modal.getProcoreProject(values.procore_project_id);
            if (!procoreProject) {
              throw new Error('Unknown procore_project_id');
            }
            const resetProject =
              modal.previoudProcoreProjectId !== values.procore_project_id;

              await upsertProcoreData({
              variables: {
                object: {
                  procore_project_id: values.procore_project_id,
                  procore_company_id: procoreProject.company_id,
                  procore_project_name: procoreProject.project_name,
                  procore_company_name: procoreProject.company_name,
                  project_id: projectId,
                  integration_enabled: false,
                  upload_daily_reports_enabled: false,
                  upload_permits_enabled: false,
                  upload_safety_reports_enabled: false,
                  upload_toolbox_talks_enabled: false,
                  upload_daily_reports_folder_id: null,
                  upload_permits_folder_id: null,
                  upload_safety_reports_folder_id: null,
                  upload_toolbox_talks_folder_id: null,
                },
              },
              updater: (store) => {
                const node = store.getRootField(
                  "insert_procore_project_data_one",
                );

                const project_full_id = GetFullID("project", projectId);
                const conn_parent = store.get(project_full_id);
                if (conn_parent) {
                  conn_parent.setLinkedRecord(node, "procore_project_data");
                }

                const conn = store
                  .getRoot()
                  .getLinkedRecord("procore_project_data_connection", {
                    where: { project_id: { _eq: projectId } },
                  });
                if (conn) {
                  /*                      const edgeID = generateClientID(conn.getDataID(), "edge", 0);
                  let edge = store.get(edgeID);
                  if (!edge) {
                    edge = store.create(edgeID, "edge");`   
                    edge.setLinkedRecord(node, 'node');
                    ConnectionHandler.insertEdgeBefore(conn, edge);
                  }*/
                  const edges = conn.getLinkedRecords("edge");
                  if (!edges?.length) {
                    const edge = ConnectionHandler.createEdge(
                      store,
                      conn,
                      node,
                      "edge",
                    );
                    ConnectionHandler.insertEdgeBefore(conn, edge);
                  }
                }
              },
            });
            if (onSubmit) onSubmit();

            if (resetProject) {
              // console.log("projectId: ", projectId);
              await resetProcoreProject({
                variables: {
                  project_id: projectId,
                },
              });
            }
            message.success("Ok");
            // cache.reset();
            //console.log("CACHE reset");
            setVisible(false);
          } finally {
            setSaving(false);
          }
      }}
    >
      <CustomSuspense>
        <ProcoreProjectConfigModalConent
          ref={modalRef}
          projectId={projectId}
          key={key}
          fetchKey={key}
          saving={saving}
        />
      </CustomSuspense>
    </Modal>
  </>);
});

export default ProcoreProjectConfigModal;
