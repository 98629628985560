class MediaPlayerError extends Error {
  originalError?: unknown;

  constructor(message: string, originalError?: unknown) {
    super(message); // Pass the errorTitle to the Error constructor
    this.name = "MediaPlayerError"; // Set the name of the error
    this.originalError = originalError;

    // This line is needed to restore the correct prototype chain.
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export default MediaPlayerError;
