import { Button, Descriptions, Flex, message, Space } from "antd";
import dayjs from "dayjs";
import { FC, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SendEmailModal, {
  SendEmailModalRef,
} from "src/common/components/dialogs/SendEmailModal";
import BackButton from "src/common/components/general/button/BackButton";
import BSpace from "src/common/components/layouts/BSpace";
import StyledContent from "src/common/components/layouts/StyledContent";
import AnonProjectWorkerTable from "src/common/components/tables/AnonProjectWorkerTable";
import ReportAwarenessTable from "src/common/components/tables/ReportAwarenessTable";
import ReportHazardsTable from "src/common/components/tables/ReportHazardsTable";
import ReportTasksTable from "src/common/components/tables/ReportTasksTable";
import ImagesTable from "src/common/components/tables/basic/ImagesTable";
import {
  GetReportByPkQuery,
  useEmailSafetyReportsPdfMutation,
  useGenerateMergedSafetyReportPdfMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import RouteIdProps from "src/common/types/manual/RouteIdProps";
import FatalFourReports from "../entryRoutes/qrProjectReports/components/FatalFourReports";
import { generateSafetyReportsPdf } from "../utils/ptpReportsUtil";
const { Item } = Descriptions;

interface Props {
  report: NonNullable<GetReportByPkQuery["report_by_pk"]>;
  showBackButton?: boolean;
}

const ReportDetail: FC<Props & RouteIdProps> = ({
  report,
  routeId,
  showBackButton,
}) => {
  const emailModal = useRef<SendEmailModalRef>(null);
  const [emailSafetyReportPdf] = useEmailSafetyReportsPdfMutation();
  const navigate = useNavigate();
  const [generateSafetyReportPdfMutation] =
    useGenerateMergedSafetyReportPdfMutation();

  const map = new Map<string, string | null | undefined>();
  report.report_project_workers.map((d) =>
    map.set(d.project_worker.worker_id, d.signature_image?.url),
  );
  const imageDataSource = report.images.map((i) => ({
    url: i.url,
    description: i.description,
    type: "simple",
  }));
  report.submission_images.map((s) =>
    imageDataSource.push({
      url: s.image.url,
      description: s.image.description,
      type: "signIn",
    }),
  );
  return (
    <div className="flex flex-col w-full gap-1">
      <div className="flex flex-row justify-start">
        {showBackButton && <BackButton />}
      </div>
      <SendEmailModal
        ref={emailModal}
        title={"Email safety report PDF"}
        onSubmit={({ email }) => {
          emailModal.current?.close();
          emailSafetyReportPdf({
            variables: {
              input: {
                emailRecipients: [email],
                safetyReportIds: [report.id],
              },
            },
          });

          message.success("Emailed report!");
        }}
      />
      <Space
        size="large"
        direction="vertical"
        style={{ width: "100%", minWidth: 850 }}
      >
        <StyledContent padding backgroundColor="white">
          <BSpace>
            <Flex gap="small" wrap="wrap">
              <Button type="primary" onClick={() => emailModal.current?.open()}>
                Email report
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  generateSafetyReportsPdf(
                    [report.id],
                    generateSafetyReportPdfMutation,
                  );
                }}
              >
                Download
              </Button>
            </Flex>

            <Descriptions title="Report" bordered size="middle">
              <Item label="Date">{dayjs(report.created_at).format("lll")}</Item>
              <Item label="Project">{report.project.name}</Item>
              {!!report.report_project_locations.length && (
                <Item label="Location(s)">
                  {report.report_project_locations
                    .map((l) => l.project_location.name.en)
                    .join(", ")}
                </Item>
              )}
              <Item label="Subcontractor">{report.subcontractor.name}</Item>
              <Item label="Crew">{report.project_crew?.name ?? ""}</Item>
              <Item label="Submitter">
                {report?.submitter_project_worker?.user?.name},{" "}
                {report?.submitter_project_worker?.title?.translation.en}
              </Item>
            </Descriptions>
          </BSpace>
        </StyledContent>
        <StyledContent padding backgroundColor="white">
          <ReportTasksTable
            isExpandable
            title={() => <h3>JHAs Performed</h3>}
            dataSource={report.tasks}
            pagination={{
              total: report.tasks.length,
            }}
          />
        </StyledContent>
        <StyledContent padding backgroundColor="white">
          <ReportHazardsTable
            title={() => <h3>Report Hazards Awareness</h3>}
            dataSource={report.report_project_hazards}
            pagination={{
              total: report.report_project_hazards.length,
            }}
          />
        </StyledContent>
        <StyledContent padding backgroundColor="white">
          <ReportAwarenessTable
            title={() => <h3>Report Awareness Questions</h3>}
            dataSource={report.report_awareness_questions}
            pagination={{ total: report.report_awareness_questions.length }}
          />
        </StyledContent>
        <StyledContent padding backgroundColor="white">
          <AnonProjectWorkerTable
            loadAll
            excludedKeys={["subcontractor", "project", "crew", "corporateDate"]}
            where={{
              ...(routeId.type === "sub"
                ? { subcontractor_id: { _eq: routeId.id } }
                : { project_id: { _eq: routeId.id } }),
              reports: {
                report_id: {
                  _eq: report.id,
                },
              },
            }}
            extraColumns={[
              {
                title: "Signature",
                key: "extra",
                dataIndex: ["worker_id"],
                render: (workerId) => {
                  return <img src={map.get(workerId) ?? ""} />;
                },
              },
            ]}
            onRowClick={({ pk }) => {
              if (routeId.type === "gce") {
                navigate(`/gce/projects/${routeId.id}/workers/worker/${pk}`);
              }
            }}
          />
        </StyledContent>
        <StyledContent padding backgroundColor="white">
          <AnonProjectWorkerTable
            loadAll
            excludedKeys={["subcontractor", "project", "crew", "corporateDate"]}
            title={"Injuries"}
            where={{
              ...(routeId.type === "sub"
                ? { subcontractor_id: { _eq: routeId.id } }
                : { project_id: { _eq: routeId.id } }),
              report_injuries: {
                report_id: {
                  _eq: report.id,
                },
              },
            }}
            onRowClick={({ pk }) => {
              if (routeId.type === "gce") {
                navigate(`/gce/projects/${routeId.id}/workers/worker/${pk}`);
              }
            }}
          />
        </StyledContent>
        <StyledContent padding backgroundColor="white">
          <ImagesTable
            title={() => <h3>Report Images</h3>}
            dataSource={imageDataSource}
          />
        </StyledContent>
        {report.report_fatal_four && (
          <StyledContent padding backgroundColor="white">
            <FatalFourReports data={report.report_fatal_four} />
          </StyledContent>
        )}
      </Space>
    </div>
  );
};

export default ReportDetail;
