import { IconArrowDown, IconArrowUp } from "@tabler/icons";
import * as React from "react";
import Icon, { IconProps } from "src/common/components/general/Icon";
import Switcher, {
  SwitcherProps,
} from "src/common/components/general/Switcher";
import ShowToggleTableColumn, { ColumnType } from "./ShowToggleTableColumn";
import clsx from "clsx";
import Button, { ButtonProps } from "../../general/button/Button";

export type GCDashAppReportsColumnType = ColumnType;

export type ExtendedRow = {
  id: string;
  title: string;
  switchers: SwitcherProps[];
  button?: ButtonProps;
  haveChilds?: boolean;
  parentItem?: boolean;
  parentId?: string;
};
export interface ShowToggleTableProps {
  mainColumn: {
    title: string;
    initiallyHideChilds?: boolean;
    id: string;
    rows: ExtendedRow[];
    switchTitles: Array<{ title: string; icon?: IconProps }>;
    onSortingClick?: () => void;
    hint?: string;
  };
  columns: ColumnType[];
}

const ShowToggleTable: React.FunctionComponent<ShowToggleTableProps> = (
  props,
) => {
  // console.group(`ShowToggleTable`);
  // console.log(props);
  // console.groupEnd();

  const columnsWrapperRef = React.useRef<HTMLDivElement>(null);
  const [columnsVisible, setColumnsVisible] = React.useState<boolean[]>(
    props.columns.map(() => true),
  );

  React.useEffect(() => {
    setColumnsVisible(props.columns.map(() => true));
  }, [props.columns]);

  const handleColumnClick = (index: number) => {
    // console.group("click");
    // console.log(`close column #${index}`);
    setColumnsVisible((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
    // console.groupEnd();
  };

  let columnsWrapperWidth: null | number = null;

  if (columnsWrapperRef.current) {
    columnsWrapperWidth =
      columnsWrapperRef.current.getBoundingClientRect().width;
  }
  const [hideChildsFor, setHideChildsFor] = React.useState<{
    [key: string]: boolean;
  }>(
    props.mainColumn.initiallyHideChilds
      ? Object.fromEntries(
          props.mainColumn.rows
            .filter((row) => row.parentItem)
            .map((r) => [r.id, true]),
        )
      : {},
  );

  const columnWidths = columnsVisible.map((visible) =>
    !!columnsWrapperWidth
      ? visible
        ? `100%`
        : "1rem"
      : `${100 / columnsVisible.length}%`,
  );

  return (
    <div
      className={clsx(
        "grid grid-cols-12  bg-white",
        props.columns.length > 0 ? "overflow-y-auto max-h-48" : "",
      )}
    >
      <div
        className={clsx(
          "flex flex-col gap-0.5",
          props.columns.length > 0 ? "col-span-5" : "col-span-10",
        )}
      >
        <div className="sticky flex z-50 flex-row justify-between items-center gap-0.5 top-0 bg-white font-accent">
          {props.mainColumn.title}
          {props.mainColumn.switchTitles.length > 0 && (
            <div className="flex gap-1 mr-12">
              {props.mainColumn.switchTitles.map(({ title, icon }) => (
                <div className="w-7 mr-0.25 flex" key={title}>
                  {title} {icon && <Icon {...icon} />}
                </div>
              ))}
            </div>
          )}

          {props.mainColumn.onSortingClick && (
            <div
              className="cursor-pointer "
              onClick={props.mainColumn.onSortingClick}
            >
              <Icon icon={IconArrowDown} color={`interactive`} size={`small`} />
            </div>
          )}
        </div>
        <div className="flex flex-col " style={{ gap: "7px" }}>
          {props.mainColumn.rows.map((item, i) => {
            return !item.parentId || !hideChildsFor[item.parentId] ? (
              <div
                key={item.id}
                className={clsx(
                  "flex flex-row justify-between items-center gap-0.5",
                  item.parentItem ? "font-accent" : "",
                  item.parentId ? "ml-1" : "",
                  props.columns.length === 0
                    ? (item.button ? "mt-1" : "") +
                        (item.parentId &&
                        props.mainColumn.rows[i + 1] &&
                        !props.mainColumn.rows[i + 1].parentId
                          ? "mb-1"
                          : "")
                    : "",
                  // item.parentItem ? "my-0.5" : ""
                )}
              >
                <span
                  style={{
                    height: "29px",
                    paddingBottom:
                      item.switchers.length > 0 ? undefined : "9.2px",
                  }}
                >
                  {item.title}
                </span>
                {item.haveChilds && (
                  <Icon
                    color="interactive"
                    icon={hideChildsFor[item.id] ? IconArrowDown : IconArrowUp}
                    onClick={() => {
                      setHideChildsFor((prev) => {
                        const newObj = { ...prev };
                        if (hideChildsFor[item.id]) {
                          delete newObj[item.id];
                        } else {
                          newObj[item.id] = true;
                        }
                        return newObj;
                      });
                    }}
                  />
                )}
                <div className="flex-1 h-px bg-suplementary-3"></div>
                {item.switchers.length > 0 && (
                  <div
                    className={clsx(
                      "flex flex-row justify-end",
                      props.columns.length > 0 || item.button ? "" : "mr-12",
                    )}
                  >
                    {item.switchers.map((switcher, i) => (
                      <div
                        className={clsx(
                          "z-0",
                          item.button && i == item.switchers.length - 1
                            ? "mr-0.25"
                            : "mr-0.75",
                        )}
                        key={i}
                      >
                        <Switcher {...switcher} />
                      </div>
                    ))}
                  </div>
                )}
                {item.button && (
                  <div className="ml-0.125">
                    <Button {...item.button} />
                  </div>
                )}
              </div>
            ) : (
              <></>
            );
          })}
        </div>
      </div>
      {props.columns.length > 0 && (
        <div
          className="flex flex-row col-span-7 transition-all"
          ref={columnsWrapperRef}
        >
          {props.columns.map((column, i) => (
            <div
              key={column.title}
              className="transition-all"
              style={{
                width: columnWidths[i],
              }}
            >
              <ShowToggleTableColumn
                {...{
                  ...column,
                  rows: column.rows.map((row) => ({
                    ...row,
                    hideRow: !!row.parentId && hideChildsFor[row.parentId],
                  })),
                  onOpenClick: () => {
                    handleColumnClick(i);
                  },
                  onCloseClick: !(
                    columnsVisible.filter((visible) => visible).length === 1 &&
                    columnsVisible[i]
                  )
                    ? () => {
                        handleColumnClick(i);
                      }
                    : undefined,
                  visible: columnsVisible[i],
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ShowToggleTable;
