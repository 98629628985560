import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import { Language } from "../language-utils/i18nTypes";
import { DEFAULT_LANGUAGE } from "../languages";

export const LanguageContext = React.createContext<
  [Language, Dispatch<SetStateAction<Language>>] | null
>(null);

type LanguageProviderProps = {
  defaultLang?: Language | null;
  children: ReactNode;
};

export const LanguageProvider: FC<LanguageProviderProps> = (props) => {
  const langState = useState<Language>(props.defaultLang ?? DEFAULT_LANGUAGE);
  return (
    <LanguageContext.Provider value={langState}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export const withLanguageProvider =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <LanguageProvider>
        <Component {...props} />
      </LanguageProvider>
    );
