import { Form, Image as AntdImage, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useMemo, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import holdingIcon from "src/common/assets/orientation-holding-icon.png";
import quizIcon from "src/common/assets/quiz-scanning-icon.png";
import { useParams } from "react-router-dom";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { OrientationTVViewerQuery } from "src/common/types/generated/relay/OrientationTVViewerQuery.graphql";
import fixEnglish from "src/domain-features/siteorientation/utils/fixEnglish";
import { translateSlide } from "src/domain-features/siteorientation/utils/translateSlide";
import {
  OrientationPage,
  SlideAnswersMapType,
} from "src/domain-features/siteorientation/siteorientationTypes";
import SiteOrientationSlidesViewerUI from "src/domain-features/siteorientation/components/slidesViewer/SiteOrientationSlidesViewerUI";
import OrientationTVWorkersList from "./OrientationTVWorkersList";
import { QRCodeCanvas } from "qrcode.react";
import ButtonHuge from "src/common/components/general/button/ButtonHuge";
import { siteFormIconLink } from "src/common/assets/siteform-icon-link";
import Button from "src/common/components/general/button/Button";
import LanguagePicker from "../worker/components/LanguagePicker";
import { returnVal } from "../../../gcDashboard/routes/status/components/OrientationProjectWorkerTable";
import ImageAutomaticSize from "src/common/components/general/images/ImageAutomaticSize";
import remToPx from "src/common/functions/remToPx";
import { withLanguageProvider } from "src/utility-features/i18n/context/languageContext";
import { AVAILABLE_LANGUAGES } from "../../../../../../utility-features/i18n/languages";
import useLangStrings, {
  useCurrentLanguageState,
} from "../../../../../../utility-features/i18n/context/languageHooks";
import useInsertSecretCode from "../../../gcDashboard/routes/settings/utils/useInsertSecretCode";
import * as uuid from "uuid";
import buildSecretCodeUpdater from "../../../gcDashboard/routes/settings/utils/buildSecretCodeUpdater";
import useAuthUser from "src/common/hooks/useAuthUser";
import getLogoToShowFromProjectData from "src/common/functions/getLogoToShow";

const query = graphql`
  query OrientationTVViewerQuery(
    $orientationWhere: orientation_bool_exp!
    $projectId: uuid!
  ) {
    quiz_code: secret_login_link_connection(
      where: {
        project_id: { _eq: $projectId }
        final_url: { _ilike: "/orientation/quiz" }
      }
      order_by: [{ created_at: desc }]
      first: 1
    ) @connection(key: "OrientationTVViewer_quiz_code", filters: []) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
          in_person_orientation
          agc_universal_orientation
          linked_orientation_projects {
            pk: id @__clientField(handle: "pk")
          }
          ...ProjectLogoFrag @relay(mask: false)
          general_contractor {
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
    orientation_connection(
      where: $orientationWhere
      order_by: [{ order: asc }, { name: asc }]
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          id
          name
          created_at
          project_id
          duration_valid
          type
          user {
            name
          }
          universalOrientationRequire: project_orientations(
            where: { project_id: { _is_null: true } }
          ) {
            required_by_all_workers
          }
          project_orientations(where: { project_id: { _is_null: true } }) {
            play_during_registration
            play_during_in_person
            required_by_all_workers
            hide_but_give_credit
          }
          slides(
            where: {
              deleted_at: { _is_null: true }
              archived_at: { _is_null: true }
              content_type: { _neq: "question" }
            }
            order_by: { order: asc }
          ) {
            ...SlideFrag @relay(mask: false)
            viewed_by(where: { user_id: { _is_null: true } }) {
              id
              created_at
            }
          }
        }
      }
    }
  }
`;

const OrientationTVViewer: React.FunctionComponent<{}> = () => {
  const projectId = useParams().projectId;
  if (!projectId) throw new Error("Project Id not found");
  const [lang, setLang] = useCurrentLanguageState();
  const langStrings = useLangStrings(lang);
  const [sideRegistQR, setSideRegisQR] = useState(true);
  const data = useLazyLoadQuery<OrientationTVViewerQuery>(
    query,
    {
      projectId,
      orientationWhere: {
        // gc agrees to play these on TV and only for these will we give credit to workers

        deleted_at: { _is_null: true },
        project_orientations: {
          project_id: { _eq: projectId },
          hide_but_give_credit: { _eq: true },
        },
        _and: [
          {
            _or: [
              // project or corporate orientations that are required
              {
                project_orientations: {
                  project_id: { _eq: projectId },
                  required_by_all_workers: { _eq: true },
                },
                general_contractor: {
                  projects: { id: { _eq: projectId } },
                },
                _or: [
                  { project_id: { _is_null: true } },
                  { project_id: { _eq: projectId } },
                ],
              },
              // universal orientation that are required
              {
                general_contractor_id: { _is_null: true },
                project_orientations: {
                  project_id: { _is_null: true },
                  required_by_all_workers: { _eq: true },
                },
              },
            ],
          },
          {},
        ],
      },
    },
    { fetchPolicy: "network-only" },
  );
  const projectData = data.project_connection.edges[0].node;
  const orientations = useMemo(
    () =>
      (data.orientation_connection?.edges || [])
        .map((v) => v.node)
        .filter((o) => {
          return projectData.agc_universal_orientation
            ? true // we are calling all orientation modules with required settings then filtering out the UO based on project setting
            : o.type !== "universal";
        })
        .sort((a, b) => {
          const val1 =
            a.type === "universal" ? 1 : a.type === "corporate" ? 2 : 3;
          const val2 =
            b.type === "universal" ? 1 : b.type === "corporate" ? 2 : 3;
          return -returnVal(val1, val2);
        }),
    [data.orientation_connection],
  );
  const [form] = Form.useForm();
  const slideAnswers = useRef<SlideAnswersMapType>({}); // slide_id and answer

  const [viewedSlides, setViewedSlides] = useState<{ [key: string]: boolean }>(
    {},
  );
  const differentThanEnglish = lang !== "en";
  const [currentScreenStatus, setCurrentScreenStatus] = useState<
    "quiz" | "register" | "orientation"
  >("register");
  const pages = useMemo(() => {
    let pageArr: Array<OrientationPage> = [];

    for (const orientation of orientations) {
      const slides = orientation.slides;
      let idx = 0;
      while (idx < slides.length) {
        const slide = differentThanEnglish
          ? translateSlide(slides[idx], lang)
          : fixEnglish(slides[idx]);
        const pageItem: OrientationPage = {
          type: "static",
          title: slide.title,
          //@ts-ignore: TODO fix it
          orientation,
          viewed: viewedSlides[slide.pk], // || viewedSlides.includes(slide.pk),
          slides: [slide],
        };
        pageArr.push(pageItem);
        idx++;
        if (slide.content_type === "image" && slide.image_url) {
          // preload image
          const img = new Image();
          img.src = slide.image_url;
        }
      }
    }
    if (orientations.length > 0) {
      pageArr.push({
        // type: "qr",
        title: langStrings.strings.quizQR,
        //@ts-ignore: TODO fix it
        orientation: orientations[orientations.length - 1],
        viewed: false,
        slides: [],
      });
    }
    return pageArr;
  }, [orientations, viewedSlides, lang]);
  const insertSecretCode = useInsertSecretCode();

  const [pageIndex, setPageIndex] = useState(() => {
    let index = 0;
    while (index < pages.length && pages[index].viewed) index++;
    return index;
  });

  const page = pageIndex < pages.length ? pages[pageIndex] : null;
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const authUser = useAuthUser();
  const saveResults = async (sigUrl: string) => {};
  const onSubmitQuiz = async () => {};
  if (!page && currentScreenStatus === "orientation")
    return (
      <div className="text-2 text-center">
        No Orientation Module or slides found to be played
      </div>
    );
  const moveToNextPage = async () => {
    if (!page) return;
    page.slides.forEach((s) => {
      setViewedSlides((prev) => ({ ...prev, [s.pk]: true }));
    });
    if (pageIndex === pages.length - 2) {
      setCurrentScreenStatus("quiz");
      setSideRegisQR(false);
    }
  };
  const generateQrCodeUrl = async () => {
    const finalUrl = `/orientation/quiz`;
    const id = uuid.v4();

    await insertSecretCode({
      variables: {
        projectId,
        userId: authUser.uid,
        id,
        only_project_data: true,
        signInRole: "quiz_qr_user",
        finalUrl,
      },
      updater: buildSecretCodeUpdater("OrientationTVViewer_quiz_code"),
    }).catch((err) => {
      message.error("Error: " + err);
    });
  };
  const logo = getLogoToShowFromProjectData(projectData);
  return (
    <div className="flex bg-white gap-1 h-full justify-between">
      {currentScreenStatus !== "orientation" && (
        <div className="flex flex-col justify-between ml-1">
          <div className="ml-1 flex gap-1">
            <div className={`flex min-h-full flex-col`}>
              {logo && (
                <ImageAutomaticSize
                  perfectImageSidePx={remToPx(14)}
                  imageProps={{
                    preview: false,
                    src: logo,
                  }}
                />
              )}{" "}
              <span
                className={`text-3.5 font-accent block text-interactive-primary`}
              >
                {currentScreenStatus === "quiz"
                  ? langStrings.strings.orientationQuiz
                  : langStrings.strings.projecOrientation}{" "}
              </span>
              <span className={`text-2 font-accent block`}>
                {projectData.name ?? ""}
              </span>
              {!logo && (
                <span className={`text-2 font-accent block`}>
                  {projectData.general_contractor.name ?? ""}
                </span>
              )}
            </div>
          </div>
          <div>
            {currentScreenStatus === "register" || data.quiz_code.edges[0] ? (
              <QRCodeCanvas
                imageSettings={{
                  src: siteFormIconLink,
                  excavate: true,
                  height: 100,
                  width: 100,
                }}
                className="my-1"
                value={
                  currentScreenStatus === "quiz"
                    ? `${document.location.origin}/singInLink/${data.quiz_code.edges[0].node.pk}?projectId=${projectId}`
                    : currentScreenStatus === "register"
                    ? `${document.location.origin}/orientations/${projectId}/inpersonComplete`
                    : ""
                }
                size={1000}
                style={{ width: "45vh", height: "45vh" }}
              />
            ) : (
              <Button label="Generate Quiz QR" onClick={generateQrCodeUrl} />
            )}
            <div className="text-3">
              {currentScreenStatus === "register" ? (
                <div>{langStrings.strings.scanToRegisterCaptial}</div>
              ) : (
                langStrings.strings.scanToTakeQuizCapital
              )}
            </div>
          </div>
          <div className="text-suplementary-2 flex items-start justify-start">
            <div className="my-1">
              <LanguagePicker
                size={"lg"}
                selectedLang={lang}
                availableLangs={AVAILABLE_LANGUAGES}
                onLangeChange={(newLang) => {
                  setLang(newLang);
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className={
          currentScreenStatus === "orientation" ? "w-10/12 h-full" : ""
        }
      >
        {currentScreenStatus === "orientation" ? (
          orientations.length > 0 && page ? (
            // @ts-ignore TODO: fix this
            <SiteOrientationSlidesViewerUI
              {...{
                pages,
                tvPlayer: true,
                lang: lang,
                quizSubmitted,
                setQuizSubmitted,
                form,
                inPersonType: true,
                type: "inperson",
                slideAnswers,
                page,
                userId: "",
                pageIndex,
                setPageIndex,
                orientations,
                moveToNextPage,
                saveResults,
                projectName: "",
                userName: "",
                onSubmitQuiz,
              }}
            />
          ) : (
            <div>No Modules found to be played</div>
          )
        ) : (
          <>
            <div className="text-3 mt-7">
              <AntdImage
                preview={false}
                src={
                  currentScreenStatus === "register" ? holdingIcon : quizIcon
                }
                width={"25vw"}
              />
            </div>
            {currentScreenStatus === "register" && (
              <div className="mt-3">
                <ButtonHuge
                  className="font-accent"
                  type="primary"
                  onClick={() => {
                    setCurrentScreenStatus("orientation");
                  }}
                >
                  {langStrings.strings.startOrientation}
                </ButtonHuge>
              </div>
            )}
          </>
        )}
      </div>
      <div className="ml-1">
        <OrientationTVWorkersList
          {...{
            projectId,
            divideListIn2: currentScreenStatus !== "orientation",
          }}
        />
        {currentScreenStatus !== "register" && (
          <div className="absolute bottom-1 right-1">
            {currentScreenStatus === "quiz" && (
              <Button
                large
                label={(sideRegistQR ? "Hide" : "Show") + " Registration QR"}
                onClick={() => setSideRegisQR((i) => !i)}
              />
            )}
            {sideRegistQR && (
              <div className="flex flex-col items-end">
                <div className="text-1.5 text-grey">
                  {langStrings.strings.scanToRegister}
                </div>{" "}
                <QRCodeCanvas
                  className="w-full h-full inset-0"
                  value={`${document.location.origin}/orientations/${projectId}/inpersonComplete`}
                  bgColor={`transparent`}
                  size={1300}
                  style={{ width: "200px", height: "200px" }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withCustomSuspense(withLanguageProvider(OrientationTVViewer));
