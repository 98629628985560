/**
 * @generated SignedSource<<f8f024ea91c5e02e4bc851d217136367>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type GCCompanySettingsQuery$variables = {
  uid: string;
};
export type GCCompanySettingsQuery$data = {
  readonly email_general_contractor_user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly general_contractor_id: string;
        readonly id: string;
        readonly pk: string;
        readonly user_id: string;
      };
    }>;
  };
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
        readonly send_corporate_video_completion_report: boolean;
      };
    }>;
  };
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly employee: {
          readonly general_contractor: {
            readonly employees: ReadonlyArray<{
              readonly uid: string;
              readonly user: {
                readonly name: string;
              };
            }>;
            readonly hierarchy_business_unit_name: string | null | undefined;
            readonly hierarchy_division_name: string | null | undefined;
            readonly hierarchy_office_name: string | null | undefined;
            readonly hot_work_permit_name: string;
            readonly logo_url: string | null | undefined;
            readonly procore_auth_data: {
              readonly id: string;
              readonly integration_enabled: boolean;
              readonly sandbox: boolean;
              readonly service_account_client_id: string | null | undefined;
              readonly user_id: string | null | undefined;
              readonly user_login: string | null | undefined;
              readonly user_name: string | null | undefined;
            } | null | undefined;
            readonly require_dob: boolean;
            readonly require_phone: boolean;
            readonly send_weekly_email: boolean;
            readonly visitor_waiver_text: string | null | undefined;
          };
          readonly general_contractor_id: string;
          readonly is_corporate_admin: boolean;
        } | null | undefined;
      };
    }>;
  };
};
export type GCCompanySettingsQuery = {
  response: GCCompanySettingsQuery$data;
  variables: GCCompanySettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uid"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "general_contractor_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "send_corporate_video_completion_report",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "uid"
  }
],
v11 = [
  {
    "fields": [
      {
        "fields": (v10/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_corporate_admin",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo_url",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_phone",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hierarchy_division_name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hierarchy_business_unit_name",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hierarchy_office_name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_dob",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "send_weekly_email",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitor_waiver_text",
  "storageKey": null
},
v21 = {
  "name": "asc"
},
v22 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "user": (v21/*: any*/)
    }
  }
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "procore_auth_data",
  "kind": "LinkedField",
  "name": "procore_auth_data",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "service_account_client_id",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "user_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "user_login",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integration_enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sandbox",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hot_work_permit_name",
  "storageKey": null
},
v26 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v27 = {
  "fields": [
    {
      "fields": [
        {
          "fields": (v10/*: any*/),
          "kind": "ObjectValue",
          "name": "uid"
        }
      ],
      "kind": "ObjectValue",
      "name": "employees"
    }
  ],
  "kind": "ObjectValue",
  "name": "general_contractor"
},
v28 = [
  (v26/*: any*/),
  {
    "fields": [
      (v27/*: any*/),
      {
        "kind": "Literal",
        "name": "type",
        "value": {
          "_eq": "corporate_completion_weekly_email"
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v29 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v30 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v31 = [
  (v26/*: any*/),
  {
    "kind": "Literal",
    "name": "order_by",
    "value": (v21/*: any*/)
  },
  {
    "fields": [
      (v27/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCCompanySettingsQuery",
    "selections": [
      {
        "alias": "email_general_contractor_user_connection",
        "args": null,
        "concreteType": "email_general_contractor_userConnection",
        "kind": "LinkedField",
        "name": "__GCCompanySettingsQuery_email_general_contractor_user_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_general_contractor_userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "email_general_contractor_user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "project_connection",
        "args": null,
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "__GCCompanySettingsQuery_project_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v3/*: any*/),
                  (v9/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor",
                        "kind": "LinkedField",
                        "name": "general_contractor",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          {
                            "alias": null,
                            "args": (v22/*: any*/),
                            "concreteType": "general_contractor_employee",
                            "kind": "LinkedField",
                            "name": "employees",
                            "plural": true,
                            "selections": [
                              (v23/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "user",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "employees(order_by:{\"user\":{\"name\":\"asc\"}})"
                          },
                          (v24/*: any*/),
                          (v25/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCCompanySettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v28/*: any*/),
        "concreteType": "email_general_contractor_userConnection",
        "kind": "LinkedField",
        "name": "email_general_contractor_user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_general_contractor_userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "email_general_contractor_user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v28/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "GCCompanySettingsQuery_email_general_contractor_user_connection",
        "kind": "LinkedHandle",
        "name": "email_general_contractor_user_connection"
      },
      {
        "alias": null,
        "args": (v31/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v3/*: any*/),
                  (v9/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v31/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "GCCompanySettingsQuery_project_connection",
        "kind": "LinkedHandle",
        "name": "project_connection"
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor",
                        "kind": "LinkedField",
                        "name": "general_contractor",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          {
                            "alias": null,
                            "args": (v22/*: any*/),
                            "concreteType": "general_contractor_employee",
                            "kind": "LinkedField",
                            "name": "employees",
                            "plural": true,
                            "selections": [
                              (v23/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "user",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": "employees(order_by:{\"user\":{\"name\":\"asc\"}})"
                          },
                          (v24/*: any*/),
                          (v25/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bee6178dbcda3836d462d6b5b08dee0a",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "email_general_contractor_user_connection"
          ]
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "project_connection"
          ]
        }
      ]
    },
    "name": "GCCompanySettingsQuery",
    "operationKind": "query",
    "text": "query GCCompanySettingsQuery(\n  $uid: uuid!\n) {\n  email_general_contractor_user_connection(where: {type: {_eq: \"corporate_completion_weekly_email\"}, general_contractor: {employees: {uid: {_eq: $uid}}}}, first: 10000) {\n    edges {\n      node {\n        general_contractor_id\n        user_id\n        id\n        pk: id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  project_connection(where: {general_contractor: {employees: {uid: {_eq: $uid}}}}, first: 10000, order_by: {name: asc}) {\n    edges {\n      node {\n        name\n        id\n        send_corporate_video_completion_report\n        pk: id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  user_connection(where: {id: {_eq: $uid}}) {\n    edges {\n      node {\n        employee {\n          general_contractor_id\n          is_corporate_admin\n          general_contractor {\n            logo_url\n            require_phone\n            hierarchy_division_name\n            hierarchy_business_unit_name\n            hierarchy_office_name\n            require_dob\n            send_weekly_email\n            visitor_waiver_text\n            employees(order_by: {user: {name: asc}}) {\n              uid\n              user {\n                name\n                id\n              }\n              id\n            }\n            procore_auth_data {\n              id\n              service_account_client_id\n              user_id\n              user_name\n              user_login\n              integration_enabled\n              sandbox\n            }\n            hot_work_permit_name\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd8c57f76dadd4f97b2f2409625d7b81";

export default node;
