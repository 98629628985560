/**
 * @generated SignedSource<<42912efbdc9478d8d8e8c9aa600cb91c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type ProcoreSubConfigModal_Sub_Query$variables = {
  projectId: string;
  uid: string;
};
export type ProcoreSubConfigModal_Sub_Query$data = {
  readonly general_contractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
        readonly procore_trade_id: string | null | undefined;
        readonly procore_vendor_id: string | null | undefined;
        readonly projects: ReadonlyArray<{
          readonly project_subcontractors: ReadonlyArray<{
            readonly procore_subcontractor: {
              readonly id: string;
              readonly pk: string;
              readonly procore_subcontractor_id: string | null | undefined;
              readonly procore_subcontractor_name: string | null | undefined;
              readonly procore_trade_id: string | null | undefined;
            } | null | undefined;
            readonly subcontractor: {
              readonly id: string;
              readonly name: string;
              readonly pk: string;
            };
          }>;
        }>;
      };
    }>;
  };
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
        readonly procore_project_data: {
          readonly procore_project_id: string | null | undefined;
          readonly procore_project_name: string | null | undefined;
          readonly project_id: string;
        } | null | undefined;
        readonly procore_trade: ReadonlyArray<{
          readonly id: string;
          readonly procore_trade_id: string | null | undefined;
          readonly project_id: string;
          readonly title_id: string;
        }>;
        readonly project_subcontractors: ReadonlyArray<{
          readonly procore_subcontractor: {
            readonly id: string;
            readonly pk: string;
            readonly procore_subcontractor_id: string | null | undefined;
            readonly procore_subcontractor_name: string | null | undefined;
            readonly procore_trade_id: string | null | undefined;
          } | null | undefined;
          readonly subcontractor: {
            readonly id: string;
            readonly name: string;
            readonly pk: string;
          };
        }>;
      };
    }>;
  };
};
export type ProcoreSubConfigModal_Sub_Query = {
  response: ProcoreSubConfigModal_Sub_Query$data;
  variables: ProcoreSubConfigModal_Sub_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uid"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "uid"
              }
            ],
            "kind": "ObjectValue",
            "name": "uid"
          }
        ],
        "kind": "ObjectValue",
        "name": "employees"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_vendor_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_trade_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_subcontractor_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_subcontractor_name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "procore_subcontractor",
  "kind": "LinkedField",
  "name": "procore_subcontractor",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v11 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_project_id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_project_name",
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "subcontractor": {
        "name": "asc"
      }
    }
  }
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "procore_trade",
  "kind": "LinkedField",
  "name": "procore_trade",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "procore_subcontractor",
  "kind": "LinkedField",
  "name": "procore_subcontractor",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcoreSubConfigModal_Sub_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "projects",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_subcontractor",
                        "kind": "LinkedField",
                        "name": "project_subcontractors",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "procore_project_data",
                    "kind": "LinkedField",
                    "name": "procore_project_data",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v15/*: any*/),
                    "concreteType": "project_subcontractor",
                    "kind": "LinkedField",
                    "name": "project_subcontractors",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": "project_subcontractors(order_by:{\"subcontractor\":{\"name\":\"asc\"}})"
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProcoreSubConfigModal_Sub_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "projects",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_subcontractor",
                        "kind": "LinkedField",
                        "name": "project_subcontractors",
                        "plural": true,
                        "selections": [
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "procore_project_data",
                    "kind": "LinkedField",
                    "name": "procore_project_data",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v15/*: any*/),
                    "concreteType": "project_subcontractor",
                    "kind": "LinkedField",
                    "name": "project_subcontractors",
                    "plural": true,
                    "selections": [
                      (v20/*: any*/),
                      (v19/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": "project_subcontractors(order_by:{\"subcontractor\":{\"name\":\"asc\"}})"
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8901a543dc181cafe1cc1d5135d4c29",
    "id": null,
    "metadata": {},
    "name": "ProcoreSubConfigModal_Sub_Query",
    "operationKind": "query",
    "text": "query ProcoreSubConfigModal_Sub_Query(\n  $projectId: uuid!\n  $uid: uuid!\n) {\n  general_contractor_connection(where: {employees: {uid: {_eq: $uid}}}) {\n    edges {\n      node {\n        id\n        name\n        pk: id\n        procore_vendor_id\n        procore_trade_id\n        projects {\n          project_subcontractors {\n            subcontractor {\n              name\n              id\n              pk: id\n            }\n            procore_subcontractor {\n              id\n              pk: id\n              procore_subcontractor_id\n              procore_subcontractor_name\n              procore_trade_id\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n  project_connection(where: {id: {_eq: $projectId}}) {\n    edges {\n      node {\n        id\n        pk: id\n        name\n        procore_project_data {\n          project_id\n          procore_project_id\n          procore_project_name\n          id\n        }\n        project_subcontractors(order_by: {subcontractor: {name: asc}}) {\n          procore_subcontractor {\n            id\n            pk: id\n            procore_subcontractor_id\n            procore_subcontractor_name\n            procore_trade_id\n          }\n          subcontractor {\n            name\n            id\n            pk: id\n          }\n          id\n        }\n        procore_trade {\n          id\n          procore_trade_id\n          project_id\n          title_id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c1abf2c3381e49c1cbba1b13d319d86";

export default node;
