export const getVideoFrameUrl = (
  url: string,
  autoPlay?: boolean,
  disableControls?: boolean,
): string => {
  const parser: HTMLAnchorElement = document.createElement("a");

  parser.href = url;
  const protocol = parser.protocol || "https:";

  switch (parser.hostname) {
    case "www.vimeo.com":
    case "vimeo.com": {
      const params = new URLSearchParams(parser.search);
      if (typeof autoPlay === "boolean") {
        params.set("autoplay", autoPlay ? "1" : "0");
      }
      return `${protocol}//player.vimeo.com/video${
        parser.pathname
      }?${params.toString()}`;
    }
    case "youtube.com":
    case "www.youtube.com":
    case "youtu.be":
    case "www.youtu.be": {
      const params = new URLSearchParams(parser.search);
      const videoId: string = params.get("v") ?? parser.pathname.slice(1);
      params.delete("v");
      if (typeof autoPlay === "boolean") {
        params.set("autoplay", autoPlay ? "1" : "0");
      }
      if (disableControls) {
        params.set("controls", "0");
      }
      params.set("rel", "0");
      params.set("enablejsapi", "1");
      params.append("modestbranding", "");
      return `${protocol}//www.youtube.com/embed/${videoId}?${params.toString()}`;
    }
    default:
      return url;
  }
};

export default getVideoFrameUrl;
