import React, { PropsWithChildren } from "react";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { Divider } from "antd";
import ButtonHuge from "src/common/components/general/button/ButtonHuge";
import CountryFlag from "src/common/components/CountryFlag";
import clsx from "clsx";
import { Language } from "src/utility-features/i18n/language-utils/i18nTypes";
import { PRIMARY_LANGUAGES } from "src/utility-features/i18n/languages";
import useLangStrings, {
  useCurrentLangStrings,
  useCurrentLanguageState,
} from "src/utility-features/i18n/context/languageHooks";
import languageToCountryCode from "src/common/functions/languageToCountryCode";

interface TranslatedButtonProps {
  lang: Language;
}

const TranslatedStartString: React.FC<TranslatedButtonProps> = (props) => {
  const langStrings = useLangStrings(props.lang);
  return <>{langStrings.strings.begin}</>;
};

export interface PtpQrStepsHelloProps extends SiteOrientationStepProps {
  project: string;
}

const PtpQrStepsHello: React.FC<PropsWithChildren<PtpQrStepsHelloProps>> = (
  props,
) => {
  const [_, setCurrentLanguage] = useCurrentLanguageState();
  const startPreTaskPlan = (lang: Language) => {
    setCurrentLanguage(lang);
    props.onNext();
  };

  const langStrings = useCurrentLangStrings();

  return (
    <>
      <SiteFeatureStepLayout>
        <div className="flex flex-col gap-1 min-h-full justify-between">
          <div className="text-2 mt-3 text-center">{props.project}</div>
          <div className={`flex flex-1 flex-col justify-center`}>
            <p className={`text-1.5 tablet-portrait:text-3 font-accent block`}>
              {langStrings.strings.preTaskPlan}
            </p>
            <Divider />
            <p className="text-1.2">
              {" "}
              {langStrings.strings.completePreTaskPlan}{" "}
            </p>
          </div>

          <div className={`flex flex-col gap-0.25`}>
            {PRIMARY_LANGUAGES.map((lang) => {
              const countryCode = languageToCountryCode(lang);
              const flagComponent = countryCode ? (
                <CountryFlag countryCode={countryCode} svg />
              ) : null;

              return (
                <ButtonHuge key={lang} onClick={() => startPreTaskPlan(lang)}>
                  {flagComponent}
                  <span className={clsx(flagComponent && "ml-0.5")}>
                    <TranslatedStartString lang={lang} />
                  </span>
                </ButtonHuge>
              );
            })}
          </div>
        </div>
      </SiteFeatureStepLayout>
    </>
  );
};

export default PtpQrStepsHello;
