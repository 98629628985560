/**
 * @generated SignedSource<<721c27d56ccfa29e9abb757b94ce1653>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { orientation_quiz_result_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SiteOrientationSlidesViewer_UpsertQuizResult_Mutation$variables = {
  object: orientation_quiz_result_insert_input;
};
export type SiteOrientationSlidesViewer_UpsertQuizResult_Mutation$data = {
  readonly insert_orientation_quiz_result_one: {
    readonly id: string;
  } | null | undefined;
};
export type SiteOrientationSlidesViewer_UpsertQuizResult_Mutation = {
  response: SiteOrientationSlidesViewer_UpsertQuizResult_Mutation$data;
  variables: SiteOrientationSlidesViewer_UpsertQuizResult_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "orientation_quiz_result_orientation_result_id_orientation_slide",
          "update_columns": [
            "answer",
            "lang"
          ]
        }
      }
    ],
    "concreteType": "orientation_quiz_result",
    "kind": "LinkedField",
    "name": "insert_orientation_quiz_result_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationSlidesViewer_UpsertQuizResult_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteOrientationSlidesViewer_UpsertQuizResult_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "296f506bea9f17e925d89d13b78ee8e5",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationSlidesViewer_UpsertQuizResult_Mutation",
    "operationKind": "mutation",
    "text": "mutation SiteOrientationSlidesViewer_UpsertQuizResult_Mutation(\n  $object: orientation_quiz_result_insert_input!\n) {\n  insert_orientation_quiz_result_one(object: $object, on_conflict: {constraint: orientation_quiz_result_orientation_result_id_orientation_slide, update_columns: [answer, lang]}) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d24a2be691a672adf6cbd56a11c20fff";

export default node;
