import { IconArrowDown, IconListSearch } from "@tabler/icons";
import clsx from "clsx";
import React, {
  HTMLAttributes,
  TdHTMLAttributes,
  useMemo,
  useRef,
} from "react";
import { Column, useTable } from "react-table";
import { SortingTypePickerProps } from "../../SortingTypePicker";
import Checklist, { CheckListProps } from "../../filter/Checklist";
import { CustomFilterType } from "../../filter/CustomFilterComponents/CustomFilterController";
import FilterController, {
  FilterSearchQueryType,
} from "../../filter/FilterController";
import Button, { ButtonProps } from "../../general/button/Button";
import Icon, { IconProps } from "../../general/Icon";
import isMobile from "src/common/functions/isMobile";
import { useBoolean } from "usehooks-ts";
import CustomTableCell, {
  CustomTableCellContentType,
  CustomTableCellPadding,
  CustomTableCellPopoverType,
} from "./CustomTableCell";
import CustomTableDateRangePicker, {
  CustomTableDateRangePickerProps,
} from "./CustomTableDateRangePicker";
import CustomTableTopBar from "./CustomTableTopBar";
import SearchFilter, { SearchFilterProps } from "./SearchFilter";
import SelectFilter, { SelectFilterProps } from "./SelectFilter";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";

// TODO
// [] Add a fixed button to scroll to the very top of the table (not to the top of a page)
//    For a case whhen a user has to get back to a table's top bar

export type CustomTableColumnSizeType =
  | "sm"
  | "xsm"
  | "md"
  | "ml"
  | "lg"
  | "xl"
  | "icon";

const sizeToClass = (size: CustomTableColumnSizeType) => {
  switch (size) {
    case "icon":
      return "min-w-1 max-w-4";
    case "xsm":
      return "min-w-4 max-w-8";
    case "sm":
      return "min-w-8 max-w-10";
    case "md":
      return "min-w-10 max-w-12";
    case "ml":
      return "min-w-12 max-w-16";
    case "lg":
      return "min-w-16";
    case "xl":
      return "min-w-20";
    default:
      return "min-w-10 max-w-12";
  }
};

export type CustomTableColumnType<T> = {
  dataIndex: ReadonlyArray<string> | string;
  render?: (value: any, record: T, index: number) => React.ReactNode;
  size?: CustomTableColumnSizeType;
  id?: string;
  title: string;
  explainerText?: string;
  contentType?: CustomTableCellContentType;
  cellPadding?: CustomTableCellPadding;
  popoverWrap?: (
    value: any,
    record: any,
    index: number,
  ) => CustomTableCellPopoverType;
};

export type FilterInputType = {
  title: string;
  controller:
    | { type: "checklist"; initialClose?: boolean; props: CheckListProps }
    | {
        type: "date-range";
        initialClose?: boolean;
        props: CustomTableDateRangePickerProps;
      }
    | { type: "search"; initialClose?: boolean; props: SearchFilterProps }
    | { type: "select"; initialClose?: boolean; props: SelectFilterProps };
  id: string;
};

export interface CustomTableProps<T> {
  dataSource: Readonly<T[]>;
  customFilters?: CustomFilterType[];
  columns: NonNullable<CustomTableColumnType<T>>[];
  topBarButtons?: ButtonProps[];
  search?: {
    onSubmit: (searchValue: string) => void;
  };
  narrow?: boolean;
  title?: string; // TODO add a title displaying
  explainerText?: string;
  titleIcon?: IconProps;
  dataIndexFilters?: {
    [key: string]: { val: string | boolean | string[]; onCancel: () => void };
  };
  headerComponent?: React.ReactElement;
  listView?: boolean; // TODO add an option to display table data a list rather than an actual table layout
  totalCount?: number; // TODO idk why do we need this. But I guess it should be displayed somewhere
  loading?: boolean; // TODO display a fallback if a table is loading
  filter?: {
    inputs: FilterInputType[];
    onSubmit: (filterSearchQuery: FilterSearchQueryType) => void;
  };
  hideFiltersAndSort?: boolean;
  sorting?: SortingTypePickerProps;
  onRowClick?: (
    data: T | any,
    index: number,
  ) => HTMLAttributes<any> | TdHTMLAttributes<any>;
  pagination?: {
    loadMore: () => void;
    hasMore: boolean;
    loading: boolean;
  };
  interactive?: boolean;
  filterNotVisibleByDefault?: boolean;
  hideFilter?: boolean;
}

const CustomTable = <T extends object>(props: CustomTableProps<T>) => {
  const defaultFilterVisibility = props.filterNotVisibleByDefault
    ? false
    : (!!props.filter || !!props.customFilters) &&
      !props.hideFilter &&
      !isMobile();

  const {
    value: filterVisible,
    toggle: toggleFilter,
    setFalse: hideFilter,
  } = useBoolean(defaultFilterVisibility);
  const hoverElementRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const columns: Column[] = useMemo(
    () =>
      props.columns.map(
        (oldColumn) =>
          ({
            accessor:
              typeof oldColumn.dataIndex === "string"
                ? oldColumn.dataIndex
                : oldColumn.dataIndex.join("."),
            Header: oldColumn.title,
            id: oldColumn.id,
            Cell: oldColumn.render,
          } as Column),
      ),

    [props.columns],
  );
  const tableInstance = useTable({
    columns,
    //@ts-ignore  TypeScript is unable to infer the type correctly for props.dataSource.
    // The map function ensures that the dataItem is either an object of type DataType or an empty object.
    data: props.dataSource.map((dataItem) =>
      typeof dataItem === "object" ? dataItem : {},
    ),
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const handleMouseEnterRow = (e: React.MouseEvent<HTMLTableRowElement>) => {
    if (hoverElementRef.current) {
      hoverElementRef.current.style.visibility = `unset`;
      hoverElementRef.current.style.height = `${e.currentTarget.offsetHeight}px`;
      hoverElementRef.current.style.top = `${e.currentTarget.offsetTop}px`;
    }
  };
  const handleMouseLeaveRow = () => {
    if (hoverElementRef.current) {
      hoverElementRef.current.style.visibility = `hidden`;
    }
  };

  return (
    <div className="flex h-full flex-col gap-1.5 w-full " ref={wrapperRef}>
      {!!props.title && (
        <div className="flex gap-0.25 w-full mt-1 justify-between">
          <div className="flex flex-row">
            <div className="flex gap-0.5 text-1.5">
              {props.title}
              {props.titleIcon?.icon && <Icon {...props.titleIcon} />}
            </div>
            {!!props.explainerText && (
              <div className="ml-1 mt-0.25">{props.explainerText}</div>
            )}
          </div>

          <div className="text-1 mr-1">{props.headerComponent}</div>
        </div>
      )}
      <div className="flex flex-1 h-auto overflow-scroll">
        <div
          className={` h-full w-full flex-row items-stretch relative flex min-h-min`}
        >
          <div
            className={clsx(
              `flex h-full w-full flex-col items-center gap-1 transition-all`,
              props.narrow && "max-w-48",
            )}
          >
            <div
              className={clsx(
                "flex w-full gap-2 desktop:flex-row desktop:items-center transition-all",
                filterVisible ? "pr-[0rem] desktop:pr-[21rem]" : "pr-[0rem]",
              )}
            >
              {(!!props.topBarButtons?.length ||
                props.search ||
                props.sorting) && (
                <CustomTableTopBar
                  {...{
                    buttons: props.topBarButtons,
                    ...(props.hideFiltersAndSort
                      ? {}
                      : props.hideFilter
                      ? { onSearchSubmit: props.search?.onSubmit }
                      : {
                          onSearchSubmit: props.search?.onSubmit,
                          sorting: props.sorting,
                          filter:
                            !isMobile() &&
                            (!!props.filter || !!props.customFilters)
                              ? // &&
                                // !!props.dataSource.length
                                {
                                  toggle: toggleFilter,
                                  visible: filterVisible && !props.hideFilter,
                                }
                              : undefined,
                        }),
                  }}
                />
              )}
            </div>
            {props.loading ? (
              <LargeTableSkeleton />
            ) : props.dataSource.length ? (
              <div className="flex flex-1 w-full overflow-scroll border-b border-suplementary-1">
                <div className="relative flex flex-row items-center w-full h-full">
                  <div
                    className={`w-full h-full overflow-x-scroll min-h-[20vh] desktop:max-h-[100vh]`}
                  >
                    <div className="relative">
                      <div className="relative flex flex-col">
                        <table
                          className="w-full transition-all"
                          ref={tableRef}
                          {...getTableProps()}
                        >
                          <thead className={`sticky z-10 bg-white -top-px`}>
                            {
                              // Loop over the header rows
                              headerGroups.map((headerGroup) => {
                                const headerGroupProps =
                                  headerGroup.getHeaderGroupProps();
                                return (
                                  // Apply the header row props
                                  <tr
                                    {...headerGroupProps}
                                    key={headerGroupProps.key} // to resolve warning for key to be not expanded in above props
                                  >
                                    {
                                      // Loop over the headers in each row
                                      headerGroup.headers.map(
                                        (columnHeader) => {
                                          const columnHeaderProps =
                                            columnHeader.getHeaderProps();
                                          return (
                                            // Apply the header cell props
                                            <th
                                              {...columnHeaderProps}
                                              key={columnHeaderProps.key}
                                              className={`bg-white`}
                                            >
                                              <div className="flex flex-col w-full border-t border-suplementary-3 rounded-t-2">
                                                <div
                                                  className={`flex flex-row items-center justify-center p-0.5`}
                                                >
                                                  <span className="font-normal text-left text-static-secondary text-0.75">
                                                    {
                                                      // Render the header
                                                      columnHeader.render(
                                                        "Header",
                                                      )
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </th>
                                          );
                                        },
                                      )
                                    }
                                  </tr>
                                );
                              })
                            }
                          </thead>
                          {/* Apply the table body props */}
                          <tbody {...getTableBodyProps()}>
                            {
                              // Loop over the table rows
                              rows.map((row, rowIndex) => {
                                // Prepare the row for display
                                prepareRow(row);
                                const onClick = !!props.onRowClick
                                  ? props.onRowClick(row.original, rowIndex)
                                      .onClick
                                  : undefined;

                                const rowProps = row.getRowProps();
                                return (
                                  // Apply the row props
                                  <tr
                                    {...rowProps}
                                    key={rowProps.key}
                                    onClick={onClick}
                                    // onClick={()=>{}}
                                    className={`${
                                      props.interactive ? "cursor-pointer" : ""
                                    }`}
                                    onMouseEnter={
                                      props.interactive
                                        ? handleMouseEnterRow
                                        : undefined
                                    }
                                    onMouseLeave={
                                      props.interactive
                                        ? handleMouseLeaveRow
                                        : undefined
                                    }
                                  >
                                    {
                                      // Loop over the rows cells
                                      row.cells.map((cell, cellIndex) => {
                                        // Apply the cell props
                                        const cellProps = cell.getCellProps();
                                        return (
                                          <td
                                            className={`border-b relative border-t ${
                                              props.interactive
                                                ? "bg-white border-interactive-secondary"
                                                : "bg-white-1 border-suplementary-1"
                                            } ${
                                              cellIndex === 0
                                                ? "rounded-l-1.5"
                                                : ""
                                            } ${sizeToClass(
                                              props.columns[cellIndex].size ??
                                                "md",
                                            )}`}
                                            {...cell.getCellProps()}
                                            key={cellProps.key}
                                          >
                                            {
                                              // TODO move border styles into CustomTableCell
                                              <CustomTableCell
                                                cellPadding={
                                                  props.columns[cellIndex]
                                                    .cellPadding
                                                }
                                                title={
                                                  props.columns[cellIndex].title
                                                }
                                                value={
                                                  !props.columns[cellIndex]
                                                    .render
                                                    ? cell.value === null ||
                                                      cell.value === undefined
                                                      ? ""
                                                      : cell.value + ""
                                                    : props.columns[
                                                        cellIndex
                                                      ].render?.(
                                                        cell.value,
                                                        cell.row.original as T,
                                                        rowIndex,
                                                      )
                                                }
                                                contentType={
                                                  props.columns[cellIndex]
                                                    .contentType
                                                }
                                                dark={!props.interactive}
                                                popover={props.columns[
                                                  cellIndex
                                                ].popoverWrap?.(
                                                  cell.value,
                                                  cell.row.original,
                                                  rowIndex,
                                                )}
                                              />
                                            }
                                          </td>
                                        );
                                      })
                                    }
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                        {props.interactive && (
                          <div
                            ref={hoverElementRef}
                            className={`absolute transition-all inset-x-0  w-full rounded-1.5 bg-transparent pointer-events-none border border-interactive-primary animate-reveal`}
                            style={{
                              width: `${
                                tableRef.current?.getBoundingClientRect().width
                              }px`,
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`w-full items-center flex-1 flex gap-1 p-1 flex-col rounded-1 bg-suplementary-1 justify-center`}
              >
                <div className="flex flex-row gap-0.5 items-center">
                  <Icon icon={IconListSearch} />
                  <div className="text-center font-accent">{`Nothing added yet`}</div>
                </div>
              </div>
            )}
            {!!props.pagination && props.pagination.hasMore && (
              <div className="flex flex-row items-center justify-center w-full">
                <Button
                  loading={props.pagination.loading}
                  onClick={props.pagination.loadMore}
                  icon={IconArrowDown}
                  secondary
                  label={`Load more`}
                />
              </div>
            )}
          </div>
          {(!!props.filter || !!props.customFilters) && (
            <div
              className={clsx(
                "overflow-hidden h-full z-10 absolute right-0 transition-all",
                filterVisible ? "w-20" : "w-0",
              )}
            >
              <div className="desktop:block z-10 h-full border-l-px border-white w-20 left-0">
                <div className={`w-full h-full`}>
                  <FilterController
                    onCloseClick={hideFilter}
                    dataIndexFilters={props.dataIndexFilters}
                    customFilters={props.customFilters}
                    inputs={(props.filter?.inputs || []).map(
                      ({ controller, title, id }) => {
                        return {
                          title,
                          id,
                          initialClose: controller.initialClose,
                          component:
                            controller.type === "date-range" ? (
                              <CustomTableDateRangePicker
                                {...controller.props}
                              />
                            ) : controller.type === "search" ? (
                              <SearchFilter {...controller.props} />
                            ) : controller.type === "select" ? (
                              <SelectFilter {...controller.props} />
                            ) : (
                              <Checklist {...controller.props} />
                            ),
                        };
                      },
                    )}
                    tags={props.filter?.inputs.map(({ controller }) => {
                      if (
                        !!controller.type &&
                        controller.type !== "checklist"
                      ) {
                        return { heading: " ", appliedFilters: [] };
                      }
                      const { items, onClick }: CheckListProps =
                        controller.props;
                      const selected: {
                        label: string;
                        id: string;
                        onClick: () => void;
                      }[] = items
                        .filter((p: { selected: any }) => p.selected)
                        .map((p: { label: any; id: any }) => ({
                          label: p.label,
                          id: p.id,
                          onClick: () => {
                            onClick(p.id);
                          },
                        }));
                      return { heading: " ", appliedFilters: selected };
                    })}
                    searchFilters={{
                      onSubmit: props.filter?.onSubmit,
                      // filters: props.filter.inputs,
                      inputOptions: (() => {
                        const inputOptionsArr: FilterSearchQueryType[] = [];
                        props.filter?.inputs.forEach(
                          ({ controller, id: filterControllerId }) => {
                            if (controller.type === "checklist") {
                              controller.props.items.forEach(
                                ({ id: inputId, label }) => {
                                  inputOptionsArr.push({
                                    text: label,
                                    inputId,
                                    filterControllerId,
                                  });
                                },
                              );
                            }
                          },
                        );
                        return inputOptionsArr;
                      })(),
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
