type UrlVideoType = "youtube" | "vimeo" | null;

const isYoutubeVideo = (url: string) => {
  const youtubeRegex = 
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\//;
  return youtubeRegex.test(url);
};

const isVimeoVideo = (url: string) => {
  // changed regexp to match logic inside getVideoFrameUrl
  const vimeoRegex = /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\//;
  return vimeoRegex.test(url);
};

const getVideoType: (url: string) => UrlVideoType = (url: string) => {
  if (isYoutubeVideo(url)) {
    return "youtube";
  }
  if (isVimeoVideo(url)) {
    return "vimeo";
  }
  return null;
};

export default getVideoType;
