import { Checkbox } from "antd";
import React from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import { useUpdatePermitProjectByPkMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitGcProjectSettingCheckbox from "./basic/PermitGcProjectSettingCheckbox";
import PermitChecklistsSettingTables from "./basic/PermitChecklistsSettingTables";

const HistoricalAccessSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  data,
  project,
  checklistGc,
  mainGc,
}) => {
  const [updateProject] = useUpdatePermitProjectByPkMutation();

  return (
    <>
      <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: project?.project_setting,
          field: "only_submitter_sign_the_historical_access_permit",
        }}
      />
      {project && (
        <StyledContent>
          <Checkbox
            checked={project.is_historical_access_permit_enabled}
            onChange={(e) => {
              const set = {
                is_historical_access_permit_enabled: e.target.checked,
              };
              updateProject({
                variables: { id: project.id, _set: set },
                optimisticResponse: {
                  update_project_by_pk: { ...project, ...set },
                },
              });
            }}
          >
            Select to use a Historical Access Permit on this project
          </Checkbox>
          <Checkbox
            checked={project.historical_duration_type == "day"}
            onChange={(e) => {
              const set = {
                historical_duration_type: e.target.checked ? "day" : "week",
              };
              updateProject({
                variables: { id: project.id, _set: set },
                optimisticResponse: {
                  update_project_by_pk: { ...project, ...set },
                },
              });
            }}
          >
            Duration - when this setting is selected the max duration of permit
            is 1 Day. UNCHECK to allow duration of 1 Week
          </Checkbox>
        </StyledContent>
      )}
      <PermitChecklistsSettingTables
        {...{
          checklistGc,
          data,
          project,
          gcPermitCheckListItems,
          queryVariables,
          checklistItemsType: "historical_access",
        }}
      />
    </>
  );
};
export default HistoricalAccessSettings;
