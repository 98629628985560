import { Checkbox, Tabs, TimePicker } from "antd";
import React from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  useUpdateGeneralContractorForPermitMutation,
  useUpdateProjectSettingByPkForPermitMutation,
  useUpdatePermitProjectByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitGcProjectSettingCheckbox from "./basic/PermitGcProjectSettingCheckbox";
import PermitChecklistsSettingTables from "./basic/PermitChecklistsSettingTables";

const ConfinedSpaceSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  project,
  mainGc,
  checklistGc,
  data,
}) => {
  const [updateProject] = useUpdatePermitProjectByPkMutation();
  const project_setting = project?.project_setting;
  const settingUseLevel = project_setting?.permit_setting_level;
  const [updateGeneralContractor] =
    useUpdateGeneralContractorForPermitMutation();
  const [updateProjectSetting] = useUpdateProjectSettingByPkForPermitMutation();
  const TypesContent: React.FC<{
    type: "reclassified" | "alternate" | "permitted";
  }> = ({ type }) => (
    <div key={type}>
      <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: project?.project_setting,
          field: `only_submitter_sign_the_${type}_confined_space_permit`,
        }}
      />
      <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: project?.project_setting,
          field: `require_photo_${type}_confined_space`,
          requirePhotoLabel: true,
        }}
      />
    </div>
  );
  const tabItems = [
    {
      label: "Reclassified",
      key: "reclassified",
      children: (
        <>
          <TypesContent type="reclassified" />
          <PermitChecklistsSettingTables
            {...{
              checklistGc,
              project,
              data,
              gcPermitCheckListItems,
              queryVariables,
              checklistItemsType: `reclassified_confined_space`,
            }}
          />
        </>
      ),
    },
    {
      label: "Alternate Entry",
      key: "alternate",
      children: (
        <>
          <TypesContent type="alternate" />
          <PermitChecklistsSettingTables
            {...{
              checklistGc,
              project,
              data,
              gcPermitCheckListItems,
              queryVariables,
              checklistItemsType: `alternate_confined_space`,
            }}
          />
        </>
      ),
    },
    {
      label: "Permit Required",
      key: "permitted",
      children: (
        <>
          <TypesContent type="permitted" />
          <PermitChecklistsSettingTables
            {...{
              checklistGc,
              project,
              data,
              gcPermitCheckListItems,
              queryVariables,
              checklistItemsType: `permitted_confined_space`,
            }}
          />
        </>
      ),
    },
  ];
  const readingInterval =
    settingUseLevel === "project"
      ? project_setting?.confined_space_reading_interval
      : mainGc.confined_space_reading_interval;
  return (
    <>
      {project && (
        <StyledContent>
          <Checkbox
            checked={project.prevent_confined_space_submission}
            onChange={(e) => {
              const set = {
                prevent_confined_space_submission: e.target.checked,
              };
              updateProject({
                variables: { id: project.id, _set: set },
                optimisticResponse: {
                  update_project_by_pk: { ...project, ...set },
                },
              });
            }}
          >
            Prevent Confined Space submission if expired Permit(s) are not
            closed
          </Checkbox>
          <div className="mt-1 mb-2 flex gap-1 items-center">
            Set the the amount of time between readings to receive reminder
            notifications{" "}
            <TimePicker
              allowClear
              minuteStep={5}
              showNow={false}
              value={
                readingInterval ? dayjs(readingInterval, "hh:mm") : undefined
              }
              showSecond={false}
              onChange={(e) => {
                const toSet = {
                  confined_space_reading_interval: e?.format("HH:mm"),
                };
                if (project_setting && settingUseLevel === "project") {
                  updateProjectSetting({
                    variables: {
                      project_id: project_setting.project_id,
                      _set: toSet,
                    },
                    optimisticResponse: {
                      update_project_setting_by_pk: {
                        ...project_setting,
                        ...toSet,
                      },
                    },
                  });
                } else {
                  updateGeneralContractor({
                    variables: { gcId: mainGc.id, _set: toSet },
                    optimisticResponse: {
                      update_general_contractor_by_pk: {
                        ...mainGc,
                        ...toSet,
                      },
                    },
                  });
                }
              }}
            />
          </div>
        </StyledContent>
      )}
      <Tabs items={tabItems} />
    </>
  );
};
export default ConfinedSpaceSettings;
