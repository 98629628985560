import NestedRoute from "../../../../../common/types/manual/NestedRoute";
import DevelopmentPlayground from "../DevelopmentPlayground";
import developmentPlaygroundNavigationItems from "../developmentPlaygroundNavigationItems";
import getRouteChildrenByNavigationItems from "../utils/getRouteChildrenByNavigationItems";

const developmentPlaygroundRoute: NestedRoute = {
  element: <DevelopmentPlayground />,
  children: getRouteChildrenByNavigationItems(
    developmentPlaygroundNavigationItems,
  ),
};

export default developmentPlaygroundRoute;
