import React from "react";
import { SiteOrientationStepProps } from "../../../domain-features/siteorientation/utils/siteOrientationTypes";
import CustomSuspense from "../general/CustomSuspense";
import ButtonHuge from "../general/button/ButtonHuge";

export interface SiteFeatureStepsLayoutProps extends SiteOrientationStepProps {
  nextDisabled?: boolean;
  nextButtonText?: string;
  hideNext?: boolean;
  nextButtonLoading?: boolean;
  children: React.ReactNode;
}

const SiteFeatureStepsLayout: React.FC<SiteFeatureStepsLayoutProps> = (
  props,
) => {
  return (
    <div className={`flex flex-col gap-px h-full w-full`}>
      <div className={`w-full flex-1 relative`}>
        <div className={`w-full h-full overflow-y-auto absolute inset-0`}>
          <CustomSuspense>{props.children}</CustomSuspense>
        </div>
      </div>
      {!props.hideNext && (
        <div className={`w-full flex flex-col gap-0.5`}>
          <ButtonHuge
            onClick={() => {
              props.onNext();
            }}
            type={"primary"}
            disabled={props.nextDisabled}
            loading={props.nextButtonLoading}
          >
            {props.nextButtonText ?? "Next"}
          </ButtonHuge>
        </div>
      )}
    </div>
  );
};

export default SiteFeatureStepsLayout;
