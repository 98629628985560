import React, { FC } from "react";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import SiteOrientationQuizQRCode from "./qr/SiteOrientationQuizQRCode";
import Button from "src/common/components/general/button/Button";
import { useUpdateProjectSettingsMutationReturningValues } from "src/common/api/relay/mutationHooks/useUpdateProjectSettings";
import { Typography } from "antd";
import RemoteQR from "./qr/RemoteQR";
import InPersonQR from "./qr/InPersonQRs";
export interface SettingsQrCodesProps {
  projectId: string;
  params: useUpdateProjectSettingsMutationReturningValues;
  data: useSiteOrientationGcDashboardDataQuery$data;
}

const SettingsQrCodes: FC<SettingsQrCodesProps> = (props) => {
  const projectData = props.data.project_connection.edges[0].node;
  return (
    <div className={`flex flex-col items-center gap-1 w-full`}>
      <p className={`font-accent text-2`}>Orientation QR Codes</p>
      {projectData.orientation_tv_player &&
      projectData.in_person_orientation ? (
        <>
          <div className="flex justify-center w-full">
            <div
              className={`p-2 rounded-1.5 w-full bg-suplementary-1 max-w-48`}
            >
              <div className={`flex flex-row gap-2`}>
                <div className={`flex-1 flex flex-col gap-1`}>
                  <p className={`text-1.5 font-accent`}>
                    Start In-Person Orientation
                  </p>
                  <Typography className={`text-static-secondary`}>
                    <ul className="list-disc">
                      <li>Click this link to start an orientation. </li>
                      <li>
                        SiteForm will display a QR code that workers can scan
                        with their devices. Registered workers will be displayed
                        on the TV.
                      </li>
                      <li>
                        Once the orientation starts, do not refresh or reload
                        the webpage.
                      </li>
                      <li>
                        If your orientation includes a Quiz, a QR Code will
                        display on the final screen to take the quiz.
                      </li>
                    </ul>
                  </Typography>
                </div>
                <div className={`w-12 relative`}>
                  <Button
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/gce/orientation/inperson-screen/${props.projectId}`,
                        "_blank"
                      )
                    }
                    label="Start Orientation"
                  ></Button>
                </div>
              </div>
            </div>
            <div className="ml-2 w-1/6"></div>
          </div>
          <InPersonQR {...props} />
          <RemoteQR
            agcUniversalOrientation={projectData.agc_universal_orientation}
            projectId={props.projectId}
          />
        </>
      ) : (
        <>
          <RemoteQR
            agcUniversalOrientation={projectData.agc_universal_orientation}
            projectId={props.projectId}
          />
          <InPersonQR {...props} />
        </>
      )}
      <div className="flex justify-center w-full">
        <SiteOrientationQuizQRCode
          projectId={props.projectId}
          destinationUrl={
            props.data.quiz_code.edges[0]
              ? `${document.location.origin}/singInLink/${props.data.quiz_code.edges[0].node.pk}?projectId=${props.projectId}`
              : undefined
          }
        />
        <div className="ml-2 w-1/6"></div>
      </div>
    </div>
  );
};

export default SettingsQrCodes;
