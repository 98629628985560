import React from "react";
import { Alert, Button } from "antd";

interface AudioPlayerErrorFallbackProps {
  error: any;
  resetError: () => void;
}

const MediaPlayerErrorFallback: React.FC<AudioPlayerErrorFallbackProps> = (
  props,
) => {
  let message = "Unknown media player error occurred";

  if (props.error instanceof Error) {
    message = props.error.message;
  }

  return (
    <Alert
      message={message}
      showIcon
      type="error"
      action={
        <Button type={"primary"} onClick={props.resetError}>
          Try Again
        </Button>
      }
    />
  );
};

export default MediaPlayerErrorFallback;
