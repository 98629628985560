import {
  IconArrowDown,
  IconArrowUp,
  IconCirclePlus,
  IconX,
} from "@tabler/icons";
import * as React from "react";
import Icon, { IconProps } from "src/common/components/general/Icon";
import Switcher, {
  SwitcherProps,
} from "src/common/components/general/Switcher";
import ShowToggleTableColumn, { ColumnType } from "./ShowToggleTableColumn";
import { Tooltip } from "antd";
import clsx from "clsx";
import Button, { ButtonProps } from "../../general/button/Button";
import ProgressBar, {
  ProgressBarProps,
} from "src/common/components/general/ProgressBar";
import getDateStr from "src/common/functions/getDateStr";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";

import { useState } from "react";

export type GCDashAppReportsColumnType = ColumnType;

export type ExtendedRow = {
  id: string;
  title: string;
  switchers: SwitcherProps[];
  button?: ButtonProps;
  haveChilds?: boolean;
  parentItem?: boolean;
  parentId?: string;
};

export interface GCPerformanceTableProps {
  mainColumn: {
    title: string;
    initiallyHideChilds?: boolean;
    id: string;
    rows: ExtendedRow[];
    switchTitles: Array<{ title: string; icon?: IconProps }>;
    onSortingClick?: () => void;
    hint?: string;
  };
  columns: ColumnType[];
}

interface WrappedProgressBarProps extends ProgressBarProps {
  missingReportsDates: Date[];
}

const WrappedProgressBar: React.FunctionComponent<WrappedProgressBarProps> = (
  props,
) => {
  return (
    <div className="relative">
      <Tooltip
        placement="left"
        trigger={props.missingReportsDates.length > 0 ? ["hover"] : []}
        overlay={
          <p className="overflow-auto max-h-24">
            {props.missingReportsDates.map((date) => (
              <span key={date.toString()} className={`block pr-0.5`}>
                {getDateStr(date)}
              </span>
            ))}
          </p>
        }
      >
        <div>
          <ProgressBar total={props.total} value={props.value} />
        </div>
      </Tooltip>
    </div>
  );
};

const GCPerformanceTable: React.FunctionComponent<GCPerformanceTableProps> = (
  props,
) => {
  const [hideChildsFor, setHideChildsFor] = React.useState<{
    [key: string]: boolean;
  }>(
    props.mainColumn.initiallyHideChilds
      ? Object.fromEntries(
          props.mainColumn.rows
            .filter((row) => row.parentItem)
            .map((r) => [r.id, true]),
        )
      : {},
  );

  const [selectedColumn, toggleColumnVisibility, setSelectedColumn] =
    useSelectedIndicies<string>();

  let totalColumnPercentage: number[] = [];
  let additionalTitleInfo: string[] = [];
  props.columns.forEach((column, i) => {
    let columnTotal = 0;
    let columnSubmitted = 0;
    column.rows.forEach(({ submitted, total }) => {
      columnTotal += total;
      columnSubmitted += submitted;
    });
    totalColumnPercentage.push(
      Math.round((100 / columnTotal) * columnSubmitted),
    );
    additionalTitleInfo.push(`${columnSubmitted} of ${columnTotal}`);
  });

  return (
    <>
      <table>
        <thead>
          <tr>
            <td className="sticky z-50 justify-center items-center text-center gap-5 top-0 bg-white font-accent w-40">
              <div
                className={`transition-opacity flex flex-col text-center justify-center w-full gap-px overflow-hidden `}
              >
                <span>{props.mainColumn.title}</span>
              </div>
            </td>
            {props.columns.map((column, i) => (
              <td
                key={column.title}
                className={clsx(
                  "z-50 justify-center items-center gap-0.5 top-0 bg-white font-accent",
                  selectedColumn.has(`column${i}`) ? "w-15" : "w-5",
                )}
              >
                <div
                  className={clsx(
                    `transition-opacity flex flex-row justify-center text-center w-full gap-px overflow-hidden`,
                  )}
                >
                  <p
                    className={clsx(`truncate whitespace-nowrap`, {
                      hidden: selectedColumn.has(`column${i}`),
                    })}
                  >
                    <span className="font-accent">
                      {`${column.title}, ${
                        !isNaN(totalColumnPercentage[i])
                          ? totalColumnPercentage[i] + "%"
                          : ""
                      } `}
                    </span>
                    {additionalTitleInfo[i] && (
                      <span className="font-normal text-0.75">
                        {additionalTitleInfo[i]}
                      </span>
                    )}
                  </p>
                  <Icon
                    color="interactive"
                    size="small"
                    icon={
                      selectedColumn.has(`column${i}`) ? IconCirclePlus : IconX
                    }
                    onClick={() => toggleColumnVisibility(`column${i}`)}
                  />
                </div>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.mainColumn.rows.map((item, i) => {
            return !item.parentId || !hideChildsFor[item.parentId] ? (
              <tr key={item.id}>
                <td>
                  <div
                    className={clsx(
                      "flex flex-row justify-between items-center gap-0.5",
                      { " font-accent": item.parentId },
                    )}
                  >
                    <div>
                      <span
                        style={{
                          marginLeft:
                            item.parentId && !hideChildsFor[item.parentId]
                              ? "10px"
                              : "0px",
                          paddingBottom:
                            item.switchers.length > 0 ? undefined : "9.2px",
                        }}
                      >
                        {item.title}
                      </span>
                    </div>
                    {item.haveChilds && (
                      <Icon
                        color="interactive"
                        icon={
                          hideChildsFor[item.id] ? IconArrowDown : IconArrowUp
                        }
                        onClick={() => {
                          setHideChildsFor((prev) => {
                            const newObj = { ...prev };
                            if (hideChildsFor[item.id]) {
                              delete newObj[item.id];
                            } else {
                              newObj[item.id] = true;
                            }
                            return newObj;
                          });
                        }}
                      />
                    )}
                    {item.switchers.length > 0 && (
                      <div className="flex flex-row justify-end w-60">
                        {item.switchers.map((switcher, i) => (
                          <Switcher key={i} {...switcher} />
                        ))}
                      </div>
                    )}
                  </div>
                </td>
                {props.columns.map((column, j) => {
                  return (
                    <td
                      key={column.title}
                      className={clsx(
                        selectedColumn.has(`column${j}`) ? "w-15" : "w-5",
                      )}
                    >
                      <div
                        className={clsx("relative w-full", {
                          hidden: selectedColumn.has(`column${j}`),
                        })}
                      >
                        <div
                          className={`transition-opacity flex flex-col justify-center w-full gap-px overflow-hidden `}
                        >
                          <WrappedProgressBar
                            key={item.id}
                            {...{
                              total: column.rows[i].total,
                              missingReportsDates:
                                column.rows[i].missingReportsDates,
                              value: column.rows[i].submitted,
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            ) : (
              <></>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
export default GCPerformanceTable;
