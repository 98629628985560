/**
 * @generated SignedSource<<66bde8aa30cde44d9eec51cefcfddf7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_edit_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendTaskForApprovalModalMutation$variables = {
  object: ReadonlyArray<task_edit_insert_input>;
};
export type SendTaskForApprovalModalMutation$data = {
  readonly insert_task_edit: {
    readonly returning: ReadonlyArray<{
      readonly comment: string | null | undefined;
      readonly edited_by_uid: string | null | undefined;
      readonly id: string;
      readonly task_id: string | null | undefined;
    }>;
  } | null | undefined;
};
export type SendTaskForApprovalModalMutation = {
  response: SendTaskForApprovalModalMutation$data;
  variables: SendTaskForApprovalModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "object"
      }
    ],
    "concreteType": "task_edit_mutation_response",
    "kind": "LinkedField",
    "name": "insert_task_edit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "task_edit",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "task_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "edited_by_uid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendTaskForApprovalModalMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendTaskForApprovalModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7aa4e7c060a075d312e4946aafa7f8f3",
    "id": null,
    "metadata": {},
    "name": "SendTaskForApprovalModalMutation",
    "operationKind": "mutation",
    "text": "mutation SendTaskForApprovalModalMutation(\n  $object: [task_edit_insert_input!]!\n) {\n  insert_task_edit(objects: $object) {\n    returning {\n      id\n      task_id\n      comment\n      edited_by_uid\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6bc452d9cfc7fa6655b4711f4789fe3a";

export default node;
