import { DatePicker, message, Popover, Tag } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { forwardRef, useState } from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import ProjectWorkerTable, {
  ColumnKeys,
  DConnection,
} from "src/common/components/tables/ProjectWorkerTable";
import isNullOrUndefined from "src/common/functions/isNullOrUndefined";
import * as uuid from "uuid";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import {
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import {
  project_worker_bool_exp,
  ProjectWorkerTableQuery,
} from "src/common/types/generated/relay/ProjectWorkerTableQuery.graphql";
import Button from "src/common/components/general/button/Button";
import DrugtestCard from "src/common/components/workerCertificationCard/DrugtestCard";
import useInsertDTResult, {
  upsertUserOrientationDrugtestMutation,
  useAcceptPrevDT,
} from "./utils/useInsertDTResult";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useInsertDTResultMutation } from "src/common/types/generated/relay/useInsertDTResultMutation.graphql";
import { DrugtestStatusType } from "src/domain-features/siteorientation/utils/siteOrientationTypes";

interface DrugtestActionTableProps {
  projectId: string;
  title: string;
  showStatus?: boolean;
  where: project_worker_bool_exp;
  usePreviousDTs?: boolean;
  data: useSiteOrientationGcDashboardDataQuery$data;
  onSave: () => void;
  retest?: boolean;
  includePrevPositiveProjectDTs?: boolean;
  positiveOption?: boolean;
  resultColumnHeader?: string;
  headerComponent?: React.ReactElement;
  negativeOption?: boolean;
  nonNegativeOption?: boolean;
  returnToWorkOption?: boolean;
}

const DrugtestActionTable = forwardRef<
  DataScrollTableRef,
  DrugtestActionTableProps
>(
  (
    {
      projectId,
      data,
      title,
      where,
      onSave,
      usePreviousDTs,
      headerComponent,
      includePrevPositiveProjectDTs,
      resultColumnHeader,
      showStatus,
      positiveOption,
      negativeOption,
      nonNegativeOption,
      retest,
      returnToWorkOption,
    },
    ref,
  ) => {
    const [saving, setSaving] = useState(false);
    const [resultsMap, setResults] = useState<{
      [key: string]: {
        result: DrugtestStatusType;
        id: string;
        date: Dayjs | undefined;
        subcontractorId: string | undefined;
        projectIdsToInsert: Array<string>;
      };
    }>({});
    const orientations = (data?.orientation_connection.edges || []).map(
      (v) => v.node,
    );
    const projectData = (data.project_connection.edges || [])[0]?.node;
    const [upsertDTUserOrientation] =
      useAsyncMutation<useInsertDTResultMutation>(
        upsertUserOrientationDrugtestMutation,
      );
    const allRequiredOrientations = orientations.filter(
      (o) =>
        o.type !== "universal" &&
        o.project_orientations[0] &&
        o.project_orientations[0].required_by_all_workers,
    );
    const hasSlides = allRequiredOrientations.length > 0;
    const hasInPerson = projectData.in_person_orientation;
    const [insertDTResult] = useInsertDTResult();
    if (!projectData) {
      throw new Error("Project id not found, ");
    }
    const [acceptPrevDT] = useAcceptPrevDT();
    const savingResultsMultiple = async (workerIds: Array<string>) => {
      const onFinish = () => {
        setResults((prev) => {
          const newObj = { ...prev };
          workerIds.forEach(
            (workerId) => newObj[workerId] && delete newObj[workerId],
          );
          return { ...newObj };
        });
        message.success("Added Drug Test");
        onSave();
      };
      try {
        await insertDTResult({ projectId, workerIds, resultsMap, onFinish });
      } catch (e) {
        message.error("failed to update result");
        console.log(e);
      } finally {
        setSaving(false);
      }
    };

    //try using map to store result of different radio buttons and the datepicker for different ProjectWorkerId(key as projectWorkerId)
    const saveAllSelected = async () => {
      const workerIds: Array<string> = [];
      setSaving(true);
      for (let workerId in resultsMap) {
        if (
          !isNullOrUndefined(resultsMap[workerId]?.result) &&
          !isNullOrUndefined(resultsMap[workerId]?.date)
        )
          workerIds.push(workerId);
      }
      try {
        await savingResultsMultiple(workerIds);
      } catch (e) {
        console.log(e);
      } finally {
        setSaving(false);
      }
      setResults({});
      // refetchBoth();
    };
    const onResultButtonPress = (
      result: DrugtestStatusType,
      subId: string,
      workerId: string,
      allProjectIdsToRegister: Array<string>,
    ) => {
      if (resultsMap[workerId]) {
        const prevVal = resultsMap[workerId];
        const newVal = {
          ...prevVal,
          result: prevVal.result !== result ? result : undefined,
          subcontractorId: subId,
          projectIdsToInsert: allProjectIdsToRegister,
        };
        setResults((prev) => ({
          ...prev,
          [workerId]: newVal,
        }));
      } else {
        const newVal = {
          result: result,
          date: dayjs(),
          id: uuid.v4(),
          subcontractorId: subId,
          projectIdsToInsert: allProjectIdsToRegister,
        };
        setResults((prev) => ({
          ...prev,
          [workerId]: newVal,
        }));
      }
    };
    const acceptPrev = async (
      row: DConnection["edges"][number]["node"],
      drugtestIdToAccept: string,
    ) => {
      try {
        setSaving(true);
        const projectIdsToInsert = row.worker.allOrientationProjects.map(
          (w) => w.project_id,
        );
        if (!projectIdsToInsert.includes(projectId)) {
          projectIdsToInsert.push(projectId);
        }
        await acceptPrevDT(
          projectIdsToInsert,
          row.worker_id,
          drugtestIdToAccept,
        );
        setSaving(false);
        onSave();
      } catch (err) {
        setSaving(false);
        console.log(err);
        message.error("Couldn't update result");
      }
    };
    return (
      <StyledContent padding backgroundColor="white">
        <ProjectWorkerTable
          ref={ref}
          projectId={projectId}
          filterNotVisibleByDefault={true}
          topBarButtons={[
            {
              secondary:
                Object.values(resultsMap).filter((p) => p.result).length === 0,
              onClick: saveAllSelected,
              label: "Save All",
            },
          ]}
          drugtestWhere={{
            project: {
              general_contractor: { projects: { id: { _eq: projectId } } },
            },
            ...(projectData.drugtest_validity_days
              ? {
                  drug_test_date: {
                    _gte: dayjs()
                      .subtract(projectData.drugtest_validity_days, "days")
                      .endOf("day")
                      .format(),
                  },
                }
              : {}),
          }}
          title={title}
          hasInPerson={hasInPerson}
          hasSlides={hasSlides}
          hideContactInfo
          hasUniversal={
            projectData.agc_universal_orientation &&
            data.orientation_connection.edges.filter(
              (o) => o.node.type === "universal",
            ).length > 0
          }
          defaultSortColumnKey={showStatus ? "drugtestDate" : "registeredDate"}
          includeValidDTs={usePreviousDTs}
          includePrevPositiveProjectDTs={includePrevPositiveProjectDTs}
          headerComponent={headerComponent}
          showRowBorderOnHover
          searchDataIndex={["user", "name"]}
          excludedKeys={[
            "trainingAndCertifications",
            "project",
            "inPersonOrientatedDate",
            "status",
            "title",
            "archivedAt",
            "role",
            ...((showStatus
              ? []
              : ["drugTest", "drugtestDate"]) as ColumnKeys[]),
            "status",
            "dateLogged",
            "universal_orientated_at",
          ]}
          where={where}
          extraColumns={[
            ...(usePreviousDTs
              ? ([
                  {
                    title: "Valid Drug Tests",
                    size: "ml",
                    key: "extra",
                    dataIndex: ["user_orientation", "drug_test_passed"],
                    queryIncludeVarKey: "includeValidDTs",
                    render: (a, row) => {
                      const workerId = row.worker_id;
                      const latestDT = row.worker.latestDT[0];
                      if (
                        latestDT &&
                        latestDT.status === "positive" &&
                        dayjs(latestDT.drug_test_date)
                          .add(projectData.drug_test_retest_days, "days")
                          .isAfter(dayjs().startOf("day"))
                      ) {
                        return (
                          <Popover
                            placement="left"
                            content={
                              <DrugtestCard
                                drugTestIds={[latestDT.pk]}
                                loading={saving}
                                onUse={async (drugtestId, result) => {
                                  await acceptPrev(row, drugtestId);
                                }}
                              />
                            }
                          >
                            <div>
                              <Tag className="ml-0.5 rounded-2 text-center border-semantic-negative text-white font-accent text-0.9 bg-semantic-negative">
                                Positive - Not Eligible <br />
                                for Retest
                              </Tag>
                            </div>
                          </Popover>
                        );
                      }
                      const validDTs = (row.worker.validDTs || [])
                        .map((p) => p.pk)
                        .filter(
                          (p) => p !== row.user_orientation?.drug_test_id,
                        );

                      return (
                        <div className="text-center">
                          {validDTs.length > 0 ? (
                            <div className="flex flex-col gap-0.5">
                              <Popover
                                placement="left"
                                content={
                                  <DrugtestCard
                                    drugTestIds={validDTs}
                                    loading={saving}
                                    onUse={async (drugtestId, result) =>
                                      await acceptPrev(row, drugtestId)
                                    }
                                  />
                                }
                              >
                                <div>
                                  <Tag className="ml-0.5 rounded-2 border-semantic-positive-green text-white font-accent text-0.9 bg-semantic-positive-green">
                                    Valid Test Available
                                  </Tag>
                                </div>
                              </Popover>
                              <Button
                                secondary
                                interactiveOnHover
                                onClick={async () =>
                                  await acceptPrev(row, validDTs[0])
                                }
                                label="Accept Previous Test"
                              />
                            </div>
                          ) : (
                            <div>🚫</div>
                          )}
                        </div>
                      );
                    },
                  },
                ] as DataScrollTableProps<
                  DConnection,
                  ColumnKeys,
                  ProjectWorkerTableQuery
                >["columns"])
              : []),
            {
              title: resultColumnHeader || "Select Test Results",
              size: "lg",
              queryIncludeVarKey: "includeOrienation",
              key: "extra",
              dataIndex: ["worker_id"],
              render: (_, row) => {
                const workerId = row.worker_id;
                const latestDT = row.worker.latestDT[0];
                if (
                  usePreviousDTs &&
                  latestDT &&
                  latestDT.status === "positive" &&
                  dayjs(latestDT.drug_test_date)
                    .add(projectData.drug_test_retest_days, "days")
                    .isAfter(dayjs().startOf("day"))
                ) {
                  return (
                    <Button
                      interactiveOnHover
                      loading={saving}
                      secondary
                      onClick={async () => await acceptPrev(row, latestDT.pk)}
                      label="Acknowledge"
                    ></Button>
                  );
                }
                const subId = row.worker.subcontractor_id as string;
                const retestEligible =
                  row.user_orientation?.drug_test?.drug_test_date &&
                  dayjs(row.user_orientation.drug_test.drug_test_date)
                    .add(projectData.drug_test_retest_days, "days")
                    .isBefore(dayjs().startOf("day"));

                //due to this subcontractor_worker:{} in where prop above we will only have those worker who are under any sub right now
                return row.user_orientation?.drug_test?.status !==
                  "negative" ? (
                  <>
                    {(!retest ||
                      (retestEligible &&
                        projectData.drug_test_retest_days > 0)) && (
                      <>
                        <div className="flex gap-0.5">
                          {positiveOption && (
                            <Button
                              loading={saving}
                              interactiveOnHover
                              textColor="red"
                              delete={
                                resultsMap[workerId]?.result === "positive"
                              }
                              secondary={
                                resultsMap[workerId]?.result !== "positive"
                              }
                              onClick={() => {
                                onResultButtonPress(
                                  "positive",
                                  subId,
                                  workerId,
                                  row.worker.allOrientationProjects.map(
                                    (w) => w.project_id,
                                  ),
                                );
                              }}
                              label="Positive"
                            ></Button>
                          )}
                          {negativeOption && (
                            <Button
                              loading={saving}
                              interactiveOnHover
                              textColor="green"
                              green={
                                resultsMap[workerId]?.result === "negative"
                              }
                              secondary={
                                resultsMap[workerId]?.result !== "negative"
                              }
                              onClick={() => {
                                onResultButtonPress(
                                  "negative",
                                  subId,
                                  workerId,
                                  row.worker.allOrientationProjects.map(
                                    (w) => w.project_id,
                                  ),
                                );
                              }}
                              label="Negative"
                            ></Button>
                          )}
                          {nonNegativeOption && (
                            <Button
                              loading={saving}
                              textColor="grey"
                              interactiveOnHover
                              secondary={
                                resultsMap[workerId]?.result !== "non-negative"
                              }
                              grey={
                                resultsMap[workerId]?.result === "non-negative"
                              }
                              onClick={() => {
                                onResultButtonPress(
                                  "non-negative",
                                  subId,
                                  workerId,
                                  row.worker.allOrientationProjects.map(
                                    (w) => w.project_id,
                                  ),
                                );
                              }}
                              label="Non-Negative"
                            ></Button>
                          )}
                        </div>
                      </>
                    )}
                    {returnToWorkOption &&
                      (!retestEligible ||
                        projectData.drug_test_retest_days <= 0) && (
                        <div className="flex gap-0.5">
                          <Button
                            loading={saving}
                            secondary={
                              resultsMap[workerId]?.result !== "negative"
                            }
                            onClick={() => {
                              onResultButtonPress(
                                "negative",
                                subId,
                                workerId,
                                row.worker.allOrientationProjects.map(
                                  (w) => w.project_id,
                                ),
                              );
                            }}
                            label={`Modify Eligibility`}
                          ></Button>
                          {resultsMap[workerId]?.result === "negative"
                            ? "Enter date of last Negative test"
                            : ""}
                        </div>
                      )}
                  </>
                ) : (
                  <></>
                );
              },
            },
            {
              title: "Date of Test",
              size: "md",
              key: "extra",
              dataIndex: [""],
              queryIncludeVarKey: "includeOrienation",
              render: (_, row) => (
                <DatePicker
                  disabled={
                    isNullOrUndefined(resultsMap[row.worker_id]?.result) ||
                    saving
                  }
                  defaultPickerValue={dayjs()}
                  defaultValue={dayjs()}
                  onChange={(e) => {
                    const workerId = row.worker_id;
                    const subId = row.worker.subcontractor_id as string;
                    if (resultsMap[workerId]) {
                      const prevVal = resultsMap[workerId];
                      const newVal = {
                        ...prevVal,
                        date: e ?? undefined,
                        subcontractorId: subId,
                        projectIdsToInsert:
                          row.worker.allOrientationProjects.map(
                            (p) => p.project_id,
                          ),
                      };
                      setResults((prev) => ({ ...prev, [workerId]: newVal }));
                    } else {
                      const newVal = {
                        id: uuid.v4(),
                        result: undefined,
                        date: e ?? undefined,
                        subcontractorId: subId,
                        projectIdsToInsert:
                          row.worker.allOrientationProjects.map(
                            (p) => p.project_id,
                          ),
                      };
                      setResults((prev) => ({ ...prev, [workerId]: newVal }));
                    }
                  }}
                />
              ),
            },
            {
              title: "Save",
              size: "md",
              key: "extra",
              queryIncludeVarKey: "includeOrienation",
              dataIndex: ["worker_id"],
              render: (_, row) => (
                <div className="text-center">
                  <Button
                    loading={saving}
                    secondary={!resultsMap[row.worker_id]?.result}
                    onClick={async () => {
                      const workerId = row.worker_id;
                      setSaving(true);
                      await savingResultsMultiple([workerId]);
                      setSaving(false);
                    }}
                  >
                    Save
                  </Button>
                </div>
              ),
            },
          ]}
        />
      </StyledContent>
    );
  },
);
export default DrugtestActionTable;
