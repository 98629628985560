import {
  IconClock,
  IconUserCheck,
  IconUserCircle,
  IconUserMinus,
  IconUserPlus,
  IconX,
} from "@tabler/icons";
import { Modal } from "antd";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import Checklist from "src/common/components/filter/Checklist";
import FilterController, {
  FilterSearchQueryType,
} from "src/common/components/filter/FilterController";
import NumericalRangePicker from "src/common/components/filter/NumericalRangePicker";
import RadioList from "src/common/components/filter/RadioList";
import SelectFilter from "src/common/components/tables/basic/SelectFilter";
import Age from "src/common/components/general/Age";
import Button from "src/common/components/general/button/Button";
import CounterLabel from "src/common/components/general/CounterLabel";
import LoadingContent from "src/common/components/general/LoadingContent";
import Switcher from "src/common/components/general/Switcher";
import PeopleList from "src/common/components/lists/people-list/PeopleList";
import { SortOrderType } from "src/common/components/SortingTypePicker";
import capitalize from "src/common/functions/capitalize";
import deepCopy from "src/common/functions/deepCopy";
import OrientationInviteModal, {
  OrientationInviteModalRef,
} from "./components/OrientationInviteModal";
import TopBar from "./SCWorkersListTopBar";
import WorkerProfile from "./worker-profile/WorkerProfile";
import { getCertificateExpiryValue } from "src/common/functions/getCertificateExpiryValue";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";

export type Certificate = {
  name: string;
  expiryDate?: string | null;
};

export type Worker = {
  name: string;
  age?: number;
  phoneNumber?: string;
  email?: string | null;
  createdPassword?: boolean;
  underAnotherSub?: boolean;
  currentWorkerSubId?: string | null;
  projectsAmount?: number;
  drugTestsAmount?: number;
  trade?: string;
  role?: string;
  certificates?: Certificate[];
  dataId: string;
  id: string;
  imageUrl?: URL;
  blurhash?: string;
  terminated: {
    status: boolean;
    onChange: () => Promise<void>;
    loading: boolean;
  };
};

type Options = { label: string; selected: boolean; id: string }[];
type SortingProperty = "name" | "age" | "projectsAmount";
const sortingTypes: { label: string; property: SortingProperty }[] = [
  { label: "name", property: "name" },
  { label: "age", property: "age" },
  { label: "# of projects", property: "projectsAmount" },
];

interface SCWorkersUIProps {
  workers: Worker[];
  projects: { id: string; name: string }[];
  onWorkerTerminate: () => void;
  onWorkerAdded?: () => void;
  loadingWorkers?: boolean;
}

const SCWorkersUI: React.FunctionComponent<SCWorkersUIProps> = (props) => {
  const subcontractorId = useSubcontractorId();
  const getFilterOptions = (
    optionName:
      | "trade"
      | "role"
      | "certificatesHave"
      | "certificatesNotHave"
      | "certificatesExpiry",
  ) => {
    let array: Options = [];
    if (
      optionName !== "certificatesHave" &&
      optionName !== "certificatesNotHave" &&
      optionName !== "certificatesExpiry"
    ) {
      props.workers.forEach((worker, i) => {
        const value = worker[optionName];
        if (
          value &&
          array.findIndex((option) => option.label === value) === -1
        ) {
          array.push({ label: value, selected: false, id: value + i });
        }
      });
    } else if (
      optionName == "certificatesHave" ||
      optionName == "certificatesNotHave"
    ) {
      props.workers.forEach((worker) => {
        if (worker.certificates) {
          worker.certificates.forEach((certificate, i) => {
            if (
              array.findIndex((item) => item.label === certificate.name) === -1
            ) {
              array.push({
                label: certificate.name,
                selected: false,
                id: certificate.name + i,
              });
            }
          });
        }
      });
    } else if (optionName == "certificatesExpiry") {
      array.push({
        label: "Expiring in < 30 days",
        selected: false,
        id: "30",
      });
      array.push({
        label: "Expiring in < 60 days",
        selected: false,
        id: "60",
      });
      array.push({
        label: "Expiring in < 90 days",
        selected: false,
        id: "90",
      });
    }
    return array;
  };

  const [selectedWorkerId, setSelectedWorkerId] = useState<string>(
    props.workers?.[0]?.id,
  );
  //why i put the question marks?? it's because it may happen that there are no workers on the subList
  const [showFilterController, setShowFilterController] = useState(true);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const modal = useRef<OrientationInviteModalRef>(null);
  const [addbackWorker, setAddBackWorker] = useState<Worker>();

  const [trades, setTrades] = useState<Options>(getFilterOptions("trade"));
  const [certificateExpiryList, setCertificateExpiryList] = useState<Options>(
    getFilterOptions("certificatesExpiry"),
  );
  const [roles, setRoles] = useState<Options>(getFilterOptions("role"));
  const [certificates, setCertificates] = useState<Options>(
    getFilterOptions("certificatesHave"),
  );
  const [certificatesNotHave, setCertificatesNotHave] = useState<Options>(
    getFilterOptions("certificatesNotHave"),
  );
  const [drugTestRequired, setDrugTestRequired] = useState<boolean>(false);
  const [age, setAge] = useState<{
    min: number | undefined;
    max: number | undefined;
  }>();
  const [sorting, setSorting] = useState<{
    type: string;
    order: SortOrderType;
  }>({
    type: sortingTypes[0].label,
    order: "ascending",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const handleChecklistClick = (
    id: string,
    setOptions: (callBack: (prevState: Options) => Options) => void,
  ) => {
    setOptions((prevOptions) => {
      const foundOptions = prevOptions.find((item) => item.id === id);

      if (foundOptions) {
        foundOptions.selected = !foundOptions.selected;
        return deepCopy(prevOptions);
      }
      return prevOptions;
    });
  };

  const handleRadiolistClick = (
    id: string,
    setOptions: (callBack: (prevState: Options) => Options) => void,
  ) => {
    setOptions((prevOptions) => {
      for (const option of prevOptions) {
        option.selected = option.id == id;
      }
      return deepCopy(prevOptions);
    });
  };

  const handleRadioPillClick = (
    id: string,
    setOptions: (callBack: (prevState: Options) => Options) => void,
  ) => {
    setOptions((prevOptions) => {
      for (const option of prevOptions) {
        if (option.id == id) option.selected = !option.selected;
      }
      return deepCopy(prevOptions);
    });
  };

  const handleSelectChange = (
    newVals: string[],
    setOptions: (callBack: (prevState: Options) => Options) => void,
  ) => {
    setOptions((prevOptions) => {
      for (const option of prevOptions) {
        option.selected = newVals.findIndex((val) => option.id == val) != -1;
      }
      return deepCopy(prevOptions);
    });
  };

  const handleFilterButtonClick = () => {
    setShowFilterController(true);
  };

  const handleWorkerClick = (workerId: string) => {
    setShowFilterController(false);
    setSelectedWorkerId(workerId);
  };

  const handleAgeChange = (min: number, max: number) => {
    setAge({ min, max });
  };

  const handleFilterSearchSubmit: (
    searyQuery: FilterSearchQueryType,
  ) => void = (searchQuery) => {
    // The function triggers once the user hits the enter button while typing in the filter's text input
    let somethingFound = false;
    const findOption = (
      options: Options,
      setOptions: (callBack: (prevOptions: Options) => Options) => void,
    ) => {
      const foundOptionIndex = options.findIndex(
        (option) => option.id === searchQuery.inputId,
      );
      if (foundOptionIndex !== -1) {
        somethingFound = true;
        setOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          newOptions[foundOptionIndex] = {
            ...newOptions[foundOptionIndex],
            selected: true,
          };
          return newOptions;
        });
      }
    };
    findOption(trades, setTrades);
    console.log("set roles", roles);
    findOption(roles, setRoles);
    findOption(certificates, setCertificates);
  };

  const handleTradesClick = (id: string) => {
    handleChecklistClick(id, setTrades);
  };
  const handleCertificatesExpirylistClick = (id: string) => {
    handleRadiolistClick(id, setCertificateExpiryList);
  };
  const handleTrainingAndCertificatesChange = (newVals: string[]) => {
    handleSelectChange(newVals, setCertificates);
  };
  const handleTrainingAndCertificatesNotHaveChange = (newVals: string[]) => {
    handleSelectChange(newVals, setCertificatesNotHave);
  };
  const handleCertificatesExpiryPillClick = (id: string) => {
    handleRadioPillClick(id, setCertificateExpiryList);
  };
  const handleRolesClick = (id: string) => {
    handleChecklistClick(id, setRoles);
  };
  const handleCertificatesClick = (id: string) => {
    handleChecklistClick(id, setCertificates);
  };
  const handleCertificatesNotHaveClick = (id: string) => {
    handleChecklistClick(id, setCertificatesNotHave);
  };

  const filterWorkers = (workersToFilter: Worker[], active: boolean) =>
    workersToFilter.filter((worker) => {
      // check if terminated/active
      if (worker.terminated && worker.terminated.status === !active) {
        return false;
      }

      // filtering by drug test
      if (drugTestRequired && !worker.drugTestsAmount) {
        return false;
      }

      // filtering by name
      if (
        searchQuery &&
        !worker.name
          .replace(/\s+/g, " ")
          .trim()
          .split(" ")
          .some(
            (word) =>
              word.substring(0, searchQuery.length).toLowerCase() ===
              searchQuery.toLowerCase(),
          )
      )
        return false;

      const optionsMatch = (
        selectedOptions: Options,
        workerOptions: string[],
      ) => {
        if (
          selectedOptions.length &&
          workerOptions &&
          selectedOptions.findIndex(({ label }) => {
            return (
              workerOptions?.findIndex(
                (certificate) => certificate === label,
              ) !== -1
            );
          }) === -1
        ) {
          return false;
        }
        return true;
      };

      const optionsExclude = (
        notSelectedOptions: Options,
        workerOptions: string[] | undefined,
      ) => {
        if (
          notSelectedOptions.length &&
          workerOptions &&
          notSelectedOptions.findIndex(({ label }) => {
            return (
              workerOptions?.findIndex(
                (certificate) => certificate === label,
              ) !== -1
            );
          }) !== -1
        ) {
          return false;
        }
        return true;
      };

      const selectedTrades = trades.filter(({ selected }) => selected);
      const workerTrades = worker.trade && [worker.trade];
      const selectedRoles = roles.filter(({ selected }) => selected);
      const workerRoles = worker.role && [worker.role];
      const selectedCertificatesExpiry = certificateExpiryList.find(
        ({ selected }) => selected,
      );
      const selectedCertificates = certificates.filter(
        ({ selected }) => selected,
      );
      const notSelectedCertificates = certificatesNotHave.filter(
        ({ selected }) => selected,
      );
      const workerCertificates = worker.certificates?.map((cert) => cert.name);

      // filtering by certificates

      if (
        (workerCertificates &&
          !optionsMatch(selectedCertificates, workerCertificates)) ||
        !optionsExclude(notSelectedCertificates, workerCertificates) ||
        (workerRoles && !optionsMatch(selectedRoles, workerRoles)) ||
        (workerTrades && !optionsMatch(selectedTrades, workerTrades))
      ) {
        return false;
      }

      // filtering by trades

      if (worker.age !== undefined && age && age.min && age.max) {
        if (age.min > worker.age) {
          return false;
        }
        if (age.max < worker.age) {
          return false;
        }
      }

      // filtering by certificatesExpiry

      if (worker.certificates && selectedCertificatesExpiry) {
        let flag = false;
        let filteredCertificates = worker.certificates;
        if (selectedCertificates.length > 0) {
          filteredCertificates = worker.certificates.filter(
            (cert) =>
              selectedCertificates.findIndex(
                (selectedCert) => cert.name == selectedCert.label,
              ) !== -1,
          );
        }
        for (const cert of filteredCertificates) {
          if (!cert.expiryDate) {
            continue;
          }
          const expiryMoment = dayjs(cert.expiryDate);
          const currentMoment = dayjs();
          const days = expiryMoment.diff(currentMoment, "days");
          if (selectedCertificatesExpiry.id == "30") {
            if (days < 30) {
              flag = true;
            }
          } else if (selectedCertificatesExpiry.id == "60") {
            if (days < 60) {
              flag = true;
            }
          } else if (selectedCertificatesExpiry.id == "90") {
            if (days < 90) {
              flag = true;
            }
          }
        }
        if (!flag) {
          return false;
        }
      }
      return true;
    });

  const sortWorkers = (workersToSort: Worker[]) => {
    const property: SortingProperty | undefined = sortingTypes.find(
      ({ label }) => label === sorting.type,
    )?.property;
    if (property === undefined) {
      return workersToSort;
    }

    let comparing: (a: string | number, b: string | number) => number;
    switch (sorting.order) {
      case "ascending":
        comparing = (a, b) => (a > b ? 1 : a < b ? -1 : 0);
        break;
      case "descending":
        comparing = (a, b) => (a < b ? 1 : a > b ? -1 : 0);
        break;
      default:
        break;
    }

    return workersToSort.sort((worker1, worker2) => {
      const a = worker1[property];
      const b = worker2[property];
      if (a !== undefined && b !== undefined) {
        return comparing(a, b);
      }
      return a !== undefined && b !== undefined
        ? comparing(a, b)
        : a !== undefined
        ? -1
        : b !== undefined
        ? 1
        : 0;
    });
  };

  const handleSortingTypeClick = (newSortingType: string) => {
    setSorting(({ order }) => ({ type: newSortingType, order }));
  };
  const handleSortOrderClick = (newSortOrder: SortOrderType) => {
    setSorting(({ type }) => ({ type, order: newSortOrder }));
  };
  const handleSearchSubmit = (newSearchQuery: string) => {
    setSearchQuery(newSearchQuery);
    return false;
  };

  const workerListItemContent = (worker: Worker) => {
    const contentArray: React.ReactElement[] = [];

    contentArray.push(
      <span key="name" className="text-1.5">
        {worker.name}
      </span>,
    );
    if (worker.age !== undefined) {
      contentArray.push(<Age key="age" value={worker.age} large />);
    }
    if (worker.trade || worker.role) {
      contentArray.push(
        <span key="trade" className="">
          {capitalize(
            [worker.trade, worker.role]
              .filter((item) => !!item)
              .join(", ")
              .toLowerCase(),
          )}
        </span>,
      );
    }
    if (worker.projectsAmount) {
      contentArray.push(
        <CounterLabel
          key="projects"
          count={worker.projectsAmount}
          label={`Projects`}
        />,
      );
    }
    if (worker.certificates) {
      let clockColor = getCertificateExpiryValue(worker.certificates);
      if (clockColor) {
        contentArray.push(
          <CounterLabel
            key="certificates"
            count={worker.certificates.length}
            label={`Certificates`}
            iconColor={clockColor}
            icon={IconClock}
            widthCssClass="w-6"
          />,
        );
      } else {
        contentArray.push(
          <CounterLabel
            key="certificates"
            count={worker.certificates.length}
            label={`Certificates`}
            widthCssClass="w-6"
          />,
        );
      }
    }
    if (worker.drugTestsAmount) {
      contentArray.push(
        <CounterLabel
          key="drugtests"
          count={worker.drugTestsAmount}
          label={`Drug tests`}
        />,
      );
    }

    return contentArray;
  };

  const handleDrugTestSwitch = (optionIndex: 1 | 0) => {
    setDrugTestRequired(optionIndex === 1);
  };
  // loading: changing, Can you put this loading status on the whole list
  return subcontractorId ? (
    <div className="w-full h-full">
      <div className="flex flex-row h-full margin-gap-x-1">
        <div className="flex flex-col flex-1 h-full flex-column margin-gap-y-1">
          <TopBar
            roles={roles.map((r) => ({ value: r.id, label: r.label }))}
            trades={trades.map((t) => ({ value: t.id, label: t.label }))}
            refetch={props.onWorkerAdded}
            workersData={props.workers
              .filter((w) => !w.terminated.status)
              .map((u) => ({
                name: u.name,
                trade: u.trade,
                id: u.id,
                dataId: u.dataId,
                email: u.email,
                createdPasswor: u.createdPassword,
                subcontractorId: subcontractorId,
              }))}
            searchBar={{
              onSearchSubmit: handleSearchSubmit,
            }}
            {...{
              onFilterButtonClick: handleFilterButtonClick,
              sorting: {
                onOrderClick: handleSortOrderClick,
                onSortingTypeClick: (newSortingType: string) => {
                  handleSortingTypeClick(newSortingType);
                },
                order: sorting.order,
                selectedSortingType: sorting.type,
                sortingTypes: sortingTypes.map(({ label }) => ({
                  id: label,
                  title: label,
                })),
              },
              onSearchSubmit: handleSearchSubmit,
              showFilterButton: !showFilterController,
              onWorkerAdded: props.onWorkerAdded,
            }}
          />
          <Modal
            title={"warning"}
            open={open}
            onOk={async () => {
              await addbackWorker?.terminated.onChange();
              setOpen(false);
              setAddBackWorker(undefined);
            }}
            onCancel={() => setOpen(false)}
          >
            WARNING: This workers is under another subcontractor, adding him
            back will remove him from his current subcontractor.
          </Modal>
          {!props.loadingWorkers ? (
            <PeopleList
              selectedItemId={
                showFilterController ? undefined : selectedWorkerId
              }
              items={sortWorkers(filterWorkers(props.workers, false)).map(
                (worker) => ({
                  onClick: () => {
                    handleWorkerClick(worker.id);
                  },
                  id: worker.id,
                  content: workerListItemContent(worker),
                  avatarImage: {
                    alt: `profile`,
                    icon: IconUserCircle,
                    imageUrl: worker.imageUrl,
                  },
                }),
              )}
              secondaryList={{
                items: sortWorkers(filterWorkers(props.workers, true)).map(
                  (worker) => ({
                    id: worker.id,
                    name: worker.name,
                    image: {
                      alt: `profile`,
                      icon: IconUserCircle,
                      imageUrl: worker.imageUrl,
                    },
                    onClick: () => {
                      handleWorkerClick(worker.id);
                    },
                    buttons: [
                      {
                        icon: worker.terminated.status
                          ? IconUserPlus
                          : IconUserMinus,
                        onClick: async () => {
                          if (worker.underAnotherSub) {
                            setAddBackWorker(worker);
                            setOpen(true);
                          } else {
                            await worker.terminated.onChange();
                          }
                        },
                        loading: worker.terminated.loading,
                      },
                    ],
                  }),
                ),
              }}
              secondaryListTitle={`Workers no longer employed`}
            />
          ) : (
            <LoadingContent fullSize />
          )}
        </div>
        <div className="relative w-20 h-full overflow-hidden rounded-2 bg-suplementary-1">
          <div
            className={`absolute h-full w-20 overflow-y-auto transition-all ${
              showFilterController ? "right-full" : "right-0"
            }`}
          >
            {selectedWorkerId &&
              (!props.loadingWorkers ? (
                <WorkerProfile
                  type="sub"
                  subcontractorId={subcontractorId}
                  key={selectedWorkerId}
                  workerId={selectedWorkerId}
                  projects={props.projects}
                  refetchWorkersList={props.onWorkerTerminate}
                />
              ) : (
                <LoadingContent fullSize />
              ))}
          </div>

          <div
            className={`absolute h-full w-20 overflow-y-auto transition-all ${
              !showFilterController ? "left-full" : "left-0"
            }`}
          >
            <OrientationInviteModal
              visible={openModal}
              ref={modal}
              projects={props.projects}
              reset={() => setOpenModal(false)}
            />
            <div className="font-accent flex justify-center items-center mt-2  rounded-2">
              <Button
                onClick={() => setOpenModal(true)}
                label="Orientation +"
                fullWidth
              />
            </div>
            <FilterController
              searchFilters={{
                placeholder: `E.g. ${roles[0]?.label ?? ""}`,
                inputOptions: [
                  ...trades.map((trade) => ({
                    inputId: trade.id,
                    text: trade.label,
                    filterControllerId: "title",
                  })),
                  ...roles.map((role) => ({
                    inputId: role.id,
                    text: role.label,
                    filterControllerId: "role",
                  })),
                  ...certificates.map((certificate) => ({
                    inputId: certificate.id,
                    text: certificate.label,
                    filterControllerId: "certificate",
                  })),
                  ...certificateExpiryList.map((certificateExpiry) => ({
                    inputId: certificateExpiry.id,
                    text: certificateExpiry.label,
                    filterControllerId: "certificateExpiry",
                  })),
                ],
                onSubmit: handleFilterSearchSubmit,
              }}
              inputs={[
                {
                  title: "Age",
                  id: "age",
                  component: (
                    <NumericalRangePicker
                      minValue={0}
                      maxValue={100}
                      onChange={handleAgeChange}
                    />
                  ),
                },
                {
                  title: "Trade",
                  id: "title",
                  component: (
                    <Checklist items={trades} onClick={handleTradesClick} />
                  ),
                },
                {
                  title: "Role",
                  id: "role",
                  component: (
                    <Checklist items={roles} onClick={handleRolesClick} />
                  ),
                },
                {
                  title: "Certificates",
                  id: "certificates",
                  component: (
                    <RadioList
                      items={certificateExpiryList}
                      onClick={handleCertificatesExpirylistClick}
                    />
                  ),
                },
                {
                  title: "Training Certifications: Have",
                  id: "certificate",
                  component: (
                    <SelectFilter
                      items={certificates}
                      onChange={handleTrainingAndCertificatesChange}
                      placeholder="Certification/Training"
                      setSelectedValues={true}
                    />
                  ),
                },
                {
                  title: "Training Certifications: NOT Have",
                  id: "certificate_not",
                  component: (
                    <SelectFilter
                      items={certificatesNotHave}
                      onChange={handleTrainingAndCertificatesNotHaveChange}
                      placeholder="Certification/Training"
                      setSelectedValues={true}
                    />
                  ),
                },
                {
                  title: "Drug test",
                  id: "drug_tests",
                  component: (
                    <Switcher
                      onChange={handleDrugTestSwitch}
                      options={[
                        { icon: IconX, label: "optional" },
                        { icon: IconUserCheck, label: "required" },
                      ]}
                      optionIndex={drugTestRequired ? 1 : 0}
                    />
                  ),
                },
              ]}
              tags={[
                {
                  heading: "",
                  appliedFilters: [
                    ...roles
                      .filter(({ selected }) => selected)
                      .map(({ id, label }) => ({
                        label,
                        id,
                        onClick: () => {
                          handleRolesClick(id);
                        },
                      })),
                  ],
                },
                {
                  heading: "Training Certifications: Have:",
                  appliedFilters: [
                    ...certificates
                      .filter(({ selected }) => selected)
                      .map(({ id, label }) => ({
                        label,
                        id,
                        onClick: () => {
                          handleCertificatesClick(id);
                        },
                      })),
                  ],
                },
                {
                  heading: "Training Certifications: NOT Have:",
                  appliedFilters: [
                    ...certificatesNotHave
                      .filter(({ selected }) => selected)
                      .map(({ id, label }) => ({
                        label,
                        id,
                        onClick: () => {
                          handleCertificatesNotHaveClick(id);
                        },
                      })),
                  ],
                },
                {
                  heading: "",
                  appliedFilters: [
                    ...trades
                      .filter(({ selected }) => selected)
                      .map(({ id, label }) => ({
                        label,
                        id,
                        onClick: () => {
                          handleTradesClick(id);
                        },
                      })),
                  ],
                },
                {
                  heading: "",
                  appliedFilters: [
                    ...certificateExpiryList
                      .filter(({ selected }) => selected)
                      .map(({ id, label }) => ({
                        label,
                        id,
                        onClick: () => {
                          handleCertificatesExpiryPillClick(id);
                        },
                      })),
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to={"/sub"} />
  );
};

export default SCWorkersUI;
