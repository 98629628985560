import React from "react";
import capitalize from "src/common/functions/capitalize";
import getDateStr from "src/common/functions/getDateStr";
import Icon, { IconType } from "src/common/components/general/Icon";
import { IconClock, IconUser, IconUsers } from "@tabler/icons";
import getPermitBackgroundColor from "src/common/functions/getPermitBackgroundColor";
/*
  --- About the permit types: ---

  Dig/Excavation/Ground Penetration - the same(companies call it differently)
  Confined Space - going into a tunnel, sewar, barrel, or space where you can be trapped and if conditions are not safe you can die easily. But this permit is not often used
  Work Above - allowing you to work above the ground with possibly workers below. There is risk of dropping tools, debris, materials so the workers are to fence the area for caution to make sure no one walks under them. This is pictures of example
 */

export interface SiteBoardPermitProps {
  type:
    | "burn"
    | "hot work"
    | "dig"
    | "out of basket"
    | "steel erection"
    | "loto"
    | "confined space"
    | "work above"
    | "access ladder"
    | "working ladder"
    | "historical access"
    | "interior penetration"
    | "dig excavation";
  status: "approved" | "closed" | "rejected" | string;
  subConfinedType?: string;
  description?: string;
  location?: string;
  time?: Date;
  workAboveElevationType?: string;
  hoursDuration?: number;
  companyName?: string;
  workerName?: string;
  workerRole?: string;
  id: string;
}

const SiteBoardPermit: React.FunctionComponent<SiteBoardPermitProps> = (
  props,
) => {
  const { semanticColorClass, semanticDarkColorClass } =
    getPermitBackgroundColor(props.status);
  let permitTypeSymbol = null;
  switch (props.type) {
    case "confined space":
      permitTypeSymbol = "🔦";
      break;
    case "dig":
    case "interior penetration":
    case "dig excavation":
      permitTypeSymbol = "⛏️";
      break;
    case "hot work":
    case "burn":
      permitTypeSymbol = "🔥";
      break;
    case "work above":
      permitTypeSymbol = props.workAboveElevationType ? "🕸️" : "🚫";
      break;
    case "loto":
      permitTypeSymbol = "🔒";
      break;
    case "steel erection":
      permitTypeSymbol = "🏗️";
      break;
    case "out of basket":
      permitTypeSymbol = "🧺";
      break;
    default:
      break;
  }

  const IconLabel: React.FunctionComponent<{
    icon: IconType;
    label: string;
  }> = ({ icon, label }) => (
    <div className={`flex flex-row gap-0.5 items-center`}>
      <Icon {...{ icon, color: "white" }} />
      <span
        className={`truncate w-full text-1.5 `}
        style={{ fontWeight: "normal" }}
      >
        {label}
      </span>
    </div>
  );

  const timeLabel = (time?: Date, hoursDuration?: number) => {
    const hoursLabel = hoursDuration
      ? hoursDuration + " hour" + (hoursDuration !== 1 ? "s" : "")
      : "";
    let timeLabel = "";
    if (time) {
      let minutes = "0" + time.getMinutes();
      timeLabel = `${getDateStr(time)} ${time.getHours()}:${minutes.substring(
        minutes.length - 2,
      )}`;
    }
    return `${timeLabel}${hoursLabel && timeLabel ? ", " : ""}${hoursLabel}`;
  };

  return (
    <div className={`flex flex-row ${semanticColorClass} rounded-1 pr-1`}>
      <div className={`flex p-1 items-center`}>
        <span
          className={`font-accent uppercase vertical-rl transform rotate-180`}
        >
          {props.status}
        </span>
      </div>
      <div
        className={`grid grid-cols-5 gap-2 px-1 py-0.5 ${semanticDarkColorClass} rounded-0.5 text-white w-full items-center`}
      >
        <div className="flex flex-row items-center col-span-2 gap-1">
          <span className="text-4">{permitTypeSymbol}</span>

          <div className={`flex flex-col gap-1 `}>
            <p className="text-3 leading-fit">{props.location}</p>
            <p className="text-2 leading-fit">
              {props.subConfinedType
                ? capitalize(props.subConfinedType) + " "
                : ""}
              {capitalize(props.type)}
            </p>
          </div>
        </div>
        <div className={`col-span-2 flex flex-col gap-1`}>
          <p className={`w-full text-3 line-clamp-2`}>
            {props.description}{" "}
            {props.workAboveElevationType
              ? ` ${props.description?.trim() ? "-" : ""} ${
                  props.workAboveElevationType
                }`
              : ""}
          </p>
        </div>
        <div className={`flex flex-col gap-0.5`}>
          {(props.time || props.hoursDuration) && (
            <IconLabel
              icon={IconClock}
              label={timeLabel(props.time, props.hoursDuration)}
            />
          )}
          {props.companyName && (
            <IconLabel icon={IconUsers} label={props.companyName} />
          )}
          {props.workerName && (
            <IconLabel
              icon={IconUser}
              label={`${props.workerName}${
                props.workerRole ? ", " + props.workerRole : ""
              }`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SiteBoardPermit;
