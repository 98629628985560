import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import insertCertificatesToVerifyMutate from "src/common/api/relay/mutations/InsertCertificatesToVerify";
import { InsertCertificatesToVerifyMutation } from "src/common/types/generated/relay/InsertCertificatesToVerifyMutation.graphql";
import SiteFeatureStepsLayout from "src/common/components/layouts/SiteFeatureStepsLayout";
import Button from "src/common/components/general/button/Button";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { SiteOrientationCertificateUploadBySubadminQuery } from "src/common/types/generated/relay/SiteOrientationCertificateUploadBySubadminQuery.graphql";
import { NewCertificationType } from "../utils/siteOrientationTypes";
import SiteOrientationDocumentCameraNewDocument from "./documentCamera/SiteOrientationDocumentCameraNewDocument";
import { useSearchParams } from "react-router-dom";
import SiteOrientationLayoutWrapper from "src/common/components/layouts/steps-navigation-layout/SiteFeatureLayoutWrapper";
import clsx from "clsx";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons";
import AddWorkerCertModal, {
  AddWorkerCertModalRef,
} from "src/common/components/dialogs/AddWorkerCertModal";
import dayjs from "dayjs";
import SubcontractorEmployeesTable from "src/common/components/tables/SubcontractorEmployeesTable";
import { auth } from "src/common/functions/firebase";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import uploadImage from "src/common/functions/upload-utility/uploadImage";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";

const certificationsQuery = graphql`
  query SiteOrientationCertificateUploadBySubadminQuery(
    $projectId: uuid!
    $subcontractorEmployeeId: uuid!
    $startTime: timestamptz!
    $startDate: date!
  ) {
    project_connection(where: { id: { _eq: $projectId } }, first: 10000) {
      edges {
        node {
          require_certs
          project_certifications {
            id
            certification {
              pk: id @__clientField(handle: "pk")
              name
            }
            alternate_certifications {
              alternate_certification_id
            }
          }
          onsiteWorkers: project_workers(
            where: {
              subcontractor: {
                subcontractor_employees: {
                  user_id: { _eq: $subcontractorEmployeeId }
                }
              }
              is_last: { _eq: true }
              deleted_at: { _is_null: true }
              project_workers: {
                _or: [
                  { reports: { report: { created_at: { _gte: $startTime } } } }
                  {
                    report_injuries: {
                      report: { created_at: { _gte: $startTime } }
                    }
                  }
                  {
                    toolbox_talks: {
                      toolbox_talk: { created_at: { _gte: $startTime } }
                    }
                  }
                  {
                    daily_work_log_workers: {
                      daily_work_log: { date: { _gte: $startDate } }
                    }
                  }
                  {
                    permits: {
                      permit: {
                        type: { _neq: "ladder" }
                        created_at: { _gte: $startTime }
                        current_status: { _in: ["submitted", "resubmitted"] }
                        is_historical: { _eq: true }
                      }
                    }
                  }
                ]
              }
            }
            order_by: { user: { name: asc } }
          ) {
            worker_id
            user {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            worker {
              worker_certifications {
                certification_id
              }
            }
          }
          possibleOrientatedWorkers: project_workers(
            where: {
              subcontractor: {
                subcontractor_employees: {
                  user_id: { _eq: $subcontractorEmployeeId }
                }
              }
              is_last: { _eq: true }
              deleted_at: { _is_null: true }
              user_orientation: {
                _or: [
                  { completed_at: { _gt: $startTime } }
                  { orientated_at: { _gt: $startTime } }
                  { in_person_orientated_at: { _gt: $startTime } }
                ]
                _not: {
                  _and: [
                    { completed_at: { _is_null: true } }
                    { orientated_at: { _is_null: true } }
                    { in_person_orientated_at: { _is_null: true } }
                  ]
                }
              }
            }
            order_by: { user: { name: asc } }
          ) {
            worker_id
            user {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            worker {
              worker_certifications {
                certification_id
              }
            }
          }
        }
      }
    }
  }
`;

const SiteOrientationCertificateUploadBySubadmin: React.FC = () => {
  const addWorkerCertModalRef = useRef<AddWorkerCertModalRef>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const subcontractorEmployeeId = auth.currentUser?.uid;
  if (!subcontractorEmployeeId) {
    throw new Error("subcontractorEmployeeId not found");
  }
  const projectId = searchParams.get("project_id");
  if (!projectId) {
    throw new Error("projectId not found");
  }
  const preSelectedWorkerId = searchParams.get("worker_id");
  const start = dayjs()
    .tz("America/New_York")
    .subtract(14, "days")
    .startOf("day");
  const data =
    useLazyLoadQuery<SiteOrientationCertificateUploadBySubadminQuery>(
      certificationsQuery,
      {
        projectId: projectId,
        subcontractorEmployeeId: subcontractorEmployeeId,
        startTime: start.toISOString(),
        startDate: start.format("YYYY-MM-DD"),
      },
    );
  const project = data.project_connection.edges[0].node;
  const projectWorkerList = [...project.onsiteWorkers];
  project.possibleOrientatedWorkers.forEach((projectWorker) => {
    if (
      !projectWorkerList.some(
        (existingProjectWorker) =>
          existingProjectWorker.worker_id === projectWorker.worker_id,
      )
    ) {
      projectWorkerList.push(projectWorker);
    }
  });
  const workerIdList: string[] = preSelectedWorkerId
    ? [preSelectedWorkerId]
    : [];
  projectWorkerList.forEach((projectWorker) => {
    const missingCerts = project.project_certifications.some((cert) => {
      return (
        projectWorker.worker.worker_certifications == null ||
        projectWorker.worker.worker_certifications.findIndex((workerCert) => {
          return (
            workerCert.certification_id == cert.certification.pk ||
            (cert.alternate_certifications &&
              cert.alternate_certifications.findIndex(
                (alternateCert) =>
                  alternateCert.alternate_certification_id ==
                  workerCert.certification_id,
              ) != -1)
          );
        }) == -1
      );
    });
    if (missingCerts) {
      workerIdList.push(projectWorker.worker_id);
    }
  });

  const preSelectedWorkerIndex = workerIdList?.findIndex(
    (workerId) => workerId == preSelectedWorkerId,
  );
  const [workerIndex, setWorkerIndex] = useState(preSelectedWorkerIndex ?? 0);
  const workerId = workerIdList?.at(workerIndex);
  const buttonLeftShown = workerIndex > 0;
  const buttonRightShown = workerIndex < (workerIdList?.length ?? 0) - 1;
  const [insertCertsToVerify] =
    useAsyncMutation<InsertCertificatesToVerifyMutation>(
      insertCertificatesToVerifyMutate,
    );
  const workerName = projectWorkerList.find(
    (projectWorker) => projectWorker.worker_id == workerId,
  )?.user?.name;
  let requiredCertNames = "";
  const projectCerts = project.project_certifications;
  if (projectCerts.length) {
    requiredCertNames = projectCerts[0].certification.name;
    if (projectCerts.length > 1) {
      requiredCertNames = projectCerts
        .slice(1, projectCerts.length)
        .reduce(
          (combinedName, currentCert) =>
            combinedName + ", " + currentCert.certification.name,
          requiredCertNames,
        );
    }
  }

  const [newWorkerCertificationMap, setNewWorkerCertificationMap] = useState<
    Map<string, NewCertificationType[]>
  >(new Map());

  const [workerDeviceUploadTotal, setWorkerDeviceUploadTotal] = useState<
    Map<string, number>
  >(new Map());

  const newCertifications = newWorkerCertificationMap.get(workerId ?? "") ?? [];
  const deviceUploadTotal = workerDeviceUploadTotal.get(workerId ?? "") ?? 0;

  let countEmpty = 0;
  newCertifications.forEach((newCert) => {
    if (!newCert.frontImage && !newCert.backImage) {
      countEmpty++;
    }
  });
  if (countEmpty !== 1) {
    setNewWorkerCertificationMap((prevState) => {
      const newState = newCertifications.filter(
        (newCert) => newCert.backImage || newCert.frontImage,
      );
      newState.push({
        backImage: null,
        frontImage: null,
      });
      const newMap = new Map(prevState);
      newMap.set(workerId ?? "", newState);
      return newMap;
    });
  }

  const [uploadingCertificates, setUploadingCertificates] = useState(false);

  const insertImages = async (
    uploadFront: { url: string },
    uploadBack: { url: string } | undefined | null,
  ) => {
    await insertCertsToVerify({
      variables: {
        objects: [
          {
            project_id: projectId || null,
            worker_id: workerId,
            document: "certificate",
            uploaded_by_uid: subcontractorEmployeeId,
            front_image: {
              data: {
                ...uploadFront,
                description: "Front Image",
                created_by_user_id: subcontractorEmployeeId,
              },
            },
            back_image: !uploadBack
              ? null
              : {
                  data: {
                    ...uploadBack,
                    description: "Back Image",
                    created_by_user_id: subcontractorEmployeeId,
                  },
                },
          },
        ],
      },
    })
      .then(() => {
        console.log("uploaded Certificate");
      })
      .catch((e) => {
        console.error(e);
        message.error(e);
      });
  };

  const onEnter = async () => {
    newCertifications.forEach(async (cert, i) => {
      if (cert.frontImage) {
        setUploadingCertificates(true);
        try {
          const uploadFront = await uploadImage(cert.frontImage);
          const uploadBack = cert.backImage
            ? await uploadImage(cert.backImage)
            : undefined;
          if (uploadFront && (!cert.backImage || uploadBack)) {
            await insertImages(uploadFront, uploadBack);
          }
          setUploadingCertificates(false);
          message.success("Certification uploaded for verification.");
        } catch (e) {
          setUploadingCertificates(false);
          console.log(e);
          message.error(e instanceof Error ? e.message : JSON.stringify(e));
        }
      }
    });
  };

  const onRightButtonClick = () => {
    if (workerIdList && workerIndex < workerIdList.length - 1) {
      setWorkerIndex(workerIndex + 1);
    }
  };
  const onLeftButtonClick = () => {
    if (workerIdList && workerIndex > 0) {
      setWorkerIndex(workerIndex - 1);
    }
  };

  return (
    <SiteOrientationLayoutWrapper>
      <div
        className={clsx(
          `absolute bg-white rounded-full p-0.5 left-0 top-1/2 transform -translate-y-1/2 rouded-2 z-10 transition-opacity`,
          buttonLeftShown ? "opacity-100" : "opacity-0",
        )}
      >
        <Button
          onClick={onLeftButtonClick}
          icon={IconArrowLeft}
          secondary
        ></Button>
      </div>
      <div
        className={clsx(
          `absolute bg-white rounded-full right-0 top-1/2 transform -translate-y-1/2 z-10 p-0.5 transition-opacity`,
          buttonRightShown ? "opacity-100" : "opacity-0",
        )}
      >
        <Button
          onClick={onRightButtonClick}
          icon={IconArrowRight}
          secondary
        ></Button>
      </div>
      <SiteFeatureStepsLayout
        nextButtonText="Done"
        onNext={onEnter}
        nextButtonLoading={uploadingCertificates}
      >
        <SiteFeatureStepsInputLayout
          headline={`Add photo(s) of ${
            workerName ?? ""
          }'s Certification and Training card(s).`}
        >
          {data.project_connection.edges[0].node.require_certs && (
            <div>
              {`You are required to have the following certifications on this project: ${requiredCertNames}`}
            </div>
          )}
          <div className={`flex flex-col gap-1`}>
            <div className={`flex flex-col gap-0.5 mt-1`}>
              {!!newCertifications.filter((p) => p.frontImage).length && (
                <p>{"Recently uploaded certifications and trainings"}:</p>
              )}
              <div className={`grid grid-cols-2 gap-1`}>
                {newCertifications.map((newCert, i) => (
                  <SiteOrientationDocumentCameraNewDocument
                    key={i}
                    frontImageSrc={newCert.frontImage}
                    backImageSrc={newCert.backImage}
                    showNextCertLabel={
                      newCertifications.length > 1 &&
                      i == newCertifications.length - 1
                    }
                    onFrontImageChange={(image) => {
                      setNewWorkerCertificationMap((prevState) => {
                        const newState = [...newCertifications];
                        newState[i].frontImage = image;
                        const newMap = new Map(prevState);
                        newMap.set(workerId ?? "", newState);
                        return newMap;
                      });
                    }}
                    onBackImageChange={(image) => {
                      setNewWorkerCertificationMap((prevState) => {
                        const newState = [...newCertifications];
                        newState[i].backImage = image;
                        const newMap = new Map(prevState);
                        newMap.set(workerId ?? "", newState);
                        return newMap;
                      });
                    }}
                  />
                ))}
              </div>
              <>
                <AddWorkerCertModal
                  {...{
                    workerId: workerId,
                    workerName: workerName,
                    byWorker: false,
                    projectId: projectId,
                    ref: addWorkerCertModalRef,
                    onInserted: () => {
                      setWorkerDeviceUploadTotal((prevState) => {
                        const newMap = new Map(prevState);
                        newMap.set(workerId ?? "", deviceUploadTotal + 1);
                        return newMap;
                      });
                      message.success(
                        "Certification uploaded for verification.",
                      );
                    },
                  }}
                />
                <div className="text-center mt-2"> {"OR"}</div>
                <div className="h-0.5"></div>
                <Button
                  secondary
                  onClick={() => addWorkerCertModalRef.current?.open()}
                  label={"Upload from your Device"}
                />
                <div className="h-0.5"></div>
                {deviceUploadTotal > 0 ? (
                  <div>
                    {`${deviceUploadTotal} Certification${
                      deviceUploadTotal > 1 ? "s" : ""
                    }
                    Uploaded`}
                    &nbsp; ✅
                  </div>
                ) : (
                  ""
                )}
              </>{" "}
            </div>
          </div>
        </SiteFeatureStepsInputLayout>
      </SiteFeatureStepsLayout>
    </SiteOrientationLayoutWrapper>
  );
};

export default SiteOrientationCertificateUploadBySubadmin;
