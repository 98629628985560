import { Form, Modal, Select } from "antd";
import React from "react";
import { GetProjectEmployeesQueryResult } from "src/common/types/generated/apollo/graphQLTypes";

interface AddProjectCovidNotifyEmployeeValues {
  projectEmployeeId: string;
}

interface AddProjectCovidNotifyEmployeeValuesModalProps {
  projectEmployees: NonNullable<
    GetProjectEmployeesQueryResult["data"]
  >["project_employee"];
  visible: boolean;
  onCreate: (values: AddProjectCovidNotifyEmployeeValues) => Promise<void>;
  onCancel: () => void;
}

const AddProjectCovidNotifyEmployeeValuesModal: React.FC<
  AddProjectCovidNotifyEmployeeValuesModalProps
> = ({ projectEmployees, visible, onCreate, onCancel }) => {
  const [form] = Form.useForm<AddProjectCovidNotifyEmployeeValues>();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    (<Modal
      open={visible}
      title="Add an Employee to be notified"
      okText="Add"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={async () => {
        const values = await form.validateFields().catch(v => null);
        if (!values)
            return;
        await onCreate(values);
        form.resetFields();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="projectEmployeeId"
          label="Employee to add"
          rules={[{ required: true, message: "Choose an employee to add" }]}
        >
          <Select style={{ width: "100%" }}>
            {projectEmployees.map(
              (p) =>
                p.id && (
                  <Select.Option key={p.id} value={p.id}>
                    {p.employee?.user.name},{" "}
                    {p.employee?.employee_title?.name.en}
                  </Select.Option>
                )
            )}
          </Select>
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default AddProjectCovidNotifyEmployeeValuesModal;
