/**
 * @generated SignedSource<<e81501bafcc655dbeab41f27f3a22e16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { general_contractor_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCCompanySettings_updateGC_Mutation$variables = {
  _set: general_contractor_set_input;
  gcId: string;
};
export type GCCompanySettings_updateGC_Mutation$data = {
  readonly update_general_contractor_by_pk: {
    readonly id: string;
    readonly require_dob: boolean;
    readonly require_phone: boolean;
    readonly send_weekly_email: boolean;
  } | null | undefined;
};
export type GCCompanySettings_updateGC_Mutation = {
  response: GCCompanySettings_updateGC_Mutation$data;
  variables: GCCompanySettings_updateGC_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gcId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "gcId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "general_contractor",
    "kind": "LinkedField",
    "name": "update_general_contractor_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "require_phone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "send_weekly_email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "require_dob",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCCompanySettings_updateGC_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCCompanySettings_updateGC_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "54e394f5f525e9faaeb3465085fc1308",
    "id": null,
    "metadata": {},
    "name": "GCCompanySettings_updateGC_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCCompanySettings_updateGC_Mutation(\n  $gcId: uuid!\n  $_set: general_contractor_set_input!\n) {\n  update_general_contractor_by_pk(pk_columns: {id: $gcId}, _set: $_set) {\n    id\n    require_phone\n    send_weekly_email\n    require_dob\n  }\n}\n"
  }
};
})();

(node as any).hash = "618fff1c1b9098c919e0eb4ba9a3345d";

export default node;
