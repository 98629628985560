import { Card, Typography } from "antd";
import React from "react";

const testingPageHref =
  "http://localhost:3000/orientations/test/a0cf3dd2-906d-475f-b3c4-a3c2f39d65ff/49d303af-b2a3-4502-8cd2-751f6e392b73/slides/slides?isTestLink=true#7b190aae-0026-40c5-80a1-44f2f476c923";
const orientationBuilderPageHref =
  "https://siteform-dev.web.app/gce/projects/a0cf3dd2-906d-475f-b3c4-a3c2f39d65ff/orientation/slides/7b190aae-0026-40c5-80a1-44f2f476c923";

const OrientationPlayerDemo: React.FC = () => {
  return (
    <Card title={"Orientation Player"}>
      <Typography>
        <Typography.Paragraph>
          The most convenient way to test it is to setup the orientation module
          <Typography.Link href={orientationBuilderPageHref} target={"_blank"}>
            {" "}
            in the orientation builder{" "}
          </Typography.Link>
          and view the orientation{" "}
          <Typography.Link href={testingPageHref} target={"_blank"}>
            {" "}
            via the testing page
          </Typography.Link>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Typography.Text code>
            SiteOrientationSlidesViewerSlideVideo.tsx
          </Typography.Text>
        </Typography.Paragraph>
      </Typography>
    </Card>
  );
};

export default OrientationPlayerDemo;
