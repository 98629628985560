import {
  IconMinus,
  IconPlus,
  IconUser,
  IconSend,
  IconUserMinus,
} from "@tabler/icons";
import { Popconfirm } from "antd";
import React from "react";
import Button from "src/common/components/general/button/Button";
import ContactLink from "src/common/components/general/ContactLink";
import Image from "src/common/components/general/images/Image";

const textData = {
  active: "Active",
  inactive: "Inactive",
};

export interface GCProjectTeamListItemProps {
  name: string;
  id: string;
  onRemoveFromTeam?: (userId: string) => Promise<void>;
  dataId?: string;
  avatarUrl?: string;
  title?: string;
  email?: string;
  phoneNumber?: string;

  status?: {
    active: boolean;
    onDeactivate: () => void;
    onActivate: () => void;
  };
  loading: boolean;
  created_password: boolean;
  reSendInvite: () => void;
}

const GCProjectTeamListItem: React.FC<GCProjectTeamListItemProps> = (props) => {
  const status = props.status;
  return (
    <div className="flex flex-row items-center  gap-0.5">
      <div className="flex flex-row items-center flex-1 border rounded-2 border-suplementary-1 bg-suplementary-1">
        <div className="w-3 h-3 overflow-hidden rounded-2">
          <Image alt={props.name} icon={IconUser} src={props.avatarUrl} />
        </div>
        <div className="py-0.25 pr-1 pl-0.5 flex flex-1  flex-row ">
          <div className="flex flex-col items-start flex-1">
            <span className="block font-accent">{props.name}</span>
            {!!props.title && (
              <span className="block text-0.75">{props.title}</span>
            )}
          </div>
          <div className="w-[10%]">
            {(!!props.phoneNumber || !!props.email) && (
              <div className="flex flex-row items-center gap-0.5 ">
                <div className="w-1/2">
                  {!!props.email && (
                    <ContactLink hideValue type="email" value={props.email} />
                  )}
                </div>
                <div className="w-1/2">
                  {!!props.phoneNumber && (
                    <ContactLink
                      hideValue
                      type="phone-number"
                      value={props.phoneNumber}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.created_password && status ? (
        <>
          <div
            className={`flex items-center justify-center w-8 px-1 py-0.5 rounded-2 ${
              status.active
                ? "bg-suplementary-1"
                : "border border-suplementary-1"
            }`}
          >
            <span className="">
              {status.active ? textData.active : textData.inactive}
            </span>
          </div>
          <Button
            loading={props.loading}
            onClick={status.active ? status.onDeactivate : status.onActivate}
            small
            icon={status.active ? IconMinus : IconPlus}
            hint={
              status.active
                ? "Make inactive (will no longer receive notifications)"
                : "Activate"
            }
          />
        </>
      ) : props.created_password ? (
        <></>
      ) : (
        <>
          <div className="flex items-center justify-center w-8 px-1 py-0.5 rounded-2 bg-suplementary-1 font-bold">
            <b> Invited</b>
          </div>
          <Button
            onClick={async () => {
              props.reSendInvite();
            }}
            hint="Resend Invite"
            small
            icon={IconSend}
          >
            {/* <IconSend size={36} color="#1890FF"></IconSend> */}
          </Button>
        </>
      )}
      {props.onRemoveFromTeam && (
        <Popconfirm
          title={`Are you sure you want to remove this user's admin credentials?`}
          onConfirm={() => props.onRemoveFromTeam?.(props.id)}
        >
          <Button
            loading={props.loading}
            small
            icon={IconUserMinus}
            hint={
              "Remove this User’s Admin Credentials?\nThis will remove their access to this Admin Level of your organization and all projects"
            }
            onClick={() => {}}
          />
        </Popconfirm>
      )}
    </div>
  );
};

export default GCProjectTeamListItem;
