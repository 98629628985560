import React from "react";
import { Button } from "antd";
import { ButtonProps } from "antd/es/button";

export interface ButtonHugeProps extends ButtonProps {}

const ButtonHuge: React.FC<ButtonHugeProps> = (props) => {
  return (
    <Button
      className={`button-huge`}
      {...{
        block: true,
        style: {
          height: `4.5rem`,
          fontSize: `2rem`,
          minWidth: "4.5rem",
        },
        /*
        `children` and `icon` props use a workaround to make an icon display correctly with hard-coded size
         */
        icon: undefined,
        children: <>{props.icon ? props.children : props.icon}</>,
        ...props,
      }}
    />
  );
};

export default ButtonHuge;
