/**
 * @generated SignedSource<<496d37cdde33262ff1fd47bde3bdde67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddProcoreIntegrationModal_upsertAuthData_Mutation$variables = {
  client_id: string;
  client_secret: string;
  general_contractor_id: string;
};
export type AddProcoreIntegrationModal_upsertAuthData_Mutation$data = {
  readonly insert_procore_auth_data_one: {
    readonly general_contractor_id: string;
    readonly integration_enabled: boolean;
    readonly service_account_client_id: string | null | undefined;
    readonly user_id: string | null | undefined;
    readonly user_login: string | null | undefined;
    readonly user_name: string | null | undefined;
  } | null | undefined;
};
export type AddProcoreIntegrationModal_upsertAuthData_Mutation = {
  response: AddProcoreIntegrationModal_upsertAuthData_Mutation$data;
  variables: AddProcoreIntegrationModal_upsertAuthData_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "client_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "client_secret"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "general_contractor_id"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "access_token",
        "value": null
      },
      {
        "kind": "Variable",
        "name": "general_contractor_id",
        "variableName": "general_contractor_id"
      },
      {
        "kind": "Literal",
        "name": "integration_enabled",
        "value": true
      },
      {
        "kind": "Literal",
        "name": "refresh_token",
        "value": null
      },
      {
        "kind": "Variable",
        "name": "service_account_client_id",
        "variableName": "client_id"
      },
      {
        "kind": "Variable",
        "name": "service_account_client_secret",
        "variableName": "client_secret"
      },
      {
        "kind": "Literal",
        "name": "token_expires_at",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "user_id",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "user_login",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "user_name",
        "value": null
      }
    ],
    "kind": "ObjectValue",
    "name": "object"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "procore_auth_data_pkey",
      "update_columns": [
        "user_id",
        "user_name",
        "user_login",
        "refresh_token",
        "access_token",
        "token_expires_at",
        "service_account_client_id",
        "service_account_client_secret",
        "integration_enabled"
      ]
    }
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "general_contractor_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_login",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "service_account_client_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integration_enabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddProcoreIntegrationModal_upsertAuthData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "procore_auth_data",
        "kind": "LinkedField",
        "name": "insert_procore_auth_data_one",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddProcoreIntegrationModal_upsertAuthData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "procore_auth_data",
        "kind": "LinkedField",
        "name": "insert_procore_auth_data_one",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2e948e2eb345782cfd396e454db572b2",
    "id": null,
    "metadata": {},
    "name": "AddProcoreIntegrationModal_upsertAuthData_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddProcoreIntegrationModal_upsertAuthData_Mutation(\n  $general_contractor_id: uuid!\n  $client_id: String!\n  $client_secret: String!\n) {\n  insert_procore_auth_data_one(object: {general_contractor_id: $general_contractor_id, service_account_client_id: $client_id, service_account_client_secret: $client_secret, integration_enabled: true}, on_conflict: {constraint: procore_auth_data_pkey, update_columns: [user_id, user_name, user_login, refresh_token, access_token, token_expires_at, service_account_client_id, service_account_client_secret, integration_enabled]}) {\n    general_contractor_id\n    user_id\n    user_name\n    user_login\n    service_account_client_id\n    integration_enabled\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "815774d89720276380cb98cc9dafbaa4";

export default node;
