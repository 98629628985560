import { Form, message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import { useSearchParams } from "react-router-dom";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import * as uuid from "uuid";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import dayjs from "dayjs";
import { commitLocalUpdate } from "relay-runtime";
import { UniversalOrientationViewerQuery } from "src/common/types/generated/relay/UniversalOrientationViewerQuery.graphql";
import {
  updateOrientationResultMutation,
  upsertOrientationResultsMutation,
  upsertQuizResultMutation,
} from "../../../../../components/slidesViewer/SiteOrientationSlidesViewer";
import {
  OrientationEmailType,
  useEmailOrientationConfirmationQrCodeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { SiteOrientationSlidesViewer_UpsertQuizResult_Mutation } from "src/common/types/generated/relay/SiteOrientationSlidesViewer_UpsertQuizResult_Mutation.graphql";
import { SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation } from "src/common/types/generated/relay/SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation.graphql";
import { SiteOrientationSlidesViewer_UpdateOrientationResult_Mutation } from "src/common/types/generated/relay/SiteOrientationSlidesViewer_UpdateOrientationResult_Mutation.graphql";
import insertImages from "src/common/api/relay/mutations/InsertImages";
import { InsertImagesMutation } from "src/common/types/generated/relay/InsertImagesMutation.graphql";
import { InsertOrientationViewedSlideMutation } from "src/common/types/generated/relay/InsertOrientationViewedSlideMutation.graphql";
import insertOrientationViewedSlide from "src/common/api/relay/mutations/InsertOrientationViewedSlide";
import fixEnglish from "src/domain-features/siteorientation/utils/fixEnglish";
import { translateSlide } from "src/domain-features/siteorientation/utils/translateSlide";
import viewedSlideInsertUpdater from "../../../../../utils/viewedSlideInsertUpdater";
import GetFullID from "src/common/functions/GetFullId";
import SiteOrientationSlidesViewerUI from "../../../../../components/slidesViewer/SiteOrientationSlidesViewerUI";
import {
  OrientationPage,
  SlideAnswersMapType,
} from "../../../../../siteorientationTypes";
import useInsertSiteOrientationResult from "src/domain-features/siteorientation/utils/useInsertSiteOrientationResult";
import sendHotjarEvent from "src/utility-features/event-tracking/hotjar/sendHotjarEvent";
import { Language } from "../../../../../../../utility-features/i18n/language-utils/i18nTypes";
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE,
} from "../../../../../../../utility-features/i18n/languages";
import stringToLanguage from "../../../../../../../utility-features/i18n/language-utils/stringToLanguage";
import useLangStrings from "../../../../../../../utility-features/i18n/context/languageHooks";

interface QuizResults {
  [key: string]: string;
}

interface OrientationResults {
  signature_url?: string;
  results: { [key: string]: QuizResults };
}

interface UniversalOrientationViewerProps {
  handleNav: (s: string) => void;
  userId?: string;
  languageCode?: Language;
}

const query = graphql`
  query UniversalOrientationViewerQuery(
    $workerId: uuid!
    $now: timestamptz!
    $universalOrientationValidDate: timestamptz!
    $orientationWhere: orientation_bool_exp!
  ) {
    user_connection(where: { id: { _eq: $workerId } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          lang
          name
          role
          username
          universal_orientations(
            where: {
              universal_orientated_at: { _gte: $universalOrientationValidDate }
            }
            order_by: { universal_orientated_at: desc }
            limit: 1
          ) {
            id
            universal_orientated_at
          }
        }
      }
    }
    orientation_connection(
      where: $orientationWhere
      order_by: [{ order: asc }, { name: asc }]
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          id
          name
          created_at
          project_id
          duration_valid
          type
          user {
            name
          }
          universalOrientationRequire: project_orientations(
            where: { project_id: { _is_null: true } }
          ) {
            required_by_all_workers
          }
          project_orientations(where: { project_id: { _is_null: true } }) {
            play_during_registration
            play_during_in_person
            required_by_all_workers
            hide_but_give_credit
          }
          completedOnOtherProjectResults: orientation_results(
            where: {
              user_id: { _eq: $workerId }
              status: { _eq: "completed" }
              expired_at: { _gte: $now }
            }
          ) {
            id
          }
          orientation_results(
            where: {
              user_id: { _eq: $workerId }
              status: { _eq: "pending" }
              project_id: { _is_null: true }
            }
          ) {
            pk: id @__clientField(handle: "pk")
            id
            expired_at
            orientation_id
            orientation {
              duration_valid
            }
            group_id
            quiz_results {
              answer
              orientation_slide_id
              id
              lang
              pk: id @__clientField(handle: "pk")
            }
          }
          slides(
            where: {
              deleted_at: { _is_null: true }
              archived_at: { _is_null: true }
            }
            order_by: { order: asc }
          ) {
            ...SlideFrag @relay(mask: false)
            viewed_by(
              where: { user_id: { _eq: $workerId } }
              order_by: { created_at: desc }
            ) {
              id
              created_at
            }
          }
        }
      }
    }
  }
`;

const UniversalOrientationViewer: FC<UniversalOrientationViewerProps> = ({
  handleNav,
  userId,
  languageCode,
}) => {
  const orientationId = document.location.hash?.substring(1);
  const [searchParams] = useSearchParams();
  const workerId = userId ?? searchParams.get("workerId");
  if (!workerId) {
    throw new Error("UniversalOrientationViewer, workerId is missing");
  }
  const lang: Language =
    languageCode ??
    stringToLanguage(searchParams.get("lang")) ??
    DEFAULT_LANGUAGE;

  const data = useLazyLoadQuery<UniversalOrientationViewerQuery>(
    query,
    {
      now: dayjs().endOf("d").format(),
      workerId,
      // although universal Orientation is valid for 12 months, we are using 11 months here so that we can
      // have user able to insert result 1 month before his orientation expires
      universalOrientationValidDate: dayjs()
        .subtract(11, "months")
        .startOf("d")
        .toISOString(),
      orientationWhere: orientationId
        ? { id: { _eq: orientationId } }
        : {
            deleted_at: { _is_null: true },
            project_id: { _is_null: true },
            general_contractor_id: { _is_null: true },
            project_orientations: {
              project_id: { _is_null: true },
              required_by_all_workers: { _eq: true },
            },
          },
    },
    { fetchPolicy: "network-only" },
  ); // userConnection
  const langStrings = useLangStrings(lang);
  const [insertSignatureImage, isInserting] =
    useAsyncMutation<InsertImagesMutation>(insertImages);
  const [upsertQuizResult] =
    useAsyncMutation<SiteOrientationSlidesViewer_UpsertQuizResult_Mutation>(
      upsertQuizResultMutation,
    );

  const [emailOrientationConfirmationQrCode, { loading: isSendingEmail }] =
    useEmailOrientationConfirmationQrCodeMutation();

  const [upsertOrientationResults] =
    useAsyncMutation<SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation>(
      upsertOrientationResultsMutation,
    );
  const [updateOrientationResult] =
    useAsyncMutation<SiteOrientationSlidesViewer_UpdateOrientationResult_Mutation>(
      updateOrientationResultMutation,
    );
  const [insertViewedSlide] =
    useAsyncMutation<InsertOrientationViewedSlideMutation>(
      insertOrientationViewedSlide,
    );
  const [insertResultMutation] = useInsertSiteOrientationResult();

  const userData = data.user_connection?.edges[0]?.node;

  const completedOrientation =
    !!userData.universal_orientations[0]?.universal_orientated_at;

  const type = "universal";

  const orientations = useMemo(
    () =>
      (data.orientation_connection?.edges || [])
        .map((v) => v.node)
        .filter((o) => !orientationId || orientationId === o.pk),
    [data.orientation_connection],
  );

  const [form] = Form.useForm();
  const environment = useRelayEnvironment();
  const slideAnswers = useRef<SlideAnswersMapType>({}); // slide_id and answer

  const orientationResultsMap = useRef<{
    [key: string]: {
      orientationResultId: string;
      groupId?: string | null;
      durationValid: number | undefined;
    };
  }>({});

  orientations.forEach((o) => {
    o.orientation_results.forEach((r) => {
      if (r.orientation_id) {
        orientationResultsMap.current[r.orientation_id] = {
          orientationResultId: r.pk,
          groupId: r.group_id,
          durationValid: r.orientation?.duration_valid,
        };
      }
      r.quiz_results.forEach((q) => {
        slideAnswers.current[q.orientation_slide_id] = {
          answer: q.answer,
          lang: stringToLanguage(q.lang),
        };
      });
    });
  });
  const alertUser = (e: {
    preventDefault: () => void;
    defaultPrevented: boolean;
    returnValue: string;
  }) => {
    e.preventDefault();
    return (e.returnValue = `Are you sure you want to exit this Orientation? All progress will be lost and you will need to start from the beginning?`);
  };
  useEffect(() => {
    if (!workerId) throw new Error("WorkerId Not Found");
    if (completedOrientation || orientations.length === 0) {
      handleNav("complete" + `?workerId=${workerId}&lang=${lang}`);
    } else {
      const groupId =
        orientationResultsMap.current[orientations?.[0]?.pk]?.groupId ??
        uuid.v4();
      if (!orientationId && !completedOrientation) {
        const resultsToBeInserted = orientations.filter(
          (o) => o.orientation_results.length === 0,
        );
        if (resultsToBeInserted.length !== 0) {
          upsertOrientationResults({
            variables: {
              objects: resultsToBeInserted.map((o) => {
                const orientResultId = uuid.v4();
                const newValue = {
                  orientationResultId: orientResultId,
                  groupId: groupId,
                  durationValid: 12, //o.duration_valid,
                };
                orientationResultsMap.current[o.pk] = newValue;
                return {
                  id: orientResultId,
                  status: "pending",
                  group_id: groupId,
                  user_id: workerId,
                  orientation_id: o.pk,
                  viewed_slides: 0,
                  total_slides: o.slides.length,
                };
              }),
            },
          }).catch((e) => console.log(e));
        }
      }
    }

    // Hotjar tracking
    sendHotjarEvent("orientation_step_modules_player");

    // Alert user before leaving the page
    window.addEventListener("beforeunload", alertUser);
    return () => window.removeEventListener("beforeunload", alertUser);
  }, []);
  const differentThanEnglish =
    AVAILABLE_LANGUAGES.includes(lang) && lang !== "en";

  const pages = useMemo(() => {
    let pageArr: Array<OrientationPage> = [];

    let fistUnviewed = 0;
    for (const orientation of orientations) {
      const slides = orientation.slides;
      let idx = 0;
      while (idx < slides.length) {
        const slide = differentThanEnglish
          ? translateSlide(slides[idx], lang)
          : fixEnglish(slides[idx]);
        const question = slide.content_type === "question";
        const pageItem: OrientationPage = {
          type: question ? "questions" : "static",
          title: question ? langStrings.strings.question : slide.title,
          orientation,
          pageLang: lang,
          viewed: slide.viewed_by[0]
            ? dayjs().isSameOrBefore(
                dayjs(slide.viewed_by[0].created_at).add(
                  orientation.duration_valid - 1 || 1,
                  "months",
                ),
              )
            : false, // || viewedSlides.includes(slide.pk),
          slides: [slide],
        };
        pageArr.push(pageItem);
        idx++;
        if (slide.content_type === "image" && slide.image_url) {
          // preload image
          const img = new Image();
          img.src = slide.image_url;
        }
        if (pageItem.viewed) fistUnviewed++;
      }
    }
    if (orientations.length > 0) {
      pageArr.push({
        type: "signature",
        title: langStrings.strings.signature,
        orientation: orientations[orientations.length - 1],
        viewed: false,
        pageLang: lang,
        slides: [],
      });
    }
    return pageArr;
  }, [orientations /*, viewedSlides*/]);

  const [pageIndex, setPageIndex] = useState(() => {
    let index = 0;
    while (index < pages.length && pages[index].viewed) index++;
    return index;
  });

  const page = pageIndex < pages.length ? pages[pageIndex] : null;
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const quizResults = useRef<OrientationResults>({
    results: Object.fromEntries(orientations.map((o) => [o.pk, {}])),
  });

  const saveResults = async (sigUrl: string) => {
    // todo wait to signature load to complete
    let groupIds: string[] = [];
    if (!workerId) {
      message.error("worker not found");
      return;
    }
    const orientationResults: {
      id: string;
      duration: number;
      orientation_id: string;
      total_slides: number;
    }[] = orientations
      .map((o) => {
        groupIds.push(
          orientationResultsMap.current[o.pk]?.groupId ?? uuid.v4(),
        );
        return {
          orientation_id: o.pk,
          id:
            orientationResultsMap.current[o.pk]?.orientationResultId ?? "None",
          duration: orientationResultsMap.current[o.pk]?.durationValid ?? 12,
          total_slides: o.slides.length,
        };
      })
      .filter((o) => o.id !== "None");
    const groupId = groupIds[0] ?? uuid.v4();
    try {
      if (!orientationId) {
        const orientationDate = dayjs().toISOString();
        const signatureId = uuid.v4();
        await insertSignatureImage({
          variables: {
            objects: [
              {
                id: signatureId,
                url: sigUrl,
                lg_url: sigUrl,
                created_by_user_id: workerId,
              },
            ],
          },
        }).then(console.log);

        await insertResultMutation({
          variables: {
            orientationResultsObjects: orientationResults.map((o) => ({
              id: o.id,
              user_id: workerId,
              orientation_id: o.orientation_id,
              status: "completed",
              group_id: groupId,
              signature_url: sigUrl,
              total_slides: o.total_slides,
              viewed_slides: o.total_slides,
              // result_inserted_by_uid: auth.currentUser?.uid,
              expired_at: dayjs().add(o.duration, "months").toISOString(),
              completed_at: dayjs().toISOString(),
            })),
            userUniversalOrientationObjects: [
              {
                user_id: workerId,
                universal_orientated_at: orientationDate,
                group_id: groupId,
                universal_orientation_signature_id: signatureId,
              },
            ],
            viewedSlidesObjects: [],
            workerOrientationObjects: [],
            workerOrientationUpdateColumns: [],
          },
        });
        await emailOrientationConfirmationQrCode({
          variables: {
            input: {
              orientationEmailType: OrientationEmailType.OnlyUniversal,
              userId: workerId,
            },
          },
        }).catch((e) =>
          notification.error({
            message: "Email Confirmation Error",
            description: e.message,
          }),
        );
      }

      handleNav("complete" + `?workerId=${workerId}&lang=${lang}`);
    } catch (e) {
      notification.error({
        message: "Couldn't insert result for orientation",
        description: e instanceof Error ? e.message : JSON.stringify(e),
      });
    }
  };
  const onSubmitQuiz = async () => {
    if (!page) return;
    const values = await form.validateFields().catch(() => null);
    if (!values) return;
    const results = quizResults.current.results[page.orientation.pk];
    for (const name of Object.keys(values)) {
      if (!name.startsWith("field_")) continue;
      const field_id = name.substring(6);
      results[field_id] = values[name];
      // console.log(page.slides, field_id, values[name]);
      slideAnswers.current[field_id] = {
        answer: values[name],
        lang: page.pageLang,
      };
      if (!orientationId) {
        upsertQuizResult({
          variables: {
            object: {
              answer: values[name],
              orientation_slide_id: field_id,
              orientation_result_id:
                orientationResultsMap.current[page.orientation.pk]
                  ?.orientationResultId,
            },
          },
        })
          .then((d) => console.log(d))
          .catch(console.error);
      }
    }
    setQuizSubmitted(true);

    if (page.slides.some((s) => s.check_correct_answer)) {
      await form
        .validateFields()
        .then((v) => console.log(v))
        .catch(() => null);
    } else moveToNextPage();
  };
  // console.log(page, pageIndex);

  const moveToNextPage = async () => {
    if (!page) return;
    if (!workerId) {
      message.error("Worker Not Found");
      return;
    }

    const viewedSlides = page.slides.map((s) => ({
      id: uuid.v4(),
      user_id: workerId,
      slide_id: s.pk,
      created_at: dayjs().format(),
      orientation_result_id:
        orientationResultsMap.current[s.orientation_id]?.orientationResultId,
    }));

    if (!orientationId) {
      if (
        orientationResultsMap.current[page.orientation.pk].orientationResultId
      ) {
        insertViewedSlide({
          variables: {
            objects: viewedSlides,
          },
          optimisticResponse: {
            insert_orientation_viewed_slide: {
              returning: viewedSlides,
            },
          },
          updater: viewedSlideInsertUpdater,
          optimisticUpdater: viewedSlideInsertUpdater,
        }).catch((e) => console.log(e));
        const allSlidesViewed =
          page.orientation.slides.filter((s) => s.viewed_by.length).length ===
          page.orientation.slides.length;
        updateOrientationResult({
          variables: {
            orientationResultWhere: {
              id: {
                _eq: orientationResultsMap.current[page.orientation.pk]
                  .orientationResultId,
              },
            },
            orientationResultSet: {
              ...(allSlidesViewed
                ? {
                    // status: "completed",
                    completed_at: dayjs().format(),
                    expired_at: dayjs()
                      .add(
                        12, // orientationResultsMap.current[page.orientation.pk].durationValid,
                        "M",
                      )
                      .format(),
                  }
                : {}),
            },
          },
        }).catch((e) => console.log(e));
      }
    } else {
      commitLocalUpdate(environment, (store) => {
        viewedSlides.forEach((viewedSlide) => {
          const slide = store.get(
            GetFullID("orientation_slide", viewedSlide.slide_id),
          );
          if (slide) {
            const item = store.create(
              viewedSlide.id,
              "orientation_viewed_slide",
            );
            const user_id = viewedSlide.user_id;
            item.setValue("id", viewedSlide.id);
            slide.setLinkedRecords([item], "viewed_by", {
              where: { user_id: { _eq: user_id } },
            });
          }
        });
      });
    }
    /*    setViewedSlides(prev => [...prev, ...page.slides
          .map(slide => slide.pk)
          .filter(pk => !prev.includes(pk))]);*/
  };

  if (!page || orientations.length === 0) {
    const text =
      orientations.length === 0
        ? langStrings.strings.orientationDoesNotExist
        : langStrings.strings.orientationIsEmptyPleaseAddSlides;
    return (
      <>
        <div className="w-full h-fulll border-1 border-grey border-solid">
          <h1 className="text-center mt-2 text-1.25">{text}</h1>
        </div>
      </>
    );
  }
  if (!orientationId && completedOrientation) {
    return (
      <>
        <div className="w-full h-fulll border-1 border-grey border-solid">
          <h1 className="text-center mt-2 text-1.25">
            {langStrings.strings.orientationCompletionThankYou}
          </h1>
        </div>
      </>
    );
  }

  return (
    <SiteOrientationSlidesViewerUI
      {...{
        pages,
        lang,
        quizSubmitted,
        setQuizSubmitted,
        form,
        inPersonType: false,
        type,
        slideAnswers,
        page,
        userId: workerId,
        pageIndex,
        setPageIndex,
        orientations,
        moveToNextPage,
        saveResults,
        projectName: "",
        userName: userData.name,
        onSubmitQuiz,
      }}
    />
  );
};

export default withCustomSuspense(UniversalOrientationViewer);
