import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import {
  InspectionChecklistItemProps,
  NotifyUserType,
} from "../../utils/siteInspectionTypes";
import { generalChecklistType } from "../../utils/generalChecklistType";
import { AutoComplete, Form, message } from "antd";
import Button from "src/common/components/general/button/Button";
import { IconCheck, IconPlus } from "@tabler/icons";

interface Props {
  onCreate: (
    values: InspectionChecklistItemProps,
    creatingAnother?: boolean,
  ) => void;
  onCancel: () => void;
  type?: string;
  allowChecklistItemPhoto: boolean;
  projectEmployees: Array<NotifyUserType>;
  checklistItemTypes: Array<string>;
  visible: boolean;
  hideNotifyees?: boolean;
}

export type AddInspectionChecklistItemModalRef =
  FModalRef<InspectionChecklistItemProps> | null;

const AddInspectionChecklistItemModal = forwardRef<
  FModalRef<InspectionChecklistItemProps>,
  Props
>(({ onCreate, onCancel, visible, type, ...props }, ref) => {
  const modal = useRef<AddInspectionChecklistItemModalRef>(null);
  const [loading, setLoading] = useState(false);
  const [notifySelected, setNotifySelected] = useState(false);
  useImperativeHandle<
    AddInspectionChecklistItemModalRef,
    AddInspectionChecklistItemModalRef
  >(ref, () => modal.current);

  const reset = () => {
    modal.current?.form.resetFields();
    setNotifySelected(false);
  };
  const onOk = async (creatingAnother?: boolean) => {
    const form = modal.current?.form;
    if (!form) return;
    try {
      const values = await form.validateFields();

      const description = (values.description || "")
        .trim()
        .replace(/\s+/g, " ");
      if (!description) {
        message.error("Description of checklist item cannot be empty");
        return;
      }
      await onCreate({ ...values, description }, creatingAnother);
      message.success("Previous item saved, you can create next");
      if (creatingAnother) {
        form.setFieldsValue({
          description: undefined,
          correct_answer: undefined,
          require_photo: props.allowChecklistItemPhoto,
        });
      } else {
        reset();
      }
    } catch (err) {
      console.log("Validate Failed:", err);
    }
  };
  return (
    <FModal
      title="New Checklist Item"
      zIndex={15}
      ref={modal}
      okText="Add +"
      open={visible}
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        reset();
      }}
      footer={
        <div className="flex flex-row-reverse gap-1">
          <Button
            label="Save & Add Next"
            icon={IconPlus}
            onClick={async () => {
              await onOk(true);
            }}
          />
          <Button label="Save" icon={IconCheck} onClick={() => onOk()} />
          <Button
            label="Cancel"
            secondary
            onClick={() => {
              onCancel();
              reset();
            }}
          />
        </div>
      }
    >
      <FModal.TextArea
        name="description"
        props={{ rows: 5 }}
        label="Checklist Item"
        requiredMessage="Enter a description"
      />
      <FModal.RadioGroup
        name="correct_answer"
        label="Choose the GOOD or CORRECT answer for this checklist item "
        required
        rules={[
          {
            required: true,
            message: (
              <div className="-mt-1.5">
                Please select one option as GOOD or CORRECT
              </div>
            ),
          },
        ]}
      >
        <div style={{ display: "flex" }}>
          <FModal.Radio props={{ value: "yes" }}>Yes</FModal.Radio>
          <FModal.Radio props={{ value: "no" }}>No</FModal.Radio>
        </div>
      </FModal.RadioGroup>
      <FModal.Checkbox name="show_na" initialValue={true}>
        Include NA as an option
      </FModal.Checkbox>
      <div className={type ? "hidden" : ""}>
        <Form.Item
          name="item_type_label"
          label="Section (group checklist items together)"
          initialValue={type === generalChecklistType ? undefined : type}
        >
          <AutoComplete
            {...{
              placeholder:
                "Enter a New Section or choose from previously added options",
              options: props.checklistItemTypes.map((v) => ({
                value: v,
              })),
              onChange: (val) => {
                modal.current?.form.setFieldsValue({
                  item_type_label: val,
                });
              },
              onSelect: (value) => {
                if (typeof value === "string") {
                  modal.current?.form.setFieldsValue({
                    item_type_label: value,
                  });
                }
              },
            }}
          />
        </Form.Item>
      </div>
      {props.allowChecklistItemPhoto && (
        <FModal.Checkbox
          valuePropName="checked"
          name="require_photo"
          initialValue={props.allowChecklistItemPhoto}
        >
          Require a photo if a checklist item is Deficient (the BAD option is
          selected)
        </FModal.Checkbox>
      )}
      {!props.hideNotifyees && (
        <>
          <FModal.Checkbox
            name="notify_on_deficient"
            props={{
              onChange: (e) => setNotifySelected(e.target.checked),
            }}
          >
            Send an email if a checklist item is Deficient
          </FModal.Checkbox>
          {notifySelected && (
            <FModal.Select
              className="-mt-0.25"
              required
              requiredMessage="Selection is required"
              name="notifyees"
              props={{
                mode: "multiple",
                showSearch: true,
                placeholder: "Select who to send the email to",
                filterOption: (input, option) => {
                  if (option?.label) {
                    return (
                      option.label
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  } else {
                    return false;
                  }
                },
                options: props.projectEmployees.map((c) => ({
                  key: c.id,
                  value: c.id,
                  label: `${c.name}, ${c.email}`,
                })),
                style: {
                  width: 400,
                },
              }}
            />
          )}
        </>
      )}
    </FModal>
  );
});

export default AddInspectionChecklistItemModal;
