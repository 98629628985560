import { DownloadOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Radio, Select, DatePicker } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import {
  useGenerateInspectionsSummaryMutation,
  useGetInspectionSummaryCreatorDataQuery,
} from "src/common/types/generated/apollo/graphQLTypes";

type FormValsType = {
  inspectionIds?: string[];
  sorter: string;
  order: string;
  file_type: "pdf" | "excel";
  start_time?: dayjs.Dayjs;
  end_time?: dayjs.Dayjs;
  qrCodeIds?: string[];
  locationIds?: string[];
  models?: string[];
  users?: string[];
  companies?: string[];
};
const InspectionSummaryCreator: React.FC<{
  inspection?: { id: string; name: string };
  showLocation: boolean;
  projectId: string;
  open: boolean;
  onClose: () => void;
}> = ({ showLocation, inspection, projectId, open, onClose }) => {
  const {
    data,
    loading: dataLoading,
    error,
  } = useGetInspectionSummaryCreatorDataQuery({
    variables: {
      inspWhere: inspection
        ? { id: { _eq: inspection.id } }
        : { project_id: { _eq: projectId } },
      projectId,
    },
    fetchPolicy: "cache-first",
  });
  if (error) throw error;

  const [generateInspectionsSummary, { loading }] =
    useGenerateInspectionsSummaryMutation();
  const [form] = Form.useForm<FormValsType>();
  const initialVals: FormValsType = {
    inspectionIds: [],
    sorter: "date",
    file_type: "pdf",
    order: "asc",
  };
  const onCloseDrawer = () => {
    form.setFieldsValue(initialVals);
    onClose();
  };
  const userOptions = useMemo(() => {
    if (!data) return [];
    const list = data.user.map((u) => ({ label: u.name, value: u.id }));
    data.user_names.forEach((ir) => {
      if (ir.user_name) list.push({ label: ir.user_name, value: ir.user_name });
    });
    return list.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
    );
  }, [data?.user, data?.user_names]);

  const companyOptions = useMemo(() => {
    if (!data) return [];
    const list = data.subcontractor.map((u) => ({
      label: u.name,
      value: u.id,
    }));
    data.company_names.forEach((ir) => {
      if (ir.company_name)
        list.push({ label: ir.company_name, value: ir.company_name });
    });
    return list.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
    );
  }, [data?.subcontractor, data?.company_names]);

  const locations = useMemo(
    () =>
      (data?.project_location || []).map((pl) => ({
        label: pl.name.en,
        value: pl.id,
      })),
    [data?.project_location],
  );
  const qrCodes = useMemo(
    () =>
      (data?.inspection_qr_code || []).map((pl) => ({
        label: pl.name,
        value: pl.id,
      })),
    [data?.inspection_qr_code],
  );
  const modelNumbers = useMemo(() => {
    const list: Array<{ label: string; value: string }> = [];
    (data?.models || []).map((pl) => {
      if (pl.model_number)
        list.push({
          label: pl.model_number,
          value: pl.model_number,
        });
    });
    return list;
  }, [data?.inspection_qr_code]);

  return (
    <Drawer
      open={open}
      title={
        inspection
          ? `Download Inspection: ${inspection.name}`
          : "Download Inspection"
      }
      destroyOnClose
      onClose={onCloseDrawer}
      footer={
        <div className="flex gap-1">
          <Button onClick={onCloseDrawer} loading={loading}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            icon={<DownloadOutlined />}
            iconPosition="end"
            onClick={async () => {
              const vals = await form.validateFields();

              const { data: res } = await generateInspectionsSummary({
                variables: {
                  input: {
                    inspectionIds: inspection
                      ? [inspection.id]
                      : vals.inspectionIds || [],
                    projectId,
                    order: vals.order,
                    sorter: vals.sorter,
                    companies: vals.companies,
                    end_time: vals.end_time?.endOf("day").toISOString(),
                    start_time: vals.start_time?.startOf("day").toISOString(),
                    file_type: vals.file_type,
                    locationIds: vals.locationIds,
                    qrCodeIds: vals.qrCodeIds,
                    models: vals.models,
                    users: vals.users,
                  },
                },
              });
              if (res) downloadFromUrl(res.generateInspectionsSummary);
              onCloseDrawer();
            }}
          >
            Download
          </Button>
        </div>
      }
    >
      <Form layout="vertical" form={form} initialValues={initialVals}>
        <Form.Item name="file_type" label="Choose File extension">
          <Radio.Group optionType="default">
            <Radio.Button value="pdf">PDF</Radio.Button>
            <Radio.Button value="excel">Excel</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="start_time" label="Start Date">
          <DatePicker
            format={"YYYY-MM-DD"}
            placeholder="From start"
            allowClear
          />
        </Form.Item>
        <Form.Item name="end_time" label="End Date">
          <DatePicker format={"YYYY-MM-DD"} placeholder="Upto Now" allowClear />
        </Form.Item>
        <Form.Item name="qrCodeIds" label="QR Code">
          <Select
            mode="multiple"
            showSearch
            filterOption={getNormalSelectOptionsFilter}
            loading={dataLoading}
            placeholder="All QR Codes selected"
            options={qrCodes}
          />
        </Form.Item>
        {showLocation && (
          <Form.Item name="locationIds" label="Job Location">
            <Select
              mode="multiple"
              loading={dataLoading}
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              placeholder="All Job Locations selected"
              options={locations}
            />
          </Form.Item>
        )}
        {!inspection && (
          <Form.Item name="inspectionIds" label="Inspection">
            <Select
              mode="multiple"
              filterOption={getNormalSelectOptionsFilter}
              showSearch
              loading={dataLoading}
              placeholder="All Inspections Selected"
              options={(data?.inspection_instance || []).map((ins) => ({
                value: ins.id,
                label: ins.name.en,
              }))}
            />
          </Form.Item>
        )}
        <Form.Item name="models" label="Model/ID">
          <Select
            mode="multiple"
            showSearch
            loading={dataLoading}
            filterOption={getNormalSelectOptionsFilter}
            placeholder="All Models selected"
            options={modelNumbers}
          />
        </Form.Item>
        <Form.Item name="users" label="Inspector">
          <Select
            mode="multiple"
            loading={dataLoading}
            showSearch
            filterOption={getNormalSelectOptionsFilter}
            placeholder="All inspectors selected"
            options={userOptions}
          />
        </Form.Item>
        <Form.Item name="companies" label="Companies">
          <Select
            mode="multiple"
            showSearch
            loading={dataLoading}
            filterOption={getNormalSelectOptionsFilter}
            placeholder="All Companies selected"
            options={companyOptions}
          />
        </Form.Item>
        <Form.Item
          name="sorter"
          label="Select sorting method"
          rules={[{ required: true, message: "select one sorting method" }]}
        >
          <Select
            filterOption={getNormalSelectOptionsFilter}
            showSearch
            options={[
              { label: "Model", value: "model" },
              ...(showLocation
                ? [{ label: "Location", value: "location" }]
                : []),
              { label: "Inspector", value: "inspector" },
              { label: "Company", value: "company" },
              { label: "Date Inspected", value: "date" },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="order"
          label="Select Sorting order"
          rules={[{ required: true, message: "Select sorting order" }]}
        >
          <Radio.Group>
            <Radio.Button value="asc">Ascending</Radio.Button>
            <Radio.Button value="desc">Descending</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default InspectionSummaryCreator;
