import React, { FC, useState } from "react";
import { Tag } from "antd";
import Icon from "src/common/components/general/Icon";
import CustomButton from "src/common/components/general/button/Button";
import {
  IconAlertTriangle,
  IconStairsUp,
  IconArrowBadgeLeft,
  IconArrowBadgeRight,
} from "@tabler/icons";
import TaskType from "src/common/types/manual/TaskType";

interface TaskVersionsViewProps {
  currentTask: TaskType;
  previousTask: TaskType;
  editedBy: string;
  editedAt: string;
  editType: string;
  previousJhaVersions: number;
  currentJHAIndex: number;
  isTurner: boolean;
  handleClick: (type: string) => void;
}

const TaskVersionsView: FC<TaskVersionsViewProps> = ({
  currentTask,
  previousTask,
  editedAt,
  editedBy,
  editType,
  previousJhaVersions,
  currentJHAIndex,
  isTurner,
  handleClick,
}) => {
  const [showEdits, setShowEdits] = useState(false);
  const [showEditsButton, setShowEditsButton] = useState(
    previousJhaVersions > 1,
  );

  const mapEcms = (ecmId: string) => {
    const e = "39ab97a5-47ac-4e5d-b287-21c2cb016c16",
      c = "05660c6a-c370-4c82-86a9-e558772d7d56",
      m = "57774dd6-3e26-45aa-a7cd-c3cb518a75f9";
    if (ecmId === e) return "E";
    else if (ecmId === c) return "C";
    else if (ecmId === m) return "M";
    else return "";
  };

  const currentJha: {
    steps: { [key: string]: string };
    hazards: { [key: string]: string };
    controls: { [key: string]: string };
    ecms: Array<string>;
    ppe: Array<string>;
    permits: Array<string>;
  } = { steps: {}, hazards: {}, controls: {}, ecms: [], ppe: [], permits: [] };

  const previousJha: {
    steps: { [key: string]: string };
    hazards: { [key: string]: string };
    controls: { [key: string]: string };
    ecms: Array<string>;
    ppe: Array<string>;
    permits: Array<string>;
  } = { steps: {}, hazards: {}, controls: {}, ecms: [], ppe: [], permits: [] };

  const deletedSteps: TaskType["steps"] = [];
  const deletedPpes: TaskType["ppes"] = [];
  const deletedPermits: TaskType["permits"] = [];

  const deletedHazards: {
    [key: string]: TaskType["steps"][number]["hazards"];
  } = {};

  const deletedEcms: {
    [key: string]: TaskType["steps"][number]["hazards"][number]["ecms"];
  } = {};

  currentTask.steps.forEach((step) => {
    currentJha.steps[step.id] = step.description;
    step.hazards.forEach((hazard) => {
      currentJha.hazards[hazard.id] = hazard.description;
      currentJha.controls[hazard.id] = hazard.control;
      hazard.ecms.forEach((ecm) => {
        if (ecm.id && ecm.description) currentJha.ecms.push(ecm.id + hazard.id);
      });
    });
  });

  currentTask.ppes.forEach((ppe) => {
    currentJha.ppe.push(ppe.id);
  });

  currentTask.permits.forEach((permit) => {
    currentJha.permits.push(permit.id);
  });

  previousTask.steps.forEach((step) => {
    previousJha.steps[step.id] = step.description;
    if (!currentJha.steps[step.id]) {
      deletedSteps.push(step);
    }
    step.hazards.forEach((hazard) => {
      previousJha.hazards[hazard.id] = hazard.description;
      previousJha.controls[hazard.id] = hazard.control;
      if (!currentJha.hazards[hazard.id]) {
        if (!deletedHazards[step.id]) {
          deletedHazards[step.id] = [hazard];
        } else {
          deletedHazards[step.id]?.push(hazard);
        }
      }
      hazard.ecms.forEach((ecm) => {
        if (ecm.id && ecm.description && ecm) {
          previousJha.ecms.push(ecm.id + hazard.id);
          if (!currentJha.ecms.includes(ecm.id + hazard.id)) {
            if (!deletedEcms[hazard.id]) {
              deletedEcms[hazard.id] = [ecm];
            } else {
              deletedEcms[hazard.id]?.push(ecm);
            }
          }
        }
      });
    });
  });

  previousTask.ppes.forEach((ppe) => {
    if (!currentJha.ppe.includes(ppe.id)) deletedPpes.push(ppe);
    previousJha.ppe.push(ppe.id);
  });

  previousTask.permits.forEach((permit) => {
    if (!currentJha.permits.includes(permit.id)) deletedPermits.push(permit);
    previousJha.permits.push(permit.id);
  });

  return (
    <div className="bg-suplementary-1  ml-2  pl-2 mr-2 pt-1 pb-1 text-0.9 rounded-2">
      <p>
        JHA Title:
        <span
          className={
            previousTask.description == currentTask.description
              ? "font-accent text-1"
              : "text-interactive-primary font-accent text-1"
          }
        >
          {" "}
          {currentTask.description}
        </span>
        {previousTask.description !== currentTask.description
          ? ` [Changed from ${previousTask.description}]`
          : ``}
        {showEditsButton &&
          (!showEdits || currentJHAIndex > 0) &&
          currentJHAIndex !== previousJhaVersions - 1 && (
            <span className="float-right mr-0.5">
              {" "}
              <CustomButton
                small
                label={
                  showEdits ? "See Current Version" : "See Previous Versions"
                }
                onClick={() => {
                  if (showEdits) {
                    handleClick("currentJha");
                    setShowEdits(false);
                  } else {
                    setShowEdits(true);
                  }
                }}
              />{" "}
            </span>
          )}
        {showEdits && currentJHAIndex > 0 && (
          <span className="float-right mr-0.5">
            {" "}
            <CustomButton
              small
              icon={IconArrowBadgeRight}
              onClick={() => handleClick("right")}
            />{" "}
          </span>
        )}
        {showEdits && currentJHAIndex == 0 && (
          <span className="float-right text-1 font-accent mr-1 mt-0.5 display-flex">
            Current JHA
          </span>
        )}
        {showEdits && currentJHAIndex < previousJhaVersions - 1 && (
          <span className="float-right mr-0.5">
            {" "}
            <CustomButton
              small
              icon={IconArrowBadgeLeft}
              onClick={() => handleClick("left")}
            />{" "}
          </span>
        )}
        {showEditsButton && currentJHAIndex === previousJhaVersions - 1 && (
          <span className="float-right text-1 font-accent mr-0.5 mt-0.5 display-flex">
            Original JHA
          </span>
        )}
      </p>
      <br />
      {previousJhaVersions &&
        editType &&
        editedBy &&
        editedAt &&
        currentJHAIndex > 0 && (
          <span className="float-right mr-0.5 mt-1">
            [Edit on {editedAt} by {editedBy}]
            <br />[{editType}]
          </span>
        )}
      {previousJhaVersions &&
        editType &&
        editedBy &&
        editedAt &&
        currentJHAIndex === 0 && (
          <span className="float-right mr-0.5 mt-1">
            [Last edit on {editedAt} by {editedBy}]
            <br />[{editType}]
          </span>
        )}
      <br />
      <br />
      <br />
      <p>
        <span className="font-accent text-1">PPE</span>{" "}
        <span>
          {" (Personal Protective Equipment)" +
            " (" +
            (currentJha.ppe ? currentJha.ppe.length : "0") +
            ")"}
        </span>
        <br />
        {currentTask.ppes.map((ppe) => {
          return (
            <>
              <span>
                <Tag
                  key={ppe.id}
                  id={ppe.id}
                  // color="#e6f7ff"
                  className={
                    !previousJha.ppe.includes(ppe.id)
                      ? "text-semantic-positive-green font-accent ml-1 mt-0.75 text-0.98"
                      : "text-interactive-primary font-accent ml-1 mt-0.75 text-0.98"
                  }
                >
                  {ppe.description}
                </Tag>
                {!previousJha.ppe.includes(ppe.id) ? `[Added]` : ``}
              </span>
            </>
          );
        })}
        <br /> <br />
        {deletedPpes && deletedPpes.length > 0 && (
          <>
            {" "}
            <span> {`Deleted PPEs(${deletedPpes.length})`} </span> <br />
            {deletedPpes.map((ppe) => {
              return (
                <>
                  <span>
                    <Tag
                      key={ppe.id}
                      id={ppe.id}
                      // color="#e6f7ff"
                      className="text-semantic-negative font-accent ml-1 mt-0.75 text-0.98"
                    >
                      {ppe.description}
                    </Tag>
                  </span>
                </>
              );
            })}
          </>
        )}
      </p>
      <br />
      <br />
      <p>
        <span className="font-accent text-1">Permits or Tags</span>
        <span>
          {" Required to Perform this JHA" +
            " (" +
            (currentJha.permits ? currentJha.permits.length : "0") +
            ")"}
        </span>
        <br />
        {currentTask.permits.map((permit) => {
          return (
            <>
              <span>
                <Tag
                  key={permit.id}
                  id={permit.id}
                  // color="#e6f7ff"
                  className={
                    !previousJha.permits.includes(permit.id)
                      ? "text-semantic-positive-green font-accent ml-1 mt-0.75 text-0.98"
                      : "text-interactive-primary font-accent ml-1 mt-0.75 text-0.98"
                  }
                >
                  {" "}
                  {permit.description}
                </Tag>
                {!previousJha.permits.includes(permit.id) ? `[Added]` : ``}
              </span>{" "}
            </>
          );
        })}
        <br />
        <br />
        {deletedPermits && deletedPermits.length > 0 && (
          <>
            {" "}
            <span> {`Deleted Permits(${deletedPermits.length})`} </span> <br />
            {deletedPermits.map((permit) => {
              return (
                <>
                  <span>
                    <Tag
                      key={permit.id}
                      id={permit.id}
                      // color="#e6f7ff"
                      className="text-semantic-negative font-accent ml-1 mt-0.75 text-0.98"
                    >
                      {permit.description}
                    </Tag>
                  </span>
                </>
              );
            })}
          </>
        )}
      </p>
      <br /> <br />
      <span className="font-accent text-1 mt-1 mb-1">
        Steps ({currentTask.steps.length})
      </span>
      <div>
        {" "}
        {currentTask.steps.map((step, i) => {
          return (
            <div key={step.id}>
              <span className="inline-flex items-center mt-1.5">
                <span>
                  {" "}
                  <Icon color="interactive" icon={IconStairsUp}></Icon>{" "}
                </span>{" "}
                <span className="ml-0.5">Step</span>
                <span className="ml-0.3">{i + 1}:</span>
                <span
                  className={
                    !previousJha.steps[step.id]
                      ? "text-semantic-positive-green ml-0.5 font-accent"
                      : previousJha.steps[step.id] !== step.description
                      ? "text-interactive-primary ml-0.5 font-accent"
                      : "ml-0.5 font-accent"
                  }
                >
                  {step.description}
                </span>
                <span className="ml-0.5">
                  {" "}
                  {!previousJha.steps[step.id]
                    ? ` [Added]`
                    : previousJha.steps[step.id] !== step.description
                    ? ` [Changed from ${previousJha.steps[step.id]}]`
                    : ``}
                </span>
              </span>{" "}
              <br />
              <br />
              {step.hazards.length > 0 && (
                <span className="pl-3 pt-0.5 font-accent text-1">
                  Hazards ({step.hazards.length ?? 0})
                </span>
              )}
              {step.hazards.map((hazard, i2) => {
                return (
                  <div key={hazard.id}>
                    <p className="flex pt-1 pl-4">
                      <span>
                        <Icon color="negative" icon={IconAlertTriangle}></Icon>
                      </span>
                      <span className="ml-0.5">Hazard {i2 + 1}:</span>
                      <span
                        className={
                          !previousJha.hazards[hazard.id]
                            ? "text-semantic-positive-green font-accent ml-1"
                            : previousJha.hazards[hazard.id] !==
                              hazard.description
                            ? "text-interactive-primary font-accent ml-1"
                            : "font-accent ml-1"
                        }
                      >
                        {" " + hazard.description}
                      </span>
                      <span className="ml-0.5">
                        {" "}
                        {!previousJha.hazards[hazard.id]
                          ? " [Added]"
                          : previousJha.hazards[hazard.id] !==
                            hazard.description
                          ? `[Changed from ${previousJha.hazards[hazard.id]}]`
                          : ""}{" "}
                      </span>
                    </p>
                    <p className="flex pl-4 mt-0.5">
                      <span className="ml-2">Control: </span>{" "}
                      <span
                        // className="ml-1.5"
                        className={
                          !previousJha.controls[hazard.id]
                            ? "text-semantic-positive-green ml-1.5"
                            : previousJha.controls[hazard.id] !== hazard.control
                            ? "text-interactive-primary ml-1.5"
                            : "ml-1.5"
                        }
                      >
                        {hazard.control}
                      </span>
                      <span className="ml-0.5">
                        {" "}
                        {!previousJha.controls[hazard.id]
                          ? " [Added]"
                          : previousJha.controls[hazard.id] !== hazard.control
                          ? `[Changed from ${previousJha.controls[hazard.id]}]`
                          : ""}{" "}
                      </span>
                    </p>
                    {isTurner && hazard.ecms && (
                      <p className="mt-0.5">
                        <span className="ml-2 pl-4">E,C,M: </span>{" "}
                        <span className="ml-2">
                          {hazard.ecms.map((ecm, ecmIndex) => (
                            <span>
                              <span
                                key={ecm.id}
                                className={
                                  !previousJha.ecms.includes(ecm.id + hazard.id)
                                    ? "text-semantic-positive-green"
                                    : ""
                                }
                              >
                                {mapEcms(ecm.id ?? "")}
                              </span>
                              <span className="ml-0.5">
                                {" "}
                                {(!previousJha.ecms.includes(ecm.id + hazard.id)
                                  ? ` [Added]`
                                  : ``) +
                                  (ecmIndex !== hazard?.ecms.length - 1
                                    ? ", "
                                    : "")}{" "}
                              </span>
                            </span>
                          ))}
                        </span>
                        {deletedEcms[hazard.id] && (
                          <span className="ml-3">
                            {" "}
                            <span>Deleted E,C,M:</span>
                            <span>
                              {deletedEcms[hazard.id]?.map((ecm, ecmIndex) => (
                                <span
                                  key={ecm.id}
                                  className="ml-1 text-semantic-negative"
                                >
                                  {mapEcms(ecm.id ?? "") +
                                    (ecmIndex !== hazard.ecms.length - 1
                                      ? ", "
                                      : "")}{" "}
                                </span>
                              ))}{" "}
                            </span>
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                );
              })}{" "}
              {deletedHazards[step.id] &&
                deletedHazards[step.id].map((hazard, i2) => {
                  return (
                    <div key={hazard.id}>
                      {i2 === 0 && (
                        <p className="ml-3 mt-1 font-accent">
                          {" "}
                          {`Deleted Hazards(${
                            deletedHazards[step.id].length
                          })`}{" "}
                        </p>
                      )}
                      <p className="flex pt-1 pl-4">
                        <span>
                          <Icon
                            color="negative"
                            icon={IconAlertTriangle}
                          ></Icon>
                        </span>
                        <span className="ml-0.5">Hazard {i2 + 1}:</span>
                        <span className="text-semantic-negative font-accent ml-1">
                          {" " + hazard.description}
                        </span>
                      </p>
                      <p className="flex pl-4 mt-0.5">
                        <span className="ml-2">Control: </span>{" "}
                        <span className="text-semantic-negative ml-1.5">
                          {hazard.control}
                        </span>
                      </p>
                      {hazard.ecms && (
                        <p className="mt-0.5">
                          <span className="ml-2 pl-4">E,C,M: </span>{" "}
                          <span className="ml-2">
                            {hazard.ecms.map((ecm, ecmIndex) => (
                              <span
                                key={ecm.id}
                                className="text-semantic-negative"
                              >
                                {mapEcms(ecm.id ?? "") +
                                  (ecmIndex !== hazard.ecms.length - 1
                                    ? ", "
                                    : "")}
                              </span>
                            ))}
                          </span>
                        </p>
                      )}
                    </div>
                  );
                })}
            </div>
          );
        })}{" "}
      </div>
      {deletedSteps && deletedSteps.length > 0 && (
        <div className="mt-2">
          <span className="font-accent text-1 mt-1 mb-1">
            Deleted steps({deletedSteps.length})
          </span>

          <div>
            {" "}
            {deletedSteps.map((step, i) => {
              return (
                <div key={step.id}>
                  <span className="inline-flex items-center mt-1.5">
                    <span>
                      {" "}
                      <Icon color="interactive" icon={IconStairsUp}></Icon>{" "}
                    </span>{" "}
                    <span className="ml-0.5">Step</span>
                    <span className="ml-0.3">{i + 1}:</span>
                    <span className="text-semantic-negative ml-0.5 font-accent">
                      {step.description}
                    </span>
                  </span>{" "}
                  <br />
                  <br />
                  {step.hazards.length > 0 && (
                    <span className="pl-3 pt-0.5 font-accent text-1">
                      Hazards ({step.hazards.length ?? 0})
                    </span>
                  )}
                  {step.hazards.map((hazard, i2) => {
                    return (
                      <div key={hazard.id}>
                        <p className="flex pt-1 pl-4">
                          <span>
                            <Icon
                              color="negative"
                              icon={IconAlertTriangle}
                            ></Icon>
                          </span>
                          <span className="ml-0.5">Hazard {i2 + 1}:</span>
                          <span className="text-semantic-negative font-accent ml-1">
                            {" " + hazard.description}
                          </span>
                        </p>
                        <p className="flex pl-4 mt-0.5">
                          <span className="ml-2">Control: </span>{" "}
                          <span
                            // className="ml-1.5"
                            className="text-semantic-negative ml-1.5"
                          >
                            {hazard.control}
                          </span>
                        </p>
                        {isTurner && hazard.ecms && (
                          <p className="mt-0.5">
                            <span className="ml-2 pl-4">E,C,M: </span>{" "}
                            <span className="ml-2">
                              {hazard.ecms.map((ecm, ecmIndex) => (
                                <span
                                  key={ecm.id}
                                  className="text-semantic-negative"
                                >
                                  {mapEcms(ecm.id ?? "") +
                                    (ecmIndex !== hazard.ecms.length - 1
                                      ? ", "
                                      : "")}
                                </span>
                              ))}
                            </span>
                          </p>
                        )}
                      </div>
                    );
                  })}{" "}
                </div>
              );
            })}{" "}
          </div>
        </div>
      )}
    </div>
  );
};
export default TaskVersionsView;
