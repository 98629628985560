import React, { useState } from "react";
import { GetIncidentByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { Card, Image, Modal } from "antd";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Icon from "src/common/components/general/Icon";
import {
  IconClipboardData,
  IconDeviceAudioTape,
  IconTrash,
  IconVideo,
} from "@tabler/icons";
import PdfModal from "src/common/components/modals/PdfModal";
import AudioPlayer from "src/common/components/media-players/audio-player/AudioPlayer";
import VideoPlayerForAnyUrl from "src/common/components/media-players/video-player/VideoPlayerForAnyUrl";
import { LanguageProvider } from "src/utility-features/i18n/context/languageContext";
import downloadFromUrl from "src/common/functions/downloadFromUrl";

import dayjs from "dayjs";

const { Meta } = Card;

export interface DeletedDocument {
  id: string;
  url: string;
  name: string;
}

interface IncidentDocViewerProps {
  documents: NonNullable<
    GetIncidentByPkQuery["incident_by_pk"]
  >["incident_types"][number]["documents"];
  deleteDocument: (doc: DeletedDocument) => Promise<void>;
}

const IncidentDocViewer: React.FunctionComponent<IncidentDocViewerProps> = ({
  documents,
  deleteDocument,
}) => {
  const [selectedPdf, setSelectedPdf] = useState<string>();
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string>();

  return (
    <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-x-1">
      {documents &&
        documents.map((doc, i) => {
          const createdDate = dayjs(doc.created_at).format("DD-MMM-YYYY");
          return (
            <div key={doc.id} className="p-1">
              <Modal
                footer={<></>}
                width={700}
                open={!!selectedVideoUrl}
                onCancel={() => {
                  setSelectedVideoUrl(undefined);
                }}
                destroyOnClose
              >
                {selectedVideoUrl && (
                  <VideoPlayerForAnyUrl url={selectedVideoUrl} />
                )}
              </Modal>
              <PdfModal
                visible={!!selectedPdf}
                pdfUrl={selectedPdf}
                destroyOnClose
                onClose={() => setSelectedPdf(undefined)}
              />

              <Card
                key={doc.url}
                className="w-8 h-8 p-1"
                styles={{
                  body: {
                    padding: "1rem",
                    borderColor: "black",
                    borderWidth: "2px",
                    borderStyle: "solid",
                  },
                }}
                onClick={() => {
                  if (doc.document_type?.includes("video")) {
                    setSelectedVideoUrl(doc.url);
                  } else if (doc.document_type?.includes("pdf"))
                    setSelectedPdf(doc.url);
                  else if (doc.document_type?.includes("audio")) {
                  } else if (doc.document_type?.includes("spreadsheet"))
                    downloadFromUrl(doc.url, true);
                }}
              >
                {doc.document_type && (
                  <div className="flex flex-col  items-center">
                    <div className="max-h-3 max-w-3 mb-1">
                      {doc.image ? (
                        <Image
                          className="object-fill max-w-3 max-h-3 "
                          src={doc.url}
                          alt={"Image: " + (i + 1)}
                        />
                      ) : (
                        <Icon
                          icon={
                            doc.document_type.includes("video")
                              ? IconVideo
                              : doc.document_type?.includes("audio")
                              ? IconDeviceAudioTape
                              : IconClipboardData
                          }
                        />
                      )}
                    </div>
                    <Meta
                      description={
                        <BPopconfirm
                          onConfirm={(e) => {
                            e?.stopPropagation();
                            deleteDocument({
                              id: doc.id,
                              url: doc.url,
                              name: doc.name ?? "Deleted Document",
                            });
                          }}
                          onCancel={(e) => e?.stopPropagation()}
                          title="Remove this from incident"
                        >
                          <div
                            className="flex justify-center z-10 cursor-pointer bg-red-500 text-white rounded"
                            onClick={(e) => e.stopPropagation()}
                          >
                            Delete
                            <Icon icon={IconTrash} />
                          </div>
                        </BPopconfirm>
                      }
                    />
                  </div>
                )}
              </Card>
              <div className="gap-x-1 mt-1 max-w-8 min-w-8 max-h-2 min-h-2 ">
                {doc.name + " (" + createdDate + ")"}
              </div>
              {doc.document_type?.includes("audio") && (
                <LanguageProvider>
                  <AudioPlayer
                    audioSrc={doc.url}
                    allowedControls={["rewind", "skip"]}
                  />
                </LanguageProvider>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default IncidentDocViewer;
