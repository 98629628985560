/**
 * @generated SignedSource<<8643bbc24109bc34b123bb977686de15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { certificates_to_verify_updates, user_updates } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SFVerifyProfilePhotoMutation$variables = {
  certsUpdates: ReadonlyArray<certificates_to_verify_updates>;
  userUpdates: ReadonlyArray<user_updates>;
};
export type SFVerifyProfilePhotoMutation$data = {
  readonly update_certificates_to_verify_many: ReadonlyArray<{
    readonly affected_rows: number;
  } | null | undefined> | null | undefined;
  readonly update_user_many: ReadonlyArray<{
    readonly affected_rows: number;
  } | null | undefined> | null | undefined;
};
export type SFVerifyProfilePhotoMutation = {
  response: SFVerifyProfilePhotoMutation$data;
  variables: SFVerifyProfilePhotoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certsUpdates"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userUpdates"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "updates",
        "variableName": "userUpdates"
      }
    ],
    "concreteType": "user_mutation_response",
    "kind": "LinkedField",
    "name": "update_user_many",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "updates",
        "variableName": "certsUpdates"
      }
    ],
    "concreteType": "certificates_to_verify_mutation_response",
    "kind": "LinkedField",
    "name": "update_certificates_to_verify_many",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SFVerifyProfilePhotoMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SFVerifyProfilePhotoMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c12d18564ca2c3270684ac6ee4fc5b60",
    "id": null,
    "metadata": {},
    "name": "SFVerifyProfilePhotoMutation",
    "operationKind": "mutation",
    "text": "mutation SFVerifyProfilePhotoMutation(\n  $userUpdates: [user_updates!]!\n  $certsUpdates: [certificates_to_verify_updates!]!\n) {\n  update_user_many(updates: $userUpdates) {\n    affected_rows\n  }\n  update_certificates_to_verify_many(updates: $certsUpdates) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "002aebb40f2e9ac36a40a6a2a9332992";

export default node;
