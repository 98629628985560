import dayjs from "dayjs";
import React, { useState } from "react";
import { SubOnsiteData } from "./GCDashAppReportsTable";
import {
  IconArrowBigRightLines,
  IconCalendar,
  IconInfoSquare,
} from "@tabler/icons";
import ShowToggleTable, {
  ExtendedRow,
} from "src/common/components/tables/ShowToggleTable/ShowToggleTable";
import Button from "src/common/components/general/button/Button";
import GCDashAppReportSettingsOnDate from "./GCDashAppReportSettingsOnDate";
import { useNavigate } from "react-router-dom";
import DatesNavigation from "../../entryRoutes/qrProjectReports/components/DatesNavigation";
import useGetSwitchers from "./useGetSwitchers";

interface GCDashAppReportSettingsProps {
  projectId: string;
  projectTimezone: string;
  subOnsiteData?: SubOnsiteData[];
  crewLeads: { [id: string]: string };
}

const GCDashAppReportSettings: React.FunctionComponent<
  GCDashAppReportSettingsProps
> = ({ projectId, subOnsiteData, projectTimezone, crewLeads }) => {
  const [date, setDate] = useState<dayjs.Dayjs>();
  const navigate = useNavigate();
  const { getSubSwitchersFor, getCrewSwitchersFor } =
    useGetSwitchers(projectId);
  const rows: ExtendedRow[] = [];
  (subOnsiteData || []).map((sub, i) => {
    const showCrewData = sub.crews.length > 1;
    rows.push({
      id: sub.subId,
      title:
        sub.subName + (showCrewData ? ` (${sub.crews.length} Crews) ` : ""),
      haveChilds: showCrewData,
      button: showCrewData
        ? {
            secondary: true,
            label: "Manage Crew(s)",
            icon: IconArrowBigRightLines,
            onClick: () =>
              navigate(
                `/gce/projects/${projectId}/subcontractors/${sub.subId}/workers-and-crews`,
              ),
          }
        : undefined,
      parentItem: true,
      switchers: Object.values(getSubSwitchersFor(sub)),
    });
    if (sub.crews.length > 1) {
      sub.crews.forEach((crew) =>
        rows.push({
          id: crew.crewId,
          title: crew.crewName + (crew.crewLead ? `, ${crew.crewLead}` : ""),
          parentId: sub.subId,
          switchers: Object.values(getCrewSwitchersFor(crew, sub)),
        }),
      );
    }
  });
  return (
    <div className="flex flex-col w-screen">
      <div className="flex  flex-row gap-1 mt-2 items-center">
        <div className="flex gap-1 items-center">
          {date ? (
            <>
              Change Previous Day{" "}
              <DatesNavigation
                date={date}
                onDateChange={(newDate) => setDate(newDate)}
                disabledDate={(current) =>
                  current.isAfter(dayjs().endOf("day"))
                }
                disabledForward={
                  date && date.isSameOrAfter(dayjs().startOf("d"))
                }
              />{" "}
              <Button
                onClick={() => setDate(undefined)}
                label="Go to Permanent Settings"
              />
            </>
          ) : (
            <Button
              icon={IconCalendar}
              label="Change a Previous Day"
              hint="Go to a previous day to adjust Onsite/Offsite status"
              onClick={() => setDate(dayjs())}
            />
          )}
        </div>
      </div>
      {date ? (
        <GCDashAppReportSettingsOnDate
          date={date}
          crewLeads={crewLeads}
          projectTimezone={projectTimezone}
          projectId={projectId}
        />
      ) : (
        <ShowToggleTable
          {...{
            columns: [],
            mainColumn: {
              rows: rows,
              switchTitles: [
                {
                  title: "Onsite Mngt",
                  icon: {
                    icon: IconInfoSquare,
                    hoverContent: {
                      content: (
                        <div className="w-24">
                          Setting to “Onsite” will mark a Sub or Crew as working
                          on the project indefinitely until they marked
                          “Offsite”. This triggers your scheduled push
                          notifications in the mobile app and emails. Onsite
                          status is only Monday through Friday. Marking a
                          subcontractor as Onsite on the weekends is done under
                          “Dashboard” in the mobile app.
                          <br />
                          Reports are only required when the Sub or Crew is
                          marked Onsite
                        </div>
                      ),
                    },
                  },
                },
                { title: "Pre-Task Plan" },
                { title: " Daily Report" },
                { title: "Toolbox Talk" },
              ],
              title: " Permanent Subcontractors Onsite ",
              id: "sub",
            },
          }}
        />
      )}
    </div>
  );
};

export default GCDashAppReportSettings;
