import { Alert, Button, Form, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { useGetEmployeeTitlesQuery } from "src/common/types/generated/apollo/graphQLTypes";

export interface CreateGCEmployeeFormValues {
  name: string;
  email: string;
  phone: string;
  titleId: string;
}

interface Props {
  visible: boolean;
  onCreate: (values: CreateGCEmployeeFormValues) => void;
  onCancel: () => void;
  title?: string;
  warning?: string;
}

const CreateGCEmployeeModal: React.FC<Props> = ({
  visible,
  onCreate,
  onCancel,
  title,
  warning,
}) => {
  const [form] = Form.useForm<CreateGCEmployeeFormValues>();
  const { data: titleData, loading: employeeTitleLoading } =
    useGetEmployeeTitlesQuery();
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={visible}
      title={title ?? "Create a General Contractor Employee"}
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={async () => {
        const values = await form.validateFields().catch(v => null);
        if (!values)
            return;
        await onCreate(values);
        form.resetFields();
      }}
      footer={
        <div className="flex-row ">
          <Button disabled={loading} loading={loading} onClick={handleCancel}>
            {"Cancel"}
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            className="text-white bg-interactive-primary border-interactive-primary ml-0"
            onClick={async () => {
              const values = await form.validateFields().catch(v => null);
              if (!values)
                  return;
              setLoading(true);
              try {
                await onCreate(values);
                form.resetFields();
              } finally {
                setLoading(false);
              }
            }}
          >
            {"Create"}
          </Button>
        </div>
      }
    >
      <Alert message={warning} type="error" className="mb-1" />
      {/* <p style={{ color: "red" }}>{`* ${warning}`}</p> */}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Enter a name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Enter a email" }]}
        >
          <Input type="email" autoCorrect="off" autoCapitalize="none" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[{ required: true, message: "Enter a phone" }]}
        >
          <Input />
        </Form.Item>
        {titleData && !employeeTitleLoading && (
          <Form.Item
            name="titleId"
            label="Title"
            rules={[{ required: true, message: "Choose a title" }]}
          >
            <Select style={{ width: "100%" }}>
              {titleData?.employee_title.map((w) => (
                <Select.Option key={w.id} value={w.id}>
                  {w.name.en}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CreateGCEmployeeModal;
