import { Form, notification, Spin } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { getNextHardHatNumber } from "src/common/functions/hardHatHelpers";
import {
  orientation_bool_exp,
  SiteOrientationSlidesViewerQuery,
} from "src/common/types/generated/relay/SiteOrientationSlidesViewerQuery.graphql";
import * as uuid from "uuid";
import dayjs from "dayjs";
import { commitLocalUpdate } from "relay-runtime";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import {
  OrientationEmailType,
  useEmailGcUsersToVerifyNewGcUserMutation,
  useEmailOrientationConfirmationQrCodeMutation,
  useUpdateCorporateOrientationResultsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import {
  orientation_result_insert_input,
  user_orientation_insert_input,
  user_orientation_update_column,
} from "src/common/types/generated/relay/useInsertSiteOrientationResultMutation.graphql";
import { SiteOrientationSlidesViewer_UpdateOrientationResult_Mutation } from "src/common/types/generated/relay/SiteOrientationSlidesViewer_UpdateOrientationResult_Mutation.graphql";
import { SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation } from "src/common/types/generated/relay/SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation.graphql";
import { SiteOrientationSlidesViewer_UpsertQuizResult_Mutation } from "src/common/types/generated/relay/SiteOrientationSlidesViewer_UpsertQuizResult_Mutation.graphql";
import useInsertSiteOrientationResult from "../../utils/useInsertSiteOrientationResult";
import { InsertImagesMutation } from "src/common/types/generated/relay/InsertImagesMutation.graphql";
import { OrientationTypeProps } from "src/common/types/manual/OrientationType";
import GetFullID from "src/common/functions/GetFullId";
import fixEnglish from "src/domain-features/siteorientation/utils/fixEnglish";
import { translateSlide } from "src/domain-features/siteorientation/utils/translateSlide";
import insertImages from "src/common/api/relay/mutations/InsertImages";
import SiteOrientationSlidesViewerUI from "src/domain-features/siteorientation/components/slidesViewer/SiteOrientationSlidesViewerUI";
import viewedSlideInsertUpdater from "src/domain-features/siteorientation/utils/viewedSlideInsertUpdater";
import { InsertOrientationViewedSlideMutation } from "src/common/types/generated/relay/InsertOrientationViewedSlideMutation.graphql";
import insertOrientationViewedSlide from "src/common/api/relay/mutations/InsertOrientationViewedSlide";
import {
  OrientationPage,
  SlideAnswersMapType,
} from "src/domain-features/siteorientation/siteorientationTypes";
import { returnVal } from "../../entryRoutes/gcDashboard/routes/status/components/OrientationProjectWorkerTable";
import SiteOrientationOrienteeContext from "../../utils/siteOrientationOrienteeContext";
import Button from "src/common/components/general/button/Button";
import useUpdateHH from "../../utils/useUpdateHH";
import { Language } from "../../../../utility-features/i18n/language-utils/i18nTypes";
import { AVAILABLE_LANGUAGES } from "../../../../utility-features/i18n/languages";
import useLangStrings, {
  useCurrentLanguageState,
} from "../../../../utility-features/i18n/context/languageHooks";
import stringToLanguage from "src/utility-features/i18n/language-utils/stringToLanguage";

interface QuizResults {
  [key: string]: string;
}

interface OrientationResults {
  signature_url?: string;
  results: { [key: string]: QuizResults };
}

interface SiteOrientationSlidesViewerProps {
  projectId: string;
  workerId: string;
  isTestLink?: boolean;
}

const query = graphql`
  query SiteOrientationSlidesViewerQuery(
    $projectId: uuid!
    $workerId: uuid!
    $orientationWhere: orientation_bool_exp!
    $now: timestamptz!
    $universalOrientationValidDate: timestamptz!
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
          in_person_orientation
          agc_universal_orientation
          add_hard_hat_during_registration
          next_hard_hat_number
          assign_hard_hat
          automatically_assign_hard_hat
          linked_orientation_projects {
            pk: id @__clientField(handle: "pk")
          }
          general_contractor {
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
    user_orientation_connection(
      where: {
        user_id: { _eq: $workerId }
        project: { orientation_project_id: { _eq: $projectId } }
      }
      order_by: { orientated_at: desc }
    ) {
      edges {
        node {
          id
          orientated_at
          in_person_orientated_at
          in_person_signature_id
        }
      }
    }
    user_connection(where: { id: { _eq: $workerId } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          lang
          name
          role
          username
          universal_orientations(
            where: {
              universal_orientated_at: { _gte: $universalOrientationValidDate }
            }
            order_by: { universal_orientated_at: desc }
            limit: 1
          ) {
            id
            universal_orientated_at
          }
          employee {
            employee_projects(
              where: {
                project: { orientation_project_id: { _eq: $projectId } }
              }
              order_by: { hard_hat_number: asc_nulls_first }
            ) {
              pk: id @__clientField(handle: "pk")
              project_id
              hard_hat_number
            }
          }
          worker {
            worker_projects(
              where: {
                project: { orientation_project_id: { _eq: $projectId } }
                subcontractor_worker: {}
              }
              order_by: { hard_hat_number: asc_nulls_first }
            ) {
              pk: id @__clientField(handle: "pk")
              project_id
              hard_hat_number
            }
          }
        }
      }
    }
    orientation_connection(
      where: $orientationWhere
      order_by: [{ order: asc }, { name: asc }]
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          id
          name
          created_at
          project_id
          duration_valid
          type
          user {
            name
          }
          project_orientations(where: { project_id: { _eq: $projectId } }) {
            play_during_registration
            play_during_in_person
            required_by_all_workers
            hide_but_give_credit
          }
          universalOrientationRequire: project_orientations(
            where: { project_id: { _is_null: true } }
          ) {
            required_by_all_workers
          }
          completedOnOtherProjectResults: orientation_results(
            where: {
              user_id: { _eq: $workerId }
              status: { _eq: "completed" }
              expired_at: { _gte: $now }
            }
          ) {
            id
          }
          orientation_results(
            where: { user_id: { _eq: $workerId }, status: { _eq: "pending" } }
          ) {
            pk: id @__clientField(handle: "pk")
            id
            expired_at
            orientation_id
            orientation {
              duration_valid
            }
            group_id
            quiz_results {
              answer
              orientation_slide_id
              lang
              id
              pk: id @__clientField(handle: "pk")
            }
          }
          slides(
            where: {
              deleted_at: { _is_null: true }
              archived_at: { _is_null: true }
            }
            order_by: { order: asc }
          ) {
            ...SlideFrag @relay(mask: false)
            viewed_by(
              where: { user_id: { _eq: $workerId } }
              order_by: { created_at: desc }
            ) {
              created_at
              id
            }
          }
        }
      }
    }
  }
`;

export const upsertQuizResultMutation = graphql`
  mutation SiteOrientationSlidesViewer_UpsertQuizResult_Mutation(
    $object: orientation_quiz_result_insert_input!
  ) {
    insert_orientation_quiz_result_one(
      object: $object
      on_conflict: {
        constraint: orientation_quiz_result_orientation_result_id_orientation_slide
        update_columns: [answer, lang]
      }
    ) {
      id
    }
  }
`;
export const upsertOrientationResultsMutation = graphql`
  mutation SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation(
    $objects: [orientation_result_insert_input!]!
  ) {
    insert_orientation_result(
      objects: $objects
      on_conflict: {
        constraint: orientation_result_pkey
        update_columns: [
          total_slides
          viewed_slides
          signature_url
          status
          expired_at
          completed_at
          group_id
          result_inserted_by_uid
        ]
      }
    ) {
      affected_rows
    }
  }
`;
export const updateOrientationResultMutation = graphql`
  mutation SiteOrientationSlidesViewer_UpdateOrientationResult_Mutation(
    $orientationResultWhere: orientation_result_bool_exp!
    $orientationResultSet: orientation_result_set_input
  ) {
    update_orientation_result(
      where: $orientationResultWhere
      _set: $orientationResultSet
    ) {
      affected_rows
    }
  }
`;
const GCSiteOrientationSlidesViewer: FC<
  SiteOrientationSlidesViewerProps &
    OrientationTypeProps & { lang: Language; goToNext?: () => void }
> = ({ projectId, workerId, isTestLink, type, lang, goToNext }) => {
  // console.log(`GCSiteOrientationSlidesViewer render worker = ${workerId}`);
  // useEffect(() => {
  //   return () => {
  //     alert("Are you sure you want to leave?");
  //   };
  // }, []);
  const [globalLang, setGlobalLang] = useCurrentLanguageState();
  const currentLang = lang ?? globalLang;
  const orientationId = document.location.hash?.substring(1);
  const context = useContext(SiteOrientationOrienteeContext);
  const [emailOrientationConfirmationQrCode, { loading: isSendingEmail }] =
    useEmailOrientationConfirmationQrCodeMutation();
  const [emailGCUsersToVerifyNewGCUser] =
    useEmailGcUsersToVerifyNewGcUserMutation();
  const orientationWhere: orientation_bool_exp = orientationId
    ? {
        id: { _eq: orientationId },
      }
    : {
        deleted_at: { _is_null: true },
        _and: [
          {
            _or: [
              {
                general_contractor_id: { _is_null: true },
                project_orientations: {
                  project_id: { _is_null: true },
                  required_by_all_workers: { _eq: true },
                },
              },
              { general_contractor: { projects: { id: { _eq: projectId } } } },
            ],
          },
          {
            _or: [
              { project_id: { _is_null: true } },
              { project_id: { _eq: projectId } },
            ],
          },
        ],
      };
  const data = useLazyLoadQuery<SiteOrientationSlidesViewerQuery>(
    query,
    {
      now: dayjs().endOf("d").format(),
      projectId,
      workerId,
      orientationWhere,
      // although universal Orientation is valid for 12 months, we are using 11 months here so that we can
      // have user able to insert result 1 month before his orientation expires
      universalOrientationValidDate: dayjs()
        .subtract(11, "months")
        .startOf("d")
        .toISOString(),
    },
    { fetchPolicy: "network-only" },
  ); // userConnection
  const [insertSignatureImage, isInserting] =
    useAsyncMutation<InsertImagesMutation>(insertImages);
  const [updateCorporateOrientationResults] =
    useUpdateCorporateOrientationResultsMutation();
  const [upsertQuizResult] =
    useAsyncMutation<SiteOrientationSlidesViewer_UpsertQuizResult_Mutation>(
      upsertQuizResultMutation,
    );

  const [upsertOrientationResults] =
    useAsyncMutation<SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation>(
      upsertOrientationResultsMutation,
    );
  const [updateOrientationResult] =
    useAsyncMutation<SiteOrientationSlidesViewer_UpdateOrientationResult_Mutation>(
      updateOrientationResultMutation,
    );
  const [insertViewedSlide] =
    useAsyncMutation<InsertOrientationViewedSlideMutation>(
      insertOrientationViewedSlide,
    );
  const [updateHH] = useUpdateHH();
  const [insertResultMutation] = useInsertSiteOrientationResult();

  const projectData = data.project_connection.edges[0].node;

  const firstUserOrientation = data.user_orientation_connection.edges[0];
  const hasInPerson =
    data.project_connection.edges[0].node.in_person_orientation;

  const inPersonType = type === "inperson" || type === "inpersonComplete";
  const orientationsBeingPlayedInPersonOnGCScreen = useMemo(
    () =>
      !inPersonType
        ? []
        : (data.orientation_connection.edges || [])
            .map((v) => v.node)
            .filter((o) => {
              if (o.type === "universal") {
                return (
                  projectData.agc_universal_orientation &&
                  o.completedOnOtherProjectResults.length === 0 &&
                  o.universalOrientationRequire[0]?.required_by_all_workers &&
                  o.project_orientations[0]?.hide_but_give_credit
                );
              } else
                return (
                  o.completedOnOtherProjectResults.length === 0 &&
                  o.project_orientations[0] &&
                  o.project_orientations[0].required_by_all_workers &&
                  o.project_orientations[0].hide_but_give_credit
                );
            }),
    [data.orientation_connection],
  );
  console.log(orientationsBeingPlayedInPersonOnGCScreen);
  const allOrientations = useMemo(
    () =>
      (data.orientation_connection.edges || [])
        .map((v) => v.node)
        .filter((o) => {
          if (orientationId) {
            return orientationId === o.pk;
          } else {
            if (o.type === "unviersal") {
              return (
                o.completedOnOtherProjectResults.length === 0 &&
                projectData.agc_universal_orientation &&
                o.universalOrientationRequire[0]?.required_by_all_workers &&
                (inPersonType && o.project_orientations[0]
                  ? !o.project_orientations[0]?.hide_but_give_credit
                  : true)
              );
            } else {
              return (
                o.completedOnOtherProjectResults.length === 0 &&
                (inPersonType && o.project_orientations[0]
                  ? !o.project_orientations[0].hide_but_give_credit
                  : true)
              );
            }
          }
        }),
    [data.orientation_connection],
  );

  const toGiveCreditOn3rdQRButNotPlay = useMemo(
    () =>
      allOrientations.filter(
        (o) =>
          o.project_orientations[0] &&
          (type === "inpersonComplete"
            ? (o.type === "universal"
                ? o.universalOrientationRequire[0]?.required_by_all_workers
                : o.project_orientations[0].required_by_all_workers) &&
              !o.project_orientations[0]?.play_during_in_person
            : false),
      ),
    [allOrientations],
  );
  const orientations = useMemo(() => {
    if (orientationId)
      return allOrientations.filter((o) => o.pk === orientationId);

    return allOrientations
      .filter((o) =>
        inPersonType
          ? o.project_orientations[0]?.play_during_in_person
          : o.project_orientations[0]?.play_during_registration,
      )
      .sort((a, b) => {
        const val1 =
          a.type === "universal" ? 1 : a.type === "corporate" ? 2 : 3;
        const val2 =
          b.type === "universal" ? 1 : b.type === "corporate" ? 2 : 3;
        const returnedValue = returnVal(val1, val2);
        return inPersonType ? -returnedValue : returnedValue;
      });
  }, [allOrientations]);
  const universalOrientations = data.orientation_connection.edges.filter(
    (p) =>
      p.node.type === "universal" &&
      projectData.agc_universal_orientation &&
      p.node.universalOrientationRequire[0].required_by_all_workers,
  );

  const userData = data.user_connection.edges[0].node;
  const project_users =
    userData.worker?.worker_projects ||
    userData.employee?.employee_projects ||
    [];
  const universalCompletedOrientation =
    !!userData.universal_orientations[0]?.universal_orientated_at; //here we're calling only valid result
  const completedProjectAndUniversalOrientation =
    data.user_orientation_connection.edges.length > 0 &&
    data.user_orientation_connection.edges.every((p) => p.node.orientated_at) &&
    universalCompletedOrientation;

  const giveUOCredit =
    projectData.agc_universal_orientation &&
    !universalCompletedOrientation &&
    universalOrientations.some((o) => {
      return inPersonType
        ? o.node.project_orientations[0]?.play_during_in_person ||
            o.node.project_orientations[0]?.hide_but_give_credit
        : o.node.project_orientations[0]?.play_during_registration;
    });

  const projectsToInsertResult =
    userData.role === "employee"
      ? projectData.linked_orientation_projects.map((p) => p.pk)
      : project_users.map((p) => p.project_id);

  const langStrings = useLangStrings(currentLang);
  const [form] = Form.useForm();
  const [savingResults, setSavingResults] = useState(false);
  const environment = useRelayEnvironment();
  const slideAnswers = useRef<SlideAnswersMapType>({}); // slide_id and answer
  const orientationResultsMap = useRef<{
    [key: string]: {
      orientationResultId: string;
      groupId?: string | null;
      durationValid: number | undefined;
    };
  }>({});
  orientations.forEach((o) => {
    o.orientation_results.forEach((r) => {
      if (r.orientation_id) {
        orientationResultsMap.current[r.orientation_id] = {
          orientationResultId: r.pk,
          groupId: r.group_id,
          durationValid: r.orientation?.duration_valid,
        };
      }

      r.quiz_results.forEach((q) => {
        slideAnswers.current[q.orientation_slide_id] = {
          answer: q.answer,
          lang: stringToLanguage(q.lang),
        };
      });
    });
  });
  const alertUser = (e: {
    preventDefault: () => void;
    defaultPrevented: boolean;
    returnValue: string;
  }) => {
    e.preventDefault();
    return (e.returnValue = `Are you sure you want to exit this Orientation? All progress will be lost and you will need to start from the beginning?`);
  };
  useEffect(() => {
    if (!workerId) throw new Error("WorkerId Not Found");
    if (!orientationId) {
      // }
      if (
        completedProjectAndUniversalOrientation ||
        (orientations.length === 0 &&
          orientationsBeingPlayedInPersonOnGCScreen.length === 0)
      ) {
        if (goToNext) {
          goToNext();
          return;
        }
      } else {
        context?.updateOrientee((u) =>
          u ? { ...u, viewerShown: true } : null,
        );
        if (orientations.length > 0) {
          const groupId =
            orientationResultsMap.current[orientations[0].pk]?.groupId ??
            uuid.v4();
          if (!orientationId && !completedProjectAndUniversalOrientation) {
            const resultsToBeInserted = orientations.filter(
              (o) => o.orientation_results.length === 0,
            );
            if (resultsToBeInserted.length !== 0) {
              upsertOrientationResults({
                variables: {
                  objects: resultsToBeInserted.map((o) => {
                    const orientResultId = uuid.v4();
                    const newValue = {
                      orientationResultId: orientResultId,
                      groupId: groupId,
                      durationValid: o.duration_valid,
                    };
                    orientationResultsMap.current[o.pk] = newValue;
                    return {
                      id: orientResultId,
                      status: "pending",
                      group_id: groupId,
                      project_id: o.type === "universal" ? null : projectId,
                      user_id: workerId,
                      orientation_id: o.pk,
                      viewed_slides: 0,
                      total_slides: o.slides.length,
                    };
                  }),
                },
              }).catch((e) => console.log(e));
            }
          }
        }
      }
    }
    window.addEventListener("beforeunload", alertUser);
    return () => window.removeEventListener("beforeunload", alertUser);
  }, []);
  const differentThanEnglish =
    AVAILABLE_LANGUAGES.includes(currentLang) && currentLang !== "en";

  const pages = useMemo(() => {
    let pagesArr: Array<OrientationPage> = [];

    let fistUnviewed = 0;
    for (const orientation of orientations) {
      const slides = orientation.slides;
      let idx = 0;
      while (idx < slides.length) {
        const slide = differentThanEnglish
          ? translateSlide(slides[idx], currentLang)
          : fixEnglish(slides[idx]);
        const question = slide.content_type === "question";
        const pageItem: OrientationPage = {
          type: question ? "questions" : "static",
          title: question ? langStrings.strings.question : slide.title,
          orientation,
          pageLang: currentLang,
          viewed: slide.viewed_by[0]
            ? dayjs().isSameOrBefore(
                dayjs(slide.viewed_by[0].created_at).add(
                  orientation.type === "universal"
                    ? orientation.duration_valid - 1
                    : orientation.duration_valid || 1,
                  "months",
                ),
              )
            : false, // || viewedSlides.includes(slide.pk),
          slides: [slide],
        };
        pagesArr.push(pageItem);
        idx++;
        // if (question) {
        //   while (
        //     idx < slides.length &&
        //     slides[idx].content_type === "question"
        //   ) {
        //     pageItem.viewed = pageItem.viewed && !!slides[idx].viewed_by.length; // || !!viewedSlides.includes(slide.pk));
        //     const question_slide = differentThanEnglish
        //       ? translateSlide(slides[idx], lang)
        //       : fixEnglish(slides[idx]);
        //     pageItem.slides.push(question_slide);
        //     idx++;
        //   }
        // }
        if (slide.content_type === "image" && slide.image_url) {
          // preload image
          const img = new Image();
          img.src = slide.image_url;
        }
        if (pageItem.viewed) fistUnviewed++;
      }
    }
    if (
      orientations.length > 0 ||
      orientationsBeingPlayedInPersonOnGCScreen.length > 0
    ) {
      pagesArr.push({
        type: "signature",
        title: langStrings.strings.signature,
        orientation: orientations[orientations.length - 1],
        viewed: false,
        slides: [],
        pageLang: currentLang,
      });
    }

    return pagesArr;
  }, [orientations, currentLang /*, viewedSlides*/]);

  const [pageIndex, setPageIndex] = useState(() => {
    let index = 0;
    while (index < pages.length && pages[index].viewed) index++;
    return index;
  });

  const page = pageIndex < pages.length ? pages[pageIndex] : null;
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const quizResults = useRef<OrientationResults>({
    results: Object.fromEntries(orientations.map((o) => [o.pk, {}])),
  });
  const saveResults = async (sigUrl: string) => {
    if (orientationId) return;
    // todo wait to signature load to complete
    let groupIds: string[] = [];
    const orientationResults: {
      id: string;
      duration: number;
      type: string;
      orientation_id: string;
      total_slides: number;
    }[] = orientations
      .map((o) => {
        groupIds.push(
          orientationResultsMap.current[o.pk]?.groupId ?? uuid.v4(),
        );
        return {
          orientation_id: o.pk,
          id:
            orientationResultsMap.current[o.pk]?.orientationResultId ?? "None",
          duration: orientationResultsMap.current[o.pk]?.durationValid ?? 6,
          total_slides: o.slides.length,
          type: o.type,
        };
      })
      .filter((o) => o.id !== "None");
    const groupId = groupIds[0] ?? uuid.v4();
    setSavingResults(true);
    try {
      if (!orientationId) {
        const allCompleted = allOrientations.every((o) => {
          if (
            o.project_orientations[0] &&
            o.project_orientations[0].required_by_all_workers
          )
            return o.slides.every((slide) => slide.viewed_by.length > 0);
          else return true;
        });
        if (!allCompleted) {
          // message.warning(
          //   "Some Required Slides are left to watch, please complete mobile Orientation"
          // );
        }

        const orientationDate = dayjs().toISOString();
        const signatureId = uuid.v4();
        await insertSignatureImage({
          variables: {
            objects: [
              {
                id: signatureId,
                url: sigUrl,
                lg_url: sigUrl,
                created_by_user_id: workerId,
              },
            ],
          },
        });
        const mainProjectPresent = projectsToInsertResult.find(
          (p) => p === projectId,
        );
        if (!mainProjectPresent) projectsToInsertResult.push(projectId);
        const workerOrientationObjects: Array<user_orientation_insert_input> =
          projectsToInsertResult.map((projectToInsertResultId) => ({
            user_id: workerId,
            project_id: projectToInsertResultId,
            orientation_provided_by_user_id: null,
            orientated_at:
              allCompleted || type === "inpersonComplete"
                ? firstUserOrientation
                  ? firstUserOrientation.node.orientated_at ?? orientationDate
                  : orientationDate
                : null,
            orientation_group_id: groupId,
            signature_id: allCompleted ? signatureId : null,
            ...(inPersonType && (hasInPerson || type === "inpersonComplete")
              ? {
                  in_person_orientated_at: firstUserOrientation
                    ? firstUserOrientation.node.in_person_orientated_at ??
                      orientationDate
                    : orientationDate,
                  in_person_signature_id: signatureId,
                  selected: true,
                }
              : {}),
            ...(type === "inpersonComplete" ||
            (type === "slides" && !hasInPerson && allCompleted)
              ? { completed_at: orientationDate }
              : {}),
          }));
        const fields: Array<user_orientation_update_column> = [
          "orientated_at",
          "signature_id",
          "orientation_group_id",
          "orientation_provided_by_user_id",
        ];
        if (inPersonType && (hasInPerson || type === "inpersonComplete"))
          fields.push(
            "in_person_orientated_at",
            "in_person_signature_id",
            "selected",
          );
        if (
          type === "inpersonComplete" ||
          (type === "slides" && !hasInPerson && allCompleted)
        )
          fields.push("completed_at");
        const toInsert =
          type === "inpersonComplete"
            ? [
                ...orientationsBeingPlayedInPersonOnGCScreen,
                ...toGiveCreditOn3rdQRButNotPlay,
              ]
            : orientationsBeingPlayedInPersonOnGCScreen;

        const orientationResultsObjects: Array<orientation_result_insert_input> =
          orientationResults.map((o) => ({
            id: o.id,
            project_id: o.type === "universal" ? null : projectId,
            user_id: workerId,
            orientation_id: o.orientation_id,
            status: "completed",
            group_id: groupId,
            signature_url: sigUrl,
            total_slides: o.total_slides,
            viewed_slides: o.total_slides,
            // result_inserted_by_uid: auth.currentUser?.uid,
            expired_at: dayjs().add(o.duration, "months").toISOString(),
            completed_at: dayjs().toISOString(),
          }));
        if (inPersonType && toInsert.length) {
          toInsert.forEach((o) =>
            orientationResultsObjects.push({
              id: uuid.v4(),
              project_id: o.type === "universal" ? null : projectId,
              user_id: workerId,
              signature_url: sigUrl,
              orientation_id: o.pk,
              status: "completed",
              group_id: groupId,
              result_inserted_by_uid: auth.currentUser?.uid,
              expired_at: dayjs().add(o.duration_valid, "months").toISOString(),
              completed_at: dayjs().toISOString(),
            }),
          );
        }

        await insertResultMutation({
          variables: {
            userUniversalOrientationObjects: giveUOCredit
              ? [
                  {
                    user_id: workerId,
                    group_id: groupId,
                    universal_orientation_signature_id: signatureId,
                    universal_orientated_at: orientationDate,
                  },
                ]
              : [],
            viewedSlidesObjects:
              orientationsBeingPlayedInPersonOnGCScreen.flatMap((orien) =>
                orien.slides.map((slide) => ({
                  slide_id: slide.pk,
                  user_id: workerId,
                  created_at: orientationDate,
                })),
              ),
            orientationResultsObjects,
            workerOrientationObjects,
            workerOrientationUpdateColumns: fields,
          },
        });

        if (
          inPersonType &&
          hasInPerson &&
          projectData.assign_hard_hat &&
          projectData.automatically_assign_hard_hat
        ) {
          let hardHat = project_users.find(
            (pw) => pw.hard_hat_number,
          )?.hard_hat_number;

          //only one of the lists will exist so either hardHat will be for worker or for oac user
          if (!hardHat) {
            const nextHH = projectData?.next_hard_hat_number || 1;
            hardHat =
              "" + (await getNextHardHatNumber(environment, projectId, nextHH));
          }
          if (project_users.length > 0 && hardHat) {
            await updateHH({
              variables: {
                projectId,
                hardHat,
                userId: workerId,
              },
            }).catch(console.error);
          }
        }
        await emailOrientationConfirmationQrCode({
          variables: {
            input: {
              orientationEmailType: OrientationEmailType.ProjectOrientation,
              userId: workerId,
              projectId: projectId,
            },
          },
        }).catch((e) => {
          console.error(e);
          notification.error({
            message: "Email Confirmation Error",
            description: e instanceof Error ? e.message : JSON.stringify(e),
            duration: 5,
          });
        });
        if (userData.role === "employee") {
          emailGCUsersToVerifyNewGCUser({
            variables: { input: { projectId, userId: workerId } },
          }).catch((e) => console.error(e));
        }

        updateCorporateOrientationResults({
          variables: {
            input: {
              project_id: projectId,
              user_id: workerId,
              signature_id: signatureId,
            },
          },
        }).catch(console.error);
      }
      // return;
    } catch (err) {
      console.error(err);
      notification.error({
        description: err instanceof Error ? err.message : JSON.stringify(err),
        message: "Could not add results for orientation",
      });
    }
    setSavingResults(false);
    if (goToNext) goToNext();
  };
  const onSubmitQuiz = async () => {
    if (!page) return;
    const values = await form.validateFields().catch(() => null);
    if (!values) return;
    const results = quizResults.current.results[page.orientation.pk];
    for (const name of Object.keys(values)) {
      if (!name.startsWith("field_")) continue;
      const field_id = name.substring(6);
      results[field_id] = values[name];
      // console.log(page.slides, field_id, values[name]);
      slideAnswers.current[field_id] = {
        answer: values[name],
        lang: page.pageLang,
      };
      if (!orientationId) {
        upsertQuizResult({
          variables: {
            object: {
              answer: values[name],
              orientation_slide_id: field_id,
              orientation_result_id:
                orientationResultsMap.current[page.orientation.pk]
                  ?.orientationResultId,
              lang: page.pageLang,
            },
          },
        })
          .then((d) => console.log("Added quiz result"))
          .catch(console.error);
      }
    }
    setQuizSubmitted(true);

    if (page.slides.some((s) => s.check_correct_answer)) {
      await form
        .validateFields()
        .then((v) => console.log("valid"))
        .catch(() => null);
    } else moveToNextPage();
  };
  // console.log(page, pageIndex);

  const moveToNextPage = async () => {
    if (!page) return;
    const viewedSlides = page.slides.map((s) => ({
      id: uuid.v4(),
      user_id: workerId,
      slide_id: s.pk,
      created_at: dayjs().format(),
      orientation_result_id:
        orientationResultsMap.current[s.orientation_id]?.orientationResultId,
    }));

    if (!orientationId) {
      if (
        orientationResultsMap.current[page.orientation.pk]?.orientationResultId
      ) {
        insertViewedSlide({
          variables: {
            objects: viewedSlides,
          },
          optimisticResponse: {
            insert_orientation_viewed_slide: {
              returning: viewedSlides,
            },
          },
          updater: viewedSlideInsertUpdater,
          optimisticUpdater: viewedSlideInsertUpdater,
        }).catch((e) => console.error(e));
        const allSlidesViewed =
          page.orientation.slides.filter((s) => s.viewed_by.length).length ===
          page.orientation.slides.length;
        const durationValid =
          orientationResultsMap.current[page.orientation.pk]?.durationValid;
        updateOrientationResult({
          variables: {
            orientationResultWhere: {
              id: {
                _eq: orientationResultsMap.current[page.orientation.pk]
                  .orientationResultId,
              },
            },
            orientationResultSet: {
              viewed_slides: page.orientation.slides.filter(
                (s) => s.viewed_by.length,
              ).length,
              ...(allSlidesViewed
                ? {
                    // status: "completed",
                    completed_at: dayjs().format(),
                    expired_at:
                      typeof durationValid === "number"
                        ? dayjs().add(durationValid, "M").format()
                        : undefined,
                  }
                : {}),
            },
          },
        }).catch((e) => console.error(e));
      }
    } else {
      commitLocalUpdate(environment, (store) => {
        viewedSlides.forEach((viewedSlide) => {
          const slide = store.get(
            GetFullID("orientation_slide", viewedSlide.slide_id),
          );
          if (slide) {
            const item = store.create(
              viewedSlide.id,
              "orientation_viewed_slide",
            );
            const user_id = viewedSlide.user_id;
            item.setValue("id", viewedSlide.id);
            slide.setLinkedRecords([item], "viewed_by", {
              where: { user_id: { _eq: user_id } },
            });
          }
        });
      });
    }
    /*    setViewedSlides(prev => [...prev, ...page.slides
      .map(slide => slide.pk)
      .filter(pk => !prev.includes(pk))]);*/
  };
  if (savingResults) {
    return (
      <div className="flex flex-col justify-center items-center">
        Submitting Signature <Spin />
      </div>
    );
  }
  if (
    !page ||
    (orientations.length === 0 &&
      orientationsBeingPlayedInPersonOnGCScreen.length === 0)
  ) {
    const text =
      orientations.length === 0
        ? langStrings.strings.orientationDoesNotExist
        : langStrings.strings.orientationIsEmptyPleaseAddSlides;
    return (
      <div className="flex items-center justify-center">
        <Button
          label={langStrings.strings.continue}
          onClick={() => goToNext && goToNext()}
        />
      </div>
    );
  }
  if (!orientationId && completedProjectAndUniversalOrientation) {
    return (
      <div className="w-full h-fulll border-1 border-grey border-solid flex flex-col items-center justify-center">
        <h1 className="text-center mt-2 text-1.25">
          {langStrings.strings.orientationCompletionThankYou}
        </h1>
        <Button
          label={langStrings.strings.continue}
          onClick={() => goToNext && goToNext()}
        />
      </div>
    );
  }

  return (
    <SiteOrientationSlidesViewerUI
      {...{
        pages,
        lang: currentLang,
        quizSubmitted,
        setQuizSubmitted,
        form,
        inPersonType,
        type,
        userId: workerId,
        projectId,
        slideAnswers,
        page,
        pageIndex,
        setPageIndex,
        orientations,
        moveToNextPage,
        saveResults,
        projectName: projectData.name,
        userName: userData.name,
        onSubmitQuiz,
        isTestLink,
      }}
    />
  );
};

export default withCustomSuspense(GCSiteOrientationSlidesViewer);
