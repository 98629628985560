import { DatePicker, Space, TableProps } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import LoadingContent from "src/common/components/general/LoadingContent";

import StyledContent from "src/common/components/layouts/StyledContent";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import useIsFirstRender from "src/common/hooks/useIsFirstRender";
import {
  useGetGcBillableWorkersQuery,
  GetProjectLocationsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";

const ProjBillableWorkers: React.FC<{
  projectTimezone: string;
  projectId: string;
  loading: boolean;
  projectPrices: {
    paymentModel: string;
    gc: { first: number; others: number };
    sub: { first: number; others: number };
  };

  locations: GetProjectLocationsQuery["project_location"];
  subslocationsPaymentModelMap: Map<string, Map<string, string>>;
}> = ({
  loading,
  projectTimezone,
  projectId,
  locations,
  projectPrices,
  subslocationsPaymentModelMap,
}) => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const monthStart = dayjs.tz(startDate, projectTimezone).startOf("month");
  const monthEnd = monthStart.add(1, "month");
  console.log({
    startTime: monthStart.format(),
    endTime: monthEnd.format(),
  });
  const {
    data,
    error,
    loading: workersLoading,
    refetch,
  } = useGetGcBillableWorkersQuery({
    variables: {
      projectId,
      startTime: monthStart.format(),
      endTime: monthEnd.format(),
    },
  });
  const firstRender = useIsFirstRender();
  if (error) throw error;
  useEffect(() => {
    if (!firstRender && startDate) {
      const monthStart = dayjs.tz(startDate, projectTimezone).startOf("month");
      const monthEnd = monthStart.add(1, "month");

      console.log({
        projectId,
        startTime: monthStart.format(),
        endTime: monthEnd.format(),
      });
      refetch({
        projectId,
        startTime: monthStart.format(),
        endTime: monthEnd.format(),
      });
    }
  }, [startDate]);
  const dataSource = (data?.project_subcontractor || [])
    .map((s) => ({
      subId: s.subcontractor.id,
      subName: s.subcontractor.name,
      billableWorkers: s.project_workers.map((pw) => {
        let reportsWithoutLocation = 0;
        const locationsCounts = new Map<string, number>(); // key location Id, number no. of reports submitted by user for that location

        if (locations.length < 2) {
          //DO NOTHING no count increment or anything
        } else {
          pw.submitted_reports.forEach((r) => {
            if (!r.report_project_locations.length) reportsWithoutLocation += 1;
            return r.report_project_locations.forEach((rpl) =>
              locationsCounts.set(
                rpl.project_location_id,
                (locationsCounts.get(rpl.project_location_id) || 0) + 1,
              ),
            );
          });
        }
        // if (!countToBill) countToBill += 1;

        return {
          ...pw,
          locationsCounts,
          reportsWithoutLocation,
        };
      }),
    }))
    .filter((ps) => ps.billableWorkers.length);

  const countBill = (row: (typeof dataSource)[number]) => {
    const locationBilledTo = subslocationsPaymentModelMap.get(row.subId);
    let subBill = 0,
      gcBill = 0;
    if (locations.length < 2) {
      return { subBill, gcBill };
    }
    let subBillingLocationPTPs = 0,
      gcBillingLocationPTPs = 0;
    const totalWorkers = row.billableWorkers.length;
    locations.forEach((l) => {
      // there should be atleast 2 locations present here (it's checked where)
      const billedTo = locationBilledTo?.get(l.id) || l.default_payment_model;
      let workersToBillOnThisLocation = 0;
      row.billableWorkers.forEach((pw) => {
        const count = pw.locationsCounts.get(l.id) || 0;
        if (count >= 3 && l.billing_enabled) {
          workersToBillOnThisLocation += 1;
        } else {
          billedTo === "sub"
            ? (subBillingLocationPTPs += count)
            : (gcBillingLocationPTPs += count);
        }
      });

      if (billedTo === "sub") {
        subBill += workersToBillOnThisLocation
          ? (l.worker_price_first || projectPrices.sub.first) +
            (workersToBillOnThisLocation - 1) *
              (l.worker_price_others || projectPrices.sub.others)
          : 0;
      } else {
        gcBill += workersToBillOnThisLocation
          ? (l.worker_price_first || projectPrices.gc.first) +
            (workersToBillOnThisLocation - 1) *
              (l.worker_price_others || projectPrices.gc.others)
          : 0;
      }
    });
    if (gcBill === 0 && subBill === 0 && totalWorkers) {
      const gcPriceTotal =
        projectPrices.gc.first + (totalWorkers - 1) * projectPrices.gc.others;
      const subPriceTotal =
        projectPrices.sub.first + (totalWorkers - 1) * projectPrices.sub.others;
      if (gcBillingLocationPTPs > subBillingLocationPTPs) {
        gcBill += gcPriceTotal;
      } else if (gcBillingLocationPTPs > subBillingLocationPTPs) {
        subBill += subPriceTotal;
      } else {
        projectPrices.paymentModel === "sub"
          ? (subBill += subPriceTotal)
          : (gcBill += gcPriceTotal);
      }
    }
    return { subBill, gcBill };
  };

  const getLocationColumns = (
    ps: (typeof dataSource)[number],
  ): TableProps<
    (typeof dataSource)[number]["billableWorkers"][number]
  >["columns"] => {
    const locationsBilledTo = subslocationsPaymentModelMap.get(ps.subId);

    return locations.map((location) => ({
      title:
        "PTP " +
        location.name.en +
        (location.billing_enabled ? "" : " (Billing Disabled)") +
        ((
          locationsBilledTo && locationsBilledTo.get(location.id)
            ? locationsBilledTo.get(location.id) === "sub"
            : location.default_payment_model === "sub"
        )
          ? " (bill to Sub)"
          : "(bill to GC)"),
      dataIndex: ["id"],
      key: location.id,
      width: "12%",
      render: (_, row) => row.locationsCounts.get(location.id) || "",
    }));
  };
  if (workersLoading) return <LoadingContent />;
  //   console.log(dataSource, data.project_subcontractor);
  return (
    <StyledContent padding backgroundColor="white">
      <DatePicker.MonthPicker
        value={startDate}
        onChange={(date) => {
          if (date) {
            setStartDate(date);
          }
        }}
        type="month"
        format="MMM"
      />
      <BaseTable
        loading={loading}
        title={() => (
          <Space direction="vertical">
            <h3>Project Billable Sub Workers</h3>
          </Space>
        )}
        rowKey={(ps) => ps.subId}
        dataSource={dataSource}
        // summary={() => (
        //   <tr>
        //     <th>Total</th>
        //     <td colSpan={2}>{projSubs.length}</td>
        //   </tr>
        // )}
        expandable={{
          expandIcon: () => "",
          rowExpandable: () => true,
          expandedRowKeys: dataSource.map((ps) => ps.subId),
          expandedRowRender: (ps) => (
            <BaseTable
              rowKey={(r) => r.user!.id}
              dataSource={ps.billableWorkers}
              columns={[
                { title: "Name", dataIndex: ["user", "name"], key: "name" },
                ...(getLocationColumns(ps) || []),
                {
                  title: "submitted TBT",
                  dataIndex: [
                    "toolbox_talks_submitted_aggregate",
                    "aggregate",
                    "count",
                  ],
                  key: "submittedTBT",
                  render: (v) => (v ? "Yes" : "No"),
                },
                {
                  title: "submitted DR",
                  dataIndex: ["project_users_aggregate", "aggregate", "count"],
                  key: "submittedDR",
                  render: (v) => (v ? "Yes" : "No"),
                },
                {
                  title: "submitted PTP\n( without location )",
                  dataIndex: ["submitted_reports"],
                  key: "submittedPTP",
                  render: (_, row) =>
                    row.reportsWithoutLocation ? "Yes" : "No",
                },
                // {
                //   title: "Counts",
                //   dataIndex: ["counts"],
                //   key: "total_count",
                //   render: (_, row) =>
                //     `To GC: ${JSON.stringify(row.gcBillData)}\n` +
                //     `To SUB: ${JSON.stringify(row.subBillData)}`,
                // },
              ]}
            />
          ),
        }}
        columns={[
          {
            title: "Subcontractor Name",
            key: "name",
            dataIndex: ["subName"],
          },
          {
            title: "Bill",
            dataIndex: ["id"],
            key: "bill",
            render: (_, row) => {
              if (locations.length < 2) {
                const prices =
                  projectPrices.paymentModel === "sub"
                    ? projectPrices.sub
                    : projectPrices.gc;
                const first = prices.first / 100;
                const others = prices.others / 100;
                const totalWorkers = row.billableWorkers.length;
                return (
                  !!totalWorkers && (
                    <div>
                      {projectPrices.paymentModel === "sub" ? "SUB" : "GC"} Bill
                      Users Count: {totalWorkers}
                      {"-->"} {first}
                      {totalWorkers - 1
                        ? ` + ${others}* ${totalWorkers - 1} = ${
                            first + (others * totalWorkers - 1)
                          }`
                        : ""}
                    </div>
                  )
                );
              }
              const { gcBill, subBill } = countBill(row);

              return (
                <div>
                  {gcBill ? `Gc Bill: ${gcBill / 100}` : ""}
                  <div>{subBill ? `Sub Bill: ${subBill / 100}` : ""}</div>
                </div>
              );
            },
          },
        ]}
      />
    </StyledContent>
  );
};
export default ProjBillableWorkers;
