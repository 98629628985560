import { IconPlus } from "@tabler/icons";
import React, { ReactNode } from "react";
import Button from "src/common/components/general/button/Button";

interface WorkerProfileInfoBlockProps {
  title: string;
  addButton?: {
    onClick: () => void;
  };
  children?: ReactNode;
}

const WorkerProfileInfoBlock: React.FunctionComponent<
  WorkerProfileInfoBlockProps
> = (props) => {
  console.groupEnd();
  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row items-center gap-1">
        {props.addButton && (
          <Button
            icon={IconPlus}
            small
            secondary
            onClick={props.addButton.onClick}
          />
        )}
        <span className="font-accent">{props.title}</span>
      </div>
      {!!props.children ? (
        <div className="flex flex-col gap-1">{props.children}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default WorkerProfileInfoBlock;
