import React from "react";
import {
  BackwardOutlined,
  ForwardOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { useCurrentLangStrings } from "../../../../utility-features/i18n/context/languageHooks";

// TODO unify audio controls UI with video controls UI

interface AudioPlayerControlsProps {
  onRewindClick?: () => void;
  onSkipClick?: () => void;
  onPlayPauseClick: () => void;
  playing: boolean;
  skipTimeSec?: number;
  startButtonText?: string;
}

// TODO show a loading state
// TODO preve users from changing states when the player is loading

const AudioPlayerControls: React.FC<AudioPlayerControlsProps> = (props) => {
  const langStrings = useCurrentLangStrings();

  return (
    <div className={`flex flex-row items-center gap-0.5`}>
      {!!props.onRewindClick && (
        <Button block onClick={props.onRewindClick} icon={<BackwardOutlined />}>
          {!!props.skipTimeSec &&
            langStrings.strings.rewindSeconds(props.skipTimeSec)}
        </Button>
      )}
      <Button
        block
        onClick={props.onPlayPauseClick}
        icon={props.playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
      >
        {props.playing
          ? langStrings.strings.pause
          : props.startButtonText ?? langStrings.strings.play}
      </Button>
      {!!props.onSkipClick && (
        <Button block onClick={props.onSkipClick} icon={<ForwardOutlined />}>
          {!!props.skipTimeSec &&
            langStrings.strings.skipSeconds(props.skipTimeSec)}
        </Button>
      )}
    </div>
  );
};

export default AudioPlayerControls;
