import { Spin } from "antd";
import { browserLocalPersistence } from "firebase/auth";
import React, { FC, useContext, useEffect, useState } from "react";
import { auth } from "src/common/functions/firebase";
import { Task_Select_Column_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns } from "src/common/types/generated/apollo/graphQLTypes";
import SiteOrientationUniversalBirthDate from "src/domain-features/siteorientation/entryRoutes/universal-orientation/components/steps/birthDate/SiteOrientationUniversalBirthDate";

type AuthData =
  | {
      uid: null;
    }
  | {
      uid: string;
      role: string;
    };

const AuthContext = React.createContext<AuthData | null>(null);

interface EmptyProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<EmptyProps> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthData>();
  useEffect(() => {
    console.log("subscribe on auth", auth.currentUser);
    const unsubAuth = auth.onAuthStateChanged((authUser) => {
      console.log("auth.onAuthStateChanged", !!authUser);
      if (!authUser) setAuthData({ uid: null });
      else {
        authUser.getIdTokenResult().then((res) => {
          console.log('getIdTokenResult', typeof res, (typeof(res) === "object")? typeof res.claims : "")
          if (typeof res.claims.role !== "string") {
            throw new Error("token.claims.role is not a string");
          }
          setAuthData({ uid: authUser.uid, role: res.claims.role });
        }).catch(error => {
          console.log('getIdTokenResult failed', error);
          setAuthData(() => {
            console.log("throw auth error from state update")
            throw error;
          })
        });
      }
    });
    return () => {
      console.log('unsubscribe auth')
      unsubAuth();
    };
  }, []);

  if (!authData) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full gap-1">
        <h1>Authorizing</h1>
        <Spin />
      </div>
    );
  } else
    return (
      <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
  const user = useContext(AuthContext);
  if (!user) {
    throw new Error("useAuth requires AuthProvider");
  }
  return user;
};

export const withAuthProvider =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <AuthProvider>
        <Component {...props} />
      </AuthProvider>
    );
