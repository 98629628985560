export const videoUrlDemoExamples: {
  title: React.ReactNode;
  url: string;
}[] = [
  { title: "Youtube 1", url: "https://www.youtube.com/watch?v=u31qwQUeGuM" },
  {
    title: "Youtube 2",
    url: "https://www.youtube.com/watch?v=pygGug3TCaI",
  },
  {
    title: "Youtube 3",
    url: "https://www.youtube.com/watch?v=j4s8D9hjFQQ",
  },
  {
    title: "Vimeo 1",
    url: "https://vimeo.com/76979871",
  },
  {
    title: "Lee Kennedy Orientation (Vimeo)",
    url: "https://vimeo.com/1017893062?share=copy#t=0",
  },
  {
    title: "CARE (Vimeo)",
    url: "https://vimeo.com/1015329125?share=copy",
  },
];
