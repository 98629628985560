/**
 * @generated SignedSource<<47c6ca1a750643866d41ef68b41aa0c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermitTable_permits$data = {
  readonly permit_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly confined_space_permit: {
          readonly confined_space_permit_readings: ReadonlyArray<{
            readonly co_ppm: string | null | undefined;
            readonly created_at: string;
            readonly h2s_ppm: string | null | undefined;
            readonly id: string;
            readonly lower_explosive_limit: string | null | undefined;
            readonly o2_percent: string | null | undefined;
            readonly type: string;
          }>;
          readonly type: string | null | undefined;
        } | null | undefined;
        readonly created_at: string;
        readonly current_status: string;
        readonly ladder_permit: {
          readonly id: string;
          readonly is_access_ladder: boolean | null | undefined;
          readonly ladder_identification: string | null | undefined;
          readonly last_inspection_at: string;
          readonly returned_at: string | null | undefined;
          readonly returned_marked_by_uid: string | null | undefined;
          readonly returned_marked_by_user: {
            readonly name: string;
          } | null | undefined;
        } | null | undefined;
        readonly permit_histories: ReadonlyArray<{
          readonly created_at: string;
        }>;
        readonly permit_project_workers_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly pk: string;
        readonly project?: {
          readonly name: string;
        };
        readonly subcontractor?: {
          readonly name: string;
        };
        readonly type: string;
        readonly updated_at: string;
        readonly work_description: {
          readonly en: string;
          readonly id: string;
        };
        readonly work_location: {
          readonly en: string;
          readonly id: string;
        };
      };
    }>;
  };
  readonly " $fragmentType": "PermitTable_permits";
};
export type PermitTable_permits$key = {
  readonly " $data"?: PermitTable_permits$data;
  readonly " $fragmentSpreads": FragmentRefs<"PermitTable_permits">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "permit_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "en",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v6 = {
  "kind": "Literal",
  "name": "order_by",
  "value": {
    "created_at": "desc"
  }
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeProject"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeSub"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PermitTableRefetchableQuery.graphql')
    }
  },
  "name": "PermitTable_permits",
  "selections": [
    {
      "alias": "permit_connection",
      "args": null,
      "concreteType": "permitConnection",
      "kind": "LinkedField",
      "name": "__PermitTable_permit_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "permitEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "permit",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": "pk",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id_pk",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updated_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "current_status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "text_translation",
                  "kind": "LinkedField",
                  "name": "work_location",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "text_translation",
                  "kind": "LinkedField",
                  "name": "work_description",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "condition": "includeProject",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "project",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "includeSub",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "subcontractor",
                      "kind": "LinkedField",
                      "name": "subcontractor",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "limit",
                      "value": 1
                    },
                    (v6/*: any*/)
                  ],
                  "concreteType": "permit_history",
                  "kind": "LinkedField",
                  "name": "permit_histories",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": "permit_histories(limit:1,order_by:{\"created_at\":\"desc\"})"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "confined_space_permit",
                  "kind": "LinkedField",
                  "name": "confined_space_permit",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": [
                        (v6/*: any*/)
                      ],
                      "concreteType": "confined_space_permit_reading",
                      "kind": "LinkedField",
                      "name": "confined_space_permit_readings",
                      "plural": true,
                      "selections": [
                        (v3/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "o2_percent",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "co_ppm",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "h2s_ppm",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lower_explosive_limit",
                          "storageKey": null
                        }
                      ],
                      "storageKey": "confined_space_permit_readings(order_by:{\"created_at\":\"desc\"})"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ladder_permit",
                  "kind": "LinkedField",
                  "name": "ladder_permit",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "is_access_ladder",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ladder_identification",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "last_inspection_at",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "returned_at",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "returned_marked_by_uid",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "user",
                      "kind": "LinkedField",
                      "name": "returned_marked_by_user",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "permit_project_worker_aggregate",
                  "kind": "LinkedField",
                  "name": "permit_project_workers_aggregate",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "permit_project_worker_aggregate_fields",
                      "kind": "LinkedField",
                      "name": "aggregate",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "67230a052cd21a52e218cede3b5325fe";

export default node;
