/**
 * @generated SignedSource<<a9d12b4b51cecad33f721c3946478eeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TasksTableTotalRefetchableQuery$variables = {
  where: task_bool_exp;
};
export type TasksTableTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TasksTable_total">;
};
export type TasksTableTotalRefetchableQuery = {
  response: TasksTableTotalRefetchableQuery$data;
  variables: TasksTableTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TasksTableTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "TasksTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TasksTableTotalRefetchableQuery",
    "selections": [
      {
        "alias": "alltaskConnection",
        "args": (v1/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "912d4da17c45bc85bd4d3abb188dd4f6",
    "id": null,
    "metadata": {},
    "name": "TasksTableTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query TasksTableTotalRefetchableQuery(\n  $where: task_bool_exp!\n) {\n  ...TasksTable_total_3FC4Qo\n}\n\nfragment TasksTable_total_3FC4Qo on query_root {\n  alltaskConnection: task_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "11e83b940950a0f36bc4cc72f13dcddf";

export default node;
