import { DevelopmentPlaygroundNavigationItem } from "./developmentPlaygroundTypes";
import NestedRoute from "src/common/types/manual/NestedRoute";
import { Navigate } from "react-router-dom";

const getRouteChildrenByNavigationItems: (
  navigationItems: DevelopmentPlaygroundNavigationItem[],
) => NestedRoute["children"] = (navigationItems) => {
  const routeChildren: NestedRoute["children"] = [];

  navigationItems.forEach((item) => {
    routeChildren.push({
      path: item.pathName,
      ...item.routeElement,
    });
  });

  if (navigationItems.length) {
    routeChildren.push({
      path: "",
      element: <Navigate to={navigationItems[0].pathName} replace />,
    });
  }
  return routeChildren;
};

export default getRouteChildrenByNavigationItems;
