import { Button, Card, Empty, Typography } from "antd";
import VideoPlayer from "../../../../../../common/components/media-players/video-player/VideoPlayer";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useState } from "react";

interface VideoPlayerAutoPlayDemoPlayerCardProps {
  videoUrlList: string[];
  autoPlayOn?: boolean;
  sameVideoPlayer?: boolean;
}

const VideoPlayerAutoPlayDemoPlayerCard: React.FC<
  VideoPlayerAutoPlayDemoPlayerCardProps
> = (props) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
  const currentVideoUrl = props.videoUrlList[currentVideoIndex];
  return (
    <Card title={"Video Player"}>
      {props.videoUrlList.length === 0 ? (
        <Empty />
      ) : (
        <div className={`flex flex-col gap-1`}>
          <div>
            <VideoPlayer
              autoPlay={props.autoPlayOn}
              videoUrl={currentVideoUrl}
              key={props.sameVideoPlayer ? "" : currentVideoIndex}
            />
          </div>
          <div className={`w-full flex flex-row items-center gap-0.5`}>
            <Button
              onClick={() => {
                setCurrentVideoIndex((prevState) => prevState - 1);
              }}
              disabled={currentVideoIndex <= 0}
              className={`flex-1`}
              icon={<LeftOutlined />}
            >
              Previous
            </Button>
            <Typography.Text code>
              {`${currentVideoIndex + 1} / ${props.videoUrlList.length}`}
            </Typography.Text>
            <Button
              disabled={currentVideoIndex >= props.videoUrlList.length - 1}
              iconPosition={"end"}
              className={`flex-1`}
              icon={<RightOutlined />}
              onClick={() => {
                setCurrentVideoIndex((prevState) => prevState + 1);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </Card>
  );
};

export default VideoPlayerAutoPlayDemoPlayerCard;
