import React from "react";
import { Button, Popconfirm } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import NavigationProgressBar, {
  NavigationProgressBarProps,
} from "src/domain-features/siteorientation/entryRoutes/inPerson/routes/worker/components/NavigationProgressBar";
import LanguagePicker, {
  LanguagePickerProps,
} from "src/domain-features/siteorientation/entryRoutes/inPerson/routes/worker/components/LanguagePicker";
import CustomSuspense from "../../general/CustomSuspense";
import FallbackSkeletonCentered from "../../FallbackSkeletonCentered";
import Carousel from "../../Carousel";

export type SiteFeatureLayoutStepProps =
  NavigationProgressBarProps["steps"][0] & {
    title: string;
    body: React.ReactNode;
    id: string;
  };

export interface SiteFeatureLayoutBodyProps {
  steps: SiteFeatureLayoutStepProps[];
  currentStepId?: string;
  onClose?: () => void;
  languagePicker?: LanguagePickerProps;
  languagePickerHidden?: boolean;
  loading?: boolean;
}

const SiteFeatureLayoutBody: React.FC<SiteFeatureLayoutBodyProps> = (props) => {
  const currentStepIndex = props.steps.findIndex(
    (step) => step.id === props.currentStepId,
  );

  let currentStep: null | SiteFeatureLayoutStepProps = null;
  if (!!props.currentStepId?.length) {
    currentStep = props.steps[currentStepIndex];
  }

  return (
    <div className={`flex flex-col gap-0.5 w-full h-full`}>
      <div className={`flex flex-col gap-1 w-full flex-1`}>
        <div className={`flex w-full flex-row items-center gap-1`}>
          {props.onClose && (
            <div>
              <Popconfirm
                title={`Leave to manager view?`}
                onConfirm={props.onClose}
              >
                <Button
                  shape="circle"
                  type={`text`}
                  className={`flex items-center justify-center`}
                  size={`large`}
                  icon={<CloseOutlined />}
                  // onClick={}
                />
              </Popconfirm>
            </div>
          )}
          <div
            className={`flex-1 h-full flex flex-row justify-center relative`}
          >
            {currentStep && (
              <NavigationProgressBar
                steps={props.steps.map((step) => ({
                  title: step.title,
                  disabled: step.disabled,
                  id: step.id,
                }))}
                currentStepId={currentStep.id}
              />
            )}
          </div>
          {props.languagePicker && !props.languagePickerHidden && (
            <LanguagePicker {...props.languagePicker} />
          )}
        </div>
        <div className={`flex-1 relative -mx-2 `}>
          {currentStep && (
            <Carousel
              carouselCurrentStep={{
                body: (
                  <div
                    key={currentStep.id}
                    className={`px-2 w-full h-full overflow-y-auto`}
                  >
                    {props.loading ? (
                      <FallbackSkeletonCentered />
                    ) : (
                      <CustomSuspense fallback={<FallbackSkeletonCentered />}>
                        {currentStep.body}
                      </CustomSuspense>
                    )}
                  </div>
                ),
                index: currentStepIndex,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SiteFeatureLayoutBody;
