import { MenuOutlined } from "@ant-design/icons";
import { Button, Checkbox, Popconfirm, Space } from "antd";
import React, { FC, useState } from "react";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import TableTitle from "src/common/components/tables/basic/TableTitle";
import DraggableTableWrapper from "src/common/components/tables/draggable/DraggableTableWrapper";
import noPropagation from "src/common/functions/noPropagation";
import {
  GetGcPermitChecklistItemsQuery,
  Permit_Checklist_Item,
} from "src/common/types/generated/apollo/graphQLTypes";

type ChecklistItemType =
  GetGcPermitChecklistItemsQuery["general_contractor"][number]["permit_checklist_items"][number];
interface PermitChecklistTableProps {
  checklistItems: ChecklistItemType[];
  onReorderItem: (args: {
    checklistItem: ChecklistItemType;
    newSortIndex: number;
  }) => any;
  editing: boolean;
  onUpdateItem: (args: {
    key: string;
    val: boolean;
    checklistItem: ChecklistItemType;
  }) => any;
  onDeleteItem: (args: { itemId: string }) => any;
  loading: boolean;
  onAddItemPress: () => any;
  title?: string;
}

// TODO clean up views w less copy pasta

const PermitChecklistTable: FC<PermitChecklistTableProps> = ({
  checklistItems,
  onReorderItem,
  editing,
  onUpdateItem,
  onDeleteItem,
  onAddItemPress,
  loading,
  title,
}) => {
  const optionsIndex = [
    {
      name: "Yes",
      index: "yes_enabled" as const,
    },
    {
      name: "No",
      index: "no_enabled" as const,
    },
    {
      name: "N/A",
      index: "na_enabled" as const,
    },
    {
      name: "Text input",
      index: "text_enabled" as const,
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <DraggableTableWrapper
        onMoveRow={async (dragIndex: number, hoverIndex: number) => {
          if (dragIndex === hoverIndex) {
            return;
          }

          const dragStep = checklistItems[dragIndex];
          const hoverStep = checklistItems[hoverIndex];

          let newSortIndex = 0;
          if (hoverIndex === 0) {
            newSortIndex = hoverStep.sort_index / 2;
          } else if (hoverIndex === checklistItems.length - 1) {
            newSortIndex = dragStep.sort_index + hoverStep?.sort_index;
          } else {
            const nextHoverStep =
              dragIndex > hoverIndex
                ? checklistItems[hoverIndex - 1]
                : checklistItems[hoverIndex + 1];
            newSortIndex =
              (hoverStep.sort_index + nextHoverStep.sort_index) / 2;
          }

          onReorderItem({
            checklistItem: dragStep,
            newSortIndex,
          });
        }}
      >
        {{
          table: (() => {
            const columns: Array<any> = [];

            if (editing) {
              columns.push({
                title: "Reorder",
                key: "reorder",
                width: 80,
                render: () => (
                  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
                ),
              });
            }

            columns.push(
              ...[
                {
                  title: "Description",
                  key: "description",
                  dataIndex: ["description", "clientText"],
                },
                {
                  title: "Item Options",
                  key: "options",
                  width: 500,
                  render: (item: Permit_Checklist_Item) =>
                    editing ? (
                      <Space>
                        {optionsIndex.map((o) => (
                          <Checkbox
                            checked={item[o.index]}
                            key={o.name}
                            onChange={(e) => {
                              onUpdateItem({
                                key: o.index,
                                val: !!e.target.checked,
                                checklistItem: item,
                              });
                            }}
                          >
                            {o.name}
                          </Checkbox>
                        ))}
                        {item.text_enabled && (
                          <Checkbox
                            checked={!!item.text_required}
                            onChange={(e) => {
                              onUpdateItem({
                                key: "text_required",
                                val: !!e.target.checked,
                                checklistItem: item,
                              });
                            }}
                          >
                            Text required
                          </Checkbox>
                        )}
                      </Space>
                    ) : (
                      <Space size="large">
                        {optionsIndex
                          .filter(
                            (o) =>
                              Object.keys(item).includes(o.index) &&
                              item[o.index] === true,
                          )
                          .map((o) => (
                            <span key={o.name}>{o.name}</span>
                          ))}
                        {item.text_required && <span>Text required</span>}
                      </Space>
                    ),
                },
              ],
            );

            if (editing) {
              columns.push({
                title: "Actions",
                key: "actions",
                width: 100,
                render: (item: Permit_Checklist_Item) => (
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={noPropagation(() => {
                      onDeleteItem({
                        itemId: item.id,
                      });
                    })}
                    onCancel={noPropagation()}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Button danger type="link" onClick={noPropagation()}>
                      Remove
                    </Button>
                  </Popconfirm>
                ),
              });
            }

            return (
              <BaseTable
                title={() => (
                  <TableTitle
                    title={title}
                    titleComponent={
                      <>
                        {checklistItems.length === 0 && (
                          <h4>Add checklist items to setup this permit</h4>
                        )}
                        {editing ? (
                          <Button
                            type="primary"
                            loading={loading}
                            onClick={() => {
                              onAddItemPress();
                            }}
                          >
                            Add Item
                          </Button>
                        ) : undefined}
                      </>
                    }
                  />
                )}
                dataSource={checklistItems}
                rowKey={(item) => item.id}
                loading={loading}
                columns={columns}
                pagination={false}
              />
            );
          })(),
        }}
      </DraggableTableWrapper>
    </Space>
  );
};

const PermitChecklistsTables: FC<
  Omit<PermitChecklistTableProps, "editing" | "onAddItemPress"> & {
    checklistItems: Array<any>;
    closingChecklistItems: Array<any>;
    onAddItemPress: (args: { itemType: "permit" | "closing" }) => any;
    permitChecklistTitle?: string;
    editDisable?: boolean;
    closingPermitChecklistTitle?: string;
    projectLevel?: boolean;
  }
> = ({
  title,
  projectLevel,
  permitChecklistTitle,
  closingPermitChecklistTitle,
  onAddItemPress,
  checklistItems,
  closingChecklistItems,
  ...props
}) => {
  const [editing, setEditing] = useState(false);

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      {title && <h2>{title}</h2>}
      <PermitChecklistTable
        editing={editing}
        key="permit"
        onAddItemPress={() => {
          onAddItemPress({
            itemType: "permit",
          });
        }}
        checklistItems={checklistItems}
        title={permitChecklistTitle ?? "Permit Checklist Items"}
        {...props}
      />
      <PermitChecklistTable
        editing={editing}
        key="closing"
        onAddItemPress={() => {
          onAddItemPress({
            itemType: "closing",
          });
        }}
        checklistItems={closingChecklistItems}
        title={closingPermitChecklistTitle ?? "Closing Permit Checklist Items"}
        {...props}
      />
      {editing ? (
        <Button type={"link"} onClick={() => setEditing(false)}>
          Done
        </Button>
      ) : projectLevel ? (
        <Button
          type={"primary"}
          onClick={() => {
            noPropagation();
            setEditing(true);
          }}
        >
          Edit Items
        </Button>
      ) : (
        <Popconfirm
          title={`Changes to these questions will be applied company wide on all projects. Are you sure?`}
          onConfirm={() => setEditing(true)}
          onCancel={noPropagation()}
          okText="Yes"
          cancelText="Cancel"
        >
          {!props.editDisable && (
            <Button type={"primary"} onClick={noPropagation()}>
              Edit Items
            </Button>
          )}
        </Popconfirm>
      )}
    </Space>
  );
};
export default PermitChecklistsTables;
