import React, { FC } from "react";
import { Button, Drawer } from "antd";
import isMobile from "../../functions/isMobile";

interface PdfViewerProps {
  visible: boolean;
  onClose: () => void;
  pdfUrl?: string;
}

const PdfViewer: FC<PdfViewerProps> = ({ visible, onClose, pdfUrl }) => {
  // TODO show user-friendly interface if PDF is absent or make pdfUrl required

  const embeddedPdf = (
    <embed
      src={pdfUrl}
      type="application/pdf"
      width="100%"
      height="100%"
      className={"bg-white"}
    />
  );

  return (
    <Drawer
      title="Basic Drawer"
      width={"70%"}
      onClose={onClose}
      visible={visible}
    >
      {isMobile() ? (
        <div className={`flex flex-col min-h-full justify-center gap-1`}>
          <a onClick={() => window.open(pdfUrl, "_blank")}>{embeddedPdf}</a>
          <Button
            type={`primary`}
            block
            onClick={() => window.open(pdfUrl, "_blank")}
          >
            View Full Document
          </Button>
        </div>
      ) : (
        embeddedPdf
      )}
    </Drawer>
  );
};
export default PdfViewer;
