import { Button, Drawer } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import clsx from "clsx";
import React, { useState } from "react";
import DevelopmentPlaygroundNavigationMenu from "./DevelopmentPlaygroundNavigationMenu";

interface DevelopmentPlaygroundNavigationTopBarProps {}

const DevelopmentPlaygroundNavigationTopBar: React.FC<
  DevelopmentPlaygroundNavigationTopBarProps
> = () => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false);

  return (
    <header className={`gap-0.5 p-0.25 sticky top-0 z-50 w-full`}>
      <Button
        icon={mobileDrawerOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setMobileDrawerOpen(!mobileDrawerOpen)}
        className={clsx(!mobileDrawerOpen && `desktop:hidden`)}
      />
      <Drawer
        open={mobileDrawerOpen}
        onClose={() => {
          setMobileDrawerOpen(false);
        }}
        placement={"left"}
      >
        <DevelopmentPlaygroundNavigationMenu
          onChange={() => {
            setMobileDrawerOpen(false);
          }}
        />
      </Drawer>
    </header>
  );
};

export default DevelopmentPlaygroundNavigationTopBar;
