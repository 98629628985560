import { Alert, message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-relay/hooks";
import { SelectorStoreUpdater } from "relay-runtime";
import {
  AddProcoreIntegrationModal_upsertAuthData_Mutation,
  AddProcoreIntegrationModal_upsertAuthData_Mutation$data,
} from "src/common/types/generated/relay/AddProcoreIntegrationModal_upsertAuthData_Mutation.graphql";
import FModal, { FModalRef } from "./FModal";
import GetFullID from "../../functions/GetFullId";

const procoreAuthDataUpdater: SelectorStoreUpdater<
  AddProcoreIntegrationModal_upsertAuthData_Mutation$data
> = (store, payload) => {
  const data = payload?.insert_procore_auth_data_one;
  if (!data) return;
  const gc_id = data.general_contractor_id;
  const value = data.integration_enabled;
  const parent_id = GetFullID("general_contractor", gc_id);
  const parent = store.get(parent_id);
  let record = parent?.getLinkedRecord("procore_auth_data");
  if (!record) {
    const record_id = GetFullID("procore_auth_data", gc_id);
    record = store.get(record_id);
    if (!record) record = store.create(record_id, "procore_auth_data");
    parent?.setLinkedRecord(record, "procore_auth_data");
  }
  const values = data as { [kay: string]: any };
  for (const name of Object.keys(values)) {
    if (name !== "general_contractor_id") record.setValue(values[name], name);
  }
};

type FormValues = {
  client_id: string;
  client_secret: string;
};
export type AddProcoreIntegrationModalProps = {
  has_auth_data: boolean;
  GCId: string;
  onSubmit: () => Promise<void>;
};
export type AddProcoreIntegrationModalRef = FModalRef<FormValues> | null;
const AddProcoreIntegrationModal = forwardRef<
  AddProcoreIntegrationModalRef,
  AddProcoreIntegrationModalProps
>(({ has_auth_data, GCId, onSubmit }, ref) => {
  const modal = useRef<FModalRef<FormValues>>(null);
  const [loading, setLoading] = useState(false);
  // const isGC = useGetUserRoleQuery().data?.role === "employee";

  useImperativeHandle<
    AddProcoreIntegrationModalRef,
    AddProcoreIntegrationModalRef
  >(ref, () => modal.current);

  const reset = () => {
    setLoading(false);
    modal.current?.form.resetFields();
    modal.current?.close();
  };
  const [upsertAuthData] =
    useMutation<AddProcoreIntegrationModal_upsertAuthData_Mutation>(graphql`
      mutation AddProcoreIntegrationModal_upsertAuthData_Mutation(
        $general_contractor_id: uuid!
        $client_id: String!
        $client_secret: String!
      ) {
        insert_procore_auth_data_one(
          object: {
            general_contractor_id: $general_contractor_id
            user_id: null
            user_name: null
            user_login: null
            refresh_token: null
            access_token: null
            token_expires_at: null
            service_account_client_id: $client_id
            service_account_client_secret: $client_secret
            integration_enabled: true
          }
          on_conflict: {
            constraint: procore_auth_data_pkey
            update_columns: [
              user_id
              user_name
              user_login
              refresh_token
              access_token
              token_expires_at
              service_account_client_id
              service_account_client_secret
              integration_enabled
            ]
          }
        ) {
          general_contractor_id
          user_id
          user_name
          user_login
          service_account_client_id
          integration_enabled
        }
      }
    `);
  return (
    <FModal
      ref={modal}
      title="Set Service Account Details"
      okText={has_auth_data ? "Change" : "Add"}
      onCancel={() => {
        reset();
      }}
      onOk={async () => {
        const form = modal.current?.form;
        if (!form) return;
        const values = await form.validateFields().catch(v => null);
        if (!values)
            return; 
        setLoading(true);
        try {
          // To check the input is correct by generating token ( to be done )
          //upsertAuthData()
          await upsertAuthData({
            variables: {
              general_contractor_id: GCId,
              client_id: values.client_id,
              client_secret: values.client_secret,
            },
            updater: procoreAuthDataUpdater,
          });
          await onSubmit();
          reset();
          message.success("Done");
        } finally {
          setLoading(false);
        }        
      }}
    >
      {has_auth_data && (
        <>
          <Alert
            message="WARNING: Changing the Client Id will delete all the project setup
            data and subcontractor setup data."
            type="warning"
            showIcon
            closable
          />
          <br />
        </>
      )}
      <FModal.Text
        requiredMessage="Please enter non-empty Client ID"
        label="Service Account Client ID"
        name="client_id"
      />
      <FModal.Text
        requiredMessage="Please enter non-empty Client Secret"
        label="Client Secret by procore"
        name="client_secret"
      />
    </FModal>
  );
});

export default AddProcoreIntegrationModal;
