import React, { FC, useMemo } from "react";

type UnsafeSpanEditProps = {
  defaultValue: string;
  onChange: (value: string) => void;
};

const UnsafeSpanEdit: FC<UnsafeSpanEditProps> = ({
  defaultValue,
  onChange,
}) => {
  // ignore changes after component is mounted.. so we do not touch users edits
  const initialContent = useMemo(() => defaultValue, []);
  return (
    <span
      className={"font-accent text-1"}
      contentEditable
      onInput={(event) => {
        const editedContent = (event.target as HTMLElement).innerText;
        onChange(editedContent);
      }}
    >
      {initialContent}
    </span>
  );
};

export default UnsafeSpanEdit;
