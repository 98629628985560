import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
// import Button from "src/common/components/general/Button";
import { SelectWhoWorkerCanAssignTodoQuery } from "src/common/types/generated/relay/SelectWhoWorkerCanAssignTodoQuery.graphql";
import { SelectWhoWorkerCanAssignTodoMutation } from "src/common/types/generated/relay/SelectWhoWorkerCanAssignTodoMutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import { ConnectionHandler } from "relay-runtime";
import GetFullID from "src/common/functions/GetFullId";
import { Button, message } from "antd";

const query = graphql`
  query SelectWhoWorkerCanAssignTodoQuery($projectId: uuid!) {
    project_employee_connection(
      first: 10000
      where: { project_id: { _eq: $projectId } }
    )
      @connection(
        key: "SelectWhoWorkerCanAssignTodo_project_employee_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          worker_can_assign_todo
          employee {
            user {
              name
            }
          }
        }
      }
    }
  }
`;

const SelectWhoWorkerCanAssignTodo: FC<{ projectId: string }> = ({
  projectId,
}) => {
  const [showModal, setShowModal] = useState(false);
  const data = useLazyLoadQuery<SelectWhoWorkerCanAssignTodoQuery>(query, {
    projectId: projectId,
  });
  const [setWorkerCanAssignTodoTo] =
    useAsyncMutation<SelectWhoWorkerCanAssignTodoMutation>(graphql`
      mutation SelectWhoWorkerCanAssignTodoMutation(
        $projectEmployeesAssignTrue: [uuid!]!
        $projectId: uuid!
      ) {
        setTrue: update_project_employee(
          where: { id: { _in: $projectEmployeesAssignTrue } }
          _set: { worker_can_assign_todo: true }
        ) {
          returning {
            id
            worker_can_assign_todo
          }
        }
        setFalse: update_project_employee(
          where: {
            id: { _nin: $projectEmployeesAssignTrue }
            project_id: { _eq: $projectId }
          }
          _set: { worker_can_assign_todo: false }
        ) {
          returning {
            id
            worker_can_assign_todo
          }
        }
      }
    `);

  const projectEmployees = data.project_employee_connection.edges;
  const selectedProjectEmployees =
    data.project_employee_connection.edges.filter(
      (pe) => pe.node.worker_can_assign_todo,
    );
  const modalOpen = () => {
    setShowModal(true);
  };

  const modalClose = () => {
    setShowModal(false);
  };
  const modalRef = useRef<FModalRef<{ projectEmployees: Array<string> }>>();
  return (
    <div className="flex flex-col gap-1">
      <p className="text-2">To-Dos</p>
      <p style={{ marginBottom: 9, fontWeight: 400 }}>
        By default Workers can't assign To-Dos to you or your staff. Adding Team
        Members allows To-Dos to be assigned to the added staff{" "}
      </p>
      <div className="-mx-0.25">
        {selectedProjectEmployees.map(({ node }) => (
          <span
            className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
            key={node.id}
          >
            {node.employee.user.name}
          </span>
        ))}
      </div>

      <div className="flex flex-row justify-start">
        <Button onClick={() => modalOpen()} loading={false} type="primary">
          Select Team Member(s)
        </Button>
      </div>
      <FModal
        ref={modalRef}
        destroyOnClose
        open={showModal}
        title="Select Team Member(s)"
        onCancel={modalClose}
        onOk={async () => {
          const val = await modalRef.current?.form.validateFields();
          const newSelectedEmployees = val?.projectEmployees || [];
          try {
            await setWorkerCanAssignTodoTo({
              variables: {
                projectId,
                projectEmployeesAssignTrue: newSelectedEmployees,
              },
              updater: (store) => {
                const conn = ConnectionHandler.getConnection(
                  store.getRoot(),
                  "SelectWhoWorkerCanAssignTodo_project_employee_connection",
                );
                const edges = conn?.getLinkedRecords("edges") || [];
                edges.forEach((edge) => {
                  const node = edge.getLinkedRecord("node");
                  if (node) {
                    const id = node.getDataID();
                    if (
                      newSelectedEmployees.find(
                        (pk) => GetFullID("project_employee", pk) === id,
                      )
                    ) {
                      node.setValue(true, "worker_can_assign_todo");
                    } else {
                      node.setValue(false, "worker_can_assign_todo");
                    }
                  }
                });
              },
            });
            modalClose();
          } catch (err) {
            message.error("Couldn't edit users. Please try again");
          }
        }}
      >
        <FModal.Select
          name={"projectEmployees"}
          initialValue={selectedProjectEmployees.map((p) => p.node.pk)}
          props={{
            mode: "multiple",
            options: projectEmployees.map((p) => ({
              value: p.node.pk,
              label: p.node.employee.user.name,
            })),
          }}
        />
      </FModal>
    </div>
  );
};
export default SelectWhoWorkerCanAssignTodo;
