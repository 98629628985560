import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import GCInspectionQRs from "./route/inspection-qr/GCInspectionQRs";
import GCManageInspections from "./route/manage/GCManageInspections";
import GCCompletedInspections from "./route/completed/GCCompletedInspections";
import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import { GCInspectionsQuery } from "src/common/types/generated/relay/GCInspectionsQuery.graphql";
import useAuthUser from "src/common/hooks/useAuthUser";

const GCInspections: React.FunctionComponent = () => {
  const projectId = useParams().projectId as string;
  const subview = useParams().subview as string;
  const authUser = useAuthUser();

  const data = useLazyLoadQuery<GCInspectionsQuery>(
    graphql`
      query GCInspectionsQuery($projectId: uuid!, $loggedInUserId: uuid!) {
        inspection_result_connection(
          where: {
            inspection_instance: { project_id: { _eq: $projectId } }
            company_name: { _is_null: false }
          }
        ) {
          edges {
            node {
              company_name
              inspection_instance_id
            }
          }
        }
        project_subcontractor_connection(
          where: {
            project_id: { _eq: $projectId }
            subcontractor: {
              inspection_results: {
                inspection_instance: { project_id: { _eq: $projectId } }
              }
            }
          }
        ) {
          edges {
            node {
              subcontractor_id
              subcontractor {
                inspection_results {
                  inspection_instance_id
                }
                name
              }
            }
          }
        }
        inspection_instance_connection(
          where: {
            project_id: { _eq: $projectId }
            deleted_at: { _is_null: true }
          }
          first: 100000
        )
          @connection(
            key: "GCInspectionsQuery_inspection_instance_connection"
            filters: []
          ) {
          edges {
            node {
              name {
                en
              }
              id
              pk: id @__clientField(handle: "pk")
            }
          }
        }
        inspection_qr_code_connection(
          where: {
            project_id: { _eq: $projectId }
            deleted_at: { _is_null: true }
          }
          first: 100000
        )
          @connection(
            key: "GCInspectionsQuery_inspection_qr_code_connection"
            filters: []
          ) {
          edges {
            node {
              name
              id
              pk: id @__clientField(handle: "pk")
            }
          }
        }
        project_employee_connection(
          where: {
            project_id: { _eq: $projectId }
            employee: { user: { email: { _is_null: false } } }
          }
          order_by: { employee: { user: { name: asc } } }
        ) {
          edges {
            node {
              id
              employee_id
              employee {
                user {
                  name
                  email
                }
              }
            }
          }
        }
        projectOptions: project_employee_connection(
          where: {
            employee_id: { _eq: $loggedInUserId }
            project_id: { _neq: $projectId }
          }
          order_by: { project: { name: asc } }
        ) {
          edges {
            node {
              project_id
              project {
                name
              }
            }
          }
        }
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              general_contractor_id
            }
          }
        }
        inspection_label_type_connection(order_by: { label_name: asc }) {
          edges {
            node {
              label_name
              id
              pk: id @__clientField(handle: "pk")
            }
          }
        }
      }
    `,
    { projectId, loggedInUserId: authUser.uid },
  );

  const navigate = useNavigate();
  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId + (tabId === "manage" ? "?table-type=project" : ""));
  };
  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "completed",
      label: "Completed Inspections",
      content: <GCCompletedInspections projectId={projectId} data={data} />,
    },
    {
      id: "manage",
      label: "Manage Inspections",
      content: <GCManageInspections projectId={projectId} data={data} />,
    },
    {
      id: "qr",
      label: " QR Code(s)",
      content: <GCInspectionQRs projectId={projectId} data={data} />,
    },
  ];
  return (
    <BasicWrapper scrollable>
      <CustomTabs
        onTabSelect={handleReportsTabClick}
        tabs={tabs}
        selectedTabId={subview}
      />
    </BasicWrapper>
  );
};
export default GCInspections;
