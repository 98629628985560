import React, { useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import Button from "src/common/components/general/button/Button";
import TextareaAutosize from "react-textarea-autosize";
import { IconMinus, IconPlus } from "@tabler/icons";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";

export interface DailyReportInfoForPtpSubmissionProps
  extends SiteOrientationStepProps {}

const DailyReportInfoForPtpSubmission: React.FC<
  DailyReportInfoForPtpSubmissionProps
> = (props) => {
  const [value, setValue] = useState(8);
  const langStrings = useCurrentLangStrings();
  const { ptpQrUser, setPtpQrUser } = usePtpQr();
  const { dailyWorkLog } = ptpQrUser;
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={props.onNext}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!dailyWorkLog.description}
    >
      <SiteFeatureStepsInputLayout>
        <div className="flex justify-center text-1.25">
          {langStrings.strings.whatWillYouDoTodayAndWhere}
        </div>

        <TextareaAutosize
          value={dailyWorkLog.description}
          onChange={(e) =>
            setPtpQrUser((prevState) => ({
              ...prevState,
              dailyWorkLog: {
                ...prevState.dailyWorkLog,
                description: e.target.value,
              },
            }))
          }
          className={`rounded-0.5 border-px w-full border-suplementary-3 flex-1 resize-none outline-none p-0.75 whitespace-pre-wrap break-word`}
        />

        <div className="mt-2 text-1.2 flex justify-center">
          {langStrings.strings.hoursPerWorker}
        </div>

        <div className="flex justify-center flex-row space-x-3">
          <span className="text-2 mt-0.125">{value}</span>
          <Button
            large
            icon={IconPlus}
            onClick={() => {
              const newValue = value + 1;
              setValue(newValue);
              setPtpQrUser((prevState) => ({
                ...prevState,
                dailyWorkLog: {
                  ...prevState.dailyWorkLog,
                  hours: newValue,
                },
              }));
            }}
          />
          <Button
            large
            icon={IconMinus}
            onClick={() => {
              const newValue = value - 1;
              setValue(newValue);
              setPtpQrUser((prevState) => ({
                ...prevState,
                dailyWorkLog: {
                  ...prevState.dailyWorkLog,
                  hours: newValue,
                },
              }));
            }}
          />
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default DailyReportInfoForPtpSubmission;
