/**
 * @generated SignedSource<<14a88df7275290cad667fffd6298773a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { general_contractor_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AGCOrientations_UpdateMutation$variables = {
  _set?: general_contractor_set_input | null | undefined;
  id: string;
};
export type AGCOrientations_UpdateMutation$data = {
  readonly update_general_contractor_by_pk: {
    readonly id: string;
  } | null | undefined;
};
export type AGCOrientations_UpdateMutation = {
  response: AGCOrientations_UpdateMutation$data;
  variables: AGCOrientations_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "general_contractor",
    "kind": "LinkedField",
    "name": "update_general_contractor_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AGCOrientations_UpdateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AGCOrientations_UpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "24411924d6f97f007a0bb01364164a47",
    "id": null,
    "metadata": {},
    "name": "AGCOrientations_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation AGCOrientations_UpdateMutation(\n  $id: uuid!\n  $_set: general_contractor_set_input\n) {\n  update_general_contractor_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9d2070db063df53167094ce564ed39d";

export default node;
