import React from "react";
import ButtonHuge from "../../../../common/components/general/button/ButtonHuge";
import { LeftOutlined } from "@ant-design/icons";

export interface SiteOrientationSlidesViewerBackButtonProps {
  onClick: () => void;
}

const SiteOrientationSlidesViewerBackButton: React.FC<
  SiteOrientationSlidesViewerBackButtonProps
> = (props) => {
  return (
    <ButtonHuge icon={<LeftOutlined />} onClick={props.onClick}>
      Back
    </ButtonHuge>
  );
};

export default SiteOrientationSlidesViewerBackButton;
