export const o2IsValid = (o2: any) =>
  !!o2 ? Number(o2) >= 19.5 && Number(o2) <= 23.5 : true;
export const lelIsValid = (lel: any) => (!!lel ? Number(lel) < 10 : true);
export const coIsValid = (co: any) => (!!co ? Number(co) < 25 : true);
export const h2sIsValid = (h2s: any) => (!!h2s ? Number(h2s) < 10 : true);
export const tempfIsValid = (f: any) => (!!f ? Number(f) < 130 : true);

export const readingItemsValid = {
  o2: o2IsValid,
  lel: lelIsValid,
  co: coIsValid,
  h2s: h2sIsValid,
  tempf: tempfIsValid,
};
const getNotPermissibleReadingsCount = (reading: {
  o2_percent?: string | null;
  lower_explosive_limit?: string | null;
  h2s_ppm?: string | null;
  co_ppm?: string | null;
}) =>
  [
    readingItemsValid.o2(reading.o2_percent),
    readingItemsValid.lel(reading.lower_explosive_limit),
    readingItemsValid.h2s(reading.h2s_ppm),
    readingItemsValid.co(reading.co_ppm),
  ].filter((r) => r === false).length;
export default getNotPermissibleReadingsCount;
