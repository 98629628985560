import {
  IconCheck,
  IconCircle,
  IconCircleCheck,
  IconUserCheck,
  IconUserX,
  IconX,
} from "@tabler/icons";
import {
  message,
  notification,
  Popconfirm,
  Radio,
  Tooltip,
  Button,
  Spin,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useEffect, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useLocation, useParams } from "react-router-dom";
import JHAEditNotifications from "src/common/components/JHAEditNotifications";
// import Button from "src/common/components/general/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import Switcher from "src/common/components/general/Switcher";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCProjectSettingsOldQuery, procore_project_data_set_input, project_set_input, project_setting_set_input } from "src/common/types/generated/relay/GCProjectSettingsOldQuery.graphql";
import { GCProjectSettingsOldProcoreProjectMutation } from "src/common/types/generated/relay/GCProjectSettingsOldProcoreProjectMutation.graphql";
import { GCProjectSettingsOldUpdateProjectMutation } from "src/common/types/generated/relay/GCProjectSettingsOldUpdateProjectMutation.graphql";
import { GCProjectSettingsUpdateProjectSettingsMutation } from "src/common/types/generated/relay/GCProjectSettingsUpdateProjectSettingsMutation.graphql";
import ProjectProps from "src/common/types/manual/ProjectProps";
import EmailProjectUserSettings from "./EmailProjectUserSettings";
import SitedailyEmailSettings from "./components/SitedailyEmailSettings";
import BlockOutDeliveryTimes from "./block-out-delivery-times/BlockOutDeliveryTimes";
import { InfoCircleOutlined } from "@ant-design/icons";
import SelectWhoWorkerCanAssignTodo from "./components/SelectWhoWorkerCanAssignTodo";
import LogisticPlan from "./LogisticPlan";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import ProfileAvatar from "../../../subcontractor/people/workers/worker-profile/ProfileAvatar";
import ProjectLocalCities from "./ProjectLocalCities";
import OpenQrCode from "src/common/components/OpenQrCode";
import ChangeProjectHierarchyModal from "../../hierarchy/component/ChangeProjectHierarchyModal";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import getLogoToShowFromProjectData from "src/common/functions/getLogoToShow";
import useAuthUser from "src/common/hooks/useAuthUser";
import ProjectLocations from "./ProjectLocations";
import { useGetProjectLocationsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import NotifyUserException from "src/utility-features/error-handling/NotifyUserException";
import { isReturnStatement } from "typescript";
import ProcoreProjectIntegrationSettings from "./ProcoreProjectIntegrationSettings";

const query = graphql`
  query GCProjectSettingsOldQuery($projectId: uuid!, $userId: uuid!) {
    state_connection(order_by: { name: asc }) {
      edges {
        node {
          code
          name
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }, first: 1)
      @connection(
        key: "GCProjectSettingsOldQuery_project_connection"
        filters: []
      ) {
      edges {
        node {
          id
          ...ProjectLogoFrag @relay(mask: false)
          pk: id @__clientField(handle: "pk")
          is_sitedelivery_active
          is_sitedelivery_on
          is_sitedelivery_approval_needed
          is_sitedelivery_block_out_active
          completed
          name
          gc_division_id
          gc_business_unit_id
          gc_office_id
          is_visitor_log_and_tracking_active
          is_visitor_log_procore_integration_enabled
          project_setting {
            id
            delivery_stacking
          }
          address {
            state_code
          }
          gc_office {
            gc_office_employees_aggregate(
              where: { user_id: { _eq: $userId } }
            ) {
              aggregate {
                count
              }
            }
          }
          gc_division {
            gc_division_employees_aggregate(
              where: { user_id: { _eq: $userId } }
            ) {
              aggregate {
                count
              }
            }
          }
          gc_business_unit {
            gc_business_unit_employees_aggregate(
              where: { user_id: { _eq: $userId } }
            ) {
              aggregate {
                count
              }
            }
          }
          project_subcontractors {
            subcontractor_id
          }
          procore_project_data {
            id
            procore_project_id
            procore_project_name
            integration_enabled
            user_id
            user_name
            user_login
            service_account_client_id
            sandbox
            upload_daily_construction_report_enabled
            upload_delivery_enabled
          }
          logistic_plans(order_by: { created_at: desc }, limit: 1) {
            ...LogisticPlanFrag @relay(mask: false)
          }
          project_local_cities(order_by: { created_at: desc }) {
            city_id
            city {
              name
            }
          }
          general_contractor {
            pk: id @__clientField(handle: "pk")
            logo_url
            procore_auth_data {
              integration_enabled
              user_id
              user_name
              user_login
              service_account_client_id
              sandbox
            }
          }
        }
      }
    }
  }
`;

const hasProperty= <T extends {},>(value: T, prop: string | number | symbol): prop is keyof(T) => {
  return prop in value;
}

const GCProjectSettingsOld: FC<ProjectProps> = ({ projectId }) => {
  const authUser = useAuthUser();
  const {
    data: locationsData,
    loading: locationsLoading,
    error,
  } = useGetProjectLocationsQuery({
    variables: { where: { project_id: { _eq: projectId } } },
  });
  const data = useLazyLoadQuery<GCProjectSettingsOldQuery>(
    query,
    { userId: authUser.uid, projectId },
    //refreshedQueryOptions ?? {}
  );
  const location = useLocation();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (location.hash === "#logistics") {
      console.log(location, scrollRef);

      scrollRef.current?.scrollIntoView(true);
    }
  }, [location]);
  // Procore Project Modal
  const projectEdge = data.project_connection.edges[0];
  if (!projectEdge)
      throw new Error("Project does not exist"); 

  const project = projectEdge.node;
  const procore_project_data = project.procore_project_data;
  const project_settings = project.project_setting;


  const { userData } = useUserData();
 
  const [updateProject, updatingProj] =
    useAsyncMutation<GCProjectSettingsOldUpdateProjectMutation>(graphql`
      mutation GCProjectSettingsOldUpdateProjectMutation(
        $projectId: uuid!
        $_set: project_set_input!
      ) {
        update_project_by_pk(pk_columns: { id: $projectId }, _set: $_set) {
          id
          completed
          is_visitor_log_and_tracking_active
          is_sitedelivery_on
          is_visitor_log_procore_integration_enabled
          logo_url
          is_sitedelivery_approval_needed
        }
      }
    `);

  const [updateProjectSetting] =
    useAsyncMutation<GCProjectSettingsUpdateProjectSettingsMutation>(graphql`
      mutation GCProjectSettingsUpdateProjectSettingsMutation(
        $projectId: uuid!
        $_set: project_setting_set_input!
      ) {
        update_project_setting_by_pk(
          pk_columns: { project_id: $projectId }
          _set: $_set
        ) {
          id
          delivery_stacking
        }
      }
    `);

  const [updateProcoreProject] =
    useAsyncMutation<GCProjectSettingsOldProcoreProjectMutation>(graphql`
      mutation GCProjectSettingsOldProcoreProjectMutation(
        $projectId: uuid!
        $_set: procore_project_data_set_input!
      ) {
        update_procore_project_data_by_pk(
          pk_columns: { project_id: $projectId }
          _set: $_set
        ) {
          id
          integration_enabled
          upload_delivery_enabled
          upload_daily_construction_report_enabled
        }
      }
    `);
  
  const notifyOnSingleBooleanFieldChange = <S extends {}, D extends {}>(set: S, data: D) => {
    const keys = Object.keys(set);
    if (keys.length !== 1)
        return;
    const key = keys[0];
    if (hasProperty(data, key) && typeof data[key] === "boolean") {
      const value = data[key];
      if (value) {
        message.success("On");
      } else {
        message.error("Off");
      }
    }
  } 

  const updateProjectValue = async (set: project_set_input) => {
    const res = await updateProject({
      variables: {
        projectId: projectId,
        _set: set,
      },
      optimisticResponse: {
        update_project_by_pk: {
          ...project,
          ...set,
        },
      },
    });
    const data = res.update_project_by_pk;
    if (!data) {
      throw new Error("Project does not exist");
    }
    notifyOnSingleBooleanFieldChange(set, data);
  }

  const updateProjectSettingsValue = async (set: project_setting_set_input) => {
    const res = await updateProjectSetting({
      variables: {
        projectId: projectId,
        _set: set,
      },
      optimisticResponse: {
        update_project_by_pk: {
          ...project_settings,
          ...set,
        },
      },
    });
    const data = res.update_project_setting_by_pk;
    if (!data) {
      throw new Error("Project settings record is missing")
    }
    notifyOnSingleBooleanFieldChange(set, data);
  }

  const updateProcoreProjectValue = async (set: procore_project_data_set_input) => {
    const res = await updateProcoreProject({
      variables: {
        projectId: projectId,
        _set: set,
      },
      optimisticResponse: {
        update_procore_project_data_by_pk: {
          ...procore_project_data,
          ...set,
        },
      },
    });
    const data = res.update_procore_project_data_by_pk;
    if (!data){
      message.error("Please configure procore integration first");
      return;
    }
    notifyOnSingleBooleanFieldChange(set, data);
  }

  const [showHierarchyModal, setShowHierarchyModal] = useState(false);  

  return (
    <div className="flex flex-row justify-center h-full gap-8">
      <div className="flex flex-col flex-1 gap-2">
        <div className="flex flex-col gap-1">
          <h1 className="text-3">{project.name}</h1>
          <p className="inline-flex flex-row items-center gap-0.5">
            The project is
            <span>
              <Switcher
                onChange={async (optionIndex) => {
                  const completed = optionIndex === 1;
                  await updateProject({
                    variables: {
                      projectId,
                      _set: { completed },
                    },
                    optimisticResponse: {
                      update_project_by_pk: {
                        ...project,
                        completed,
                      },
                    },
                  });
                }}
                optionIndex={project.completed ? 1 : 0}
                options={[
                  { icon: IconCircle, label: "Active" },
                  { icon: IconCircleCheck, label: "Complete" },
                ]}
              />
            </span>
          </p>
        </div>
        {userData.employee?.is_corporate_admin &&
        (userData.employee.general_contractor.hierarchy_business_unit_name ||
          userData.employee.general_contractor.hierarchy_division_name ||
          userData.employee.general_contractor.hierarchy_office_name) ? (
          <div>
            <CustomSuspense>
              {showHierarchyModal && (
                <ChangeProjectHierarchyModal
                  projectId={projectId}
                  showLevels={["business-unit", "division", "office"]}
                  onSuccess={() => setShowHierarchyModal(false)}
                  onCancel={() => setShowHierarchyModal(false)}
                  officeId={project.gc_office_id}
                  divisionId={project.gc_division_id}
                  businessUnitId={project.gc_business_unit_id}
                />
              )}
              <Button
                type="primary"
                onClick={() => setShowHierarchyModal(true)}
              >
                Change Project Hierarchy Level
              </Button>
            </CustomSuspense>
          </div>
        ) : null}
        <div>
          <CustomSuspense>
            <JHAEditNotifications projectId={projectId} />
          </CustomSuspense>
        </div>
        <div>
          <CustomSuspense>
            <SelectWhoWorkerCanAssignTodo projectId={projectId} />
          </CustomSuspense>
        </div>
        <div>
          <CustomSuspense>
            <SitedailyEmailSettings />
          </CustomSuspense>
        </div>

        <div>
          <CustomSuspense>
            <EmailProjectUserSettings
              type="active_users"
              key="active_users"
              projectId={projectId}
              title="Active Users"
              description="Team Members to be emailed on the 1st day of each month the list of Active Users (users that have access to the project in the mobile app or website)"
            />
          </CustomSuspense>
        </div>
        <div>
          <CustomSuspense>
            <EmailProjectUserSettings
              type="safety_directors"
              key="safety_directors"
              projectId={projectId}
              title="Area Safety Director"
              buttonTitle="Select Safety Director"
              description="Safety Director(s) assigned to this project"
            />
          </CustomSuspense>
        </div>
        <div>
          <CustomSuspense>
            <EmailProjectUserSettings
              key="incident_reporting"
              type="incident_reporting"
              title="Incident Management"
              description="Select project team members who should be copied on all incident reports"
              projectEmpFilter={{
                _or: [
                  { direct_project_assign: { _eq: true } },
                  { direct_project_assign: { _is_null: true } },
                ],
              }}
              projectId={projectId}
            />
          </CustomSuspense>
        </div>
        {project.is_sitedelivery_active && (
          <div className="flex flex-col gap-1">
            <p className="text-2">SiteDelivery</p>
            <p className="inline-flex flex-row items-center gap-0.5">
              SiteDelivery: enable or disable delivery and logistics management
              <Switcher
                options={[
                  { label: "enable", icon: IconCheck },
                  { label: "disable", icon: IconX },
                ]}
                optionIndex={project.is_sitedelivery_on ? 0 : 1}
                onChange={(selectedOptionIndex) => {
                  const siteDeliveryOn = selectedOptionIndex === 0;
                  return updateProjectValue({ is_sitedelivery_on: siteDeliveryOn });
                }}
              />
            </p>

            <p className="inline-flex flex-row items-center gap-0.5">
              Delivery Stacking: allow stacking or double-booking of deliveries
              <Switcher
                options={[
                  {
                    icon: IconCheck,
                    label: "on",
                  },
                  {
                    icon: IconX,
                    label: "off",
                  },
                ]}
                optionIndex={
                  project.project_setting?.delivery_stacking ? 0 : 1
                }
                onChange={(optionIndex) => 
                  updateProjectSettingsValue({delivery_stacking: optionIndex === 0})
                }
              />
            </p>

            <p className="inline-flex flex-row items-center gap-0.5">
              Delivery Approval: require approval for deliveries added by
              subcontractors
              <Switcher
                options={[
                  {
                    icon: IconCheck,
                    label: "on",
                  },
                  {
                    icon: IconX,
                    label: "off",
                  },
                ]}
                optionIndex={
                  project.is_sitedelivery_approval_needed ? 0 : 1
                }
                onChange={async (optionIndex) => 
                  updateProjectValue({is_sitedelivery_approval_needed: optionIndex === 0})
                }
              />
            </p>
            <BlockOutDeliveryTimes
              projectId={projectId}
              featureOn={project.is_sitedelivery_block_out_active}
            />
            <p className="inline-flex flex-row items-center gap-0.5">
              Procore: add completed deliveries to your Daily Report
              <Switcher
                onChange={(optionIndex) =>
                  updateProcoreProjectValue({
                    upload_delivery_enabled: optionIndex === 0
                  })
                }
                options={[
                  { icon: IconCheck, label: "enabled" },
                  { icon: IconX, label: "disabled" },
                ]}
                optionIndex={procore_project_data?.upload_delivery_enabled ? 0 : 1}
              />
            </p>
          </div>
        )}
        <div ref={scrollRef}>
          <LogisticPlan projectId={projectId} data={data} />
        </div>
        <p className="text-2">SiteVisitor</p>
        <p className="inline-flex flex-row items-center gap-0.5">
          Visitor log and tracking is{" "}
          <Switcher
            options={[
              {
                icon: IconCheck,
                label: "on",
              },
              {
                icon: IconX,
                label: "off",
              },
            ]}
            optionIndex={
              project.is_visitor_log_and_tracking_active ? 0 : 1
            }
            onChange={(optionIndex) =>
              updateProjectValue({ is_visitor_log_and_tracking_active: optionIndex === 0 })
            }
          />
          <Tooltip
            placement="top"
            title={
              <div style={{ color: "black" }}>
                Visitors check-in and sign your visitor log. We capture their
                on-site hours in SiteForm and can add the visitors to Procore.
                When this is turned ON, go to <strong>REPORTS</strong> and{" "}
                <strong>Visitors</strong> to access the feature.
              </div>
            }
            color="white"
          >
            <InfoCircleOutlined />
          </Tooltip>
        </p>
        {project.is_visitor_log_and_tracking_active && (
          <p className="inline-flex flex-row items-center gap-0.5">
            Procore Visitor log Integration is{" "}
            <Switcher
              options={[
                {
                  icon: IconCheck,
                  label: "on",
                },
                {
                  icon: IconX,
                  label: "off",
                },
              ]}
              optionIndex={
                project.is_visitor_log_procore_integration_enabled ? 0 : 1
              }
              onChange={(optionIndex) => 
                updateProjectValue({ is_visitor_log_procore_integration_enabled: optionIndex === 0 }) 
              }
            />
          </p>
        )}
        {locationsLoading ? (
          <Spin />
        ) : locationsData ? (
          <ProjectLocations
            data={locationsData}
            projectId={projectId}
            projectSubs={project.project_subcontractors.map(
              (s) => s.subcontractor_id,
            )}
          />
        ) : error ? (
          <div className="text-semantic-negative">{error.message}</div>
        ) : (
          "Locations data not found"
        )}
        <ProjectLocalCities
          stateCode={project.address.state_code}
          currentCities={project.project_local_cities}
          projectId={projectId}
        />

        <ProcoreProjectIntegrationSettings 
          projectId={projectId} 
          project={project} 
          updateProcoreProjectValue={updateProcoreProjectValue}
        />
  
      </div>
      <div className="sticky top-0 w-20 h-20 ">
        <div className="flex flex-col">
          <p className="text-center text-1.5">
            Project Report and Permit QR Code
          </p>
          <OpenQrCode
            destinationUrl={`${document.location.origin}/qr/p/${projectId}`}
            alwaysVisible
            downloadableFileName="report_permit_qr"
          />
        </div>

        <p className="text-center text-1.5 mt-1">Upload/Change Project Logo</p>
        <ProfileAvatar
          imageUrl={getLogoToShowFromProjectData(project) || undefined}
          onFileUpload={async (imageUrl) => {
            await updateProject({
              variables: { projectId, _set: { logo_url: imageUrl } },
              optimisticResponse: { ...project, logo_url: imageUrl },
            });
          }}
          onFileRemove={async () => {
            await updateProject({
              variables: { projectId, _set: { logo_url: null } },
              optimisticResponse: { ...project, logo_url: null },
            });
          }}
        />
      </div>
    </div>
  );
};

const GCProjectSettingsWrapper: React.FunctionComponent = () => {
  const { projectId } = useParams();
  if (typeof projectId !== "string") {
    return <h1> Wrong url</h1>;
  }

  return (
    <BasicWrapper scrollable>
      <GCProjectSettingsOld projectId={projectId} />
    </BasicWrapper>
  );
};

export default withCustomSuspense(GCProjectSettingsWrapper);
