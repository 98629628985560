import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Modal, Form, Input, Space, Checkbox } from "antd";
import FModal, { FModalRef } from "./FModal";

interface Values {
  description: string;
  selectedOptions: Array<string>;
  notifyees?: Array<string>;
}

interface Props {
  onCreate: (values: Values) => void;
  onCancel: () => void;
  contacts: Array<{
    email: string;
    name: string;
  }>;
}

export type AddChecklistItemModalRef = FModalRef<Values> | null;

const AddChecklistItemModal = forwardRef<FModalRef<Values>, Props>(
  ({ onCreate, onCancel, contacts }, ref) => {
    const modalRef = useRef<AddChecklistItemModalRef>(null);
    const [loading, setLoading] = useState(false);
    const [notifySelected, setNotifySelected] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

    useImperativeHandle<AddChecklistItemModalRef, AddChecklistItemModalRef>(
      ref,
      () => modalRef.current,
    );

    const options = [
      {
        value: "option_yes",
        label: "Yes",
      },
      {
        value: "option_no",
        label: "No",
      },
      {
        value: "option_na",
        label: "N/A",
      },
    ];

    if (selectedOptions.includes("option_yes")) {
      options.push(
        {
          value: "response_on_yes",
          label: "When Yes, response required",
        },
        { value: "notify_yes", label: "When Yes, Notify" },
      );
    }

    if (selectedOptions.includes("option_no")) {
      options.push(
        {
          value: "response_on_no",
          label: "When No, response required",
        },
        { value: "notify_no", label: "When No, Notify" },
      );
    }

    const reset = () => {
      modalRef.current?.form.resetFields();
      setNotifySelected(false);
      setSelectedOptions([]);
    };

    return (
      <FModal
        title="New Checklist Question"
        ref={modalRef}
        confirmLoading={loading}
        onCancel={() => {
          onCancel();
          reset();
        }}
        onOk={async () => {
          const modal = modalRef.current;
          const form = modal?.form;
          if (!form) return;
          const values = await form.validateFields().catch(v => null);
          if (!values)
              return;    
          setLoading(true);
          try {
            await onCreate(values);
            modal.close();
            reset();
          } finally {
            setLoading(false);
          }
        }}
      >
        <FModal.Text
          name="description"
          label="Question"
          requiredMessage="Enter a description"
        />
        <Form.Item
          name="selectedOptions"
          // TODO forece at least yes, no, or na
          // rules={[
          //   {
          //     min: 1,
          //   },
          // ]}
        >
          <Checkbox.Group>
            {options.map((o) => (
              <Checkbox
                value={o.value}
                key={o.label}
                onChange={(e) => {
                  if (o.value.includes("notify")) {
                    setNotifySelected(e.target.checked);
                  }
                  if (e.target.checked) {
                    setSelectedOptions([...selectedOptions, o.value]);
                  } else {
                    setSelectedOptions(
                      selectedOptions.filter((t) => t !== o.value),
                    );
                  }
                }}
              >
                {o.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
        {notifySelected && (
          <FModal.Select
            name="notifyees"
            props={{
              mode: "tags",
              placeholder: "Select notifyees",
              options: [...contacts].map((c) => ({
                key: c.email + c.name,
                value: c.email,
                label: `${c.name}, ${c.email}`,
              })),
              style: {
                width: 400,
              },
            }}
          />
        )}
      </FModal>
    );
  },
);

export default AddChecklistItemModal;
