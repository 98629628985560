import { Button } from "antd";
import {
  BackwardOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { useCurrentLangStrings } from "../../../../utility-features/i18n/context/languageHooks";

// TODO unify audio controls UI with video controls UI

interface VideoPlayerCustomControlsProps {
  onPlayClick: () => void;
  onPauseClick: () => void;
  onRewindClick: () => void;
  isPlaying: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const VideoPlayerCustomControls: React.FC<VideoPlayerCustomControlsProps> = (
  props,
) => {
  const langStrings = useCurrentLangStrings();
  return (
    <div className={`flex flex-row items-center gap-0.5 `}>
      <Button
        disabled={props.disabled}
        loading={props.isLoading}
        block
        onClick={props.onRewindClick}
        icon={<BackwardOutlined />}
      >
        {langStrings.strings.rewindSeconds(30)}
      </Button>
      <Button
        block
        disabled={props.disabled}
        loading={props.isLoading}
        onClick={props.isPlaying ? props.onPauseClick : props.onPlayClick}
        icon={
          props.isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />
        }
      >
        {props.isPlaying ? langStrings.strings.pause : langStrings.strings.play}
      </Button>
    </div>
  );
};

export default VideoPlayerCustomControls;
