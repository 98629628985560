import {
  Alert,
  Col,
  Form,
  message,
  Modal,
  notification,
  Row,
  Select,
  Tooltip,
} from "antd";
import { FormInstance, useForm } from "antd/es/form/Form";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import {
  useGetProcoreSubcontractorListQuery,
  useGetProcoreTradeListQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation } from "src/common/types/generated/relay/ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation.graphql";
import { ProcoreSubConfigModal_updateProcoreGC_Mutation } from "src/common/types/generated/relay/ProcoreSubConfigModal_updateProcoreGC_Mutation.graphql";
import { ProcoreSubConfigModal_Sub_Query } from "src/common/types/generated/relay/ProcoreSubConfigModal_Sub_Query.graphql";
import { InfoCircleOutlined } from "@ant-design/icons";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import compareStringsIgnoreCase from "src/common/functions/compareStringsIgnoreCase";
import compareStrings from "src/common/functions/compareStrings";

const query = graphql`
  query ProcoreSubConfigModal_Sub_Query($projectId: uuid!, $uid: uuid!) {
    general_contractor_connection(
      where: { employees: { uid: { _eq: $uid } } }
    ) {
      edges {
        node {
          id
          name
          pk: id @__clientField(handle: "pk")
          procore_vendor_id
          procore_trade_id

          projects {
            project_subcontractors {
              subcontractor {
                name
                id
                pk: id @__clientField(handle: "pk")
              }
              procore_subcontractor {
                id
                pk: id @__clientField(handle: "pk")
                procore_subcontractor_id
                procore_subcontractor_name
                procore_trade_id
              }
            }
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name
          procore_project_data {
            project_id
            procore_project_id
            procore_project_name
          }
          project_subcontractors(order_by: { subcontractor: { name: asc } }) {
            procore_subcontractor {
              id
              pk: id @__clientField(handle: "pk")
              procore_subcontractor_id
              procore_subcontractor_name
              procore_trade_id
            }
            subcontractor {
              name
              id
              pk: id @__clientField(handle: "pk")
            }
          }
          procore_trade {
            id
            procore_trade_id
            project_id
            title_id
          }
        }
      }
    }
  }
`;

type FormValues = {
  [sub: string]: any;
};

export type ModalFormRef<V extends object> = {
  form: FormInstance<V>;
};

export type ProcoreSubConfigModalProps = {
  projectId: string;
  GCId: string;
  onSubmit?: () => void;
};

interface Trade {
  id: string;
  procore_trade_id: string;
  subcontractor_id: string;
}

const zeroToNull = (v: string | null | undefined) =>
  v === "0" || !v ? null : v;

export type ProcoreSubConfigModalRef = {
  open: () => void;
}; //FModalRef<FormValues> | null;

type ProcoreSubConfigContentModalProps = {
  projectId: string;
  GCId: string;
  saving: boolean;
  fetchKey: number;
};

const ProcoreSubConfigModalContent = forwardRef<
  ModalFormRef<FormValues>,
  ProcoreSubConfigContentModalProps
>(({ projectId, GCId, saving, fetchKey }, ref) => {
  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error("User not logged in");
  }
  const data = useLazyLoadQuery<ProcoreSubConfigModal_Sub_Query>(
    query,
    {
      uid: userId,
      projectId,
    },
    {
      fetchPolicy: "network-only",
      fetchKey,
    },
  );
  const [formRef] = useForm();
  useImperativeHandle<ModalFormRef<FormValues>, ModalFormRef<FormValues>>(
    ref,
    () => ({
      form: formRef,
    }),
  );

  //queryOptions
  const project_edges = data.project_connection.edges || [];
  const gc_edges = data.general_contractor_connection.edges || [];
  if (project_edges.length == 0)
    throw new Error(`Project does not exist, project_id = ${projectId}`);

  const project_node = project_edges[0].node;
  const procoreProjectData = project_node.procore_project_data;

  const procore_project_id = procoreProjectData?.procore_project_id;
  if (!procore_project_id)
    throw new Error(
      `Procore project is not selected, project_id = ${projectId}`,
    ); // this usualy means error in parent component logic. (we should not render this dialog if project is not set)

  // Alex: the code that removes subcontractor from a project does not delete procore_subcontractor record.
  // so we can read linked procore record but subcontractor record will be absent for them.
  // we should not display such records, so filter them
  const subData = project_node.project_subcontractors.filter(
    (sub) => sub.subcontractor,
  );
  const gcData = data.general_contractor_connection.edges[0].node;

  const { data: procoreSubList, loading } = useGetProcoreSubcontractorListQuery(
    {
      variables: {
        projectId,
        procore_project_id,
      },
    },
  );
  const { data: procoreTradeList, loading: tradeListLoading } =
    useGetProcoreTradeListQuery({
      variables: {
        projectId,
        procore_project_id,
      },
    });
  const procoreSubcontractors =
    procoreSubList?.procoreSubcontractorList?.subcontractor.filter(
      (s, i) =>
        i ===
        procoreSubList?.procoreSubcontractorList?.subcontractor.findIndex(
          (ss) => ss.subcontractor_id === s.subcontractor_id,
        ),
    ) ?? [];

  const procoreTrades = procoreTradeList?.procoreTradeList.trade
    .filter(
      (t, i) =>
        i ===
        procoreTradeList.procoreTradeList?.trade.findIndex(
          (tt) => tt.trade_id === t.trade_id,
        ),
    )
    .sort((t1, t2) => compareStringsIgnoreCase(t1.trade_name, t2.trade_name));
  const titlePredictionData: Array<Trade> = [];
  if (gc_edges.length)
    gc_edges[0].node.projects.forEach(async (sub) => {
      sub.project_subcontractors.forEach((p) => {
        if (
          p.procore_subcontractor?.procore_trade_id
        ) {
          const titleTempData = {
            id: p.procore_subcontractor.id,
            procore_trade_id: p.procore_subcontractor.procore_trade_id,
            subcontractor_id: p.subcontractor.pk,
          };

          titlePredictionData.push(titleTempData);
        }
      });
    });

  const renderCompanyRow = (
    fieldPrefix: string,
    companyName: string,
    vendorId: string,
    tradeId: string,
    predictionValue: string,
  ) => {
    return (
      <Row gutter={16} style={{ lineHeight: 2 }} key={fieldPrefix}>
        <Col span={8}>{companyName}</Col>
        <Col span={8}>
          <Form.Item name={fieldPrefix} initialValue={vendorId}>
            <Select
              style={{ width: "100%" }}
              disabled={saving}
              loading={loading || saving}
              //searchValue={loading ? "loading...": undefined}
              showSearch
              optionFilterProp="children"
              filterOption={getNormalSelectOptionsFilter}
              options={procoreSubcontractors.map((t) => {
                return {
                  label: t.subcontractor_name,
                  value: t.subcontractor_id,
                };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            style={
              !tradeId && predictionValue
                ? { marginBottom: "5px" }
                : { marginBottom: "24px" }
            }
            name={`${fieldPrefix}_trade`}
            initialValue={tradeId || predictionValue}
          >
            <Select
              style={{ width: "100%" }}
              disabled={saving}
              loading={tradeListLoading || saving}
              //searchValue={loading ? "loading...": undefined}
              showSearch
              optionFilterProp="children"
              filterOption={getNormalSelectOptionsFilter}
              options={procoreTrades?.map((t) => {
                return {
                  label: t.trade_name,
                  value: t.trade_id,
                };
              })}
            />
          </Form.Item>

          {!tradeId  && !!predictionValue && (
            <div style={{ marginBottom: "19px" }}>
              <text
                style={{
                  borderColor: "#1890ff",
                  color: "#1890ff",
                  // backgroundColor: "#1890ff",
                  borderWidth: 1,
                  borderRadius: 100,
                  fontWeight: 1,
                  fontSize: 10,
                  paddingBottom: 3,
                  paddingRight: 9,
                  paddingLeft: 9,
                }}
              >
                Suggested
              </text>
            </div>
          )}
        </Col>
      </Row>
    );
  };
  const subModalField = subData.map((l) => {
    const tradeId = l.procore_subcontractor?.procore_trade_id ?? "";
    const vendorId = l.procore_subcontractor?.procore_subcontractor_id ?? "";
    const possiblePredictionValue: Array<Trade> = [];
    let predictionValue = "";
    let predicitonValueCount = 0;
    titlePredictionData.forEach((value) => {
      if (
        value.subcontractor_id === l.subcontractor.pk &&
        procoreTrades?.find((t) => t.trade_id === value.procore_trade_id)
      ) {
        possiblePredictionValue.push(value);
      }
    });
    // if (possiblePredictionValue.length)
    //   predictionValue = possiblePredictionValue[0].procore_trade_id;
    // for (var i = 0; i < possiblePredictionValue.length; ) {
    //   for (
    //     var j = i + 1;
    //     possiblePredictionValue[j] === possiblePredictionValue[i];
    //     j++
    //   )
    //     if (predicitonValueCount < j - i) {
    //       predicitonValueCount = j - i;
    //       predictionValue = possiblePredictionValue[i].procore_trade_id;
    //     }
    //   i = j;
    // }
    possiblePredictionValue.sort((a, b) =>
      compareStrings(a.procore_trade_id, b.procore_trade_id),
    );

    predicitonValueCount = 0;
    predictionValue = "";
    let p1 = 0;
    while (p1 < possiblePredictionValue.length) {
      const p2 = p1;
      const { procore_trade_id } = possiblePredictionValue[p2];
      while (
        p1 < possiblePredictionValue.length &&
        possiblePredictionValue[p1].procore_trade_id === procore_trade_id
      )
        p1++;
      if (predicitonValueCount < p1 - p2) {
        predicitonValueCount = p1 - p2;
        predictionValue = possiblePredictionValue[p2].procore_trade_id;
      }
    }
    return renderCompanyRow(
      l.subcontractor.pk,
      l.subcontractor.name,
      vendorId,
      tradeId,
      predictionValue,
    );
  });
  const gcVendorId = gcData.procore_vendor_id ?? "";
  const gcTradeId = gcData.procore_trade_id ?? "";
  const gcModalField = renderCompanyRow(
    "gc_",
    gcData.name,
    gcVendorId,
    gcTradeId,
    "",
  );

  return (
    <Form form={formRef} layout="vertical">
      <Alert
        style={{ margin: "16px 0" }}
        type="info"
        showIcon={false}
        banner
        message={
          <Row gutter={16} style={{ lineHeight: 2 }}>
            <Col span={8}>SiteForm Sub</Col>
            <Col span={8}>Procore Sub</Col>
            <Col span={8}>
              Procore Trade{" "}
              <Tooltip
                color="white"
                placement="top"
                title={
                  <div style={{ color: "black" }}>
                    Selecting the Subcontractor Trade is not required unless you
                    want SiteForm to populate the Subcontractor Trade in your
                    Manpower log - OR - you are creating Work Logs in the
                    SiteForm mobile app that should be added to your DCR in
                    Procore
                  </div>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
        }
      />
      {/*loading && (
        <StyledContent align="center">
          <LoadingContent />
        </StyledContent>
      )*/}
      <>{gcModalField}</>
      <>{subModalField}</>
    </Form>
  );
});

const ProcoreSubConfigModal = forwardRef<
  ProcoreSubConfigModalRef,
  ProcoreSubConfigModalProps
>(({ projectId, GCId, onSubmit }, ref) => {
  const modalRef = useRef<ModalFormRef<FormValues>>(null);
  const [saving, setSaving] = useState(false);
  const [visible, setVisible] = useState(false);
  // Refresh
  const [key, setKey] = useState(0);

  const [upsertProcoreSubcontractor] =
    useAsyncMutation<ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation>(
      graphql`
        mutation ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation(
          $objects: [procore_subcontractor_insert_input!]!
        ) {
          insert_procore_subcontractor(
            objects: $objects
            on_conflict: {
              constraint: procore_subcontractor_project_id_subcontractor_id_key
              update_columns: [procore_subcontractor_id, procore_trade_id]
            }
          ) {
            affected_rows
          }
        }
      `,
    );
  const [updateProcoreGC] =
    useAsyncMutation<ProcoreSubConfigModal_updateProcoreGC_Mutation>(
      graphql`
        mutation ProcoreSubConfigModal_updateProcoreGC_Mutation(
          $id: uuid!
          $_set: general_contractor_set_input!
        ) {
          update_general_contractor(
            where: { employees: { uid: { _eq: $id } } }
            _set: $_set
          ) {
            returning {
              id
            }
          }
        }
      `,
    );

  useImperativeHandle<ProcoreSubConfigModalRef, ProcoreSubConfigModalRef>(
    ref,
    () => ({
      open: () => {
        setKey((v) => v + 1);
        setVisible(true);
      },
    }),
  );
  return (
    <>
      <Modal
        open={visible}
        cancelText="Cancel"
        title="Subcontractor Setup"
        okText="Save"
        width={750}
        confirmLoading={saving}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={async () => {
          const form = modalRef.current?.form;
          if (!form) return;

            const userId = auth.currentUser?.uid;
            if (!userId) {
              throw new Error("User not logged in");
            }
            const values = await form.validateFields().catch(v => null);
            if (!values)
                return;

            // it would be better to select fields that we need (instead of exclude those we do not need)
            // if we add new field to the form - we have to change filtering condition.
            const vendorFields = Object.entries(values).filter(
              ([name]) => !name.endsWith("_trade"),
            );

            setSaving(true);
            try {
              let updates = vendorFields.map(([vendorFieldName, value]) => {
                const vendor_id = zeroToNull(value);

                if (vendorFieldName.startsWith("gc_")) {
                  return updateProcoreGC({
                    variables: {
                      id: userId,
                      _set: {
                        procore_vendor_id: vendor_id,
                        procore_trade_id: zeroToNull(
                          values[vendorFieldName + "_trade"],
                        ),
                      },
                    },
                  });
                } else {
                  const sub_id = vendorFieldName;
                  return upsertProcoreSubcontractor({
                    variables: {
                      objects: [
                        {
                          procore_subcontractor_id: vendor_id,
                          project_id: projectId,
                          subcontractor_id: sub_id,
                          procore_trade_id: zeroToNull(
                            values[vendorFieldName + "_trade"],
                          ),
                        },
                      ],
                    },
                  });
                }
              });

              await Promise.all(updates);

              if (onSubmit) onSubmit();
              setVisible(false);
              message.success("Done");
            } finally {
              setSaving(false);
            }
        }}
      >
        <CustomSuspense>
          <ProcoreSubConfigModalContent
            ref={modalRef}
            key={key}
            projectId={projectId}
            GCId={GCId}
            saving={saving}
            fetchKey={key}
          />
        </CustomSuspense>
      </Modal>
    </>
  );
});

export default ProcoreSubConfigModal;
