import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import Button from "src/common/components/general/button/Button";
import BlockOutDeliveryTimesTableColumnTimeBlock, {
  BlockOutDeliveryTimesTableColumnTimeBlockProps,
} from "./BlockOutDeliveryTimesTableColumnTimeBlock";
import dayjs from "dayjs";
import { TimeRange } from "./type";
import ErrorMessage from "src/utility-features/error-handling/components/ErrorMessage";
import { IconPlus } from "@tabler/icons";
import { TimePicker } from "antd";

const defaultTime: TimeRange = {
  start: dayjs().startOf("day"),
  end: dayjs().hour(23).minute(0),
};

interface BlockOutDeliveryTimesTableColumnProps {
  weekDay: number;
  times: BlockOutDeliveryTimesTableColumnTimeBlockProps[];
  onCreateNewTimeBlock: (time: TimeRange) => void;
}

const BlockOutDeliveryTimesTableColumn: React.FunctionComponent<
  BlockOutDeliveryTimesTableColumnProps
> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [newTime, setNewTime] = useState<TimeRange>(defaultTime);

  const modalOpen = () => {
    setModalVisible(true);
  };

  const modalClose = () => {
    setModalVisible(false);
  };

  const handleTimeSubmit = () => {
    if (newTime) {
      props.onCreateNewTimeBlock(newTime);
    }
    modalClose();
  };

  const timeRangeIsValid = newTime.end.diff(newTime.start) > 0;

  return (
    <div className="flex flex-col justify-between gap-1 ">
      <div className="flex flex-col gap-0.5 ">
        <div className="flex flex-row px-0.75 justify-center items-center">
          <span className="text-0.75 text-center">
            {dayjs.weekdays(false)[props.weekDay]}
          </span>
        </div>
        <div className="text-0.75">
          {props.times.length ? (
            props.times.map((timeBlockProps) => (
              <BlockOutDeliveryTimesTableColumnTimeBlock {...timeBlockProps} />
            ))
          ) : (
            <p className="text-center text-static-secondary">
              No blocked out times
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Button onClick={modalOpen} icon={IconPlus} small />
        <Modal
          open={modalVisible}
          onCancel={modalClose}
          onOk={timeRangeIsValid ? handleTimeSubmit : undefined}
          okButtonProps={{ hidden: !timeRangeIsValid }}
        >
          <div className="flex flex-col items-center justify-center gap-1">
            <p>Set the time range</p>
            <TimePicker.RangePicker
              showHour={true}
              showMinute={true}
              showSecond={false}
              allowClear={false}
              showNow={false}
              minuteStep={15}
              onChange={(timeRange) => {
                console.log('onChange', timeRange);
                if (timeRange)
                  setNewTime((prevState) => {
                    return {
                      end: timeRange[1] || prevState.end,
                      start: timeRange[0] || prevState.start,
                    };
                  });
              }}
              value={[newTime.start, newTime.end]}
            />
            {!timeRangeIsValid && <ErrorMessage message="The range must be at least 15 minutes long" />}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default BlockOutDeliveryTimesTableColumn;
