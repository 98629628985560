import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { VideoPlayerProps, VideoPlayerRef } from "../utils/VideoPlayerTypes";
import Player, { TimeEvent } from "@vimeo/player";
import getVideoFrameUrl from "../utils/getVideoFrameUrl";
import useThrowRenderError from "../../../../../utility-features/error-handling/useThrowRenderError";
import MediaPlayerError from "../../MediaPlayerError";

const VimeoVideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>(
  (
    {
      videoUrl,
      disableControls,
      onWatched,
      onPlay,
      onPause,
      onReady,
      autoPlay,
      onLoadFailed,
    },
    ref,
  ) => {
    const vimeoIframeRef = useRef(null);
    const vimeoPlayerRef = useRef<Player>();
    const stateRef = useRef<boolean>(false);
    const throwRenderError = useThrowRenderError();

    useEffect(() => {
      if (!vimeoIframeRef.current) return;
      const player = new Player(vimeoIframeRef.current, {
        controls: false,
      });
      vimeoPlayerRef.current = player;
      player
        .ready()
        .then(async () => {
          onReady?.();
        })
        .catch((e) => {
          throwRenderError(
            new MediaPlayerError("Vimeo player failed to load", e),
          );
          onLoadFailed?.();
        });
      return () => {
        player.destroy();
        vimeoPlayerRef.current = undefined;
      };
    }, [autoPlay]);

    useEffect(() => {
      const player = vimeoPlayerRef.current;
      if (!player || !onWatched) return;

      const onEnded = async (e: TimeEvent) => {
        console.log("ended", e);
        stateRef.current = false;
        onWatched?.();
      };

      player.on("ended", onEnded);
      return () => {
        player.off("ended", onEnded);
      };
    }, [onWatched]);

    useEffect(() => {
      const player = vimeoPlayerRef.current;
      if (!player || !onPlay) return;

      const playStarted = async (e: TimeEvent) => {
        stateRef.current = true;
        onPlay();
      };

      player.on("play", playStarted);
      return () => {
        player.off("play", playStarted);
      };
    }, [onPlay]);

    useEffect(() => {
      const player = vimeoPlayerRef.current;
      if (!player || !onPause) return;

      const handler = (e: TimeEvent) => {
        console.log("onPause", e);
        stateRef.current = false;
        onPause();
      };

      player.on("pause", handler);
      return () => {
        player.off("pause", handler);
      };
    }, [onPause]);

    const getPlayer = () => {
      const player = vimeoPlayerRef.current;
      if (!player) throw new MediaPlayerError("VimeoPlayer is null");
      return player;
    };

    useImperativeHandle<VideoPlayerRef, VideoPlayerRef>(ref, () => ({
      play: async () => {
        const player = getPlayer();
        await player.play();
      },
      pause: async () => {
        const player = getPlayer();
        await player.pause();
      },
      getDuration: async () => {
        const player = getPlayer();
        return await player.getDuration();
      },
      rewind: async () => {
        const player = getPlayer();
        const currentTime = await player.getCurrentTime();
        const newTime = Math.max(0, currentTime - 30);
        await player.setCurrentTime(newTime);
      },
    }));

    const frame_url = getVideoFrameUrl(videoUrl, autoPlay);

    return (
      <>
        <iframe
          ref={vimeoIframeRef}
          className="absolute top-0 left-0 w-full h-full mh"
          tabIndex={disableControls ? -1 : undefined}
          style={{ maxHeight: "80vh" }} // must be equal to == maxWidth * 56.25%
          src={frame_url}
          allow="autoplay"
          frameBorder="0"
          data-vimeo-controls="0"
        ></iframe>
        {disableControls && (
          <div
            className="absolute top-0 left-0 w-full h-full mh"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const player = vimeoPlayerRef.current;
              if (player) {
                if (stateRef.current) {
                  player.pause();
                } else if (player.play) {
                  player.play();
                }
              }
            }}
          ></div>
        )}
      </>
    );
  },
);

export default VimeoVideoPlayer;
