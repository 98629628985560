import React, { useState, useMemo } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import Button from "src/common/components/general/button/Button";
import RollCallView from "../roll-call-view/RollCallView";
import { useSuspenseQuery } from "@apollo/client";
import {
  GetCrewGroupedWorkerListQuery,
  GetCrewGroupedWorkerListQueryVariables,
  GetCrewGroupedWorkerListDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Input } from "antd";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";
import useCrewSections from "src/domain-features/sitesafety/entryRoutes/qrProjectReports/routes/ptp/utils/useCrewSections";

export interface ReviewInjuriesForPtpSubmissionProps
  extends SiteOrientationStepProps {
  includeCrew?: boolean;
}

const ReviewInjuriesForPtpSubmission: React.FC<
  ReviewInjuriesForPtpSubmissionProps
> = (props) => {
  const langStrings = useCurrentLangStrings();
  const [wasAnyOneInjured, setWasAnyOneInjured] = useState<boolean>();
  const [searchPrompt, setSearchPrompt] = useState<string>("");
  const { ptpQrUser, setPtpQrUser, projectId } = usePtpQr();

  const userId = ptpQrUser.userInfo?.id;
  if (!userId) {
    throw new Error("user id not found");
  }
  const { data } = useSuspenseQuery<
    GetCrewGroupedWorkerListQuery,
    GetCrewGroupedWorkerListQueryVariables
  >(GetCrewGroupedWorkerListDocument, {
    variables: {
      projectId,
      userId: userId,
    },
  });
  if (!data) throw new Error("Crew Workers Data Not Found");
  const workersInfo = ptpQrUser.workersInfo;
  const crewId = data?.crewInfo?.at(0)?.project_crew?.id;
  const { crewSections } = useCrewSections(data.project_worker, crewId);

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        props.onNext();
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={wasAnyOneInjured === undefined}
    >
      <SiteFeatureStepsInputLayout>
        <div className="flex justify-center text-1.25">
          {langStrings.strings.wasAnyoneInjuredLastTimeYouWorked}
        </div>

        <div className="mt-2 flex justify-center items-center space-x-2 ">
          <Button
            large
            delete={wasAnyOneInjured === true}
            label="Yes"
            onClick={() => setWasAnyOneInjured(true)}
            secondary={wasAnyOneInjured !== true}
            textColor={"red"}
          />
          <Button
            large
            green={wasAnyOneInjured === false}
            label="No"
            onClick={() => setWasAnyOneInjured(false)}
            secondary={wasAnyOneInjured !== false}
            textColor={"green"}
          />
        </div>

        {wasAnyOneInjured && (
          <Input
            placeholder={langStrings.strings.searchForUsers}
            value={searchPrompt}
            onChange={(e) => setSearchPrompt(e.target.value)}
          />
        )}

        {wasAnyOneInjured &&
          crewSections.map((section) => {
            return (
              <RollCallView
                header={
                  <>
                    {section.title +
                      (!!crewId && section.crew?.id == crewId
                        ? langStrings.strings.yourCrew
                        : "")}
                  </>
                }
                key={section.key}
                list={section.data.map((worker) => ({
                  project_worker_id: worker.id,
                  name: worker.user?.name ?? "",
                  selected: workersInfo.injuredWorkers.has(worker.id),
                  companyName: worker.hard_hat_number
                    ? "#" + worker.hard_hat_number
                    : "" +
                      (worker.leading_project_crews.length > 0
                        ? `, ${langStrings.strings.crewLead}`
                        : ""),
                  title: worker.title?.translation.en,
                }))}
                onSelectToggle={(user) => {
                  setPtpQrUser((prev) => {
                    const newSet = new Set(prev.workersInfo.injuredWorkers);
                    if (newSet.has(user.project_worker_id))
                      newSet.delete(user.project_worker_id);
                    else newSet.add(user.project_worker_id);
                    return {
                      ...prev,
                      workersInfo: {
                        ...prev.workersInfo,
                        injuredWorkers: newSet,
                      },
                    };
                  });
                }}
                searchPrompt={searchPrompt}
              />
            );
          })}
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default ReviewInjuriesForPtpSubmission;
