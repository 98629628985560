import { Button as AntdButton, Drawer, message } from "antd";
import Button from "src/common/components/general/button/Button";
import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useCopyToClipboard } from "usehooks-ts";
// import useCopyToClipboard
import {
  IconCopy,
  IconDownload,
  IconEye,
  IconEyeOff,
  IconQrcodeOff,
} from "@tabler/icons";
import clsx from "clsx";

export interface OpenQrCodeProps {
  destinationUrl?: string;
  generateQrCodeUrl?: () => Promise<void>; // generates URL
  alwaysVisible?: boolean;
  onlyIcons?: boolean;
  textShowButton?: string;
  copyLinkText?: string;
  imageSettings?: {
    src: string;
    height: number;
    width: number;
    excavate: boolean;
  };
  showQR?: boolean;
  downloadableFileName?: string;
}

export const downloadQRCode = (downloadableFileName: string) => {
  const canvas = document.getElementById(
    downloadableFileName,
  ) as HTMLCanvasElement;
  const magnifiedCanvas = document.createElement("canvas");
  const magnifiedContext = magnifiedCanvas.getContext("2d");
  const magnification = 2.5;
  const magnifiedWidth = canvas.width * magnification;
  const magnifiedHeight = canvas.height * magnification;
  magnifiedCanvas.width = magnifiedWidth;
  magnifiedCanvas.height = magnifiedHeight;
  if (magnifiedContext) magnifiedContext.fillStyle = "white";
  magnifiedContext?.fillRect(0, 0, magnifiedWidth, magnifiedHeight);
  magnifiedContext?.drawImage(canvas, 0, 0, magnifiedWidth, magnifiedHeight);
  const magnifiedDataURL = magnifiedCanvas.toDataURL();
  let downloadLink = document.createElement("a");
  downloadLink.href = magnifiedDataURL;
  downloadLink.download = downloadableFileName + ".png";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const OpenQrCode: React.FunctionComponent<OpenQrCodeProps> = ({
  destinationUrl,
  generateQrCodeUrl,
  alwaysVisible,
  showQR,
  onlyIcons,
  textShowButton,
  downloadableFileName,
  imageSettings,
  copyLinkText,
}) => {
  const [showImage, setShowImage] = useState(alwaysVisible);
  const [generating, setGenerating] = useState(false);
  const [previewQR, setPreviewQR] = useState(false);
  const [_, copy] = useCopyToClipboard();
  const [messageApi, contextHolder] = message.useMessage();

  const showMessageOnCopy = () => {
    messageApi.success(`Link copied`);
  };

  const qrCodeShown = !!destinationUrl && (showImage || !!alwaysVisible);

  const onGenerateQrClick = async () => {
    if (!generateQrCodeUrl) {
      return;
    }
    setGenerating(true);

    await generateQrCodeUrl();
    message.success(`QR Code generated`);
    setGenerating(false);
  };

  return (
    <div
      className={clsx(
        `w-full flex flex-col`,
        showImage && textShowButton ? "mx-1 mt-2" : "",
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {contextHolder}
      {(showImage || !onlyIcons) && (
        <div
          className={`w-full relative h-0  pb-[100%] rounded-0.5 ${
            showImage && textShowButton ? "mb-2" : ""
          }`}
        >
          {/* QR Code container */}
          <div
            className={clsx(
              `w-full absolute h-full flex flex-col gap-0.5 items-center justify-center`,
              qrCodeShown ? "bg-transparent" : "bg-suplementary-2",
            )}
          >
            {!!destinationUrl ? (
              showImage ? (
                <QRCodeCanvas
                  className="w-full h-full inset-0 cursor-pointer"
                  value={destinationUrl}
                  imageSettings={imageSettings}
                  id={downloadableFileName}
                  bgColor={`transparent`}
                  onClick={() => {
                    if (!showQR) window.open(destinationUrl, "_blank");
                  }}
                  size={800}
                  style={{ width: "200px", height: "200px" }}
                />
              ) : (
                !onlyIcons && (
                  <IconEyeOff size={`4rem`} className={`opacity-20`} />
                )
              )
            ) : (
              <IconQrcodeOff size={`4rem`} className={`opacity-20`} />
            )}
          </div>
        </div>
      )}
      <div className={`flex flex-col gap-0.5`}>
        {!destinationUrl && !!generateQrCodeUrl && (
          <Button loading={generating} onClick={onGenerateQrClick}>
            Generate QR Code
          </Button>
        )}
        {destinationUrl ? (
          onlyIcons ? (
            <div
              className={clsx(
                "flex gap-1",
                !textShowButton && "justify-center",
              )}
            >
              {textShowButton ? (
                <div
                  className={clsx(
                    "flex flex-col justify-start items-start",
                    showImage && "-mx-1",
                  )}
                >
                  <AntdButton
                    type="text"
                    onClick={() => {
                      setShowImage((v) => !v);
                    }}
                  >
                    {textShowButton}
                  </AntdButton>
                  {copyLinkText && (
                    <AntdButton
                      type="text"
                      onClick={() => {
                        if (!!destinationUrl) {
                          copy(destinationUrl);
                          showMessageOnCopy();
                        }
                      }}
                    >
                      {copyLinkText}
                    </AntdButton>
                  )}
                </div>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      setShowImage((v) => !v);
                    }}
                    icon={showImage ? IconEyeOff : IconEye}
                  />
                  <Button
                    onClick={() => {
                      if (!!destinationUrl) {
                        copy(destinationUrl);
                        showMessageOnCopy();
                      }
                    }}
                    icon={IconCopy}
                  />
                </>
              )}
            </div>
          ) : (
            <>
              <div className="flex justify-center mt-1">
                {showQR ? (
                  <>
                    <Button
                      onClick={() => {
                        setPreviewQR((v) => !v);
                      }}
                    >
                      Show QR
                    </Button>
                    <Drawer
                      width={1300}
                      open={previewQR}
                      placement="left"
                      onClose={() => setPreviewQR(false)}
                    >
                      <QRCodeCanvas
                        className="m-auto mt-0.5"
                        value={destinationUrl}
                        size={1300}
                        style={{ width: "850px", height: "850px" }}
                      />
                    </Drawer>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      if (!!destinationUrl) {
                        copy(destinationUrl);
                        showMessageOnCopy();
                      }
                    }}
                    icon={IconCopy}
                  >
                    Copy Link
                  </Button>
                )}
                {downloadableFileName && (
                  <div className="ml-0.5">
                    <Button
                      onClick={() => downloadQRCode(downloadableFileName)}
                      icon={IconDownload}
                    ></Button>
                  </div>
                )}
              </div>
              {!alwaysVisible && (
                <>
                  <Button
                    onClick={() => {
                      setShowImage((v) => !v);
                    }}
                    icon={!showImage ? IconEye : IconEyeOff}
                  >
                    {!showImage ? `Show QR Code` : `Hide QR Code`}
                  </Button>
                </>
              )}
            </>
          )
        ) : null}
      </div>
    </div>
  );
};

export default OpenQrCode;
