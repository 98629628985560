import { IconCirclePlus } from "@tabler/icons";
import { graphql } from "babel-plugin-relay/macro";
import React, { useEffect } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutlet,
  useParams,
} from "react-router-dom";
import NavigationPlaceholder from "src/common/components/NavigationPlaceholder";
import Button from "src/common/components/general/button/Button";
import ContactLink from "src/common/components/general/ContactLink";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import NavigationWrapper, {
  NavigationMenuProps,
} from "src/common/components/layouts/NavigationWrapper";
import useAuthUser from "src/common/hooks/useAuthUser";
import { GCProjectsQuery } from "src/common/types/generated/relay/GCProjectsQuery.graphql";

export interface GCProjectsProps {}

const GCProjects: React.FunctionComponent<GCProjectsProps> = (props) => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();
  // typescript parameter name validated
  const selectedProjectId = useParams().projectId;
  const authUser = useAuthUser();
  const projectsListData = useLazyLoadQuery<GCProjectsQuery>(
    graphql`
      query GCProjectsQuery($uid: uuid!) {
        general_contractor_employee_connection(where: { uid: { _eq: $uid } }) {
          edges {
            node {
              employee_projects {
                project {
                  pk: id @__clientField(handle: "pk")
                  completed
                  name
                }
              }
            }
          }
        }
      }
    `,
    { uid: authUser.uid },
  );

  const getProjects = () => {
    const edges = projectsListData.general_contractor_employee_connection.edges;
    if (edges.length === 0) return [];
    //throw new Error("User does not exist");
    return edges[0].node.employee_projects.map(({ project }) => ({
      name: project.name,
      completed: project.completed,
      id: project.pk,
      selected: project.pk === selectedProjectId,
    }));
  };
  const { projectId } = useParams();
  const projects = getProjects();

  const menu: NavigationMenuProps = {
    navigationItems: projects
      .sort(
        (
          { name: name1, completed: completed1 },
          { name: name2, completed: completed2 },
        ) => {
          const complrassion =
            completed1 !== completed2
              ? +completed1 * 2 - 1
              : name1.toLowerCase().localeCompare(name2.toLowerCase());

          return complrassion;
        },
      )
      .map((project) => ({
        label: (project.completed ? "✓ " : "") + project.name,
        rootPath: project.id,
        onClick: () => {
          const subRoutes = location.pathname
            .replace("/gce/projects", "")
            .split("/");
          let subRoute = "";
          if (subRoutes.length > 2) {
            subRoute = "/" + subRoutes[2];
          }
          navigate(`${project.id}${subRoute}`);
        },
      })),
    utilityItems: [
      {
        component: (
          <Button
            onClick={() => {
              // setAddingNewProject(true);
              navigate("new-project");
            }}
            icon={IconCirclePlus}
            label="Add new project"
          />
        ),
      },
    ],
  };

  useEffect(() => {
    const defaultProjectRedirect = async () => {
      if (projects.length && projects[0]) {
        navigate(`${projects[0].id}`);
      }
    };

    if (!selectedProjectId) {
      defaultProjectRedirect();
    }
  }, []);

  return (
    <NavigationWrapper menu={menu}>
      {(!projectId || projects.find((p) => p.id === projectId)) && outlet ? (
        <Outlet />
      ) : !!projects.length ? (
        <NavigationPlaceholder message={`Select a project`} />
      ) : (
        <div className={`w-full h-full flex items-center justify-center`}>
          <div className="flex flex-col items-center gap-1.5">
            <p className="text-1.5 text-center items-center flex justify-center flex-col">
              We have not assigned you to a project yet! <br />
              <div className="w-4/6 font-normal">
                Select “Add new project” in the left side menu to create a NEW
                project or contact your company’s SiteForm administrator to add
                you to an existing project.
              </div>
              <br />
              Please contact us if you have any questions.
            </p>
            <div className="flex flex-row gap-1">
              <ContactLink type="email" value="support@siteform.io" />
              <ContactLink type="phone-number" value="352-328-1513" />
            </div>
          </div>
        </div>
      )}
    </NavigationWrapper>
  );
};

export default withCustomSuspense(GCProjects);
