import { Outlet } from "react-router-dom";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import OrientationTestView from "./OrientationTestView";

//Used for test orientation module flow.

const orientationTestRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: ":workerId/slides/:type",
      element: <OrientationTestView />,
    },
  ],
};

export default orientationTestRoute;
