import { DatePicker, Form, Input, Modal, Select, Spin } from "antd";
import { Dayjs } from "dayjs";
import React, { useState } from "react";
import apolloClient from "src/common/api/apollo/apolloClient";
import capitalize from "src/common/functions/capitalize";
import {
  GetNameUsernameDocument,
  GetNameUsernameQuery,
  GetNameUsernameQueryVariables,
  useGetProjectWorkerRolesQuery,
  useGetWorkerTitlesQuery,
} from "src/common/types/generated/apollo/graphQLTypes";

export interface AddWorkerValues {
  name: string;
  workerRole: string;
  birthDate?: Dayjs;
  titleId: string;
  phoneNumber?: string;
  email?: string;
  username: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  onCreate: (values: AddWorkerValues) => Promise<void>;
  onCancel: () => void;
}

const CreateWorkerModal: React.FC<CollectionCreateFormProps> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [gettingUsername, setGettingUsername] = useState(false);
  const { data, loading } = useGetProjectWorkerRolesQuery();
  const { data: titleData, loading: titlesLoading } = useGetWorkerTitlesQuery();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={visible}
      title="Create a new worker"
      okText="Add"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={async () => {
        const values = await form.validateFields().catch(v => null);
        if (!values)
            return;
        setGettingUsername(true);
        const { data } = await apolloClient.query<
          GetNameUsernameQuery,
          GetNameUsernameQueryVariables
        >({
          query: GetNameUsernameDocument,
          variables: {
            name: values.name,
          },
        });
        setGettingUsername(false);
        if (data.getNewUsername) {
          form.resetFields();
          await onCreate({
            ...values,
            username: data.getNewUsername,
          } as AddWorkerValues);
        }
      }}
    >
      {/* <p>
        New workers will be prompted to create a password for themselves on the
        SiteForm app if it is their first time logging in.
      </p> */}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="name"
          label="First and last name"
          rules={[{ required: true, message: "Enter the name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="workerRole"
          label="Worker current role"
          rules={[
            { required: true, message: `Choose the worker's current role` },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            disabled={loading}
            loading={loading}
          >
            {data?.worker_role.map((c) => (
              <Select.Option key={c.value} value={c.value}>
                {capitalize(c.value)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="titleId"
          label="Worker trade"
          rules={[
            { required: true, message: `Choose the worker's current trade` },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            disabled={titlesLoading}
            loading={titlesLoading}
          >
            {titleData?.worker_title.map((t) => (
              <Select.Option key={t.id} value={t.id}>
                {t.translation.en}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="birthDate" label="Birth date">
          <DatePicker placeholder={`YYYY-MM-DD`} />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Phone number">
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email Address">
          <Input type="email" autoCorrect="off" autoCapitalize="none" />
        </Form.Item>
      </Form>
      {gettingUsername && <Spin />}
    </Modal>
  );
};

export default CreateWorkerModal;
