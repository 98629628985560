/**
 * @generated SignedSource<<596396f301c26913396e7e5da3746b65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type ProcoreTradeConfigModal_Trade_Query$variables = {
  projectId: string;
};
export type ProcoreTradeConfigModal_Trade_Query$data = {
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly general_contractor: {
          readonly projects: ReadonlyArray<{
            readonly procore_project_data: {
              readonly procore_company_id: string | null | undefined;
              readonly procore_project_id: string | null | undefined;
              readonly procore_project_name: string | null | undefined;
            } | null | undefined;
            readonly procore_trade: ReadonlyArray<{
              readonly id: string;
              readonly procore_trade_id: string | null | undefined;
              readonly project_id: string;
              readonly title_id: string;
            }>;
          }>;
        };
        readonly id: string;
        readonly procore_project_data: {
          readonly procore_project_id: string | null | undefined;
          readonly procore_project_name: string | null | undefined;
          readonly project_id: string;
        } | null | undefined;
        readonly procore_trade: ReadonlyArray<{
          readonly id: string;
          readonly procore_trade_id: string | null | undefined;
          readonly project_id: string;
          readonly title_id: string;
        }>;
      };
    }>;
  };
};
export type ProcoreTradeConfigModal_Trade_Query = {
  response: ProcoreTradeConfigModal_Trade_Query$data;
  variables: ProcoreTradeConfigModal_Trade_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_project_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_company_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_project_name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "procore_trade",
  "kind": "LinkedField",
  "name": "procore_trade",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "procore_trade_id",
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title_id",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcoreTradeConfigModal_Trade_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor",
                    "kind": "LinkedField",
                    "name": "general_contractor",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "projects",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "procore_project_data",
                            "kind": "LinkedField",
                            "name": "procore_project_data",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "procore_project_data",
                    "kind": "LinkedField",
                    "name": "procore_project_data",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProcoreTradeConfigModal_Trade_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor",
                    "kind": "LinkedField",
                    "name": "general_contractor",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "projects",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "procore_project_data",
                            "kind": "LinkedField",
                            "name": "procore_project_data",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "procore_project_data",
                    "kind": "LinkedField",
                    "name": "procore_project_data",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c5ef8f4ec8d8020def406b0990d2f39a",
    "id": null,
    "metadata": {},
    "name": "ProcoreTradeConfigModal_Trade_Query",
    "operationKind": "query",
    "text": "query ProcoreTradeConfigModal_Trade_Query(\n  $projectId: uuid!\n) {\n  project_connection(where: {id: {_eq: $projectId}}) {\n    edges {\n      node {\n        id\n        general_contractor {\n          projects {\n            procore_project_data {\n              procore_project_id\n              procore_company_id\n              procore_project_name\n              id\n            }\n            procore_trade {\n              id\n              procore_trade_id\n              project_id\n              title_id\n            }\n            id\n          }\n          id\n        }\n        procore_project_data {\n          project_id\n          procore_project_id\n          procore_project_name\n          id\n        }\n        procore_trade {\n          id\n          procore_trade_id\n          project_id\n          title_id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2391a0b4de37b9d12759e049996b393";

export default node;
