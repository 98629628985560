import { useMemo } from "react";
import compareStringsIgnoreCase from "src/common/functions/compareStringsIgnoreCase";
import { GetCrewGroupedWorkerListQuery } from "src/common/types/generated/apollo/graphQLTypes";

type Item = NonNullable<
  GetCrewGroupedWorkerListQuery["project_worker"][number]
>;
type Crew = Item["project_crew"];
type CrewData = {
  crew?: Crew | null;
  title: string;
  data: Array<Item>;
};

const useCrewSections = (projectWorkers: Item[], crewId?: string) => {
  const isSelfCrew = (c: string) => c === crewId;

  const { crewSections, projWorkersCrewIdMap } = useMemo(() => {
    const projWorkersCrewIdMap = new Map<string, string>();

    const crewSections = Object.entries(
      projectWorkers.reduce(
        (a, w) => {
          if (w.project_crew) projWorkersCrewIdMap.set(w.id, w.project_crew.id);
          if (w.project_crew && !w.project_crew.deleted_at) {
            const crew = w.project_crew;
            if (!a[crew.id]) {
              a[crew.id] = {
                crew,
                data: [w],
                title:
                  crew.name ||
                  (crew.lead_foreman_project_worker
                    ? crew.lead_foreman_project_worker.user!.name
                    : ""),
              };
            } else {
              a[crew.id].data.push(w);
            }
          } else {
            a.no_crew.data.push(w);
          }
          return a;
        },
        {
          no_crew: {
            data: [],
            title: "Others on this project",
            crew: null,
          },
        } as { [crewKey: string]: CrewData },
      ),
    )
      .map(([crewKey, v]) => ({
        ...v,
        key: crewKey,
        data: v.data.sort((a, b) => compareStringsIgnoreCase(a.user!.name, b.user!.name)),
        // user won't be null because project_worker has fk to worker and worker has fk to user.
      }))
      .sort((a, b) => {
        if (!a.crew) return 1;
        if (!b.crew) return -1;
        if (a.crew && isSelfCrew(a.crew.id)) return -1;
        if (b.crew && isSelfCrew(b.crew.id)) return 1;
        return a.title.localeCompare(b.title);
      });

    return { crewSections, projWorkersCrewIdMap };
  }, [projectWorkers, crewId]);
  return { crewSections, projWorkersCrewIdMap };
};

export default useCrewSections;
