import dayjs from "dayjs";
import React, { FC, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "src/common/components/general/button/BackButton";
import Button from "src/common/components/general/button/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import DateProps from "src/common/types/manual/DateProps";
import ProjectProps from "src/common/types/manual/ProjectProps";
import PermitTable, { ColumnKeys } from "../../../../../components/PermitTable";
import CardWrapper from "../../../components/CardWrapper";
import DatesNavigation from "../../../components/DatesNavigation";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { QrProjectPermitsQuery } from "src/common/types/generated/relay/QrProjectPermitsQuery.graphql";
import capitalize from "src/common/functions/capitalize";

const query = graphql`
  query QrProjectPermitsQuery($projectId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          general_contractor {
            id
            hot_work_permit_name
          }
        }
      }
    }
  }
`;

const QrProjectPermits: FC<ProjectProps & DateProps> = ({
  projectId,
  date,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const permitType = searchParams.get("permitType");
  const location = useLocation();
  const data = useLazyLoadQuery<QrProjectPermitsQuery>(query, {
    projectId,
  });

  const hotWorkPermitName =
    data?.project_connection.edges[0].node.general_contractor
      .hot_work_permit_name;
  const [toggle, setToggle] = useState(
    dayjs(date).format() !== dayjs().startOf("day").format(),
  );
  console.log(date, dayjs(date));

  const onDateChange = (newDate: dayjs.Dayjs) => {
    navigate(`/qr/p/${projectId}/permits/date/${newDate.toISOString()}`);
  };
  const permitWhere = {
    project_id: { _eq: projectId },
    is_historical: { _eq: false },
    ...(permitType ? { type: { _eq: permitType } } : {}),
  };
  const permitTitle = permitType
    ? `${permitType.replaceAll("_", " ")} permits`
    : "permits";
  return (
    <MobileViewWrapper
      footer={
        <>
          {!!toggle && (
            <DatesNavigation date={dayjs(date)} onDateChange={onDateChange} />
          )}
          <div className="flex flex-row items-center gap-1">
            <BackButton url={`/qr/p/${projectId}`} fullWidth />
            <Button
              fullWidth
              label={
                toggle
                  ? `Show active ${permitTitle}`
                  : `See ${permitTitle} by date`
              }
              onClick={() => setToggle(!toggle)}
            />
          </div>
        </>
      }
    >
      <CardWrapper>
        {!toggle ? (
          <PermitTable
            excludedKeys={[
              "project",
              ...((permitType ? ["type"] : []) as ColumnKeys[]),
            ]}
            title={capitalize(permitTitle)}
            defaultSortColumnKey="status"
            defaultSortOrder="desc"
            hotWorkPermitName={hotWorkPermitName}
            where={{
              ...permitWhere,
              current_status: {
                _nin: ["pending", "closed"],
              },
            }}
            onRowClick={({ type, pk }) => {
              navigate(`${location.pathname}/${type}/${pk}`, {
                state: {
                  permitId: pk,
                },
              });
            }}
          />
        ) : (
          <PermitTable
            excludedKeys={[
              "project",
              "date",
              ...((permitType ? ["type"] : []) as ColumnKeys[]),
            ]}
            loadAll
            title={capitalize(permitTitle)}
            defaultCustomInitialSort={true}
            defaultSortColumnKey="status"
            hotWorkPermitName={hotWorkPermitName}
            where={{
              ...permitWhere,
              updated_at: {
                _gte: dayjs
                  .tz(date, "America/New_York")
                  .startOf("day")
                  .format(),
                _lte: dayjs.tz(date, "America/New_York").endOf("day").format(),
              },
              current_status: {
                _nin: ["pending"],
              },
            }}
            onRowClick={({ type, pk }) => {
              navigate(`${location.pathname}/${type}/${pk}`, {
                state: {
                  permitId: pk,
                },
              });
            }}
          />
        )}
      </CardWrapper>
    </MobileViewWrapper>
  );
};

export default withCustomSuspense(QrProjectPermits);
