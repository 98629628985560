import { Alert, Card, Form, Input, Switch, Typography } from "antd";
import React, { useState } from "react";
import ChartWrapperCard from "../../../../../common/components/charts/ChartWrapperCard";

const ChartWrapperCardBuilder: React.FC = () => {
  const [title, setTitle] = useState("");
  const [extraTopText, setExtraTopText] = useState("");
  const [extraBottomText, setExtraBottomText] = useState("");
  const [chartVisible, setChartVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  return (
    <div className={`grid desktop:grid-cols-2 gap-1`}>
      <Card title={`Chart Wrapper Card Builder`}>
        <Typography.Paragraph>
          <Typography.Text code>ChartWrapperCard</Typography.Text> is a card
          component for wrapping any chart component.
        </Typography.Paragraph>
        <div>
          <Form.Item layout={"vertical"} label="Chart Title">
            <Input
              placeholder="Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </Form.Item>
          <Form.Item layout={"vertical"} label="Extra Top Elements">
            <Input
              placeholder="Text"
              value={extraTopText}
              onChange={(e) => setExtraTopText(e.target.value)}
            />
          </Form.Item>
          <Form.Item layout={"vertical"} label="Extra Bottom Elements">
            <Input
              placeholder="Text"
              value={extraBottomText}
              onChange={(e) => setExtraBottomText(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            layout={"vertical"}
            label="Chart Present"
            valuePropName="checked"
          >
            <Switch value={chartVisible} onChange={setChartVisible} />
          </Form.Item>
          <Form.Item label={`Loading`} layout={"vertical"}>
            <Switch checked={loading} onChange={setLoading} />
          </Form.Item>
        </div>
      </Card>
      <div className={`w-full`}>
        <ChartWrapperCard
          {...{
            title: title,
            extraTop: extraTopText,
            extraBottom: extraBottomText,
            loading: loading,
          }}
        >
          {chartVisible ? (
            <Alert message="Place for any chart component." type="info" />
          ) : null}
        </ChartWrapperCard>
      </div>
    </div>
  );
};

export default ChartWrapperCardBuilder;
