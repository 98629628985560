import { Button, Modal } from "antd";
import React, { FC, useRef, useState } from "react";
import { auth } from "src/common/functions/firebase";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import CustomButton from "src/common/components/general/button/Button";
import { IconBackhoe, IconBooks } from "@tabler/icons";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import uploadFiles from "src/common/functions/upload-utility/uploadFiles";
import { usePdfToImageMutation } from "src/common/types/generated/apollo/graphQLTypes";
import AddToolboxTalkPdfModal from "./AddToolboxTalkPdfModal";
import UploadedToolboxTalkTable from "./UploadedToolboxTalkTable";
import {
  ManageToolboxTalksMutation,
  uploaded_toolbox_talk_bool_exp,
} from "src/common/types/generated/relay/ManageToolboxTalksMutation.graphql";
import UploadedToolboxTalk from "./UploadedToolboxTalk";

export const insertUploadedTBTMutation = graphql`
  mutation ManageToolboxTalksMutation(
    $object: uploaded_toolbox_talk_insert_input!
  ) {
    insert_uploaded_toolbox_talk_one(object: $object) {
      id
    }
  }
`;
const ManageToolboxTalks: FC<{
  subId?: string;
  projectId?: string;
  projectsOption?: Array<{ id: string; name: string }>;
  subsOption?: Array<{ id: string; name: string }>;
  gcId?: string;
}> = ({ subId, projectId, gcId, ...props }) => {
  const [loading, setLoading] = useState(false);
  const userId = auth.currentUser?.uid;
  const tableRef = useRef<DataScrollTableRef>(null);
  const [pdfToImage] = usePdfToImageMutation();

  const [toolboxTalk, setToolboxTalk] = useState<string | undefined>(undefined);
  const [addTbt, setAddTbt] = useState(false);
  const [community, setCommunity] = useState(false);
  const [insertUploadedTBT] = useAsyncMutation<ManageToolboxTalksMutation>(
    insertUploadedTBTMutation,
  );
  const [addModal, setAddModal] = useState(false);
  const libraryWhere: uploaded_toolbox_talk_bool_exp = {
    ...(gcId
      ? {
          general_contractor_id: { _eq: gcId },
          uploaded_toolbox_talk_projects: {
            project_id: { _eq: projectId },
          },
        }
      : subId && projectId
      ? {
          subcontractor_id: { _eq: subId },
          _or: [
            { assign_to: { _eq: "all-projects" } },
            {
              uploaded_toolbox_talk_projects: {
                project_id: { _eq: projectId },
              },
            },
          ],
        }
      : subId
      ? { subcontractor_id: { _eq: subId } }
      : { id: { _is_null: true } }),
  };
  const handleTBTClose = () => {
    setToolboxTalk(undefined);
  };
  return (
    <>
      {toolboxTalk && (
        <Modal
          open={true}
          width={1200}
          footer={<Button onClick={handleTBTClose}>Cancel</Button>}
          onCancel={handleTBTClose}
          title="Toolbox Talk"
        >
          <UploadedToolboxTalk
            subs={props.subsOption}
            projects={props.projectsOption}
            community={community}
            gcId={gcId}
            onFinish={() => {
              handleTBTClose();
              setCommunity(false);
            }}
            uploaded_toolbox_talk_id={toolboxTalk}
            projectId={projectId}
            subId={subId}
          />
        </Modal>
      )}
      <Modal
        open={addModal}
        onCancel={() => setAddModal(false)}
        footer={
          <Button onClick={() => setToolboxTalk(undefined)}>Cancel</Button>
        }
      >
        <UploadedToolboxTalkTable
          where={{
            archived_at: { _is_null: true },
            assign_to: { _is_null: true },
            subcontractor_id: { _eq: subId },
            _not: {
              uploaded_toolbox_talk_projects: {
                project_id: { _eq: projectId },
              },
            },
          }}
          hideFiltersAndSort
          projectId={projectId}
          onAddFromAnotherProjectToThisProjectFinish={() => {
            setAddModal(false);
            tableRef.current?.refetch();
          }}
          excludedKeys={[
            "createdBy",
            "date",
            "due_date",
            "date",
            "general_contractor",
            "presented",
            "project",
            "subcontractor",
            "workers",
          ]}
          userId={userId!}
        />
      </Modal>
      <AddToolboxTalkPdfModal
        visible={addTbt}
        projectId={projectId}
        subId={subId}
        subs={props.subsOption}
        projects={props.projectsOption}
        loading={loading}
        onCancel={() => setAddTbt(false)}
        onCreate={async (values) => {
          // TODO uploads to image container?
          setLoading(true);
          try {
            const images = [];
            const content = values.content
              ? values.content.trim().replace(/\s(?=\s)/g, "")
              : null;

            if (values.toolboxTalkPdfObjects) {
              const [files] = await uploadFiles(
                values.toolboxTalkPdfObjects.map((o) => o.originFileObj),
              );
              if (files[0]?.url)
                if (
                  values.toolboxTalkPdfObjects[0].originFileObj.name.includes(
                    ".pdf",
                  )
                ) {
                  await pdfToImage({
                    variables: { input: { pdf_link: files[0].url } },
                  }).then((d) => {
                    if (d.data?.pdfToImage) {
                      images.push(...d.data.pdfToImage.images);
                    }
                  });
                } else {
                  images.push(files[0].url);
                }
            } else if (values.images) {
              const [files] = await uploadFiles(
                values.images.map((o) => o.originFileObj),
              );
              files.forEach((t) => {
                images.push(t.url);
              });
            }

            await insertUploadedTBT({
              variables: {
                object: {
                  subcontractor_id: subId,
                  created_by_uid: userId,
                  added_to_community: values.addToCommunityLib,
                  name: values.name,
                  ...(content
                    ? { content: { data: { original: content } } }
                    : {}),
                  ...(values.dueDate
                    ? {
                        due_time: dayjs(values.dueDate)
                          .endOf("d")
                          .toISOString(),
                      }
                    : {}),
                  images: {
                    data: images.map((imageUrl, i) => ({
                      url: imageUrl,
                      sort_index: i,
                      description: "Toolbox Talk",
                    })),
                  },
                  ...(gcId
                    ? {
                        general_contractor_id: gcId,
                        uploaded_toolbox_talk_projects: {
                          data: [{ project_id: projectId }],
                        },
                        ...((values.subsSelected || []).length === 0
                          ? { assign_to: "all-subcontractors" }
                          : {
                              uploaded_toolbox_talk_subcontractors: {
                                data: (values.subsSelected || []).map((s) => ({
                                  subcontractor_id: s,
                                })),
                              },
                            }),
                      }
                    : subId && projectId
                    ? {
                        subcontractor_id: subId,
                        uploaded_toolbox_talk_projects: {
                          data: [{ project_id: projectId }],
                        },
                      }
                    : subId
                    ? {
                        subcontractor_id: subId,
                        ...((values.projectsSelected || []).length === 0
                          ? { assign_to: "all-projects" }
                          : {
                              uploaded_toolbox_talk_projects: {
                                data: (values.projectsSelected || []).map(
                                  (s) => ({
                                    project_id: s,
                                  }),
                                ),
                              },
                            }),
                      }
                    : {}),
                  //if projectId is present then either GC or subcontractor Id will be there
                },
              },
            }).then(console.log);
            tableRef.current && tableRef.current.refetch();
          } catch (e) {
            throw e;
          }
          setLoading(false);
          setAddTbt(false);
        }}
      />
      <UploadedToolboxTalkTable
        ref={tableRef}
        headerComponent={
          <div className="items-end justify-end flex gap-1">
            {subId && projectId && (
              <CustomButton
                label="Add From your other Project TBTs"
                onClick={() => {
                  setAddModal(true);
                }}
              />
            )}
            <CustomButton
              label={
                community
                  ? projectId
                    ? "Back to Project Toolbox Talk Library"
                    : "Back to Subcontractor Toolbox Talk Library"
                  : "Community Toolbox Talk Library"
              }
              onClick={() => {
                setCommunity((i) => !i);
              }}
              icon={community ? IconBackhoe : IconBooks}
            />
          </div>
        }
        topBarButtons={
          community
            ? []
            : [
                {
                  label: "+ Add",
                  onClick: () => setAddTbt(true),
                  loading: loading,
                },
              ]
        }
        excludedKeys={
          community
            ? ["due_date", "createdBy", "subcontractor", "project"]
            : gcId
            ? ["project"]
            : ["subcontractor"]
        }
        subId={subId}
        community={community}
        projectId={projectId}
        gcId={gcId}
        userId={userId!}
        where={{
          archived_at: { _is_null: true },
          ...(community ? { added_to_community: { _eq: true } } : libraryWhere),
        }}
        onRowClick={(pk) => setToolboxTalk(pk)}
      />
    </>
  );
};

export default ManageToolboxTalks;
