/**
 * @generated SignedSource<<06b73b1e1f6104e3d8c7065bdc84c24f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_worker_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CrewProjectWorkerOptions_project_worker_connection_frag_refetch$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  projectWorkerWhere: project_worker_bool_exp;
};
export type CrewProjectWorkerOptions_project_worker_connection_frag_refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CrewProjectWorkerOptions_project_worker_connection_frag">;
};
export type CrewProjectWorkerOptions_project_worker_connection_frag_refetch = {
  response: CrewProjectWorkerOptions_project_worker_connection_frag_refetch$data;
  variables: CrewProjectWorkerOptions_project_worker_connection_frag_refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 100000,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectWorkerWhere"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "user": {
        "name": "asc"
      }
    }
  },
  {
    "fields": [
      {
        "items": [
          {
            "kind": "Variable",
            "name": "_and.0",
            "variableName": "projectWorkerWhere"
          },
          {
            "kind": "Literal",
            "name": "_and.1",
            "value": {
              "deleted_at": {
                "_is_null": true
              }
            }
          }
        ],
        "kind": "ListValue",
        "name": "_and"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CrewProjectWorkerOptions_project_worker_connection_frag_refetch",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "projectWorkerWhere",
            "variableName": "projectWorkerWhere"
          }
        ],
        "kind": "FragmentSpread",
        "name": "CrewProjectWorkerOptions_project_worker_connection_frag"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CrewProjectWorkerOptions_project_worker_connection_frag_refetch",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hard_hat_number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_crew_aggregate",
                    "kind": "LinkedField",
                    "name": "leading_project_crews_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_crew_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker_title",
                    "kind": "LinkedField",
                    "name": "title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "translation",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "en",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_crew",
                    "kind": "LinkedField",
                    "name": "project_crew",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_worker",
                        "kind": "LinkedField",
                        "name": "lead_foreman_project_worker",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "user",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phone_number",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "CrewProjectWorkerOptions_project_worker_connection",
        "kind": "LinkedHandle",
        "name": "project_worker_connection"
      }
    ]
  },
  "params": {
    "cacheID": "321aa2a69f74e8d99092e48d7375b313",
    "id": null,
    "metadata": {},
    "name": "CrewProjectWorkerOptions_project_worker_connection_frag_refetch",
    "operationKind": "query",
    "text": "query CrewProjectWorkerOptions_project_worker_connection_frag_refetch(\n  $after: String\n  $first: Int = 100000\n  $projectWorkerWhere: project_worker_bool_exp!\n) {\n  ...CrewProjectWorkerOptions_project_worker_connection_frag_4w2XwR\n}\n\nfragment CrewProjectWorkerOptions_project_worker_connection_frag_4w2XwR on query_root {\n  project_worker_connection(where: {_and: [$projectWorkerWhere, {deleted_at: {_is_null: true}}]}, order_by: {user: {name: asc}}, first: $first, after: $after) {\n    edges {\n      node {\n        id\n        pk: id\n        hard_hat_number\n        leading_project_crews_aggregate {\n          aggregate {\n            count\n          }\n        }\n        title {\n          translation {\n            en\n            id\n          }\n          id\n        }\n        subcontractor {\n          id\n          pk: id\n          name\n        }\n        project_crew {\n          id\n          pk: id\n          name\n          lead_foreman_project_worker {\n            id\n            pk: id\n            user {\n              name\n              id\n            }\n          }\n        }\n        user {\n          id\n          pk: id\n          name\n          email\n          phone_number\n          username\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "400b987cdd8c52e172b82f1d1b3663c5";

export default node;
