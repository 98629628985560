import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import useYoutubePlayer, { StateChangeEvent } from "./useYoutubePlayer";
import { VideoPlayerProps, VideoPlayerRef } from "../utils/VideoPlayerTypes";
import getVideoFrameUrl from "../utils/getVideoFrameUrl";
import MediaPlayerError from "../../MediaPlayerError";

const YoutubeVideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>(
  (
    {
      videoUrl,
      disableControls,
      onWatched,
      onPlay,
      onPause,
      autoPlay,
      onReady,
      onLoadFailed,
    },
    ref,
  ) => {
    const frameRef = useRef<HTMLIFrameElement>(null);
    const { playerRef, callbackRef } = useYoutubePlayer(frameRef, {});
    const stateRef = useRef<Boolean>(false);

    callbackRef.current = useMemo(
      () => ({
        onStateChange: (e: StateChangeEvent) => {
          const { data } = e;
          // data 0 - ended (or stopped) - 1 -playing 2- pause
          if (data >= 0 && data <= 2) stateRef.current = data === 1;
          if (data === 2 && onPause) onPause();
          if (data === 1 && onPlay) onPlay();
          if (data === 0 && onWatched) onWatched();
        },
        onReady: onReady,
        onLoadFailed: onLoadFailed,
      }),
      [onPause, onPlay, onWatched],
    );
    const getPlayer = () => {
      const player = playerRef.current;
      if (!player) throw new MediaPlayerError("Player reference is null");
      return player;
    };

    useImperativeHandle<VideoPlayerRef, VideoPlayerRef>(ref, () => ({
      play: async () => {
        const player = getPlayer();
        await player.playVideo();
      },
      pause: async () => {
        const player = getPlayer();
        await player.pauseVideo();
      },
      getDuration: async () => {
        const player = getPlayer();
        return await player.getDuration();
      },
      rewind: async () => {
        const player = getPlayer();
        const currentTime = await player.getCurrentTime();
        const newTime = Math.max(0, currentTime - 30);
        await player.seekTo(newTime, true);
      },
    }));
    const frame_url = getVideoFrameUrl(videoUrl, autoPlay, disableControls);
    return (
      <div className={`absolute inset-0 w-full h-full`}>
        <iframe
          ref={frameRef}
          tabIndex={disableControls ? -1 : undefined}
          className="absolute inset-0 w-full h-full"
          src={frame_url}
          allow="autoplay"
        ></iframe>
        {!!disableControls && (
          <div
            className="absolute inset-0 w-full h-full"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const player = playerRef.current;
              if (player) {
                if (stateRef.current) {
                  player.pauseVideo();
                } else if (player.playVideo) {
                  player.playVideo();
                }
              }
            }}
          ></div>
        )}
      </div>
    );
  },
);

export default YoutubeVideoPlayer;
