import React, { forwardRef } from "react";
import { VideoPlayerProps, VideoPlayerRef } from "./utils/VideoPlayerTypes";
import VimeoVideoPlayer from "./vimeo/VimeoVideoPlayer";
import YoutubeVideoPlayer from "./youtube/YoutubeVideoPlayer";
import getVideoType from "./utils/getVideoType";
import { Empty } from "antd";
import withCustomSuspense from "../../general/withCustomSuspense";
import withCustomErrorBoundaryFallback from "../../../../utility-features/error-handling/components/withCstomErrorBoundaryFallback";
import MediaPlayerErrorFallback from "../MediaPlayerErrorFallback";

const DEFAULT_VIDEO_RATIO = 16 / 9; // 16:9
const heightToWidth = 1 / DEFAULT_VIDEO_RATIO;

type VideoPropsExtended = VideoPlayerProps & {
  maxHeight?: string;
};

const VideoPlayer = forwardRef<VideoPlayerRef, VideoPropsExtended>(
  (props, ref) => {
    const videoType = getVideoType(props.videoUrl);
    return (
      <div translate={"no"} className="flex flex-col h-full overflow-y-hidden">
        <div style={{ flexGrow: 1 }} />
        <div
          className="m-auto relative"
          style={{
            marginTop: "0px",
            paddingBottom: props.maxHeight
              ? `min(${heightToWidth * 100}%, ${props.maxHeight})`
              : `${heightToWidth * 100}%`,
            height: "0px", /// do not remove this... it is needed to resize video proportionally
            width: props.maxHeight
              ? `min(100%, calc(${props.maxHeight} / ${heightToWidth}))`
              : "100%",
            // maxWidth: "142.8vh", // adjust this together with maxHeight of iframe.. check that maxHeight == maxWidth * 56.25%
          }}
        >
          {(() => {
            switch (videoType) {
              case "youtube":
                return <YoutubeVideoPlayer ref={ref} {...props} />;
              case "vimeo":
                return <VimeoVideoPlayer ref={ref} {...props} />;
              default:
                return <Empty />;
            }
          })()}
        </div>
        <div style={{ flexGrow: 2 }} />
      </div>
    );
  },
);

export default withCustomSuspense(
  withCustomErrorBoundaryFallback(VideoPlayer, ({ error, resetError }) => (
    <MediaPlayerErrorFallback error={error} resetError={resetError} />
  )),
);
