import { IconUpload } from "@tabler/icons";
import { message } from "antd";
import React, { useRef, useState } from "react";
import uploadFile from "src/common/functions/upload-utility/uploadFile";
import Button from "./button/Button";

interface FileUploaderProps {
  onFileUpload: (url: string) => Promise<void>;
  accept?: string;
  loading?: boolean;
  label?: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  onFileUpload,
  loading,
  accept,
  label,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // handle validations
    // console.log(e.target.files, fileInput.current, e.target);

    if (e.target.files && e.target.files.length) {
      setUploading(true);
      await uploadFile(e.target.files[0])
        .then(async (d) => {
          if (d?.url) {
            await onFileUpload(d?.url);
          } else {
            message.error("Upload Error");
          }
        })
        .catch((e) => message.error(e));
      setUploading(false);
    }
  };

  return (
    <div className="">
      <input
        ref={fileInput}
        className="hidden"
        type="file"
        accept={accept}
        onChange={handleFileInput}
      />
      <Button
        label={label}
        small
        secondary
        loading={loading || uploading}
        onClick={() => {
          fileInput.current?.click();
        }}
        icon={IconUpload}
      />
    </div>
  );
};

export default FileUploader;
