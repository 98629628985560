import { useState } from "react";
import VideoPlayerAutoPlayDemoPlayerCard from "./VideoPlayerAutoPlayDemoPlayerCard";
import VideoPlayerAutoPlayDemoInputsCard from "./VideoPlayerAutoPlayDemoInputsCard";

const VideoPlayerSequenceDemo: React.FC = () => {
  const [videoUrlList, setVideoUrlList] = useState<string[]>([]);
  const [autoPlayOn, setAutoPlayOn] = useState<boolean>(false);
  const [sameVideoPlayer, setSameVideoPlayer] = useState<boolean>(false);

  return (
    <div>
      <div className={`grid desktop:grid-cols-2 gap-1`}>
        <VideoPlayerAutoPlayDemoInputsCard
          //
          autoPlayOn={autoPlayOn}
          setAutoPlayOn={setAutoPlayOn}
          //
          sameVideoPlayer={sameVideoPlayer}
          setSameVideoPlayer={setSameVideoPlayer}
          //
          videoUrlList={videoUrlList}
          setVideoUrlList={setVideoUrlList}
        />
        <VideoPlayerAutoPlayDemoPlayerCard
          videoUrlList={videoUrlList}
          autoPlayOn={autoPlayOn}
          sameVideoPlayer={sameVideoPlayer}
        />
      </div>
    </div>
  );
};

export default VideoPlayerSequenceDemo;
