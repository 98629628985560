/**
 * @generated SignedSource<<324bd359831def7225d121ff3b2e6cf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type ProcoreFolderConfigModal_procoreProjectData_Query$variables = {
  projectId: string;
};
export type ProcoreFolderConfigModal_procoreProjectData_Query$data = {
  readonly procore_project_data_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly integration_enabled: boolean;
        readonly procore_project_id: string | null | undefined;
        readonly procore_project_name: string | null | undefined;
        readonly upload_daily_reports_enabled: boolean;
        readonly upload_daily_reports_folder_id: string | null | undefined;
        readonly upload_inspection_report_enabled: boolean;
        readonly upload_inspection_report_folder_id: string | null | undefined;
        readonly upload_permits_enabled: boolean;
        readonly upload_permits_folder_id: string | null | undefined;
        readonly upload_safety_reports_enabled: boolean;
        readonly upload_safety_reports_folder_id: string | null | undefined;
        readonly upload_toolbox_talks_enabled: boolean;
        readonly upload_toolbox_talks_folder_id: string | null | undefined;
        readonly upload_worker_orientation_transcript_enabled: boolean;
        readonly upload_worker_orientation_transcript_folder_id: string | null | undefined;
      };
    }>;
  };
};
export type ProcoreFolderConfigModal_procoreProjectData_Query = {
  response: ProcoreFolderConfigModal_procoreProjectData_Query$data;
  variables: ProcoreFolderConfigModal_procoreProjectData_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "project_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_project_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_project_name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_safety_reports_folder_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_daily_reports_folder_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_toolbox_talks_folder_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_worker_orientation_transcript_folder_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_permits_folder_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_safety_reports_enabled",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_daily_reports_enabled",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_toolbox_talks_enabled",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_worker_orientation_transcript_enabled",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_permits_enabled",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integration_enabled",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_inspection_report_enabled",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_inspection_report_folder_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcoreFolderConfigModal_procoreProjectData_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "procore_project_dataConnection",
        "kind": "LinkedField",
        "name": "procore_project_data_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "procore_project_dataEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "procore_project_data",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProcoreFolderConfigModal_procoreProjectData_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "procore_project_dataConnection",
        "kind": "LinkedField",
        "name": "procore_project_data_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "procore_project_dataEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "procore_project_data",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "499a00410e9a5efc1c571c937a8bfa15",
    "id": null,
    "metadata": {},
    "name": "ProcoreFolderConfigModal_procoreProjectData_Query",
    "operationKind": "query",
    "text": "query ProcoreFolderConfigModal_procoreProjectData_Query(\n  $projectId: uuid!\n) {\n  procore_project_data_connection(where: {project_id: {_eq: $projectId}}) {\n    edges {\n      node {\n        procore_project_id\n        procore_project_name\n        upload_safety_reports_folder_id\n        upload_daily_reports_folder_id\n        upload_toolbox_talks_folder_id\n        upload_worker_orientation_transcript_folder_id\n        upload_permits_folder_id\n        upload_safety_reports_enabled\n        upload_daily_reports_enabled\n        upload_toolbox_talks_enabled\n        upload_worker_orientation_transcript_enabled\n        upload_permits_enabled\n        integration_enabled\n        upload_inspection_report_enabled\n        upload_inspection_report_folder_id\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "550d5b8d947e823bb0169456a40a969b";

export default node;
