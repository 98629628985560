import GCSendInviteModal, {
  GCSendInviteModalRef,
} from "src/utility-features/invitations/GCSendInviteModal";
import {
  useGetProjectSubcontractorsQuery,
  Order_By,
  useUpdateSubcontractorEmployeeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { IconCircle, IconCircleCheck } from "@tabler/icons";
import { FC, useEffect, useRef, useState } from "react";
import { Table, message } from "antd";

import Button from "src/common/components/general/button/Button";
import LoadingContent from "src/common/components/general/LoadingContent";
import Switcher from "src/common/components/general/Switcher";
import { useParams } from "react-router-dom";

interface SubcontractorAdmin {
  id: string;
  subcontractorName: string;
  name: string;
  title?: string | null;
  email?: string | null;
  phone?: string | null;
  autoEmailsEnabled: boolean;
}
const GCProjectEmployeeVerification: FC = () => {
  const projectId = useParams()["projectId"] as string;
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [subAdmins, setSubAdmins] = useState<Array<SubcontractorAdmin>>([]);
  const sendInvite = useRef<GCSendInviteModalRef>(null);

  const [updateSubcontractorEmployee] =
    useUpdateSubcontractorEmployeeMutation();
  const { data, loading } = useGetProjectSubcontractorsQuery({
    variables: {
      where: { project_id: { _eq: projectId } },
      order_by: [{ subcontractor: { name: Order_By.Asc } }],
    },
  });

  useEffect(() => {
    if (data) {
      setSubAdmins(
        data.project_subcontractor
          .flatMap(
            (projectSubcontractor) =>
              projectSubcontractor.subcontractor.subcontractor_employees,
          )
          .map((subAdmin) => {
            return {
              id: subAdmin.user.id,
              subcontractorName: subAdmin.subcontractor.name,
              name: subAdmin.user.name,
              title: subAdmin.employee_title
                ? subAdmin.employee_title.name.en
                : "",
              email: subAdmin.user.email,
              phone: subAdmin.user.phone_number,
              autoEmailsEnabled: subAdmin.employee_verification_emails_enabled,
            };
          }),
      );
    }
  }, [data]);

  return loading ? (
    <LoadingContent />
  ) : (
    <div className="flex flex-col w-full">
      <GCSendInviteModal ref={sendInvite} projectId={projectId} />
      <div className="flex flex-row items-center gap-1">
        <Button
          onClick={() => sendInvite.current?.open()}
          label="Invite Subcontractor Administrator"
        ></Button>
      </div>
      <div className="flex flex-row w-full h-full overflow-y-scroll">
        <Table
          title={() => (
            <div className="gap-0.5 text-1.5">
              Subcontractor Administrators Performing Verification{" "}
              {subAdmins.length > 0 ? ` (${subAdmins.length})` : ""}
            </div>
          )}
          rowKey="id"
          className="w-full"
          dataSource={subAdmins}
          pagination={false}
          columns={[
            {
              title: "Send Emails",
              dataIndex: ["autoEmailsEnabled"],
              render: (value, record, index) => {
                return (
                  <div>
                    <Switcher
                      loading={loadings[index]}
                      onChange={(optionIndex) => {
                        setLoadings((prevLoadings) => {
                          const newLoadings = [...prevLoadings];
                          newLoadings[index] = true;
                          return newLoadings;
                        });
                        setSubAdmins((prev) => {
                          const newData = [...prev];

                          const recIndex = newData.findIndex(
                            (subAdmin) => subAdmin.id === record.id,
                          );
                          if (recIndex >= 0)
                            newData[recIndex] = {
                              ...newData[recIndex],
                              autoEmailsEnabled: !value,
                            };
                          return newData;
                        });
                        const enabled = optionIndex === 1;
                        updateSubcontractorEmployee({
                          variables: {
                            where: { user_id: { _eq: record.id } },
                            _set: {
                              employee_verification_emails_enabled: enabled,
                            },
                          },
                        })
                          .catch((error) => {
                            message.error(
                              `ERROR: ${
                                error instanceof Error
                                  ? error.message
                                  : String(error)
                              }`,
                            );
                          })
                          .finally(() => {
                            setLoadings((prevLoadings) => {
                              const newLoadings = [...prevLoadings];
                              newLoadings[index] = false;
                              return newLoadings;
                            });
                          });
                      }}
                      optionIndex={value ? 1 : 0}
                      options={[
                        { icon: IconCircle, label: "Disabled" },
                        { icon: IconCircleCheck, label: "Enabled" },
                      ]}
                    />
                  </div>
                );
              },
            },
            {
              title: "Company",
              dataIndex: ["subcontractorName"],
              sorter: (a, b) =>
                a.subcontractorName.localeCompare(b.subcontractorName),
              defaultSortOrder: "ascend",
            },
            {
              title: "Name",
              dataIndex: ["name"],
            },
            {
              title: "Title",
              dataIndex: ["title"],
            },
            {
              title: "Email",
              dataIndex: ["email"],
            },
            {
              title: "Phone #",
              dataIndex: ["phone"],
            },
          ]}
        ></Table>
      </div>
    </div>
  );
};

export default GCProjectEmployeeVerification;
