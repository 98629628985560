/**
 * @generated SignedSource<<10a2c808aa0e30f264f28e4395d47c79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { add_task_request_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadTaskToSiteformModalMutation$variables = {
  object: add_task_request_insert_input;
};
export type UploadTaskToSiteformModalMutation$data = {
  readonly insert_add_task_request_one: {
    readonly id: string;
    readonly pk: string;
  } | null | undefined;
};
export type UploadTaskToSiteformModalMutation = {
  response: UploadTaskToSiteformModalMutation$data;
  variables: UploadTaskToSiteformModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadTaskToSiteformModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "add_task_request",
        "kind": "LinkedField",
        "name": "insert_add_task_request_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadTaskToSiteformModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "add_task_request",
        "kind": "LinkedField",
        "name": "insert_add_task_request_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a01cafedc70648748ad630bb28fdcac1",
    "id": null,
    "metadata": {},
    "name": "UploadTaskToSiteformModalMutation",
    "operationKind": "mutation",
    "text": "mutation UploadTaskToSiteformModalMutation(\n  $object: add_task_request_insert_input!\n) {\n  insert_add_task_request_one(object: $object) {\n    id\n    pk: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "71e3448a916b5f2d16138dff176597fa";

export default node;
