import { Card, Checkbox, Flex, Tooltip } from "antd";
import VideoPlayerAutoPlayDemoInputsTable from "./VideoPlayerAutoPlayDemoInputsTable";
import { InfoCircleOutlined } from "@ant-design/icons";

interface VideoPlayerAutoPlayDemoInputsCardProps {
  setAutoPlayOn: (autoPlayOn: boolean) => void;
  autoPlayOn: boolean;
  setSameVideoPlayer: (sameVideoPlayer: boolean) => void;
  sameVideoPlayer: boolean;
  setVideoUrlList: (videoUrlList: string[]) => void;
  videoUrlList: string[];
}

const VideoPlayerAutoPlayDemoInputsCard: React.FC<
  VideoPlayerAutoPlayDemoInputsCardProps
> = (props) => {
  return (
    <Card title={"Video Inputs"}>
      <Flex gap={"middle"} className={`w-full`} vertical>
        <Checkbox
          onChange={(e) => props.setAutoPlayOn(e.target.checked)}
          checked={props.autoPlayOn}
        >
          Auto Play
        </Checkbox>
        <Checkbox
          onChange={(e) => props.setSameVideoPlayer(e.target.checked)}
          checked={props.sameVideoPlayer}
        >
          Same Video Player{" "}
          <Tooltip title={`changes "key" property of VideoPlayer`}>
            <InfoCircleOutlined />
          </Tooltip>
        </Checkbox>

        <VideoPlayerAutoPlayDemoInputsTable
          setVideoUrlList={props.setVideoUrlList}
          videoUrlList={props.videoUrlList}
        />
      </Flex>
    </Card>
  );
};

export default VideoPlayerAutoPlayDemoInputsCard;
