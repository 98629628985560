import { Checkbox } from "antd";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  GetGcPermitChecklistItemsQuery,
  useUpdateGeneralContractorForPermitMutation,
  useUpdateProjectSettingByPkForPermitMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
type ProjSettingType = NonNullable<
  GetGcPermitChecklistItemsQuery["project"][number]["project_setting"]
>;
type CommonSettingFields = Extract<keyof MainGcType, keyof ProjSettingType>;

type MainGcType = GetGcPermitChecklistItemsQuery["general_contractor"][number];
const PermitGcProjectSettingCheckbox: React.FC<{
  project_setting?: ProjSettingType | null;
  mainGc: MainGcType;
  field: Exclude<
    CommonSettingFields,
    "__typename" | "confined_space_reading_interval"
  >;
  label?: string;
  requirePhotoLabel?: boolean;
}> = ({ mainGc, project_setting, field, label, requirePhotoLabel }) => {
  const [updateGeneralContractor] =
    useUpdateGeneralContractorForPermitMutation();
  const [updateProjectSetting] = useUpdateProjectSettingByPkForPermitMutation();
  const settingUseLevel = project_setting?.permit_setting_level;
  return (
    <StyledContent>
      <Checkbox
        disabled={settingUseLevel !== "project" && !!project_setting}
        checked={
          project_setting && settingUseLevel === "project"
            ? project_setting[field]
            : mainGc[field]
        }
        onChange={(e) => {
          const checked = e.target.checked;
          if (project_setting && settingUseLevel === "project") {
            updateProjectSetting({
              variables: {
                project_id: project_setting.project_id,
                _set: { [field]: checked },
              },
              optimisticResponse: {
                update_project_setting_by_pk: {
                  ...project_setting,
                  [field]: checked,
                },
              },
            });
          } else {
            updateGeneralContractor({
              variables: { gcId: mainGc.id, _set: { [field]: checked } },
              optimisticResponse: {
                update_general_contractor_by_pk: {
                  ...mainGc,
                  [field]: checked,
                },
              },
            });
          }
        }}
      >
        {requirePhotoLabel
          ? "Photo:  require a photo when submitting permit"
          : label ||
            "Only require the person submitting the permit (foreman/competent person) to sign. When this setting is OFF all workers involved in the work are required to sign"}
      </Checkbox>
    </StyledContent>
  );
};
export default PermitGcProjectSettingCheckbox;
