/**
 * @generated SignedSource<<1f0272c408e98af73eaa6260850b0ad4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { procore_project_data_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectSettingsOldProcoreProjectMutation$variables = {
  _set: procore_project_data_set_input;
  projectId: string;
};
export type GCProjectSettingsOldProcoreProjectMutation$data = {
  readonly update_procore_project_data_by_pk: {
    readonly id: string;
    readonly integration_enabled: boolean;
    readonly upload_daily_construction_report_enabled: boolean;
    readonly upload_delivery_enabled: boolean;
  } | null | undefined;
};
export type GCProjectSettingsOldProcoreProjectMutation = {
  response: GCProjectSettingsOldProcoreProjectMutation$data;
  variables: GCProjectSettingsOldProcoreProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "project_id",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "procore_project_data",
    "kind": "LinkedField",
    "name": "update_procore_project_data_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "integration_enabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "upload_delivery_enabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "upload_daily_construction_report_enabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectSettingsOldProcoreProjectMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectSettingsOldProcoreProjectMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "81f2b08e6a83e53f47c72a692b571788",
    "id": null,
    "metadata": {},
    "name": "GCProjectSettingsOldProcoreProjectMutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectSettingsOldProcoreProjectMutation(\n  $projectId: uuid!\n  $_set: procore_project_data_set_input!\n) {\n  update_procore_project_data_by_pk(pk_columns: {project_id: $projectId}, _set: $_set) {\n    id\n    integration_enabled\n    upload_delivery_enabled\n    upload_daily_construction_report_enabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b0b341a28926a57798ec1d8244c3eb6";

export default node;
