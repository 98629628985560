import React, { FC } from "react";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Card, Image } from "antd";
import ButtonHuge from "src/common/components/general/button/ButtonHuge";

export interface PtpQrReviewPendingTBTsProps extends SiteOrientationStepProps {}

const PtpQrReviewPendingTBTs: FC<PtpQrReviewPendingTBTsProps> = (props) => {
  const { ptpQrUser } = usePtpQr();
  const pendingTbts = ptpQrUser.pendingTbts;
  const langStrings = useCurrentLangStrings();

  return (
    <SiteFeatureStepLayout onBackButtonClick={props.onBack}>
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.fullyReviewThisTBTWithYourCrew}
      >
        <div style={{ padding: 20 }}>
          {pendingTbts.map((toolboxTalk, i) => (
            <div key={toolboxTalk.id} className="mt-2">
              <div style={{ justifyContent: "flex-start" }}>
                <div>
                  <div style={{ width: "100%", minHeight: "40px" }}>
                    {toolboxTalk.name || langStrings.strings.topic}
                  </div>

                  <div>
                    {toolboxTalk.content && (
                      <div
                        style={{
                          width: "100%",
                          minHeight: "40px",
                        }}
                      >
                        {toolboxTalk.content}
                      </div>
                    )}
                  </div>
                </div>

                {toolboxTalk.images.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      marginTop: "5px",
                      marginBottom: "10px",
                      justifyContent: "flex-start",
                    }}
                  >
                    {toolboxTalk.images.map((image) => (
                      <Card
                        key={image.url}
                        className="w-full"
                        styles={{
                          body: {
                            padding: 2,
                            borderColor: "black",
                            borderWidth: 2,
                            borderStyle: "solid",
                          },
                        }}
                      >
                        <div className="relative z-0">
                          <div className="flex justify-between cursor-pointer"></div>
                          <Image
                            className="z-0"
                            src={image.url}
                            key={image.url}
                            alt={"Image: " + (i + 1)}
                          />
                        </div>
                      </Card>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <ButtonHuge onClick={props.onNext} type={"primary"}>
          {langStrings.strings.next}
        </ButtonHuge>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpQrReviewPendingTBTs;
