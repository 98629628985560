import { IconPlus } from "@tabler/icons";
import { Button, Card, Popconfirm, Space } from "antd";
import CustomButton from "src/common/components/general/button/Button";

import React, { useState } from "react";
import Image from "src/common/components/general/images/Image";
import TableFooterTotal from "src/common/components/tables/TableFooterTotal";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import noPropagation from "src/common/functions/noPropagation";
import {
  GetTasksQuery,
  Task,
} from "src/common/types/generated/apollo/graphQLTypes";
import RecursivePartial from "src/common/types/manual/RecursivePartial";
import styled from "styled-components";

type TaskType = GetTasksQuery["task"][number];

type Props = Omit<BaseTableProps<RecursivePartial<Task>>, "columns"> & {
  onDelete?: (r: RecursivePartial<Task>) => any;
  onClicked?: (r: TaskType) => any;
  showDelete?: boolean;
  showJhaProjects?: boolean;
  isExpandable?: boolean;
};

type ReportTasksTable = (p: Props) => JSX.Element;

const ReportTasksTable = styled(BaseTable).attrs(
  ({
    isExpandable = false,
    pagination,
    onClicked,
    onDelete,
    showDelete,
    showJhaProjects,
    ...props
  }: Props) => {
    const [addProjectToJhaOpen, setAddProjectToJhaOpen] = useState(false);

    const columns: Array<any> = [
      {
        title: "Description",
        key: "description",
        dataIndex: ["description", "clientText"],
        render: (a: string, t: Task) =>
          t.show_type === "images" ? a + " (Image Upload)" : a,
        sorter: (a: any, b: any) =>
          a.description.clientText
            ? a.description.clientText.localeCompare(b.description.clientText)
            : 1,
      },
      {
        title: "Steps",
        key: "steps",
        dataIndex: ["task_steps_aggregate", "aggregate", "count"],
        render: (a: number, b: Task) => (b.show_type === "images" ? "" : a),
        sorter: (a: any, b: any) => a.task_steps.length - b.task_steps.length,
      },
      {
        title: "Hazards",
        key: "hazards",
        render: (t: Task) =>
          t.show_type === "images"
            ? ""
            : t.task_steps.reduce((a, s) => a + s.task_step_hazards.length, 0),
      },
      {
        title: "Permits",
        key: "permits",
        dataIndex: ["task_permit_types_aggregate", "aggregate", "count"],
        render: (a: number, b: Task) => (b.show_type === "images" ? "" : a),
        sorter: (a: any, b: any) =>
          a.task_permit_types.length - b.task_permit_types.length,
      },
      {
        title: "PPE",
        key: "ppe",
        dataIndex: ["task_ppe_types_aggregate", "aggregate", "count"],
        render: (a: number, b: Task) => (b.show_type === "images" ? "" : a),
        sorter: (a: any, b: any) =>
          a.task_ppe_types.length - b.task_ppe_types.length,
      },
    ];

    if (showJhaProjects) {
      columns.push({
        title: "Add to projects",
        key: "actions",
        width: "10%",
        render: (_: any, task: TaskType) => {
          const projectNames = new Set();
          task.project_tasks.forEach((x) => {
            projectNames.add(x.project?.name);
          });

          const handleClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            if (onClicked) {
              onClicked(task);
            }
          };

          return (
            // <CustomButton
            //   onClick={() => handleClick}
            //   label={projectNames.size.toString()}
            //   // icon={IconPlus}
            //   small
            //   secondary
            // ></CustomButton>

            <Space>
              <div onClick={handleClick} className="flex items-center">
                <div className="flex items-center justify-center">
                  <span style={{ marginLeft: "30px" }}>
                    {projectNames.size}
                  </span>

                  <div className="text-sm">
                    <CustomButton fake icon={IconPlus} small></CustomButton>
                  </div>
                </div>
              </div>
            </Space>
          );
        },
      });
    }

    if (showDelete) {
      columns.push({
        title: "",
        key: "actions",
        render: (_: any, task: Task) => (
          <Space>
            <Popconfirm
              title="Are you sure?"
              onConfirm={noPropagation(() => {
                if (onDelete) onDelete(task);
              })}
              onCancel={noPropagation()}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button danger type="link" onClick={noPropagation()}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      });
    }

    return {
      ...props,
      columns,
      rowKey: (w: Task) => w.id,
      expandable: {
        rowExpandable: () => isExpandable,
        expandedRowRender: (w: Task) =>
          w.show_type === "images" && w.task_creation_request ? (
            <div className="flex flex-col gap-1 overflow-x-scroll">
              <div className="font-accent">
                Photos({w.task_creation_request.attached_files.length}):
              </div>
              <div className="flex flex-row overflow-x-scroll">
                {w.task_creation_request.attached_files.map((o, i) => (
                  <div className="w-3/12 font-accent" key={i}>
                    <Card
                      hoverable
                      className="w-11/12 ml-1"
                      cover={
                        <div>
                          <Image src={o.url} alt={"Image " + (i + 1)} preview />
                        </div>
                      }
                    ></Card>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <p>Steps ({w.task_steps.length})</p>
              {w.task_steps.map((step, i) => (
                <>
                  <p>
                    <strong>Step {i + 1}.</strong> {step.description.en}
                  </p>
                  {step.task_step_hazards.length > 0 && (
                    <div
                      style={{
                        paddingLeft: 24,
                      }}
                    >
                      <p>
                        <strong>
                          Hazards ({step.task_step_hazards.length})
                        </strong>
                      </p>
                      {step.task_step_hazards.map((h, i) => (
                        <>
                          <p>
                            {i + 1}. {h.description.en}
                          </p>
                          {h.control && <p>Control: {h.control.en}</p>}
                        </>
                      ))}
                    </div>
                  )}
                </>
              ))}
            </div>
          ),
      },
      summary: () =>
        pagination && (
          <TableFooterTotal cols={columns.length} total={pagination.total} />
        ),
    };
  }
)``;

export default ReportTasksTable as ReportTasksTable;
