import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { DatePicker, Form } from "antd";
import { Dayjs } from "dayjs";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";

interface VisitorSpreadsheetValues {
  startDate: Dayjs;
  endDate: Dayjs;
}

interface VisitorSpreadsheetModalProps {
  onCreate: (values: VisitorSpreadsheetValues) => void;
  onCancel: () => void;
}

export type VisitorSpreadsheetModalRef =
  FModalRef<VisitorSpreadsheetValues> | null;
const VisitorSpreadsheetModal = forwardRef<
  VisitorSpreadsheetModalRef,
  VisitorSpreadsheetModalProps
>(({ onCreate, onCancel }, ref) => {
  const [form] = Form.useForm<VisitorSpreadsheetValues>();
  const modal = useRef<FModalRef<VisitorSpreadsheetValues>>(null);
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  useImperativeHandle<VisitorSpreadsheetModalRef, VisitorSpreadsheetModalRef>(
    ref,
    () => modal.current,
  );
  return (
    <FModal
      ref={modal}
      title="Visitor Spreadsheet"
      okText="Download"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={async () => {
        const values = await form.validateFields().catch(v => null);
        if (!values)
            return;
        onCreate(values);
        form.resetFields();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item
          name="startDate"
          label="From date"
          rules={[{ required: true, message: "Choose a date" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="endDate"
          label="To date"
          rules={[{ required: true, message: "Choose a date" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </FModal>
  );
});

export default VisitorSpreadsheetModal;
