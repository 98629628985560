import { IconCheck, IconUserCheck, IconUserX, IconX } from "@tabler/icons";
import { Button, message, notification, Popconfirm, Radio } from "antd";
import { useEffect, useRef, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import ProcoreFolderConfigModal, { ProcoreFolderConfigModalRef } from "src/common/components/dialogs/ProcoreFolderConfigModal";
import ProcoreProjectConfigModal, { ProcoreProjectConfigModalRef } from "src/common/components/dialogs/ProcoreProjectConfigModal";
import ProcoreSubConfigModal, { ProcoreSubConfigModalRef } from "src/common/components/dialogs/ProcoreSubConfigModal";
import ProcoreTradeConfigModal, { ProcoreTradeConfigModalRef } from "src/common/components/dialogs/ProcoreTradeConfigModal";
import Switcher from "src/common/components/general/Switcher";
import { GCProjectSettingsOldQuery$data, procore_project_data_set_input } from "src/common/types/generated/relay/GCProjectSettingsOldQuery.graphql";
import ConnectAsUserButton from "src/common/components/ConnectProcoreUserButton";
import { ProcoreProjectIntegrationSettings_deleteProcoreProjectData_Mutation } from "src/common/types/generated/relay/ProcoreProjectIntegrationSettings_deleteProcoreProjectData_Mutation.graphql"

import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";  

const ChangeProcoreAccountWarning =
  "WARNING: Changing procore account\\user will reset procore configuration for this project, are you sure you wish to contine?";

type ProjectSettingsNode = GCProjectSettingsOldQuery$data["project_connection"]["edges"][number]["node"];

const ProcoreProjectIntegrationSettings: React.FC<{
    projectId: string;
    project: ProjectSettingsNode;
    updateProcoreProjectValue: (set: procore_project_data_set_input) => Promise<void>

}> = ({ projectId, project, updateProcoreProjectValue }) => {

    const { userData } = useUserData();

    const gc = project.general_contractor;
    const auth_data = gc.procore_auth_data;
    const prcore_project_data = project.procore_project_data;
  
    const company_integration_enabled = !!auth_data?.integration_enabled;
    const has_company_auth = !!(
      auth_data?.service_account_client_id || auth_data?.user_id
    );
    const has_project_auth = !!(
      prcore_project_data?.service_account_client_id || prcore_project_data?.user_id
    );
  
    const has_connected_project = !!prcore_project_data?.procore_project_id;
    const isHigherLevelAdmin = !!(
      project.gc_business_unit?.gc_business_unit_employees_aggregate.aggregate
        ?.count ||
      project.gc_office?.gc_office_employees_aggregate.aggregate?.count ||
      project.gc_division?.gc_division_employees_aggregate.aggregate?.count ||
      userData.employee?.is_corporate_admin
    );
    const switchDisabled = !prcore_project_data || !company_integration_enabled;
    //const [switchDisabled, setSwitchDisabledProject] = useState(
    //    !procoreProject || !has_auth_data
    //);
  
    const company_sandbox_suffix = auth_data?.sandbox ? " [sandbox]" : "";
    const project_sandbox_suffix = prcore_project_data?.sandbox ? " [sandbox]" : "";
    const company_conection_state = auth_data?.user_login
      ? `Company procore user: ${auth_data.user_login} ${
          auth_data.user_name || ""
        }${company_sandbox_suffix}`
      : auth_data?.service_account_client_id
      ? `Company service account Client Id: ${auth_data.service_account_client_id}${company_sandbox_suffix}`
      : "authentication method is not enabled";
    const project_connection_state = prcore_project_data?.user_login
      ? `Project procore user: ${prcore_project_data.user_login} ${
          prcore_project_data.user_name || ""
        }${project_sandbox_suffix}`
      : prcore_project_data?.service_account_client_id
      ? `Project service account Client Id: ${prcore_project_data.service_account_client_id}${project_sandbox_suffix}`
      : "Setup Procore login for this project.";
  
    const connect_as_user_title = prcore_project_data?.user_login
      ? "Change Procore User"
      : "Connect as Procore User";
  
    //const completed = project.completed;
    const integrationSwitch = prcore_project_data?.integration_enabled && !switchDisabled;
    //const connectionMethod = has_project_auth? 1 : 0;
  
    console.log(
      "has_company_auth",
      has_company_auth,
      "integrationSwitch",
      integrationSwitch,
    );
  
    const [settingsLevel, setSettingsLevel] = useState(
      has_project_auth ? "project" : "company",
    );
  
    const has_credential =
      (has_company_auth && settingsLevel === "company") ||
      (has_project_auth && settingsLevel === "project");
  
    //  const [procoreProjectSwitch, setProcoreProjectSwitch] = useState(
    //       procoreProject?.integration_enabled && !switchDisabled
    //  );
    const procoreProjectConfigModal = useRef<ProcoreProjectConfigModalRef>(null);
    const procoreSubConfigModal = useRef<ProcoreSubConfigModalRef>(null);
    const procoreFolderConfigModal = useRef<ProcoreFolderConfigModalRef>(null);
    const procoreTradeConfigModal = useRef<ProcoreTradeConfigModalRef>(null);

  const [deleteProcoreProjectData] =
    useAsyncMutation<ProcoreProjectIntegrationSettings_deleteProcoreProjectData_Mutation>(graphql`
      mutation ProcoreProjectIntegrationSettings_deleteProcoreProjectData_Mutation(
        $projectId: uuid!
      ) {
        delete_procore_subcontractor(
          where: { project_id: { _eq: $projectId } }
        ) {
          returning {
            id @deleteRecord
          }
        }
        delete_procore_project_data_by_pk(project_id: $projectId) {
          id @deleteRecord
        }
      }
    `);

    const updateIntegrationSwitch = async (value: boolean) => {
        if (!prcore_project_data) {
          if (!has_company_auth)
            message.error("Please setup procore connection first");
          else 
            message.error("Please setup procore integration first");
          return;
        }
        //if (!has_connected_project)
        //throw new Error("procore project is not configured");
        return await updateProcoreProjectValue({ integration_enabled: value });
      };

      useEffect(() => {
        const search = document.location.search;
        const params = new URLSearchParams(search);
        if (params.has("error")) {
          notification.error({
            message: "Error: " + params.get("error"),
            description: params.get("error_description"),
          });
        }
      }, []);
    
      const onRadioClicked = async (value: string) => {
        if (value === "company" && prcore_project_data /*&& !is_same_account*/) {
          await deleteProcoreProjectData({
            variables: {
              projectId,
            },
          });
        }
        setSettingsLevel(value);
      };
    
      const renderOption = (
        show_warning: boolean,
        value: string,
        children: React.ReactNode,
      ) => {
        const clickHandler = () => onRadioClicked(value);
        if (show_warning && settingsLevel !== value) {
          console.log("value " + value + " protected");
          return (
            <Popconfirm
              title={ChangeProcoreAccountWarning}
              onConfirm={clickHandler}
            >
              <Radio style={{ whiteSpace: "break-spaces" }} value={value}>
                {children}
              </Radio>
            </Popconfirm>
          );
        } else {
          console.log("value " + value + " unprotected");
          return (
            <Radio
              style={{ whiteSpace: "break-spaces" }}
              value={value}
              onClick={clickHandler}
            >
              {children}
            </Radio>
          );
        }
      };
      const is_same_account =
        has_company_auth &&
        has_project_auth &&
        (auth_data?.user_id === prcore_project_data?.user_id ||
          auth_data?.service_account_client_id ===
            prcore_project_data?.service_account_client_id);
      const show_warning = has_connected_project; // && !is_same_account;
      // console.log(
      //   "has_connected_project = ",
      //   has_connected_project,
      //   "is_same_acc ",
      //   is_same_account
      // );
      const companyOption = renderOption(
        show_warning,
        "company",
        company_conection_state,
      );
      const projectOption = renderOption(
        show_warning,
        "project",
        project_connection_state,
      );

    return <>
        {company_integration_enabled && (
        <div className="flex flex-col gap-1">
          <p className="text-2"> Procore Integration</p>
          <p className="inline-flex flex-row items-center gap-0.5">
            Procore integration is
            <Switcher
              optionIndex={integrationSwitch ? 0 : 1}
              options={[
                { icon: IconUserCheck, label: "on" },
                {
                  icon: IconUserX,
                  label: "off",
                },
              ]}
              onChange={(optionIndex) => 
                updateIntegrationSwitch(optionIndex === 0)
              }
            />
          </p>
          <p className="inline-flex flex-row items-center gap-0.5">
            Daily construction reports is{" "}
            <Switcher
              options={[
                {
                  icon: IconCheck,
                  label: "enabled",
                },
                {
                  icon: IconX,
                  label: "disabled",
                },
              ]}
              optionIndex={
                prcore_project_data?.upload_daily_construction_report_enabled ? 0 : 1
              }
              onChange={(optionIndex) => 
                updateProcoreProjectValue({upload_daily_construction_report_enabled: optionIndex === 0 })
              }
            />
          </p>
          {has_company_auth && (
            <Radio.Group value={settingsLevel}>
              <div className="flex flex-col gap-0.5">
                {companyOption}
                {projectOption}
              </div>
            </Radio.Group>
          )}
          {!has_company_auth && project_connection_state}
          {(settingsLevel === "project" || !has_company_auth) && (
            <div>
              <ConnectAsUserButton
                title={connect_as_user_title}
                projectId={projectId}
              />
              {has_project_auth && !has_company_auth && (
                <>
                  <Popconfirm
                    title="Are you sure you want to clear procore integration configuation?"
                    onConfirm={() => 
                      deleteProcoreProjectData({
                        variables: {
                          projectId,
                        },
                      })
                    }
                    okText="Confirm"
                    cancelText="No"
                  >
                    <Button
                      type="primary"
                      onClick={
                        () => {
                          console.log("This button does nothing");
                        }
                        //TODO do something on click
                      }
                    >
                      Clear login data
                    </Button>
                  </Popconfirm>
                </>
              )}
            </div>
          )}
        </div>
      )}
      {company_integration_enabled && has_credential && (
        <div className="flex flex-col gap-1">
          <div className="flex flex-row flex-wrap gap-1">
            <ProcoreProjectConfigModal
              ref={procoreProjectConfigModal}
              projectId={projectId}
            />

            <Button
              onClick={() => {
                procoreProjectConfigModal.current?.open();
              }}
              type="primary"
            >
              Project Setup
            </Button>
            {has_connected_project && (
              <>
                <ProcoreSubConfigModal
                  ref={procoreSubConfigModal}
                  projectId={projectId}
                  GCId={gc.pk}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    procoreSubConfigModal.current?.open();
                  }}
                >
                  Subcontractor Setup
                </Button>
                <ProcoreFolderConfigModal
                  ref={procoreFolderConfigModal}
                  projectId={projectId}
                  GCId={gc.pk}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    procoreFolderConfigModal.current?.open();
                  }}
                >
                  PDF Folder Setup
                </Button>
                <ProcoreTradeConfigModal
                  ref={procoreTradeConfigModal}
                  projectId={projectId}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    procoreTradeConfigModal.current?.open();
                  }}
                >
                  Trade Setup
                </Button>
              </>
            )}
          </div>
        </div>
      )}</>
};

export default ProcoreProjectIntegrationSettings;

  