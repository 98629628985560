import React, { useState } from "react";
import ChartsDemoTable, { ChartsTableDemoDataType } from "./ChartsDemoTable";
import ChartWrapperCard from "../../../../../common/components/charts/ChartWrapperCard";
import LineChart from "../../../../../common/components/charts/LineChart";
import { Card, Typography } from "antd";

const LineChartDemo: React.FC = () => {
  const [dataSource, setDataSource] = useState<ChartsTableDemoDataType[]>([]);
  const [valueTitle, setValueTitle] = useState<string>("Value Title");

  return (
    <div className={`grid desktop:grid-cols-2 gap-1`}>
      <Card title={`Line Chart Builder`}>
        <ChartsDemoTable
          dataSource={dataSource}
          onDataSourceChange={setDataSource}
          onSharedValueTitleChange={setValueTitle}
          sharedValueTitle={valueTitle}
        />
      </Card>

      <ChartWrapperCard
        title={`Line Chart`}
        extraTop={<Typography.Text code>LineChart.tsx</Typography.Text>}
      >
        <LineChart
          valueTitle={valueTitle}
          dataSource={dataSource.map((item) => ({
            value: item.value,
            xField: item.name,
          }))}
        />
      </ChartWrapperCard>
    </div>
  );
};

export default LineChartDemo;
