import searchParamsProvider from "../../../../common/functions/searchParamsProvider";
import OrientationViewer from "../../components/slidesViewer/SiteOrientationSlidesViewer";
import { LanguageProvider } from "../../../../utility-features/i18n/context/languageContext";
import SiteFeatureLayoutBody, {
  SiteFeatureLayoutStepProps,
} from "../../../../common/components/layouts/steps-navigation-layout/SiteFeatureLayoutBody";
import SiteFeatureLayoutWrapper from "../../../../common/components/layouts/steps-navigation-layout/SiteFeatureLayoutWrapper";

const OrientationTestView: React.FC = () => {
  const steps: SiteFeatureLayoutStepProps[] = [
    {
      id: "orientation_module_test",
      title: "Orientation Module Test",
      body: searchParamsProvider(OrientationViewer),
    },
  ];

  return (
    <LanguageProvider>
      <SiteFeatureLayoutWrapper>
        <SiteFeatureLayoutBody currentStepId={steps[0].id} steps={steps} />
      </SiteFeatureLayoutWrapper>
    </LanguageProvider>
  );
};

export default OrientationTestView;
