import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import searchParamsProvider from "src/common/functions/searchParamsProvider";
// import GCProjectCalendarSettings from "src/root/routes/views/general-contractor/projects/calendar/settings/GCProjectCalendarSettings";
import GCProjectCalendarSitedelivery from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/GCProjectCalendarSitedelivery";
import GCProjectCalendarSitedeliverySubBlocks from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/sub-blocks/GCProjectCalendarSitedeliverySubBlocks";
import siteboardEntryRoutes from "src/domain-features/siteboard/siteboardEntryRoutes";
import siteOrientationRoutes from "src/domain-features/siteorientation/siteOrientationEntryRoutes";
import siteSafetyRoutes from "src/domain-features/sitesafety/siteSafetyRoutes";
import GCProjectCrew from "src/domain-features/worker-crew/entry-routes/gc/GCProjectCrew";
import Feedback from "src/root/routes/views/Feedback";
import JHAReviewedAndAcceptedWrapper from "src/root/routes/views/JHAReviewedAndAccepted";
import UploadSingleCertWrapper from "src/root/routes/views/UploadSingleCert";
import SCEmailReview from "src/root/routes/views/anonymous/SubAdminEmailReview";
import GCNavigation from "src/root/routes/views/general-contractor/GCNavigation";
import GCProcoreProjectSetup from "src/root/routes/views/general-contractor/GCProcoreProjectSetup";
import GCEmployeeVerification from "src/root/routes/views/general-contractor/employeeVerification/GCEmployeeVerification";
import GCProjectDetail from "src/root/routes/views/general-contractor/projects/GCProjectDetail";
import GCProjects from "src/root/routes/views/general-contractor/projects/GCProjects";
import GCAlerts from "src/root/routes/views/general-contractor/projects/alerts/GCProjectSiteAlerts";
import GCTasks from "src/root/routes/views/general-contractor/jha/GCTasks";
// import GCProjectCalendarSitedeliveryDeliveries from "src/root/routes/views/general-contractor/projects/calendar/sitedelivery/deliveries/GCProjectCalendarSitedeliveryDeliveries";
import GCProjectSettings from "./views/general-contractor/projects/settings/GCProjectSettings";
import GCProjectSubcontractors from "src/root/routes/views/general-contractor/projects/subcontractors/GCProjectSubcontractors";
import GCSubcontractor from "src/root/routes/views/general-contractor/projects/subcontractors/GCSubcontractor";
import GCProjectTeam from "src/root/routes/views/general-contractor/projects/team/GCProjectTeam";
import GCProjectWorkers from "src/root/routes/views/general-contractor/projects/workers/GCProjectWorkers";
import GCProjectWorkersOverview from "src/root/routes/views/general-contractor/projects/workers/overview/GCProjectWorkersOverview";
import GCProjectWorkersTable from "src/root/routes/views/general-contractor/projects/workers/table/GCProjectWorkersTable";
import GCprojectWorker from "src/root/routes/views/general-contractor/projects/workers/worker/GCProjectWorkersWorker";
import GCProfile from "src/root/routes/views/general-contractor/settings/GCProfile";
import GCSettings from "src/root/routes/views/general-contractor/settings/GCSettings";
import RejectedOrArchivedCerts from "src/root/routes/views/sf-employee/RejectedOrArchivedCertsTable";
import SFData from "src/root/routes/views/sf-employee/SFData";
import SFEmployee from "src/root/routes/views/sf-employee/SFEmployee";
import SFProfile from "src/root/routes/views/sf-employee/SFProfile";
import SFRequestedJHAs from "src/root/routes/views/sf-employee/SFRequestedJHAs";
import SFRequestedSDS from "src/root/routes/views/sf-employee/SFRequestedSDS";
import SFRequestedSubcontractors from "src/root/routes/views/sf-employee/SFRequestedSubcontractors";
import SFUpdateCertsData from "src/root/routes/views/sf-employee/SFUpdateCertsData";
import SFVerifyProfilePhotoTable from "src/root/routes/views/sf-employee/SFVerifyProfilePhoto";
import SFShowWorkerTable from "src/root/routes/views/sf-employee/SFWorkerToVerifyTable";
import VerifyAndAddJHAs from "src/root/routes/views/sf-employee/VerifyAndAddJHAs";
import VerifyCerts from "src/root/routes/views/sf-employee/VerifyCerts";
import VerifySubcontractor from "src/root/routes/views/sf-employee/VerifySubcontractor";
import Subcontractor from "src/root/routes/views/subcontractor/Subcontractor";
import SCEmployees from "src/root/routes/views/subcontractor/people/employees/SCEmployees";
import SCPeople from "src/root/routes/views/subcontractor/people/employees/SCPeople";
import SecretCodeSignInLink from "src/utility-features/authorization/SecretCodeSignInLink";
import adminRoute from "./views/admin/adminRoute";
import GCInviteSubAdminsToAddJhas from "./views/general-contractor/projects/subcontractors/sub-info/GCInviteSubAdminsToAddJhas";
import VerifyAndAddSDS from "./views/sf-employee/VerifyAndAddSDS";
// import SCWorker from "src/root/routes/views/subcontractor/people/workers/SCWorker";
import GCProjectCalendarSitedeliveryDeliveries from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/deliveries/GCProjectCalendarSitedeliveryDeliveries";
import siteInspectionEntryRoutes from "src/domain-features/siteinspection/siteInspectionEntryRoutes";
import SiteOrientationCertificateUpload from "src/domain-features/siteorientation/components/SiteOrientationCertificateUpload";
import SiteOrientationCertificateUploadBySubadmin from "src/domain-features/siteorientation/components/SiteOrientationCertificateUploadBySubadmin";
import { ReportManagementRedirect } from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/report-settings/gcProjectCalendarReports/gcProjectReportManagementRoute";
import VisitorTnC from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/visitor/components/visitorTermsAndConditions";
import gcProjectToolboxTalksRoute from "src/domain-features/sitesafety/entryRoutes/gcProjectToolboxTalks/gcToolboxTalksRoute";
import FlaggedJHAViewAndEdit from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/FlaggedTaskEmailLinkView";
import UnflagTaskConfirmation from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/UnflagTaskConfirmation";
import siteSafetyDataSheetRoutes from "src/domain-features/sitesafety/safety-data-sheets/siteSafetyDataSheetEntryRoutes";
import siteSafetyPlanRoutes from "src/domain-features/sitesafety/siteSafetyPlan/siteSafetyPlanEntryRoutes";
import SubProjectMobilizationPseudoLoginView from "src/domain-features/sitesafety/sub-project-mobilization/components/SubProjectMobilizationPseudoLoginView";
import SCProjectCrew from "src/domain-features/worker-crew/entry-routes/sub/SCProjectCrew";
import workerEntryRoutes from "src/domain-features/worker-web-app/workerEntryRoutes";
import GuestCovidFail from "src/root/routes/views/GuestCovidFail";
import GuestCovidPass from "src/root/routes/views/GuestCovidPass";
import GuestCovidQuestions from "src/root/routes/views/GuestCovidQuestions";
import GCBilling from "src/root/routes/views/general-contractor/billing/GCBilling";
import SCBilling from "src/root/routes/views/subcontractor/billing/SCBilling";
import SCWorkers from "src/root/routes/views/subcontractor/people/workers/SCWorkers";
import SCProfile from "src/root/routes/views/subcontractor/profile/SCProfile";
import SCProject from "src/root/routes/views/subcontractor/projects/SCProject";
import SCProjectTask from "src/root/routes/views/subcontractor/projects/SCProjectTask";
import SCProjects from "src/root/routes/views/subcontractor/projects/SCProjects";
import SCProjectsNavigation from "src/root/routes/views/subcontractor/projects/SCProjectsNavigation";
import SCTask from "src/root/routes/views/subcontractor/tasks/SCTask";
import SCTasks from "src/root/routes/views/subcontractor/tasks/SCTasks";
import SCTasksNavigation from "src/root/routes/views/subcontractor/tasks/SCTasksNavigation";
import AToken from "src/utility-features/authorization/AToken";
import ForgotPassword from "src/utility-features/authorization/ForgotPassword";
import ResetPassword from "src/utility-features/authorization/ResetPassword";
import Invite from "src/utility-features/invitations/Invite";
import InviteSuccessMobile from "src/utility-features/invitations/InviteSuccessMobile";
import NestedRoute from "../../common/types/manual/NestedRoute";
import VisitorOrientationDone from "../../domain-features/sitesafety/entryRoutes/gcProjectReports/routes/visitor/components/VisitorOrientationDone";
import VisitorQuestions from "../../domain-features/sitesafety/entryRoutes/gcProjectReports/routes/visitor/components/VisitorQuestions";
import GCAddNewProject from "./views/general-contractor/projects/newProject/GCAddNewProject";
import GCSds from "./views/general-contractor/sds/GCSds";
import SFDrugtestTable from "./views/sf-employee/SFDrugtestTable";
import VerifyDrugtest from "./views/sf-employee/VerifyDrugtest";
import SCBillingProjectsNavigation from "./views/subcontractor/projects/SCBillingProjectsNavigation";
// import DevelopmentPlayground from "./views/DevelopmentPlayground";
import observationEntryRoutes from "src/domain-features/observation/observationEntryRoutes";
import incidentEntryRoutes from "src/domain-features/incident-management/incidentEntryRoutes";
import GCProjectCalendarSettings from "src/domain-features/site-delivery/entry-routes/calendar/settings/GCProjectCalendarSettings";
import GCProjectEmployeeVerification from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/employeeVerification/GCProjectEmployeeVerification";
import { UserDataProvider } from "src/utility-features/authorization/UserDataProvider";
import { LanguageProvider } from "src/utility-features/i18n/context/languageContext";
import { isDevelopmentEnvironment } from "../../common/constants/environment";
import GCSubcontractors from "./views/general-contractor/projects/subcontractors/GCSubcontractors";
import csrRoute from "src/utility-features/customer-portal/entryRoute";
import gcHierarchyRoutes from "./views/general-contractor/hierarchy/gcHierarchyRoutes";
import PtpQrCodeView from "src/domain-features/sitesafety/entryRoutes/qrProjectReports/routes/ptp/components/PtpQrCodeView";
import DataErasureRequestScreen from "src/utility-features/DataErasureRequestScreen";
import SCChildSubsView from "./views/subcontractor/projects/child-subs/SCChildSubsView";
import developmentPlaygroundRoute from "./views/development-playground/components/developmentPlaygroundRoute";

const Login = React.lazy(
  () => import("src/utility-features/authorization/Login"),
);

const homeRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    { path: "webform/erasure", element: <DataErasureRequestScreen /> },
    {
      path: "orientations/:projectId",
      ...siteOrientationRoutes.remoteOrientation,
    },
    {
      path: "orientations/test/:projectId",
      ...siteOrientationRoutes.orientationTest,
    },
    {
      path: "universal/orientation",
      ...siteOrientationRoutes.universalOrientation,
    },
    {
      path: "uo/:code",
      ...siteOrientationRoutes.universalOrientationShort,
    },
    {
      path: "ptp/:projectId",
      element: <PtpQrCodeView />,
    },
    {
      path: "",
      element: (
        <UserDataProvider>
          <Outlet />
        </UserDataProvider>
      ),
      children: [
        {
          path: "siteboard/:projectId", // A link localhost:3000/siteboard/4d7a8072-fdef-467c-b708-ba42e3cf7fb8
          ...siteboardEntryRoutes.mainEntry,
        },
        {
          path: "qr", //example: http://localhost:3000/qr/p/944334ea-32e7-4246-9693-e7247e4c1afd
          ...siteSafetyRoutes.qrProjectReports,
        },
        { path: "agc", ...siteOrientationRoutes.agcDashboard },
        {
          path: "site-inspection/:qrCodeId/:projectId",
          ...siteInspectionEntryRoutes.workerInspection,
        },
        {
          path: "requestToSub/:requestId",
          element: searchParamsProvider(SCEmailReview),
        },
        {
          path: "flagged-jha-edit/:taskId/:requestId",
          element: searchParamsProvider(FlaggedJHAViewAndEdit),
        },

        {
          path: "unflagged-jha-confirmation/:taskId",
          element: searchParamsProvider(UnflagTaskConfirmation),
        },
        {
          path: "invite/:inviteId",
          element: searchParamsProvider(Invite),
        },
        {
          path: "im",
          element: searchParamsProvider(InviteSuccessMobile),
        },
        {
          path: "covid/:projectId/form",
          element: searchParamsProvider(GuestCovidQuestions),
        },
        {
          path: "covid/:projectId/pass",
          element: searchParamsProvider(GuestCovidPass),
        },
        {
          path: "covid/:projectId/fail",
          element: searchParamsProvider(GuestCovidFail),
        },
        {
          path: "visitors/:projectId/form",
          element: searchParamsProvider(VisitorQuestions),
        },
        {
          path: "visitors/:projectId/form/success",
          element: searchParamsProvider(VisitorOrientationDone),
        },
        {
          path: "visitors/:projectId/form/visitorTnC",
          element: searchParamsProvider(VisitorTnC),
        },
        {
          path: "atoken",
          element: searchParamsProvider(AToken),
        },
        {
          path: "admin",
          ...adminRoute,
        },
        {
          path: "reviewedandacceptedtask",
          element: (
            <RequireRoleWrapper requiredRole={["pseudo_worker"]}>
              <JHAReviewedAndAcceptedWrapper />
            </RequireRoleWrapper>
          ),
        },
        {
          path: "send-invite-link-to-subadmins",
          element: (
            <RequireRoleWrapper requiredRole="pseudo_worker">
              <Outlet />
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "",
              element: searchParamsProvider(GCInviteSubAdminsToAddJhas),
            },
          ],
        },
        {
          path: "sub-admin-mobilization",
          element: (
            <RequireRoleWrapper requiredRole="mobilization_user">
              <Outlet />
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "",
              element: searchParamsProvider(
                SubProjectMobilizationPseudoLoginView,
              ),
            },
          ],
        },
        {
          path: "upload-certificates-by-worker",
          element: (
            <RequireRoleWrapper requiredRole="pseudo_worker">
              <Outlet />
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "",
              element: (
                <LanguageProvider>
                  <SiteOrientationCertificateUpload />
                </LanguageProvider>
              ),
            },
          ],
        },
        {
          path: "upload-certificates-by-subadmin",
          element: (
            <RequireRoleWrapper requiredRole="subcontractor_employee">
              <Outlet />
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "",
              element: (
                <LanguageProvider>
                  <SiteOrientationCertificateUploadBySubadmin />
                </LanguageProvider>
              ),
            },
          ],
        },
        {
          path: "worker",
          ...workerEntryRoutes,
        },
        {
          path: "gce",
          element: (
            <RequireRoleWrapper requiredRole="employee">
              <Outlet />
            </RequireRoleWrapper>
          ),
          children: [
            {
              // do not change this route (it is used in procore manifest)
              path: "procoreProjectSetup",
              element: <GCProcoreProjectSetup />,
            },
            {
              path: "orientation",
              ...siteOrientationRoutes.inPersonOrientation,
            },

            {
              path: "",
              element: <GCNavigation />,
              children: [
                ...gcHierarchyRoutes,
                {
                  path: "projects",
                  element: <GCProjects />,
                  children: [
                    { path: "new-project", element: <GCAddNewProject /> },
                    {
                      path: ":projectId",
                      element: <GCProjectDetail />,
                      children: [
                        {
                          path: "inspections",
                          ...siteInspectionEntryRoutes.gcInpsection,
                        },
                        {
                          path: "observations",
                          ...observationEntryRoutes.gcObservationRoute,
                        },
                        {
                          path: "dashboard",
                          ...siteSafetyRoutes.gcProjectDashboard,
                        },
                        {
                          path: "workers", // projectWorkers
                          element: <GCProjectWorkers />,
                          children: [
                            {
                              path: "table",
                              element: <GCProjectWorkersTable />,
                            },
                            {
                              path: "overview",
                              element: <GCProjectWorkersOverview />,
                            },
                            {
                              path: "worker/:projectWorkerId",
                              element: <GCprojectWorker />,
                            },
                            {
                              path: "",
                              element: <Navigate to={`overview`} replace />,
                            },
                          ],
                        },
                        {
                          path: "incidents",
                          ...incidentEntryRoutes.gcIncidentRoute,
                        },

                        {
                          path: "jha",
                          element: <GCTasks />,
                        },

                        {
                          path: "sds",
                          element: <Outlet />,
                          children: [
                            {
                              path: ":subview",
                              element: <GCSds />,
                            },
                            {
                              path: "",
                              element: <Navigate replace to={"project"} />,
                            },
                          ],
                        },

                        {
                          path: "subcontractors",
                          element: <GCProjectSubcontractors />,
                          children: [
                            {
                              path: "table",
                              element: <GCSubcontractors />,
                            },
                            {
                              path: "employeeverification",
                              element: <GCProjectEmployeeVerification />,
                            },
                            {
                              path: ":subcontractorId",
                              element: <Outlet />,
                              children: [
                                {
                                  path: "task/:taskId",
                                  element: <GCSubcontractor />,
                                },
                                {
                                  path: "",
                                  element: <Navigate replace to="sub-info" />,
                                },
                                {
                                  path: ":subview",
                                  element: <GCSubcontractor />,
                                },
                                {
                                  path: "task/:taskId",
                                  element: <GCSubcontractor />,
                                },
                                {
                                  path: "crew/:crewId",
                                  element: <GCProjectCrew />,
                                },
                              ],
                            },
                            {
                              path: "",
                              element: <Navigate to="table" replace />,
                            },
                          ],
                        },
                        {
                          path: "alerts",
                          element: <Outlet />,
                          children: [
                            { path: ":subview", element: <GCAlerts /> },
                            {
                              path: "",
                              element: <Navigate to={"site-alert"} />,
                            },
                          ],
                        },
                        {
                          path: "toolbox-talks",
                          ...gcProjectToolboxTalksRoute,
                        },
                        {
                          path: "reports",
                          ...siteSafetyRoutes.gcProjectReports,
                        },
                        {
                          path: "settings",
                          element: <GCProjectSettings />,
                        },
                        {
                          path: "orientation",
                          ...siteOrientationRoutes.gcDashboard,
                        },
                        {
                          path: "calendar",
                          element: <Outlet />,
                          children: [
                            {
                              path: "",
                              element: <Navigate to={"siteDelivery"} replace />,
                            },
                            {
                              path: "siteDelivery",
                              element: <GCProjectCalendarSitedelivery />,
                              children: [
                                {
                                  path: "deliveries",
                                  element: (
                                    <GCProjectCalendarSitedeliveryDeliveries />
                                  ),
                                },
                                {
                                  path: "settings",
                                  element: <GCProjectCalendarSettings />,
                                },
                                {
                                  path: "subBlocks",
                                  element: (
                                    <GCProjectCalendarSitedeliverySubBlocks />
                                  ),
                                },
                                {
                                  path: "",
                                  element: <Navigate to="deliveries" replace />,
                                },
                              ],
                            },
                            {
                              path: "reportManagement",
                              element: <ReportManagementRedirect />,
                            },
                            // {
                            //   path: "",
                            //   element: <Navigate to="siteDelivery" />,
                            // },
                          ],
                        },
                        {
                          path: "team",
                          element: <GCProjectTeam />,
                        },
                        {
                          path: "billing",
                          element: <GCBilling />,
                        },
                        // {
                        //   path: "",
                        //   element: <Navigate replace to={"reports"} />,
                        // },
                      ],
                    },
                  ],
                },
                {
                  path: "settings",
                  element: <GCSettings />,
                  children: [
                    {
                      path: "",
                      element: <GCProfile />,
                    },
                  ],
                },
                {
                  path: "empver",
                  element: <GCEmployeeVerification />,
                },
                {
                  path: "",
                  element: <Navigate replace to="projects" />,
                },
              ],
            },
          ],
        },
        { path: "csr", ...csrRoute },
        {
          path: "sfe",
          element: (
            <RequireRoleWrapper requiredRole="sf_employee">
              <CustomSuspense>
                <SFEmployee />
              </CustomSuspense>
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "profile",
              element: searchParamsProvider(SFProfile),
              children: [],
            },
            {
              path: "",
              element: searchParamsProvider(SFData),
              children: [
                {
                  path: "verify",
                  children: [
                    {
                      path: "updateCerts",
                      element: searchParamsProvider(SFUpdateCertsData),
                    },
                    {
                      path: "certificate",
                      element: searchParamsProvider(SFShowWorkerTable),
                    },
                    {
                      path: "certificate/:workerId",
                      element: searchParamsProvider(VerifyCerts),
                    },
                    {
                      path: "drugtest",
                      element: searchParamsProvider(SFDrugtestTable),
                    },
                    {
                      path: "drugtest/:certId/:workerId",
                      element: searchParamsProvider(VerifyDrugtest),
                    },
                    {
                      path: "rejected_archived",
                      element: searchParamsProvider(RejectedOrArchivedCerts),
                    },
                    {
                      path: "profile_photo",
                      element: searchParamsProvider(SFVerifyProfilePhotoTable),
                    },
                    {
                      path: "jha",
                      element: searchParamsProvider(SFRequestedJHAs),
                    },
                    {
                      path: "sds",
                      element: searchParamsProvider(SFRequestedSDS),
                    },
                    {
                      path: "sds/:requestId",
                      element: searchParamsProvider(VerifyAndAddSDS),
                    },
                    {
                      path: "jha/:requestId",
                      element: searchParamsProvider(VerifyAndAddJHAs),
                    },
                    {
                      path: "subcontractors",
                      element: searchParamsProvider(SFRequestedSubcontractors),
                    },
                    {
                      path: "subcontractors/:certId",
                      element: searchParamsProvider(VerifySubcontractor),
                    },
                    {
                      path: "",
                      element: <Navigate to={"certificate"} />,
                    },
                  ],
                },
                {
                  path: "",
                  element: <Navigate to={"verify"} />,
                },
              ],
            },
          ],
        },
        {
          path: "sub",
          element: (
            <RequireRoleWrapper requiredRole="subcontractor_employee">
              <Subcontractor />
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "profile",
              element: <SCProfile />,
            },
            {
              path: "observations",
              ...observationEntryRoutes.scObservationRoute,
            },
            {
              path: "feedback",
              element: <Feedback />,
            },
            { path: "toolbox-talks", ...siteSafetyRoutes.scTBTs },
            {
              path: "reports",
              ...siteSafetyRoutes.scReports,
            },
            {
              path: "people",
              element: <SCPeople />,
              children: [
                {
                  path: "workers",
                  element: <Outlet />,
                  children: [
                    {
                      path: "verify",
                      element: <SCWorkers showEmployeeVerificationModal />,
                    },
                    {
                      path: "",
                      element: <SCWorkers />,
                    },
                  ],
                },
                {
                  path: "employees",
                  element: <SCEmployees />,
                },
                { path: "", element: <Navigate to={"workers"} /> },
                {
                  path: "",
                  element: <Navigate to={`workers`} replace />,
                },
              ],
            },
            {
              path: "tasks",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <SCTasks />,
                },
                {
                  path: ":taskId",
                  element: <SCTasksNavigation />,
                  children: [{ path: "", element: <SCTask /> }],
                },
              ],
            },
            {
              path: "sds",
              ...siteSafetyDataSheetRoutes.scSdsRoute,
            },
            { path: "safety-plan", ...siteSafetyPlanRoutes.safetyPlanRoute },
            {
              path: "projects",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <SCProjects />,
                },
                {
                  path: ":projectId",
                  element: <SCProjectsNavigation />,
                  children: [
                    {
                      path: "child-sub/:childSubId",
                      element: <SCChildSubsView />,
                    },
                    {
                      path: ":subview",
                      element: searchParamsProvider(SCProject),
                    },
                    {
                      path: "",
                      element: <Navigate replace to="workers-and-crews" />,
                    },

                    {
                      path: "crew",
                      element: <SCProjectCrew />,
                    },
                    {
                      path: "task",
                      element: <SCProjectTask />,
                    },
                  ],
                },
                { path: "", element: <Navigate to="workers" /> },
              ],
            },
            {
              path: "billing",
              element: <SCBillingProjectsNavigation />,
              children: [
                {
                  path: ":projectId",
                  element: <SCBilling />,
                },
              ],
            },

            // {
            //   path: "companySettings",
            //   element: <SCCompanySettings />,
            // },

            {
              path: "",
              element: <Navigate to="people" replace />,
            },
          ],
        },
        {
          path: "upload/:docId",
          element: (
            <RequireRoleWrapper
              requiredRole={["employee", "worker", "subcontractor_employee"]}
            >
              <UploadSingleCertWrapper />
            </RequireRoleWrapper>
          ),
        },
        {
          path: "worker-universal-orientation",
          ...siteOrientationRoutes.workerUniversalOrientation,
        },

        {
          path: "orientation/quiz",
          ...siteOrientationRoutes.quizQR,
        },
        {
          path: "singInLink/:code",
          // Used by qrcode
          element: searchParamsProvider(SecretCodeSignInLink),
        },
        {
          path: "sl/:code",
          element: searchParamsProvider(SecretCodeSignInLink),
        },
        {
          path: "resetPassword/:forgotPasswordKey",
          element: searchParamsProvider(ResetPassword),
        },
        {
          path: "forgotPassword",
          element: searchParamsProvider(ForgotPassword),
        },
        {
          path: "",
          element: <Login />,
        },
      ],
    },
    // development playground is used for testing and should be only available in dev environment
    ...(isDevelopmentEnvironment
      ? [
          {
            path: "development-playground",
            ...developmentPlaygroundRoute,
          },
        ]
      : []),
  ],
};

export default homeRoute;
