import chartsDemoRoute from "./charts-demo/chartsDemoRoute";
import tabsDemoRoute from "./tabs-demo/tabsDemoRoute";
import videoPlayerDemoRoute from "./video-player-demo/videoPlayerDemoRoute";
import { DevelopmentPlaygroundNavigationItem } from "./utils/developmentPlaygroundTypes";
import draftRoute from "./draft/draftRoute";
import orientationPlayerDemoRoute from "./orientation-player/orientationPlayerDemoRoute";
import audioPlayerDemoRoute from "./audio-player/audioPlayerDemoRoute";

const developmentPlaygroundNavigationItems: DevelopmentPlaygroundNavigationItem[] =
  [
    {
      pathName: "charts",
      routeElement: chartsDemoRoute,
      title: `Charts`,
    },
    {
      pathName: "tabs",
      routeElement: tabsDemoRoute,
      title: "Tabs",
    },
    {
      pathName: "video-player",
      routeElement: videoPlayerDemoRoute,
      title: `Video Player`,
    },
    {
      pathName: "orientation",
      routeElement: orientationPlayerDemoRoute,
      title: "Orientation Player",
    },
    {
      pathName: "audio-player",
      routeElement: audioPlayerDemoRoute,
      title: "Audio Player",
    },
    {
      pathName: "draft",
      routeElement: draftRoute, // <--- Test your components here
      title: " Draft",
    },
  ];

export default developmentPlaygroundNavigationItems;
