import { graphql } from "babel-plugin-relay/macro";
import { useSuspenseQuery } from "@apollo/client";
import React, { FC, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { message } from "antd";
import withEmployeeData, {
  EmployeeProps,
} from "src/common/components/EmployeeDataHOC";
import { useGetGcSubsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import SelectSubAdminsToUploadJHAsModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/SelectSubAdminsToUploadJHAsModal";
import SelectSubcontractorModal, {
  SelectSubcontractorModalRef,
} from "src/common/components/dialogs/SelectSubcontractorModal";
import Button from "src/common/components/general/button/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import ProjectSubcontractorTableNew from "src/common/components/tables/ProjectSubcontractorTableNew";
import { GCSubcontractorsQuery } from "src/common/types/generated/relay/GCSubcontractorsQuery.graphql";
import insertSubcontractorsMutation from "src/common/api/relay/mutations/InsertSubcontractors";
import { InsertSubcontractorsMutation } from "src/common/types/generated/relay/InsertSubcontractorsMutation.graphql";
import { ConnectionHandler } from "relay-runtime";
import * as uuid from "uuid";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import GCSendInviteModal, {
  GCSendInviteModalRef,
} from "src/utility-features/invitations/GCSendInviteModal";
import { auth } from "src/common/functions/firebase";
import { useParams } from "react-router-dom";

const query = graphql`
  query GCSubcontractorsQuery($projectId: uuid!, $userId: uuid) {
    project_location_connection(
      where: {
        project_id: { _eq: $projectId }
        archived_at: { _is_null: true }
      }
      order_by: { name: { en: asc } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
        }
      }
    }
    project_subcontractor_connection(
      where: { project_id: { _eq: $projectId } }
      order_by: { subcontractor: { name: asc } }
      first: 10000
    )
      @connection(
        key: "GCSubcontractorsQuery_project_subcontractor_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          subcontractor_id
          project {
            pk: id @__clientField(handle: "pk")
            name
          }
          project_subcontractor_onsite_periods(
            where: { closed_at: { _is_null: true } }
          ) {
            id
          }
          project_subcontractor_locations {
            id
            pk: id @__clientField(handle: "pk")
            project_location_id
            is_sub_active
          }
          project_crews(where: { deleted_at: { _is_null: true } }) {
            id
          }
          subcontractor {
            pk: id @__clientField(handle: "pk")
            name
            subcontractor_employees_aggregate {
              aggregate {
                count
              }
            }
            trade {
              name
            }
            lib_tasks: tasks_aggregate(
              where: {
                project_id: { _is_null: true }
                is_pending_acceptance: { _eq: false }
                report_id: { _is_null: true }
                deleted_at: { _is_null: true }
              }
            ) {
              aggregate {
                count
              }
            }
            proj_tasks: tasks_aggregate(
              where: {
                project_id: { _eq: $projectId }
                report_id: { _is_null: true }
                deleted_at: { _is_null: true }
              }
            ) {
              aggregate {
                count
              }
            }
            lib_sds: safety_data_sheets_aggregate(
              where: {
                project_id: { _is_null: true }
                deleted_at: { _is_null: true }
              }
            ) {
              aggregate {
                count
              }
            }
            proj_sds: safety_data_sheets_aggregate(
              where: {
                project_id: { _eq: $projectId }
                deleted_at: { _is_null: true }
              }
            ) {
              aggregate {
                count
              }
            }
            lib_sssp: safety_plans_aggregate(
              where: {
                project_id: { _is_null: true }
                deleted_at: { _is_null: true }
              }
            ) {
              aggregate {
                count
              }
            }
            proj_sssp: safety_plans_aggregate(
              where: {
                project_id: { _eq: $projectId }
                deleted_at: { _is_null: true }
              }
            ) {
              aggregate {
                count
              }
            }
          }
          project_workers_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
    subcontractor_employee_connection(order_by: { user: { name: asc } }) {
      edges {
        node {
          subcontractor_id
          user_id
          user {
            pk: id @__clientField(handle: "pk")
            id
            name
            email
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          name
        }
      }
    }
    user_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          name
          pk: id @__clientField(handle: "pk")
          id
          employee {
            general_contractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            employee_projects(where: { project_id: { _eq: $projectId } }) {
              project {
                id
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
        }
      }
    }
    project_subcontractor_employee_connection(
      where: { project_id: { _eq: $projectId } }
      first: 10000
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

type subAdminForEmailDataType =
  GCSubcontractorsQuery["response"]["subcontractor_employee_connection"]["edges"];
const GCSubcontractors: FC<EmployeeProps> = ({ employeeData }) => {
  const projectId = useParams()["projectId"] as string;
  const employee = employeeData.user_by_pk?.employee;
  if (!employee) {
    throw new Error("User must be logged in as employee");
  }
  const general_contractor_id = employee.general_contractor.id;
  const [selectSubAdminsOpenModal, setSelectSubAdminsOpenModal] = useState<
    string | undefined
  >(undefined);
  const [selctSubAdminUsers, setSelectSubAdminUsers] = useState<
    { id: string; name: string }[] | undefined
  >(undefined);
  const { data: gcSubs, error } = useGetGcSubsQuery({
    variables: {
      where: {
        subcontractor_projects: {
          project: { general_contractor_id: { _eq: general_contractor_id } },
        },
      },
    },
  });
  if (error) {
    throw error;
  }

  const [addSubs] = useAsyncMutation<InsertSubcontractorsMutation>(
    insertSubcontractorsMutation,
  );

  const data = useLazyLoadQuery<GCSubcontractorsQuery>(query, {
    projectId: projectId!,
    userId: auth.currentUser?.uid,
  });

  const projectName = data.project_connection.edges[0].node.name;
  const gcName =
    data.user_connection.edges[0].node.employee?.general_contractor.name;

  const subsOnProject = data.project_subcontractor_connection.edges.map(
    (sub) => sub.node.subcontractor_id,
  );

  const selectSubsModal = useRef<SelectSubcontractorModalRef>(null);
  const sendInvite = useRef<GCSendInviteModalRef>(null);
  const [fetchKey, setFetchKey] = useState(0);

  return (
    <div className="flex flex-col w-full gap-1">
      <SelectSubcontractorModal
        onSubcontractorInserted={() => setFetchKey((value) => value + 1)}
        ref={selectSubsModal}
        title={"Add Subs to Project"}
        subsOnProject={subsOnProject}
        subsUnderGc={gcSubs?.subcontractor || []}
        onSubmit={async (values) => {
          await addSubs({
            // awaitRefetchQueries: true,
            variables: {
              subcontractorObjects: values.subcontractorIds.map((sid) => ({
                project_id: projectId,
                subcontractor_id: sid,
              })),
              // projectCrewObjects: [],
            },
            updater: (store) => {
              const node = store.getRootField("insert_project_subcontractor");
              const conn = ConnectionHandler.getConnection(
                store.getRoot(),
                "SelectSubcontractorModalQuery_project_subcontractor_connection",
              );
              if (conn && node) {
                node.getLinkedRecords("returning").forEach((val) => {
                  const edge = store.create(uuid.v4(), "edge");
                  edge.setLinkedRecord(val, "node");
                  ConnectionHandler.insertEdgeAfter(conn, edge);
                });
              }
            },
          })
            .then(() => {
              setFetchKey((key) => key + 1);
              message.success("Subcontractor added successfully");
            })
            .catch((e) => {
              console.log(e);
              message.error("Error in adding subcontractor");
            });
        }}
      />
      {selctSubAdminUsers && selectSubAdminsOpenModal && (
        <SelectSubAdminsToUploadJHAsModal
          iniviteText
          subcontractorId={selectSubAdminsOpenModal}
          type="gc_project_jha"
          subAdminUsersData={selctSubAdminUsers}
          sentFromCompanyName={gcName ?? ""}
          projectName={projectName}
          projectId={projectId}
          visible={!!selectSubAdminsOpenModal}
          onCancel={() => setSelectSubAdminsOpenModal(undefined)}
        />
      )}

      <GCSendInviteModal ref={sendInvite} projectId={projectId} />
      <div className="flex flex-row items-center gap-1">
        <Button
          onClick={() => selectSubsModal.current?.open()}
          //loading={insertingSubs}
          label={`Add Subcontractors`}
        ></Button>
        <Button
          onClick={() => sendInvite.current?.open()}
          //loading={insertingSubs}
          label={`Invite Subcontractor Administrator`}
        ></Button>
      </div>
      <div className="flex flex-row w-full h-full overflow-y-scroll">
        <ProjectSubcontractorTableNew
          projectId={projectId}
          data={data}
          fetchKey={fetchKey}
          showsubadmininvite
          onclicked={(record) => {
            if (record.subcontractor_id) {
              const subAdminEmailData =
                data.subcontractor_employee_connection.edges.filter(
                  (value) =>
                    value.node.subcontractor_id == record.subcontractor_id,
                );
              setSelectSubAdminUsers(
                subAdminEmailData.map((data) => ({
                  id: data.node.user_id,
                  name: data.node.user.name,
                })),
              );
              setSelectSubAdminsOpenModal(record.subcontractor_id);
            }
          }}
        />
      </div>
    </div>
  );
};

export default withCustomSuspense(withEmployeeData(GCSubcontractors));
