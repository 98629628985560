import { IconPlus, IconSettings, IconUser } from "@tabler/icons";
import { Input } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, useEffect, useRef, useState } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import CustomButton from "src/common/components/general/button/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import {
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import OrientationProjectEmployeeTable, {
  ColumnKeys as EmployeeColumnKeys,
} from "./components/OrientationProjectEmployeeTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { getNextHardHatNumber } from "src/common/functions/hardHatHelpers";
import { GCProjectOrientationStatus_SetHardHatNumberMutation } from "src/common/types/generated/relay/GCProjectOrientationStatus_SetHardHatNumberMutation.graphql";
import { GCProjectOrientationStatus_UpdateSettingsMutation } from "src/common/types/generated/relay/GCProjectOrientationStatus_UpdateSettingsMutation.graphql";
import {
  OrientationProjectWorkerTableQuery,
  project_employee_bool_exp,
  project_worker_bool_exp,
  user_orientation_bool_exp,
  worker_certification_bool_exp,
} from "src/common/types/generated/relay/OrientationProjectWorkerTableQuery.graphql";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import capitalize from "src/common/functions/capitalize";
import OrientationInviteModal from "src/root/routes/views/subcontractor/people/workers/components/OrientationInviteModal";
import OrientationProjectWorkerTable, {
  ColumnKeys,
  DConnection,
} from "./components/OrientationProjectWorkerTable";
import PerformWorkerFunctionsButton from "src/common/components/modals/PerformWorkerFunctionsButton";

const setHardHatNumberMutation = graphql`
  mutation GCProjectOrientationStatus_SetHardHatNumberMutation(
    $projectId: uuid!
    $userId: uuid!
    $hardHatNumber: String!
  ) {
    update_project_worker(
      where: { project_id: { _eq: $projectId }, worker_id: { _eq: $userId } }
      _set: { hard_hat_number: $hardHatNumber }
    ) {
      affected_rows
    }
    update_project_employee(
      where: { project_id: { _eq: $projectId }, employee_id: { _eq: $userId } }
      _set: { hard_hat_number: $hardHatNumber }
    ) {
      affected_rows
    }
  }
`;

const GetFullID = (table: string, id: string) =>
  btoa(`[1, "public", "${table}", "${id}"]`);

const updateProjectMutation = graphql`
  mutation GCProjectOrientationStatus_UpdateSettingsMutation(
    $_set: project_set_input
    $projectId: uuid!
  ) {
    update_project_by_pk(_set: $_set, pk_columns: { id: $projectId }) {
      id
      next_hard_hat_number
    }
  }
`;

interface GCProjectOrientationStatusProps {
  projectId: string;
  data: useSiteOrientationGcDashboardDataQuery$data;
}

// TODO Why are this values defined outside the component?
let selectedCertificateIds: Array<string> | null;
let selectedCertExpiryDays: number | null;

const GCProjectOrientationStatus: FC<GCProjectOrientationStatusProps> = ({
  projectId,
  data,
}) => {
  // $projectId: uuid!

  // $projectId: uuid!
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [updateProject, isUpdating] =
    useAsyncMutation<GCProjectOrientationStatus_UpdateSettingsMutation>(
      updateProjectMutation,
    );
  const [archivedFilter, setArchivedFilter] = useState<project_worker_bool_exp>(
    { archived_at: { _is_null: true } },
  );
  const [orientationDateFilter, setOrientationDateFilter] =
    useState<project_worker_bool_exp>({});
  const [orientationDateEmployeeFilter, setOrientationDateEmployeeFilter] =
    useState<project_employee_bool_exp>({ user_orientation: {} });
  const [customFilterWhere, setcustomFilterWhere] =
    useState<project_worker_bool_exp>({});
  const [statusFilterWhere, setStatusFilterWhere] = useState<
    Map<string, project_worker_bool_exp>
  >(new Map());
  const [statusFilterEmployeeWhere, setStatusFilterEmployeeWhere] =
    useState<project_employee_bool_exp>({});
  // const [upsertDrugTestInWorkerOrientation, isUpserting] =
  //   useAsyncMutation<VerifyDrugtestUpsertWorkerOrientationMutation>(
  //     upsertWorkerOrientationMutation
  //   );
  const projectData = (data?.project_connection.edges || [])[0]?.node;
  if (!projectData) {
    throw new Error("Project id not found, ");
  }

  const registerdTableRef = useRef<DataScrollTableRef>(null);
  const registerdEmployeeTableRef = useRef<DataScrollTableRef>(null);

  const orientations = (data?.orientation_connection.edges || []).map(
    (v) => v.node,
  );
  const refetchBoth = () => {
    registerdTableRef.current?.refetch();
  };
  const allRequiredOrientations = orientations.filter(
    (o) =>
      o.type !== "universal" &&
      o.project_orientations[0] &&
      o.project_orientations[0].required_by_all_workers,
  );
  const requiredUniversalOrientations = orientations.filter(
    (o) => o.type === "universal",
  );
  const hasSlides = allRequiredOrientations.length > 0;
  const hasInPerson = projectData.in_person_orientation;
  const refetch = () => {
    registerdTableRef.current?.refetch();
  };
  // console.log("render", projectData);

  let registeredOrientationWhere: user_orientation_bool_exp = {};
  if (hasSlides && hasInPerson) {
    registeredOrientationWhere = {
      _or: [
        { orientated_at: { _is_null: true } },
        { in_person_orientated_at: { _is_null: true } },
      ],
    };
  } else if (hasSlides) {
    registeredOrientationWhere = { orientated_at: { _is_null: true } };
  } else if (hasInPerson) {
    registeredOrientationWhere = {
      in_person_orientated_at: { _is_null: true },
    };
  }
  const nextHH = projectData.next_hard_hat_number ?? undefined;
  const [setHardHatNumber] =
    useAsyncMutation<GCProjectOrientationStatus_SetHardHatNumberMutation>(
      setHardHatNumberMutation,
    );
  const onSaveNextHH = (nextHH: any) =>
    updateProject({
      variables: {
        projectId,
        _set: {
          next_hard_hat_number: nextHH,
        },
      },
      optimisticResponse: {
        update_project_by_pk: {
          id: projectData.id,
          next_hard_hat_number: nextHH,
        },
      },
    });

  const nextHardHatRef = useRef<React.ElementRef<typeof Input>>(null);
  const nextHardHatSave = useRef<NodeJS.Timeout>();
  const environment = useRelayEnvironment();
  const [generating, setGeneraing] = useState(false);

  useEffect(() => {
    // if (nextHH) {
    //   nextHardHatRef.current && nextHardHatRef.current.setValue(`${nextHH}`);
    // }
    return () => cancelPendingSave();
  }, [nextHH]);

  const generateHardHatNumber = async (userId: string) => {
    const valueStr = nextHardHatRef.current?.input?.value;
    const value = valueStr ? parseInt(valueStr) : 1;
    const hardHat = await getNextHardHatNumber(environment, projectId, value);
    await setHardHatNumber({
      variables: {
        projectId,
        userId,
        hardHatNumber: `${hardHat}`,
      },
      optimisticResponse: {
        update_project_worker: { affected_rows: 0 },
        update_project_employee: { affected_rows: 0 },
      },
    }).then(
      (r) => {
        if (!!nextHardHatRef.current?.input) {
          nextHardHatRef.current.input.value = `${hardHat + 1}`;
        }
        onSaveNextHH(hardHat + 1);
        refetch();
        return false;
      },
      (e) => {
        console.log("error", e.message);
        alert("Failed to generate number, Error: " + e.message);
      },
    );
  };
  const cancelPendingSave = () => {
    if (nextHardHatSave.current) {
      clearTimeout(nextHardHatSave.current);
      nextHardHatSave.current = undefined;
    }
  };

  const saveNextHardHat = () => {
    nextHardHatSave.current = undefined;
    const valueStr = nextHardHatRef.current?.input?.value;
    const value = valueStr ? parseInt(valueStr) || null : null;
    onSaveNextHH(value);
  };

  useEffect(() => {
    if (!nextHH) {
      getNextHardHatNumber(environment, projectId).then(
        (n) => {
          if (nextHardHatRef?.current?.input)
            nextHardHatRef.current.input.value = `${n}`;
        },
        (e) => console.error("failed to get number ", e),
      );
    }
  }, []);
  const hasUniversal =
    projectData.agc_universal_orientation &&
    requiredUniversalOrientations.length > 0;
  const nonOrientedWorkerWhere: project_worker_bool_exp = {
    _or: [
      ...(hasUniversal
        ? [
            {
              _not: {
                user: {
                  universal_orientations: {
                    universal_orientated_at: { _is_null: false },
                  },
                },
              },
            },
          ]
        : []),
      { _not: { user_orientation: {} } },
      {
        user_orientation: {
          ...registeredOrientationWhere,
          completed_at: { _is_null: true },
        },
      },
    ],
  };
  const completedOrientationFully: project_worker_bool_exp = {
    ...(hasUniversal
      ? {
          user: {
            universal_orientations: {
              universal_orientated_at: { _is_null: false },
            },
          },
        }
      : {}),
    user_orientation: {
      _or: [
        { completed_at: { _is_null: false } },
        {
          ...(hasSlides ? { orientated_at: { _is_null: false } } : {}),
          ...(projectData.in_person_orientation
            ? {
                in_person_orientated_at: {
                  _is_null: false,
                },
              }
            : {}),
        },
      ],
    },
  };
  const orientedWorkerWhere: project_worker_bool_exp = {
    _and: [
      {
        ...completedOrientationFully,
        ...(projectData.assign_hard_hat
          ? {
              _and: [
                { hard_hat_number: { _is_null: false } },
                // { hard_hat_number: { _neq: "" } },
              ],
            }
          : {}),
      },
    ],
  };

  const addWorkerHHWhere: project_worker_bool_exp = {
    ...completedOrientationFully,
    ...(projectData.assign_hard_hat
      ? {
          _or: [
            { hard_hat_number: { _is_null: true } },
            // { hard_hat_number: { _eq: "" } },
          ],
        }
      : {}),
  };
  const corporateOrientations = data.orientation_connection.edges.filter(
    (o) =>
      o.node.type === "corporate" &&
      o.node.project_orientations[0] &&
      o.node.project_orientations[0].required_by_all_workers,
  );
  const fourteenDaysBefore = dayjs().subtract(14, "days").startOf("day");

  return (
    <div className="w-full h-full overflow-y-auto">
      <OrientationInviteModal
        selectedProject={{ id: projectId, name: projectData.name }}
        visible={openInviteModal}
        reset={() => setOpenInviteModal(false)}
      />

      <OrientationProjectWorkerTable
        ref={registerdTableRef}
        title="Orientation Worker Log"
        loadAll
        showArchiveButton
        searchString=""
        showSubAdminTag
        requiredOrientationIds={allRequiredOrientations.map((p) => p.pk)}
        hasInPerson={hasInPerson}
        hasSlides={hasSlides}
        subs={projectData.project_subcontractors}
        titles={data.worker_title_connection.edges}
        hasUniversal={
          projectData.agc_universal_orientation &&
          requiredUniversalOrientations.length > 0
        }
        defaultSortColumnKey="status"
        assignHardHat={projectData.assign_hard_hat}
        showOnsiteTag={projectData.show_onsite_for_not_oriented_workers}
        searchDataIndex={["name"]}
        allowEditHH
        showOrientationTag={hasSlides && hasInPerson}
        backgroundTracker={projectData.background_check_tracker}
        excludedKeys={[
          "project",
          "drugTest",
          ...(projectData.agc_universal_orientation || hasInPerson || hasSlides
            ? []
            : (["status"] as ColumnKeys[])),
          ...(projectData.agc_universal_orientation
            ? []
            : (["universal_orientated_at"] as ColumnKeys[])),
          ...(projectData.perform_drugtest
            ? []
            : (["drugtestDate"] as ColumnKeys[])),
          ...(corporateOrientations.length > 0
            ? []
            : (["inPersonOrientatedDate"] as ColumnKeys[])),
        ]}
        startTime={
          projectData.show_onsite_for_not_oriented_workers
            ? fourteenDaysBefore.format()
            : undefined
        }
        nextHH={nextHH}
        startDate={
          projectData.show_onsite_for_not_oriented_workers
            ? fourteenDaysBefore.format("YYYY-MM-DD")
            : undefined
        }
        projectId={projectId}
        headerComponent={
          <div className="flex justify-between">
            <div>
              <CustomButton
                onClick={() => setOpenInviteModal(true)}
                label="Orientation Invite"
                icon={IconPlus}
              />
            </div>
            <div className="flex flex-row items-baseline ml-1">
              <span className="mr-1">Next Hard Hat #:</span>
              <Input
                ref={nextHardHatRef}
                defaultValue={nextHH}
                onChange={(e) => {
                  cancelPendingSave();
                  saveNextHardHat();
                  // nextHardHatSave.current = setTimeout(saveNextHardHat, 500);
                }}
                onPressEnter={() => {
                  cancelPendingSave();
                  saveNextHardHat();
                }}
                className="w-6 bg-interactive-light"
              />
            </div>
            <PerformWorkerFunctionsButton
              type="noData"
              projWorkerWhere={{
                project_id: { _eq: projectId },
                is_last: { _eq: true },
              }}
              isGC
              refetch={() => registerdTableRef.current?.refetch()}
            />
          </div>
        }
        where={{
          project_id: { _eq: projectId },
          is_last: { _eq: true },
          deleted_at: { _is_null: true },
          _or: [
            { user: { role: { _eq: "worker" } } },
            {
              user_orientation: {
                _or: [
                  { completed_at: { _is_null: false } },
                  {
                    ...(hasSlides
                      ? { orientated_at: { _is_null: false } }
                      : {}),
                    ...(projectData.in_person_orientation
                      ? {
                          in_person_orientated_at: {
                            _is_null: false,
                          },
                        }
                      : {}),
                  },
                ],
              },
            },
          ],
          _and: [
            customFilterWhere,
            orientationDateFilter,
            statusFilterWhere.size === 0
              ? {}
              : { _or: Array.from(statusFilterWhere.values()) },
            archivedFilter,
          ],
        }}
        onAddHH={(user_id: string) => {
          setGeneraing(true);
          generateHardHatNumber(user_id).finally(() => setGeneraing(false));
          // console.log(user_id);
        }}
        extraColumns={[
          ...((projectData.perform_drugtest
            ? [
                {
                  title: "Drug Test Status",
                  size: "ml",
                  key: "drugTest",
                  dataIndex: ["user_orientation", "drug_test", "status"],
                  render: (val) => (val ? capitalize(val) : ""),
                },
              ]
            : []) as DataScrollTableProps<
            DConnection,
            ColumnKeys,
            OrientationProjectWorkerTableQuery
          >["columns"]),
        ]}
        customFilters={[
          // ...((projectData.orientationEmployees.length > 0
          //   ? [
          //       // {
          //       //   type: "checkbox",
          //       //   title: "User Type",
          //       //   removeFilter: () => {
          //       //     setUserType({});
          //       //   },
          //       //   options: [
          //       //     {
          //       //       option: "Worker",
          //       //       onApply: () => {
          //       //         setUserType((prev) => {
          //       //           if (prev._or && prev?._or.length === 1) {
          //       //             return { _or: [{ worker: {} }, { employee: {} }] };
          //       //           } else {
          //       //             return { _or: [{ worker: {} }] };
          //       //           }
          //       //         });
          //       //       },
          //       //       onCancel: () => {
          //       //         setUserType((prev) => {
          //       //           if (prev._or && prev?._or.length === 2) {
          //       //             return { _or: [{ employee: {} }] };
          //       //           } else {
          //       //             return {};
          //       //           }
          //       //         });
          //       //       },
          //       //     },
          //       //     {
          //       //       option: "Other: O,A,C, etc.",
          //       //       onApply: () => {
          //       //         setUserType((prev) => {
          //       //           if (prev._or && prev?._or.length === 1) {
          //       //             return { _or: [{ worker: {} }, { employee: {} }] };
          //       //           } else {
          //       //             return { _or: [{ employee: {} }] };
          //       //           }
          //       //         });
          //       //       },
          //       //       onCancel: () => {
          //       //         setUserType((prev) => {
          //       //           if (prev._or && prev?._or.length === 2) {
          //       //             return { _or: [{ worker: {} }] };
          //       //           } else {
          //       //             return {};
          //       //           }
          //       //         });
          //       //       },
          //       //     },
          //       //   ],
          //       // },
          //     ]
          //   : []) as CustomFilterType[]),
          {
            type: "checkbox",
            title: "Status",
            removeFilter: () => {
              setStatusFilterWhere(() => {
                return new Map();
              });
            },
            options: [
              {
                option: "Completed Orientation",
                onApply: () => {
                  setStatusFilterWhere((prev) => {
                    const newMap = new Map(prev.entries());
                    newMap.set("orientedWorkerWhere", orientedWorkerWhere);
                    return newMap;
                  });
                },
                onCancel: () => {
                  setStatusFilterWhere((prev) => {
                    const newMap = new Map(prev.entries());
                    newMap.delete("orientedWorkerWhere");
                    return newMap;
                  });
                },
              },
              {
                option: "Not Completed",
                onApply: () => {
                  setStatusFilterWhere((prev) => {
                    const newMap = new Map(prev.entries());
                    newMap.set(
                      "nonOrientedWorkerWhere",
                      nonOrientedWorkerWhere,
                    );
                    return newMap;
                  });
                },
                onCancel: () => {
                  setStatusFilterWhere((prev) => {
                    const newMap = new Map(prev.entries());
                    newMap.delete("nonOrientedWorkerWhere");
                    return newMap;
                  });
                },
              },
              ...(projectData.assign_hard_hat
                ? [
                    {
                      option: "No Hard Hat #",
                      onApply: () => {
                        setStatusFilterWhere((prev) => {
                          const newMap = new Map(prev.entries());
                          newMap.set("addWorkerHHWhere", addWorkerHHWhere);
                          return newMap;
                        });
                      },
                      onCancel: () => {
                        setStatusFilterWhere((prev) => {
                          const newMap = new Map(prev.entries());
                          newMap.delete("addWorkerHHWhere");
                          return newMap;
                        });
                      },
                    },
                  ]
                : []),
              {
                option: "Employment Verified",
                onApply: () => {
                  setStatusFilterWhere((prev) => {
                    const newMap = new Map(prev.entries());
                    newMap.set("projectWorkerVerifiedWhere", {
                      worker: {
                        verified_at: { _is_null: false },
                        status: { _eq: "verified" },
                      },
                    });
                    return newMap;
                  });
                },
                onCancel: () => {
                  setStatusFilterWhere((prev) => {
                    const newMap = new Map(prev.entries());
                    newMap.delete("projectWorkerVerifiedWhere");
                    return newMap;
                  });
                },
              },
              {
                option: "Archived",
                onApply: () => {
                  setArchivedFilter(() => ({
                    archived_at: { _is_null: false },
                  }));
                },
                onCancel: () => {
                  setArchivedFilter(() => ({
                    archived_at: { _is_null: true },
                  }));
                },
              },
            ],
          } as CustomFilterType,
          ...((projectData.perform_drugtest
            ? [
                {
                  type: "radio",
                  title: "Drug Test",
                  removeFilter: () => {
                    setcustomFilterWhere((prev) => {
                      const newObj = { ...prev };
                      newObj.user_orientation && delete newObj.user_orientation;
                      newObj._not && delete newObj._not;
                      return { ...newObj };
                    });
                  },
                  options: [
                    {
                      option: "Eligible",
                      onClick: () => {
                        setcustomFilterWhere((prev) => {
                          const { _not, ...rest } = prev;

                          return {
                            ...rest,
                            user_orientation: {
                              drug_test_passed: { _eq: true },
                            },
                          };
                        });
                      },
                    },
                    {
                      option: "Noneligible",
                      onClick: () => {
                        setcustomFilterWhere((prev) => {
                          const { user_orientation, ...rest } = prev;
                          return {
                            ...rest,
                            _not: {
                              user_orientation: {
                                drug_test_passed: { _eq: true },
                              },
                            },
                          };
                        });
                      },
                    },
                  ],
                },
              ]
            : []) as CustomFilterType[]),
          {
            type: "radio",
            title: "Certificates",
            removeFilter: () => {
              setcustomFilterWhere((prev) => {
                selectedCertExpiryDays = null;
                if (
                  selectedCertificateIds &&
                  selectedCertificateIds.length > 0
                ) {
                  return {
                    ...prev,
                    worker: {
                      _and: selectedCertificateIds.map((id) => ({
                        worker_certifications: {
                          certification_id: { _eq: id },
                        },
                      })),
                    },
                  };
                } else {
                  const { worker, ...rest } = prev;
                  return rest;
                }
              });
            },
            options: [
              {
                option: "Expiring in < 30 days",
                onClick: () => {
                  let queryCondition: worker_certification_bool_exp;
                  selectedCertExpiryDays = 30;
                  const thirtyDaysAfter = dayjs()
                    .add(30, "days")
                    .endOf("day")
                    .format();
                  if (
                    selectedCertificateIds &&
                    selectedCertificateIds.length > 0
                  ) {
                    queryCondition = {
                      _and: [
                        { certification_id: { _in: selectedCertificateIds } },
                        {
                          expires_on: { _lt: thirtyDaysAfter },
                        },
                      ],
                    };
                  } else {
                    queryCondition = { expires_on: { _lt: thirtyDaysAfter } };
                  }
                  setcustomFilterWhere((prev) => ({
                    ...prev,
                    worker: {
                      _and: [{ worker_certifications: queryCondition }],
                    },
                  }));
                },
              },
              {
                option: "Expiring in < 60 days",
                onClick: () => {
                  let queryCondition: worker_certification_bool_exp;
                  selectedCertExpiryDays = 60;
                  const siztyDaysAfter = dayjs()
                    .add(60, "days")
                    .endOf("day")
                    .format();
                  if (
                    selectedCertificateIds &&
                    selectedCertificateIds.length > 0
                  ) {
                    queryCondition = {
                      _and: [
                        { certification_id: { _in: selectedCertificateIds } },
                        { expires_on: { _lt: siztyDaysAfter } },
                      ],
                    };
                  } else {
                    queryCondition = { expires_on: { _lt: siztyDaysAfter } };
                  }
                  setcustomFilterWhere((prev) => ({
                    ...prev,
                    worker: {
                      _and: [{ worker_certifications: queryCondition }],
                    },
                  }));
                },
              },
              {
                option: "Expiring in < 90 days",
                onClick: () => {
                  let queryCondition: worker_certification_bool_exp;
                  selectedCertExpiryDays = 90;
                  const ninetyDaysAfter = dayjs()
                    .add(90, "days")
                    .endOf("day")
                    .format();
                  if (
                    selectedCertificateIds &&
                    selectedCertificateIds.length > 0
                  ) {
                    queryCondition = {
                      _and: [
                        { certification_id: { _in: selectedCertificateIds } },
                        { expires_on: { _lt: ninetyDaysAfter } },
                      ],
                    };
                  } else {
                    queryCondition = { expires_on: { _lt: ninetyDaysAfter } };
                  }
                  setcustomFilterWhere((prev) => ({
                    ...prev,
                    worker: {
                      _and: [{ worker_certifications: queryCondition }],
                    },
                  }));
                },
              },
            ],
          },
          {
            title: "Orientated Between",
            type: "date-range",
            removeFilter: () => {
              setOrientationDateFilter((prev) => {
                const { user_orientation, ...rest } = prev;
                return rest;
              });
            },
            onOk: (fromMoment, toMoment) => {
              if (fromMoment && toMoment) {
                const from = dayjs(fromMoment).startOf("day");
                const to = dayjs(toMoment).endOf("day");
                setOrientationDateFilter(() => ({
                  user_orientation: {
                    _or: [
                      ...(hasInPerson
                        ? [
                            {
                              in_person_orientated_at: {
                                _gte: from.format(),
                                _lt: to.format(),
                              },
                            },
                          ]
                        : []),
                      ...(hasSlides
                        ? [
                            {
                              orientated_at: {
                                _gte: from.format(),
                                _lt: to.format(),
                              },
                            },
                          ]
                        : []),
                      {
                        completed_at: {
                          _gte: from.format(),
                          _lt: to.format(),
                        },
                      },
                    ],
                  },
                }));
              }
            },
          },
          {
            type: "select",
            title: "Training Certifications: Have",
            placeholder: "Certification/Training",

            removeFilter: () => {
              const selectedDaysAfter = dayjs()
                .add(selectedCertExpiryDays || 0, "days")
                .endOf("day")
                .format();
              setcustomFilterWhere((prev) => {
                selectedCertificateIds = null;
                if (selectedCertExpiryDays) {
                  return {
                    ...prev,
                    worker: {
                      _and: [
                        {
                          worker_certifications: {
                            expires_on: { _lt: selectedDaysAfter },
                          },
                        },
                      ],
                    },
                  };
                } else {
                  const { worker, ...rest } = prev;
                  return rest;
                }
              });
            },
            selectOptions: data.certification_connection.edges.map((p) => ({
              name: p.node.name,
              id: p.node.pk,
            })),
            onOk: (ids: Array<string>) => {
              selectedCertificateIds = ids;
              const selectedDaysAfter = dayjs()
                .add(selectedCertExpiryDays || 0, "days")
                .endOf("day")
                .format();
              if (selectedCertExpiryDays) {
                if (ids.length == 0) {
                  setcustomFilterWhere((prev) => ({
                    ...prev,
                    worker: {
                      _and: [
                        {
                          worker_certifications: {
                            expires_on: { _lt: selectedDaysAfter },
                          },
                        },
                      ],
                    },
                  }));
                } else {
                  setcustomFilterWhere((prev) => ({
                    ...prev,
                    worker: {
                      _and: ids.map((id) => ({
                        worker_certifications: {
                          expires_on: { _lt: selectedDaysAfter },
                          certification_id: { _eq: id },
                        },
                      })),
                    },
                  }));
                }
              } else {
                setcustomFilterWhere((prev) => ({
                  ...prev,
                  worker: {
                    _and: ids.map((id) => ({
                      worker_certifications: {
                        certification_id: { _eq: id },
                      },
                    })),
                  },
                }));
              }
            },
          },
          {
            type: "select",
            title: "Training Certifications: NOT Have",
            placeholder: "Certification/Training",
            removeFilter: () => {
              setcustomFilterWhere((prev) => {
                const newObj = { ...prev };
                newObj.user && delete newObj.user;
                return { ...newObj };
              });
            },
            selectOptions: data.certification_connection.edges.map((p) => ({
              name: p.node.name,
              id: p.node.pk,
            })),
            onOk: (ids: Array<string>) => {
              setcustomFilterWhere((prev) => ({
                ...prev,
                user: {
                  worker: {
                    _not: {
                      worker_certifications: { certification_id: { _in: ids } },
                    },
                  },
                },
              }));
            },
          },
        ]}
      />
      {projectData.orientationEmployees.length > 0 ? (
        <OrientationProjectEmployeeTable
          ref={registerdEmployeeTableRef}
          title="OAC Users "
          loadAll
          requiredOrientationIds={allRequiredOrientations.map((p) => p.pk)}
          hasInPerson={hasInPerson}
          hasSlides={hasSlides}
          // subs={projectData.project_subcontractors}
          // titles={data.worker_title_connection.edges}
          defaultSortColumnKey="status"
          assignHardHat={projectData.assign_hard_hat}
          // showOnsiteTag={projectData.show_onsite_for_not_oriented_workers}
          searchDataIndex={["name"]}
          allowEditHH
          showOrientationTag={hasSlides && hasInPerson}
          excludedKeys={[
            ...(hasInPerson || hasSlides
              ? []
              : (["status"] as EmployeeColumnKeys[])),
            "project",
            ...(corporateOrientations.length > 0
              ? []
              : (["inPersonOrientatedDate"] as EmployeeColumnKeys[])),
          ]}
          nextHH={nextHH}
          projectId={projectId}
          where={{
            project_id: { _eq: projectId },
            ...statusFilterEmployeeWhere,
            ...orientationDateEmployeeFilter,
          }}
          onAddHH={(user_id: string) => {
            setGeneraing(true);
            generateHardHatNumber(user_id).finally(() => setGeneraing(false));
            // console.log(user_id);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default withCustomSuspense(GCProjectOrientationStatus);
