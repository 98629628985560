import { Menu } from "antd";
import React from "react";
import developmentPlaygroundNavigationItems from "../developmentPlaygroundNavigationItems";
import { useNavigate } from "react-router-dom";

const items = developmentPlaygroundNavigationItems.map((item) => ({
  key: item.pathName,
  icon: item.icon,
  label: item.title,
}));

interface DevelopmentPlaygroundNavigationMenuProps {
  onChange?: (key: string) => void;
}

const DevelopmentPlaygroundNavigationMenu: React.FC<
  DevelopmentPlaygroundNavigationMenuProps
> = (props) => {
  const navigate = useNavigate();
  const select = (key: string) => {
    navigate(key);
    props.onChange && props.onChange(key);
  };
  return (
    <Menu
      // selectedKeys={selectedMenuItem ? [selectedMenuItem] : []}
      style={{ border: "none" }}
      mode="vertical"
      items={items.map((item) => ({
        key: item.key,
        label: item.label,
      }))}
      onSelect={({ key }) => {
        select(key);
      }}
    />
  );
};

export default DevelopmentPlaygroundNavigationMenu;
