import React, { useEffect } from "react";
import SiteOrientationSlidesViewer from "../../../../../../../components/slidesViewer/SiteOrientationSlidesViewer";
import ErrorMessage from "../../../../../../../../../utility-features/error-handling/components/ErrorMessage";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import sendHotjarEvent from "src/utility-features/event-tracking/hotjar/sendHotjarEvent";

import { useCurrentLanguage } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsOrientationViewerProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerStepsOrientationViewer: React.FC<
  SiteOrientationInPersonWorkerStepsOrientationViewerProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();

  const language = useCurrentLanguage();

  useEffect(() => {
    sendHotjarEvent("orientation_step_modules_player");
  }, []);

  return (
    <SiteFeatureStepLayout>
      {workerContext.orientee?.id ? (
        <SiteOrientationSlidesViewer
          projectId={workerContext.projectId}
          workerId={workerContext.orientee.id}
          goToNext={props.onNext}
          type={workerContext.type}
          lang={language}
        />
      ) : (
        <ErrorMessage message="Please, register first" />
      )}
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsOrientationViewer;
