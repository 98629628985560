import { IconEyeOff, IconX } from "@tabler/icons";
import React, { useState } from "react";
import Pill from "src/common/components/general/Pill";
import Button from "../general/button/Button";
import { RangeType } from "../tables/basic/CustomTableDateRangePicker";
import CustomFilterController, {
  CustomFilterType,
} from "./CustomFilterComponents/CustomFilterController";
import CustomFilterTopSection from "./CustomFilterComponents/CustomFilterTopSection";
import FilterControllerInput, {
  FilterControllerInputProps,
} from "./FilterControllerInput";

export type FilterSearchQueryType = {
  text: string;
  filterControllerId: string;
  inputId: string;
};

interface FilterControllerProps {
  inputs: ({ id: string } & FilterControllerInputProps)[];
  customFilters?: CustomFilterType[];
  dataIndexFilters?: {
    [key: string]: { val: string | boolean | string[]; onCancel: () => void };
  };
  searchFilters?: {
    placeholder?: string;
    onSubmit?: (searchQuery: FilterSearchQueryType) => void;
    inputOptions?: FilterSearchQueryType[];
  };
  tags?: {
    heading: string;
    appliedFilters: { label: string; id: string; onClick: () => void }[];
  }[];
  onCloseClick?: () => void;
}

const FilterController: React.FunctionComponent<FilterControllerProps> = (
  props
) => {
  const [appliedFiltersfilterMap, setAppliedFilter] = useState<{
    [key: string]:
      | boolean
      | string
      | Array<{ name: string; id: string }>
      | RangeType;
  }>({});
  const [removalFilters, setRemovalFilters] = useState<{
    [key: string]: (
      list?: Array<{ name: string; id: string }>,
      listItemToRemove?: string
    ) => void;
  }>({});
  // const handleTextInputSubmit = (textInputValue: string) => {
  //   const foundFilter = props.searchFilters.inputOptions.find(
  //     ({ text }) => text === textInputValue
  //   );
  //   if (!!foundFilter) {
  //     props.searchFilters.onSubmit(foundFilter);
  //     return true;
  //   }
  //   return false;
  // };

  return (
    <div
      className={`px-1.5 w-full h-full pb-1.5 bg-suplementary-1 rounded-1 pt-3 overflow-scroll`}
    >
      <div className="flex flex-col h-full">
        <div
          className={`flex flex-col pb-1 margin-gap-y-1 border-b-px border-suplementary-3`}
        >
          <div className="flex flex-row items-center justify-center gap-1">
            <span className="text-center text-2">Filters</span>
            {!!props.onCloseClick && (
              <div className="flex flex-row justify-center">
                <Button
                  onClick={props.onCloseClick}
                  icon={IconEyeOff}
                  small
                  secondary
                />
              </div>
            )}
          </div>
          <CustomFilterTopSection
            {...{
              dataIndexFilters: props.dataIndexFilters,
              removalFilters,
              setRemovalFilters,
              setAppliedFilter,
              appliedFiltersfilterMap,
            }}
          />

          {!!props.tags && !!props.tags.length && (
            <div className="block -m-0.5 w-full">
              {props.tags.map(({ heading, appliedFilters }, i) => (
                <div key={heading + i}>
                  {appliedFilters.length > 0 && (
                    <div>
                      {heading}
                      <br />{" "}
                      {appliedFilters.map(({ label, id, onClick }) => (
                        <div className="m-0.5" key={id}>
                          <Pill
                            onClick={onClick}
                            key={id}
                            label={label}
                            icon={IconX}
                            darker
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex-1">
          <div className="overflow-auto">
            <div className="text-1 font-accent text-center mt-0.5">
              Select Filters
            </div>
            {props.customFilters && (
              <div className="relative flex-grow pb-1">
                <CustomFilterController
                  customFilters={props.customFilters}
                  {...{
                    appliedFiltersfilterMap,
                    setAppliedFilter,
                    setRemovalFilters,
                  }}
                />
              </div>
            )}
            <div className="flex flex-col margin-gap-y-1.5  pt-1">
              {props.inputs.map((inputProps) => (
                <FilterControllerInput key={inputProps.id} {...inputProps} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterController;
