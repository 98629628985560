import { RefObject, useEffect, useRef } from "react";
import YoutubePlayer from "youtube-player";
import { Options, YouTubePlayer } from "youtube-player/dist/types";
import useThrowRenderError from "../../../../../utility-features/error-handling/useThrowRenderError";
import MediaPlayerError from "../../MediaPlayerError";

export interface PlayerEvent {
  target: EventTarget | null;
}

export interface StateChangeEvent extends PlayerEvent {
  data: number;
}

export interface PlaybackRateChangeEvent extends PlayerEvent {
  data: number;
}

export interface PlayerCallbacks {
  onPlay?: () => void;
  onReady?: (event: PlayerEvent) => void;
  onLoadFailed?: () => void;

  onStateChange?(event: StateChangeEvent): void;

  onPlaybackRateChange?(event: CustomEvent<any>): void;
}

const useYoutubePlayer = (
  frameRef: RefObject<HTMLIFrameElement>,
  config?: Options,
) => {
  const playerRef = useRef<YouTubePlayer | null>(null);
  const callbackRef = useRef<PlayerCallbacks | null>(null);
  const throwRenderError = useThrowRenderError();

  useEffect(() => {
    const frame = frameRef.current;
    if (!frame) throw new MediaPlayerError("Youtube frame is not found");
    const player = YoutubePlayer(frame, config);
    playerRef.current = player;
    player.on("stateChange", (e) => callbackRef.current?.onStateChange?.(e));
    player.on("ready", (e) => callbackRef.current?.onReady?.(e));
    player.on("playbackRateChange", (e) =>
      callbackRef.current?.onPlaybackRateChange?.(e),
    );

    player.on("error", (event) => {
      /*
      from https://developers.google.com/youtube/iframe_api_reference ...
      This event fires if an error occurs in the player. The API will pass an event object to the event listener function. That object's data property will specify an integer that identifies the type of error that occurred. Possible values are:
      2 – The request contains an invalid parameter value. For example, this error occurs if you specify a video ID that does not have 11 characters, or if the video ID contains invalid characters, such as exclamation points or asterisks.
      5 – The requested content cannot be played in an HTML5 player or another error related to the HTML5 player has occurred.
      100 – The video requested was not found. This error occurs when a video has been removed (for any reason) or has been marked as private.
      101 – The owner of the requested video does not allow it to be played in embedded players.
      150 – This error is the same as 101. It's just a 101 error in disguise!
      */
      const errorEvent = event as CustomEvent<any> & { data: number };
      const data = errorEvent.data;

      switch (data) {
        case 2:
          throwRenderError(new MediaPlayerError("The video id is invalid"));
          break;
        case 5:
          throwRenderError(
            new MediaPlayerError("The video cannot be played in this player"),
          );
          break;
        case 100:
          throwRenderError(new MediaPlayerError("The video is not found"));
          break;
        case 101:
        case 150:
          throwRenderError(
            new MediaPlayerError(
              "The video is not allowed to be played in embedded players",
            ),
          );
          break;
        default:
          throwRenderError(
            new MediaPlayerError(
              "Unknown error occurred while loading the youtube video",
            ),
          );
      }
      callbackRef.current?.onLoadFailed?.();
    });
    return () => {
      player.destroy();
    };
  }, []);
  return { playerRef, callbackRef };
};

export default useYoutubePlayer;
