import React, { FC, useState } from "react";
import Button from "src/common/components/general/button/Button";
import { DConnection } from "./CrewTable";
import { WarningFilled } from "@ant-design/icons";
import { IconDotsVertical, IconPencil, IconTrash } from "@tabler/icons";
import { Button as AntdButton, message, notification, Popover } from "antd";
import dayjs from "dayjs";
import { ConnectionHandler } from "relay-runtime";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import GetFullID from "src/common/functions/GetFullId";
import noPropagation from "src/common/functions/noPropagation";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { CrewProjectWorkerOptions_project_worker_connection_frag$data } from "src/common/types/generated/relay/CrewProjectWorkerOptions_project_worker_connection_frag.graphql";
import { CrewTableActionPopover_DeleteCrewMutation } from "src/common/types/generated/relay/CrewTableActionPopover_DeleteCrewMutation.graphql";
import EditCrewModal from "./modal/EditCrewModal";
import { graphql } from "babel-plugin-relay/macro";
import Icon from "src/common/components/general/Icon";
import usePermanentOnsiteSetting from "src/domain-features/sitesafety/utils/usePermanentOnsiteSetting";

const deleteSelectedCrew = graphql`
  mutation CrewTableActionPopover_DeleteCrewMutation(
    $crewWhere: project_crew_bool_exp!
    $removeWorkerWhere: project_worker_bool_exp!
    $_setCrew: project_crew_set_input
  ) {
    update_project_crew(where: $crewWhere, _set: $_setCrew) {
      returning {
        ...CrewFrag @relay(mask: false)
      }
    }
    update_project_worker(
      where: $removeWorkerWhere
      _set: { project_crew_id: null }
    ) {
      affected_rows
    }
  }
`;

const CrewTableActionPopover: FC<{
  row: DConnection["edges"][number]["node"];
  workersOnProject: CrewProjectWorkerOptions_project_worker_connection_frag$data;
}> = ({ row, workersOnProject }) => {
  const [deleteCrew, loading] =
    useAsyncMutation<CrewTableActionPopover_DeleteCrewMutation>(
      deleteSelectedCrew,
    );
  const [showEditCrewModal, setShowEditCrewModal] = useState<string>();
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const {
    setCrewOffSite,
    setCrewOnSite,
    loading: onsiteLoading,
  } = usePermanentOnsiteSetting(row.project.pk);
  return (
    <Popover
      open={open}
      placement="bottom"
      onOpenChange={handleOpenChange}
      key={row.pk}
      trigger={"click"}
      // closeOnChange={closeOnChange}
      content={
        <div className="flex flex-col gap-0.5">
          <Button
            green
            icon={IconPencil}
            onClick={noPropagation(() => {
              handleOpenChange(false);
              setShowEditCrewModal(row.pk);
            })}
            label="Edit"
          />
          <EditCrewModal
            visible={showEditCrewModal === row.pk}
            onCancel={() => setShowEditCrewModal(undefined)}
            workersOnProject={workersOnProject}
            crew={row}
            onFinish={() => {
              // if (ref && typeof ref !== "function")
              //   ref.current?.refetch();
            }}
          />
          <BPopconfirm
            cancelText={"Cancel"}
            okText={"Yes"}
            icon={<WarningFilled color="red" />}
            onConfirm={async () => {
              // delete crew
              const currentCrewWorkers = row.project_workers.map((w) => w.pk);

              const currentCrewId = row.pk;
              console.log(currentCrewId);
              try {
                await deleteCrew({
                  variables: {
                    crewWhere: { id: { _eq: currentCrewId } },
                    removeWorkerWhere: {
                      id: { _in: currentCrewWorkers },
                    },
                    _setCrew: {
                      deleted_at: dayjs().toISOString(),
                    },
                  },
                  updater: (store) => {
                    const crewTableConn = ConnectionHandler.getConnection(
                      store.getRoot(),
                      "CrewTableQuery_project_crew_connection",
                    );
                    if (crewTableConn) {
                      const edges = crewTableConn.getLinkedRecords("edges");
                      if (edges) {
                        ConnectionHandler.deleteNode(
                          crewTableConn,
                          GetFullID("project_crew", currentCrewId),
                        );
                      }
                    }
                  },
                });
                message.success("Deleted Crew Successfully");
              } catch (e) {
                console.log(e);
                notification.error({
                  message: "Couldn't delete " + row.name,
                  duration: 5000,
                  description:
                    e instanceof Error ? e.message : JSON.stringify(e),
                });
              }
            }}
            placement="left"
            title={
              <div className="w-24">
                <div className="text-semantic-negative font-accent">
                  Delete this Crew
                </div>
                <div>
                  This will delete this crew. It cannot be undone. <br />
                  Are you sure?
                </div>
              </div>
            }
          >
            <Button
              label="Delete"
              delete
              loading={loading}
              icon={IconTrash}
              onClick={noPropagation(() => {})}
            />
          </BPopconfirm>
          <Button
            label={
              row.project_subcontractor_crew_onsite_periods.length > 0
                ? "Mark Offsite"
                : "Mark Onsite"
            }
            loading={onsiteLoading}
            onClick={async () => {
              if (row.project_subcontractor_crew_onsite_periods.length > 0) {
                await setCrewOffSite(row.pk, row.subcontractor.pk);
              } else await setCrewOnSite(row.pk, row.subcontractor.pk);
            }}
          />
        </div>
      }
    >
      <AntdButton className="rounded-1 font-accent">
        <Icon icon={IconDotsVertical} color="interactive" />
      </AntdButton>
    </Popover>
  );
};
export default CrewTableActionPopover;
