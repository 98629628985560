import { Form, Select } from "antd";
import React, { useMemo, useRef } from "react";
import { fetchQuery, useRelayEnvironment } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import FormStepBlock from "src/common/components/FormStepBlock";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCAddNewProjectQuery$data } from "src/common/types/generated/relay/GCAddNewProjectQuery.graphql";
import { GCNewProjectStepSubcontractorsMutation } from "src/common/types/generated/relay/GCNewProjectStepSubcontractorsMutation.graphql";
import { GCNewProjectStepSubcontractorsSubadminWorkersQuery } from "src/common/types/generated/relay/GCNewProjectStepSubcontractorsSubadminWorkersQuery.graphql";
import { GCNewProjectStepType } from "../gcNewProjectStepType";
import Button from "src/common/components/general/button/Button";
import GCAddNewSubModal, {
  GCAddNewSubModalRef,
} from "../../subcontractors/sub-info/GCAddNewSubModal";

interface SelectSubcontractorModalValues {
  subcontractorIds: Array<string>;
}

interface Props {
  subcontractors: GCAddNewProjectQuery$data["subcontractor_connection"];
}

const SubSelectFormItem = withCustomSuspense<Props>(({ subcontractors }) => {
  const options = useMemo(
    () =>
      subcontractors.edges.map((e) => ({
        label: e.node.name,
        value: e.node.pk,
        key: e.node.pk,
      })),
    [subcontractors],
  );
  return (
    <Form.Item name="subcontractorIds" label="Add Subcontractors">
      <Select
        optionFilterProp="label"
        mode="multiple"
        style={{ width: "80%" }}
        options={options}
      />
    </Form.Item>
  );
});

const subadminWorkersQuery = graphql`
  query GCNewProjectStepSubcontractorsSubadminWorkersQuery($subIds: [uuid!]!) {
    user_connection(
      where: {
        subcontractor_employee: { subcontractor_id: { _in: $subIds } }
        worker: {}
      }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          subcontractor_employee {
            subcontractor_id
          }
        }
      }
    }
  }
`;

interface GCNewProjectStepSubcontractosProps extends GCNewProjectStepType {
  projectId: string;
  subcontractors: GCAddNewProjectQuery$data["subcontractor_connection"];
}

const GCNewProjectStepSubcontractos: React.FC<
  GCNewProjectStepSubcontractosProps
> = ({ subcontractors, projectId, ...props }) => {
  const [form] = Form.useForm();
  const environment = useRelayEnvironment();
  const addSubRef = useRef<GCAddNewSubModalRef>();
  const [insertProjectSubcontractors, insertingSubs] =
    useAsyncMutation<GCNewProjectStepSubcontractorsMutation>(
      graphql`
        mutation GCNewProjectStepSubcontractorsMutation(
          $project_subs: [project_subcontractor_insert_input!]!
          $project_workers: [project_worker_insert_input!]!
        ) {
          insert_project_subcontractor(objects: $project_subs) {
            affected_rows
          }
          insert_project_worker(objects: $project_workers) {
            affected_rows
          }
        }
      `,
    );

  const onNext = async () => {
    const values = await form.validateFields().catch(() => null);

    const subIds = values?.subcontractorIds ?? [];
    if (subIds.length > 0) {
      const response =
        fetchQuery<GCNewProjectStepSubcontractorsSubadminWorkersQuery>(
          environment,
          subadminWorkersQuery,
          {
            subIds: subIds,
          },
        );
      const workers = await response.toPromise();

      const project_workers = (workers?.user_connection?.edges || []).map(
        (edge) => ({
          worker_id: edge.node.pk,
          worker_role: "foreman",
          subcontractor_id: edge.node.subcontractor_employee?.subcontractor_id,
          project_id: projectId,
          can_insert_report: true,
        }),
      );

      await insertProjectSubcontractors({
        variables: {
          project_subs: subIds.map((subId: string) => ({
            project_id: projectId,
            subcontractor_id: subId,
          })),
          project_workers,
        },
      });
    }
    props.goToNext();
  };

  return (
    <FormStepBlock
      dataIsValid={true}
      description="Add your Subcontractors and Trade Partners"
      onNext={onNext}
      //onSkip={props.goToNext}
      nextButtonTitle="Done"
      title="Subcontractors' information"
      willBeChangedLater={
        <div>
          You can add more later. Go to{" "}
          <span className="font-accent">Subcontractors</span> in the left side
          menu
        </div>
      }
      filled={true}
      open={props.open}
    >
      <div>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
        >
          <SubSelectFormItem subcontractors={subcontractors} />
        </Form>
        <GCAddNewSubModal
          ref={addSubRef}
          projectId={projectId}
          onSubmit={() => {}}
        />
        <Button
          label=" + Create New"
          onClick={() => {
            addSubRef.current?.open();
          }}
        ></Button>
      </div>
    </FormStepBlock>
  );
};

export default GCNewProjectStepSubcontractos;
